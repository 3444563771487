import { useMemo, useEffect, createRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  ButtonKind,
  ButtonIconPosition,
  ButtonSize,
  Link,
  LinkKind,
  LinkVariant,
  LinkSize,
  Pill,
  PillColor,
  PhosphorIcon,
  TableBodyCell,
  TableRow,
  IconName,
  IconDirection,
  Tooltip,
  OpenAxis,
  Text,
  TextKind,
  TextElement,
} from 'design-system/components';
import { ScreeningStatus, PolicyRequirementType } from 'design-system/data';
import ScreeningStatusPill from 'components/ScreeningStatusPill';
import styles from '../policyRequirements.module.scss';
import { useRetailerBrandPaywall } from 'views/Brands/shared/RetailerBrandPaywall/context';

const PolicyRequirementRow = ({
  requirement,
  url,
  onClick,
  modalOpen,
  isActive,
}) => {
  const history = useHistory();
  const isBrandRequirement =
    requirement.requirementType === PolicyRequirementType.Brand;
  const isAttestable =
    requirement.screeningStatus === ScreeningStatus.NeedsAttention ||
    requirement.screeningStatus === ScreeningStatus.NeedsInformation;

  const buttonRef = createRef();

  useEffect(() => {
    if (isActive && !modalOpen) {
      buttonRef?.current?.focus();
    } else {
      buttonRef?.current?.blur();
    }
  }, [buttonRef, isActive, modalOpen]);

  const getScreeningStatusPillValue = () => {
    if (requirement.isPendingRetailerAction) return ScreeningStatus.Pending;
    return requirement.screeningStatus;
  };

  const ctaData = {
    isBrandRequirement,
    to: url,
    // Attest | Review | Attest for SKUs | Review SKUs
    text: `${isAttestable ? 'Attest' : 'Review'} ${
      !isBrandRequirement ? `${isAttestable ? 'for' : ''} SKUs` : ''
    }`,
  };

  const tableRowOnClick = ctaData.isBrandRequirement
    ? onClick
    : () => history.push(ctaData.to);

  const maximumPillCharacters = 40;
  const truncatedPillText = useMemo(() => {
    const text = requirement.category;
    if (typeof text !== 'string') return text;
    return text?.length > maximumPillCharacters
      ? `${text.slice(0, maximumPillCharacters)}...`
      : text;
  }, [requirement.category]);

  const { cannotReviewRequirements } = useRetailerBrandPaywall();

  return (
    <TableRow
      onClick={cannotReviewRequirements === false ? tableRowOnClick : undefined}
      className={isActive ? styles.active : ''}
    >
      <TableBodyCell>{requirement.name}</TableBodyCell>
      <TableBodyCell>
        <Tooltip
          openAxis={OpenAxis.Y}
          triggerElement={
            <Pill color={PillColor.Default}>{truncatedPillText}</Pill>
          }
          disabled={requirement.category?.length <= maximumPillCharacters}
        >
          {requirement.category}
        </Tooltip>
      </TableBodyCell>
      <TableBodyCell>
        <ScreeningStatusPill withDot status={getScreeningStatusPillValue()} />
      </TableBodyCell>
      <TableBodyCell>
        <div className={styles['cta-container']}>
          <Tooltip
            disabled={!cannotReviewRequirements}
            triggerElement={
              ctaData.isBrandRequirement ? (
                <Button
                  kind={
                    isAttestable ? ButtonKind.Alternate : ButtonKind.Tertiary
                  }
                  size={ButtonSize.Small}
                  iconName={IconName.ArrowV2}
                  iconPosition={ButtonIconPosition.Right}
                  iconDirection={IconDirection.Right}
                  onClick={onClick}
                  refProp={buttonRef}
                  disabled={cannotReviewRequirements}
                >
                  {ctaData.text}
                </Button>
              ) : (
                <Link
                  kind={LinkKind.Internal}
                  variant={
                    isAttestable ? LinkVariant.Alternate : LinkVariant.Tertiary
                  }
                  size={LinkSize.Small}
                  to={ctaData.to}
                  disabled={cannotReviewRequirements}
                >
                  <div className={styles.cta}>
                    {ctaData.text}
                    <span className={styles['icon-container']}>
                      <PhosphorIcon iconName="ArrowRight" size={18} />
                    </span>
                  </div>
                </Link>
              )
            }
          >
            <Text kind={TextKind.TextSMBold} element={TextElement.Span}>
              In order to review these requirements, please see the Complete
              Payment prompt in the Dashboard.
            </Text>
          </Tooltip>
        </div>
      </TableBodyCell>
    </TableRow>
  );
};

export default PolicyRequirementRow;
