import { useRef } from 'react';
import { arrayOf, object, func, bool } from 'prop-types';
import {
  Modal,
  ModalType,
  Toast,
  ToastKind,
  Text,
  TextKind,
  TextElement,
} from 'design-system/components';
import styles from './composable-conditions-modal.module.scss';
import InputField from './input-field';
import { isEmpty } from 'lodash';
import withLineBreaks from 'utils/withLineBreaks';

function ComposableConditionsModal({
  show,
  onDone,
  onClose,
  condition,
  inputs = [],
  newValues,
  isReadOnly,
  onInputChange,
  error,
}) {
  const errorToastRef = useRef();
  if (!isEmpty(error)) {
    errorToastRef.current.launchToast();
  }

  const getErrorMessage = (inputName) => {
    if (!isEmpty(error)) {
      return error[inputName];
    }
  };

  return (
    <Modal
      show={show}
      type={ModalType.Large}
      onClick={onClose}
      onCancel={onClose}
      cancelCtaLabel="Go back"
      onDone={onDone}
      doneCtaLabel={isReadOnly ? 'Done' : 'Save'}
      title="Enter data"
      showActionButtons
    >
      <div className={styles['modal-description']}>
        <Text
          kind={TextKind.TextSMBold}
          element={TextElement.P}
          className={styles['description-header']}
        >
          Requirement
        </Text>
        <Text kind={TextKind.TextMD} element={TextElement.P}>
          {withLineBreaks(condition?.attestationDescription)}
        </Text>
      </div>
      <div className={styles['modal-content']}>
        {inputs.map((input) => {
          return (
            <InputField
              key={input.name}
              currentValue={input.currentValue}
              onChange={onInputChange}
              isReadOnly={isReadOnly}
              placeholder={input.currentValue}
              inputName={input.name}
              inputDescription={input.description}
              inputLabel={input.label}
              inputSuffix={input.suffix}
              errorMessage={getErrorMessage(input.name)}
              value={isReadOnly ? null : newValues[input.name]}
              step={input.step}
            />
          );
        })}
      </div>
      <Toast
        kind={ToastKind.Error}
        title="Invalid submission"
        ref={errorToastRef}
      >
        Your input was invalid, please check your values and try again
      </Toast>
    </Modal>
  );
}

ComposableConditionsModal.propTypes = {
  show: bool,
  onDone: func.isRequired,
  onClose: func.isRequired,
  condition: object,
  inputs: arrayOf(object),
  isReadOnly: bool,
  onInputChange: func,
  error: object,
};

export default ComposableConditionsModal;
