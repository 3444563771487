import { createContext, useContext, useMemo, useState } from 'react';
import { Modal } from 'design-system/components';
import { PLACEHOLDER } from 'design-system/data';

const ModalContext = createContext(null);

export const useModalContext = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('Must be used with a ModalProvider');
  }
  return context;
};

export const ModalProvider = ({ children }) => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState(null);

  // Avoid content glitches by dismissing the modal and clearing the data if the screen is resized
  window.addEventListener('resize', () => {
    if (show) {
      setData(null);
      setShow(false);
    }
  });

  const context = useMemo(
    () => ({
      launchModal: (data) => {
        setData(data);
        setShow(true);
      },
      refreshModal: (data) => {
        setData(data);
      },
      dismissModal: () => {
        setData(null);
        setShow(false);
      },
    }),
    []
  );

  return (
    <ModalContext.Provider value={context}>
      <Modal
        {...data}
        show={show}
        title={data?.title || PLACEHOLDER}
        onClick={() => {
          setShow(false);
          data?.onClick && data.onClick();
        }}
        onCancel={() => {
          !data?.overrideOnCancelAutoDismiss && setShow(false);
          data?.onCancel && data.onCancel();
        }}
        onDone={() => {
          !data?.overrideOnDoneAutoDismiss && setShow(false);
          data?.onDone && data.onDone();
        }}
      >
        {data?.contents}
      </Modal>
      {children}
    </ModalContext.Provider>
  );
};
