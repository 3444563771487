import { MixpanelAnalyticsVendor } from './MixpanelAnalyticsVendor';
import { DatadogAnalyticsVendor } from './DatadogAnalyticsVendor';
import { SegmentAnalyticsVendor } from './SegmentAnalyticsVendor';

const mixpanelAnalyticsVendor = new MixpanelAnalyticsVendor();
const datadogAnalyticsVendor = new DatadogAnalyticsVendor();
const segmentAnalyticsVendor = new SegmentAnalyticsVendor();

// Register user properties
export const analyticsRegisterUser = (properties) => {
  mixpanelAnalyticsVendor.register(properties);
};

// Track event with associated properties
export const analyticsTrackEvent = (properties) => {
  mixpanelAnalyticsVendor.track(properties);
  datadogAnalyticsVendor.track(properties);
};

// Use email to connect existing users to anonymous public site users
export const linkToAnonUserByEmail = (email) => {
  if (email) segmentAnalyticsVendor.identifyByEmail(email);
}; // Use email and user id to connect newly signed up users to anonymous public site users
export const linkToAnonUserByUserInfo = (user) => {
  if (user) segmentAnalyticsVendor.identifyByUser(user);
};
