// Function to get text width from an input element
export function getTextWidthFromInput(
  inputElement: HTMLInputElement,
  text: string
): number {
  const fontSize = window.getComputedStyle(inputElement).fontSize;
  const fontFamily = window.getComputedStyle(inputElement).fontFamily;
  const fontStyle = `${fontSize} ${fontFamily}`;

  return getTextWidth(text, fontStyle);
}

// Internal function to measure text width using a canvas
function getTextWidth(text: string, font: string): number {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  // TypeScript check to ensure context is not null
  if (!context) {
    throw new Error('Failed to get 2D context');
  }

  context.font = font;
  const width = context.measureText(text).width;
  return width;
}
