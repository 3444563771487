import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { UrlParam, PolicyType, ScreeningStatus } from 'design-system/data';
import { sortDirection } from '../table';

export function createTimeFrameDataObject(data, methodToUse) {
  return data
    ? {
        '30d': methodToUse(data['30_days']),
        '60d': methodToUse(data['60_days']),
        '90d': methodToUse(data['90_days']),
        YTD: methodToUse(data.ytd),
        'All Time': methodToUse(data['all_time']),
      }
    : {};
}

export function appendParameter(params, name, value) {
  if (value && value.length !== 0) {
    params.append(name, value);
  }
}

export const useSetUrlSearchParameters = (
  tableState,
  currentPage,
  itemsPerPage
) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const currentParams = new URLSearchParams();
    if (tableState) {
      appendParameter(currentParams, UrlParam.Sort, tableState.sortBy);
      appendParameter(
        currentParams,
        UrlParam.Direction,
        sortDirection(tableState)
      );
    }
    appendParameter(currentParams, UrlParam.Page, currentPage);
    appendParameter(currentParams, UrlParam.Items, itemsPerPage);

    if (params.toString() !== currentParams.toString()) {
      history.replace(`${location.pathname}?${currentParams.toString()}`);
    }
  }, [tableState, currentPage, itemsPerPage, history, location]);
};

export function mergePolicies({ data, prioritizeRetailer = false }) {
  const retailerPolicies = data?.retailerPolicies?.map((policy) => ({
    ...policy,
    type: PolicyType.RetailerPolicy,
  }));
  const consumerBrandPolicies = data?.consumerBrandPolicies?.map((policy) => ({
    ...policy,
    type: PolicyType.ConsumerBrandPolicy,
  }));
  const consumerProductPolicies = data?.consumerProductPolicies?.map(
    (policy) => ({ ...policy, type: PolicyType.ConsumerProductPolicy })
  );
  const consumerPackagePolicies = data?.consumerPackagePolicies?.map(
    (policy) => ({ ...policy, type: PolicyType.ConsumerPackagePolicy })
  );
  const formulationPolicies = data?.formulationPolicies?.map((policy) => ({
    ...policy,
    type: PolicyType.FormulationPolicy,
  }));

  const orderedPolicies = [
    consumerProductPolicies,
    consumerPackagePolicies,
    formulationPolicies,
    consumerBrandPolicies,
    retailerPolicies,
  ];

  if (prioritizeRetailer) {
    orderedPolicies.splice(0, 0, orderedPolicies.pop()); // Move retailerPolicies to the start
  }

  return orderedPolicies.flat().filter((item) => item !== undefined);
}

export function hasUndefinedParam(params) {
  return !!Object.values(params).find((param) => param.value === undefined);
}

export function getVettingStatus(status, userStatus) {
  // User status overrides base status iff status is needs_information
  return status === ScreeningStatus.NeedsInformation ? userStatus : status;
}
