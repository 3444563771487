import { useCallback, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import MuiDrawer from '@material-ui/core/Drawer';
import MuiList from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';

import {
  Text,
  TextKind,
  TextElement,
  Icon,
  IconName,
  PhosphorIcon,
} from 'design-system/components';
import { Color } from 'design-system/data';
import { useApp } from 'context/AppContext';
import { ContractManufacturerLinks } from './Links';
import Help from './Help';
import { useNav } from 'layouts/NoviNav';

export default function Menu() {
  const { slim: isSlim, setSlim } = useNav();

  const clickToToggleDrawer = useCallback(
    (e) => {
      if (e.target === e.currentTarget) {
        setSlim((was) => !was);
      }
    },
    [setSlim]
  );

  return (
    <Drawer
      data-slim={isSlim}
      variant="permanent"
      onClick={clickToToggleDrawer}
      PaperProps={{ onClick: clickToToggleDrawer }}
    >
      <List>
        <ContractManufacturerLinks />
        <Help />
      </List>
    </Drawer>
  );
}

export function MenuLink(props) {
  const {
    title,
    href,
    icon,
    requiredFeatures,
    activeExactMatch,
    children,
    onClick,
    dataCy,
    usePhosphorIcon,
    component = NavLink,
  } = props;
  const { user } = useApp();
  const location = useLocation();
  const noFeaturesRequired = !requiredFeatures || requiredFeatures.length === 0;
  const visible = noFeaturesRequired || user.hasFF(requiredFeatures);
  const url = new URL(href, window.location.href);
  const hrefRegex = new RegExp(
    activeExactMatch ? `${url.pathname}$` : url.pathname
  );
  let active = hrefRegex.test(location.pathname);

  if (!visible) {
    return null;
  }
  return (
    <ListItem
      data-cy={dataCy}
      data-active={active}
      component={component}
      to={href}
      onClick={onClick}
    >
      {icon && (
        <IconWrapper>
          {usePhosphorIcon ? (
            <PhosphorIcon size={20} iconName={icon} color={Color.Black.value} />
          ) : (
            <Icon size={20} name={icon || IconName.Node} />
          )}
        </IconWrapper>
      )}
      <StyledText kind={TextKind.TextSMMedium} element={TextElement.P}>
        {title}
      </StyledText>
      {children}
    </ListItem>
  );
}

export function MenuLinkAccordion(props) {
  const [open, setOpen] = useState(false);
  const { children, ...parentProps } = props;
  return (
    <div>
      <MenuLink
        {...parentProps}
        component="ul"
        onClick={() => {
          setOpen(!open);
          parentProps.onClick();
        }}
      >
        <div style={{ flexGrow: '1', textAlign: 'right', paddingRight: 12 }}>
          <PhosphorIcon iconName={open ? 'CaretUp' : 'CaretDown'} size={12} />
        </div>
      </MenuLink>
      {open && (
        <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
          {children}
        </div>
      )}
    </div>
  );
}

export const List = styled(MuiList)`
  margin-top: 12px;
  padding: 0 12px;
`;

export const IconWrapper = styled('span')`
  min-width: auto;
  padding: 0px 12px;
  color: rgba(0, 0, 0, 0.54);
`;

export const ListItem = styled((props) => (
  <MuiListItem disableGutters {...props} />
))`
  cursor: pointer;
  height: 48px;
  border-radius: 8px;
  margin-bottom: 6px;
  padding: 6px 0;
  color: inherit;
  &:hover {
    background-color: hsla(
      var(--brand-color-h),
      var(--brand-color-s),
      var(--brand-color-l),
      0.04
    );
  }
  &[data-active='true'] {
    color: var(--brand-color);
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.08);
    border-left: 5px solid var(--brand-color);

    ${IconWrapper} {
      color: var(--brand-color);
      filter: invert(22%) sepia(98%) saturate(7500%) hue-rotate(252deg)
        brightness(87%) contrast(105%) !important;
    }
  }
`;

const StyledText = styled(Text)`
  padding-left: 16px;
  white-space: nowrap;
`;

const Drawer = styled(MuiDrawer)`
  .MuiPaper-root {
    position: relative;
    background-image: linear-gradient(
      to bottom,
      ${Color.Purple50.value},
      ${Color.White.value}
    );
    border: none;
    width: 270px;
    transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    overflow: hidden;
  }
  &[data-slim='true'] {
    .MuiPaper-root {
      width: 74px;
    }
    ${ListItem} {
      width: min-content;
    }
    ${StyledText} {
      display: none;
    }
  }
`;
