import { useState } from 'react';
import {
  Divider,
  PhosphorIcon,
  PhosphorIconWeight,
  Text,
  TextElement,
  TextKind,
  SelectV2,
  SelectV2Item,
  Link,
  LinkKind,
  LinkVariant,
} from 'design-system/components';
import { Color } from 'design-system/data';
import styles from './business-details-form.module.scss';

import countries from '../../shared/data/countries.json';

import {
  FormInput,
  FormRow,
  SectionHeader,
} from '../../shared/components/Form';

const AddressForm = ({ variant }) => {
  return (
    <>
      <FormRow
        title="Building name"
        field={
          <FormInput
            type="text"
            id="product-code"
            placeholder="Name"
            name={`building-name-${variant}`}
          />
        }
      />
      <Divider />

      <FormRow
        title="Address"
        field={
          <FormInput
            type="text"
            id="product-code"
            placeholder="Street address"
          />
        }
      />
      <Divider />

      <FormRow
        title="Address line 2"
        field={
          <FormInput
            type="text"
            id="product-code"
            placeholder="Suite, building, floor"
          />
        }
      />
      <Divider />

      <FormRow
        title="Country and postal code"
        field={
          <>
            <SelectV2 placeholder="Select country...">
              {countries.map((country) => (
                <SelectV2Item key={country.name} value={country.name}>
                  <Text kind={TextKind.TextMDMedium} element={TextElement.Span}>
                    {country.name}
                  </Text>
                </SelectV2Item>
              ))}
            </SelectV2>
            <FormInput
              type="text"
              id="product-code"
              placeholder="Postal code"
            />
          </>
        }
      />
      <Divider />

      <FormRow
        title="City and state"
        field={
          <>
            <FormInput type="text" id="product-code" placeholder="City" />
            <SelectV2 placeholder="Select state...">
              <SelectV2Item value="7">
                <Text kind={TextKind.TextMDMedium} element={TextElement.Span}>
                  Item Seven
                </Text>
              </SelectV2Item>
              <SelectV2Item value="8">
                <Text kind={TextKind.TextMDMedium} element={TextElement.Span}>
                  Item Eight
                </Text>
              </SelectV2Item>
              <SelectV2Item value="9">
                <Text kind={TextKind.TextMDMedium} element={TextElement.Span}>
                  Item Nine
                </Text>
              </SelectV2Item>
            </SelectV2>
          </>
        }
      />
    </>
  );
};

const BusinessDetailsForm = () => {
  const [sameAsBusinessAddress, setSameAsBusinessAddress] = useState(false);

  const toggleSameAsBusinessAddress = () => {
    setSameAsBusinessAddress(!sameAsBusinessAddress);
  };

  return (
    <div className={styles.root}>
      {/* Row 1 */}
      <FormRow
        title="Business name"
        field={<FormInput type="text" id="sku-name" placeholder="Name" />}
      />
      <Divider />

      {/* Row 2 */}
      <FormRow
        title="Type of business"
        description="tktktktktktkt"
        field={
          <SelectV2 placeholder="Select business type">
            <SelectV2Item value="7">
              <Text kind={TextKind.TextMDMedium} element={TextElement.Span}>
                Item Seven
              </Text>
            </SelectV2Item>
            <SelectV2Item value="8">
              <Text kind={TextKind.TextMDMedium} element={TextElement.Span}>
                Item Eight
              </Text>
            </SelectV2Item>
            <SelectV2Item value="9">
              <Text kind={TextKind.TextMDMedium} element={TextElement.Span}>
                Item Nine
              </Text>
            </SelectV2Item>
          </SelectV2>
        }
      />
      <Divider />

      {/* Row 3 */}
      <FormRow
        title="FEI/DUNS number"
        description="tktktktktktkt"
        field={
          <FormInput
            type="text"
            id="sku-listing"
            placeholder="Listing number"
          />
        }
      />
      <Divider />

      {/* Row 5 */}
      <FormRow
        title="Small business exemption"
        description="tktktktktktkt"
        field={
          <div className={styles['checkbox-container']}>
            <input
              type="checkbox"
              name="small_business_exemption"
              value="false"
              id="subscribe_to_emails"
            />
            <Text
              htmlFor="subscribe_to_emails"
              id="input-label-subscribe_to_emails"
              element={TextElement.Label}
              kind={TextKind.TextSM}
              color={Color.Neutral600}
            >
              This business qualifies as a small business according to the
              guidelines set forth by the FDA.{' '}
              <Link
                kind={LinkKind.External}
                variant={LinkVariant.Default}
                href={'#'}
              >
                Learn more
              </Link>
            </Text>
          </div>
        }
      />

      <SectionHeader
        title="Contact information"
        subtitle="Please provide the contact information for someone at your organization that the FDA can get in contact with questions or followups."
      />

      {/* Row 3 */}
      <FormRow
        title="Name"
        field={
          <>
            <FormInput type="text" id="product-code" placeholder="First name" />
            <FormInput type="text" id="product-code" placeholder="Last name" />
          </>
        }
      />
      <Divider />

      {/* Row 3 */}
      <FormRow
        title="Role"
        field={
          <FormInput
            type="text"
            id="product-code"
            placeholder="Job title or position"
          />
        }
      />
      <Divider />

      {/* Row 4 */}
      <FormRow
        title="Email address"
        field={
          <FormInput
            type="email"
            id="pdp-url"
            placeholder="example@example.com"
            icon={
              <PhosphorIcon
                iconName="EnvelopeSimple"
                size="1.5rem"
                weight={PhosphorIconWeight.Light}
                color={Color.Neutral600.value}
              />
            }
          />
        }
      />
      <Divider />

      {/* Row 3 */}
      <FormRow
        title="Phone number"
        field={
          <>
            <FormInput
              type="text"
              id="product-code"
              placeholder="+1"
              className={styles['phone-prefix']}
            />
            <FormInput type="text" id="product-code" placeholder="555-5555" />
            <FormInput type="text" id="product-code" placeholder="EXT-5555" />
          </>
        }
      />

      <SectionHeader
        title="Business Address"
        subtitle="Physical address requirements?"
      />

      <AddressForm variant="business" />

      <SectionHeader
        title="Mailing address"
        subtitle="Can be the same as your business address"
        suffix={
          <div className={styles['checkbox-container']}>
            <input
              type="checkbox"
              name="same_as_business_address"
              value={String(sameAsBusinessAddress)}
              id="same_as_business_address"
              onClick={toggleSameAsBusinessAddress}
            />
            <Text
              htmlFor="same_as_business_address"
              id="input-label-same_as_business_address"
              element={TextElement.Label}
              kind={TextKind.TextSM}
              color={Color.Neutral600}
            >
              Same as business address
            </Text>
          </div>
        }
      />

      {!sameAsBusinessAddress && <AddressForm variant="mailing" />}
    </div>
  );
};

export default BusinessDetailsForm;
