function AlertRound(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="alert-round-icon"
    >
      <circle cx="12" cy="12.5" r="12" fill="#ffdab7" />
      <path
        d="M12.7999 13.7517L13.1669 8.24723C13.2118 7.57225 12.6765 7 12 7C11.3235 7 10.7882 7.57225 10.8331 8.24723L11.2001 13.7517C11.2282 14.1728 11.578 14.5 12 14.5C12.422 14.5 12.7718 14.1728 12.7999 13.7517Z"
        fill="#FB7900"
      />
      <circle cx="12" cy="17" r="1" fill="#FB7900" />
    </svg>
  );
}

export default AlertRound;
