import { useRef, useState } from 'react';
import propTypes from 'prop-types';
import {
  Button,
  ButtonKind,
  IconName,
  Switch,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';
import { useClickoff } from 'design-system/utils';
import styles from './filter-menu.module.scss';
import { Color } from 'design-system/data';

function FilterMenu({ title, filters, onClick }) {
  const containerRef = useRef();
  const [showMenu, setShowMenu] = useState(false);

  useClickoff(containerRef, () => {
    if (showMenu) {
      setShowMenu(false);
    }
  });

  return (
    <div ref={containerRef} className={styles.container}>
      <Button
        kind={ButtonKind.Secondary}
        iconName={IconName.Filter}
        onClick={() => setShowMenu(!showMenu)}
        aria-label="Click to expand menu"
        aria-expanded={showMenu}
      />
      <div className={styles[showMenu ? 'menu-show' : 'menu']}>
        <div className={styles['menu-header']}>
          <Text
            kind={TextKind.TextSMBold}
            element={TextElement.P}
            color={Color.Neutral500}
          >
            {title}
          </Text>
          <button
            className={styles['menu-header-button']}
            onClick={() => onClick()}
          >
            View all
          </button>
        </div>
        <ul className={styles.filters}>
          {filters.map((filter) => (
            <li className={styles.filter} key={filter.value}>
              <div className={styles['filter-name']}>
                <Text kind={TextKind.TextMD} element={TextElement.P}>
                  {filter.label}
                </Text>
                <Text
                  id="label"
                  kind={TextKind.TextMD}
                  element={TextElement.P}
                  color={Color.Neutral400}
                >
                  {filter.quantity}
                </Text>
              </div>
              <Switch
                small
                isActive={filter.active}
                onClick={() => onClick(filter.value)}
                ariaLabelledBy="label"
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

FilterMenu.propTypes = {
  title: propTypes.string.isRequired,
  filters: propTypes.arrayOf(
    propTypes.shape({
      label: propTypes.string,
      value: propTypes.string,
      quantity: propTypes.number,
      active: propTypes.bool,
    }).isRequired
  ),
  onClick: propTypes.func.isRequired,
};

export default FilterMenu;
