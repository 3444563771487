import { useRef } from 'react';
import { Redirect } from 'react-router-dom';

import {
  Breadcrumbs,
  Button,
  ProductForm,
  FormData,
  ButtonKind,
  ToastManager,
} from 'design-system/components';

import { PATHS } from 'constants/index';
import PageHeader from 'views/FDARegistration/shared/components/PageHeader';

import { useYourProductContext } from '../YourProducts/context';
import { useCreateProductContext } from './context/CreateProductContext';
import { hydrateProductFormConfig } from './data/FormConfig';
import styles from './create-product.module.scss';

const baseLink = PATHS.retailerBrandProducts;
const breadcrumbsItems = [
  {
    text: 'SKUs',
    link: baseLink,
  },
  {
    text: 'Upload SKU',
    link: '.',
  },
];

function CreateProductContainer() {
  const { shouldOpenPaywall } = useYourProductContext();
  const formRef = useRef<HTMLFormElement>(null);
  const { formData, handleSubmit, handleCancel, loading, toastData } =
    useCreateProductContext();

  if (shouldOpenPaywall) {
    return <Redirect to={PATHS.retailerBrandProducts} />;
  }

  const productFormConfig = hydrateProductFormConfig(formData);
  const onSubmit = (formState: FormData) => {
    handleSubmit(formState);
  };
  const onCancel = () => {
    handleCancel();
  };

  const triggerFormSubmit = () => {
    formRef?.current?.triggerSubmit();
  };
  const triggerFormCancel = () => {
    formRef?.current?.triggerCancel();
  };
  return (
    <div className={styles.root}>
      <PageHeader
        title="Upload a SKU"
        subtitle="Fill out the form below to upload a new SKU to Novi Connect."
        breadcrumbs={<Breadcrumbs items={breadcrumbsItems} />}
        actions={
          <>
            <Button
              onClick={triggerFormCancel}
              kind={ButtonKind.Tertiary}
              className={styles.cta}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              onClick={triggerFormSubmit}
              className={styles.cta}
              disabled={loading}
            >
              {loading ? 'Submitting...' : 'Save'}
            </Button>
          </>
        }
      />
      <ProductForm
        formConfig={productFormConfig}
        onSubmit={onSubmit}
        onCancel={onCancel}
        ref={formRef}
        loading={loading}
      />
      <ToastManager data={toastData} />
    </div>
  );
}

export default CreateProductContainer;
