import { func, number, string, shape } from 'prop-types';
import {
  DropdownMenu,
  DropdownMenuItem,
  Icon,
  IconName,
  TableRowMenuTrigger,
} from 'design-system/components';
import styles from './documents-table.module.scss';

function DocumentsTableRowDropDown({
  setShowDocumentModal,
  setShowRetailerNotesModal,
  document,
}) {
  return (
    <DropdownMenu isFixed triggerElement={<TableRowMenuTrigger />}>
      <DropdownMenuItem
        onClick={() => {
          setShowDocumentModal({ show: true, document });
        }}
      >
        <div className={styles['table-row-dropdown-item']}>
          <Icon name={IconName.Upload} size={30} />
          Reupload document
        </div>
      </DropdownMenuItem>
      <DropdownMenuItem
        onClick={() =>
          setShowRetailerNotesModal({
            show: true,
            document,
          })
        }
      >
        <div className={styles['table-row-dropdown-item']}>
          <Icon name={IconName.Edit} size={30} />
          View retailer notes
        </div>
      </DropdownMenuItem>
    </DropdownMenu>
  );
}

DocumentsTableRowDropDown.propTypes = {
  setShowDocumentModal: func.isRequired,
  setShowNotesModal: func.isRequired,
  document: shape({
    name: string,
    createdAt: string,
    documentName: string,
    id: number,
    policy: shape({
      name: string,
      id: number,
    }),
    retailer: shape({
      id: number,
      name: string,
      image: string,
    }),
    status: string,
    updatedAt: string,
  }).isRequired,
};

export default DocumentsTableRowDropDown;
