import { FormConfig } from 'design-system/components';
import { CreateProductContextFormDataType } from '../context/CreateProductContext';
import { validateUpc } from 'utils/validateUpc';

const validateRequired =
  (fieldName: string) =>
  (state: string): string | true => {
    return state && state.length ? true : `${fieldName} is a required field`;
  };

// tightly coupled to existing returned data structure
const convertToMultiSelectOptions = (
  data: { label: string; value: string }[]
): { id: string; name: string }[] => {
  return data.map(({ label, value }) => ({ id: value, name: label }));
};

const hydrateProductFormConfig = (
  data: CreateProductContextFormDataType
): FormConfig => {
  const productFormConfig: FormConfig = {
    staticFields: [
      {
        name: 'product_name',
        label: 'Product (SKU) name',
        type: 'text',
        required: true,
        onValidate: validateRequired('Product name'),
      },
      {
        name: 'product_line',
        label: 'Product line',
        type: 'text',
      },
      {
        name: 'sku_id',
        label: 'SKU ID',
        type: 'text',
      },
      {
        name: 'upc_number',
        label: 'UPC number',
        type: 'number',
        required: false,
        onValidate: (state: string) => {
          if (!state?.length) return true;

          const isValid = validateUpc(state);
          return isValid ? true : 'Please enter a valid UPC';
        },
      },
      {
        name: 'retailer_sales_channel',
        label: 'Sales channel',
        type: 'multi-select',
        placeholder: 'Select sales channel',
        options: data?.salesChannelOptions
          ? convertToMultiSelectOptions(
              data?.salesChannelOptions as { label: string; value: string }[]
            )
          : [],
      },
      {
        name: 'ingredient_names',
        label: 'Ingredients',
        type: 'text-area',
      },
      {
        name: 'type',
        label: 'Product type',
        type: 'select',
        required: true,
        placeholder: 'Select product type',
        onValidate: validateRequired('Product type'),
        options: [
          { value: 'BPC', label: 'Beauty and Personal Care' },
          { value: 'Food', label: 'Food' },
          { value: 'Other', label: 'Other' },
        ],
      },
    ],
    conditionalFields: {
      BPC: [
        {
          name: 'application_time', // rinse off, leave on
          label: 'Intended use',
          type: 'select',
          options: data?.BPC?.intendedUseOptions || [],
          required: true,
          placeholder: 'Select intended use',
          onValidate: validateRequired('Intended use'),
        },
        {
          name: 'product_use_ids',
          label: 'Intended application',
          type: 'multi-select',
          placeholder: 'Select intended application(s)',
          options: data?.BPC?.intendedApplicationOptions || [],
          required: true,
          onValidate: validateRequired('Intended application'),
        },
        {
          name: 'product_form_id',
          label: 'Formula format',
          type: 'select',
          options: data?.BPC?.formlaFormatOptions,
          required: true,
          placeholder: 'Select formula format',
          onValidate: validateRequired('Formula format'),
        },
        {
          name: 'fragrance',
          label: 'Fragrance',
          type: 'radio',
          options: [
            { value: 'yes', label: 'Yes' },
            { value: 'no', label: 'No' },
          ],
          required: true,
          onValidate: (state) => state !== undefined,
        },
        {
          name: 'flavor',
          label: 'Flavor',
          type: 'radio',
          options: [
            { value: 'yes', label: 'Yes' },
            { value: 'no', label: 'No' },
          ],
          required: true,
          onValidate: (state) => state !== undefined,
        },
        {
          name: 'product_category_id',
          label: 'Product category',
          type: 'select',
          placeholder: 'Select product category',
          options: data?.BPC?.productCategoryOptions,
        },
        {
          name: 'product_claim_ids',
          label: 'Additional details',
          placeholder: 'Select additional details',
          type: 'multi-select',
          options: data?.BPC?.additionalProductDetailsOptions || [],
        },
      ],
      Food: [
        {
          name: 'product_claim_ids',
          label: 'Additional details',
          placeholder: 'Select additional details',
          type: 'multi-select',
          options: data?.Food?.additionalProductDetailsOptions || [],
        },
        {
          name: 'product_category_id',
          label: 'Product category',
          type: 'select',
          placeholder: 'Select product category',
          options: data?.Food?.productCategoryOptions || [],
        },
      ],
      Other: [
        {
          name: 'product_category_id',
          label: 'Product category',
          type: 'select',
          placeholder: 'Select product category',
          options: data?.Other?.productCategoryOptions || [],
        },
      ],
    },
  };
  return productFormConfig;
};

export { hydrateProductFormConfig };
