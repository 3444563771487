import { Text, TextElement, TextKind } from 'design-system/components';
import styles from './list-item.module.scss';

const SupplierModalListItem = ({ value, index, key }) => (
  <div className={styles.container} key={`${key}-${index}`}>
    <Text kind={TextKind.TextMD} element={TextElement.P}>
      {index + 1}
      {'. '}
    </Text>
    <Text kind={TextKind.TextMD} element={TextElement.P}>
      {value}
    </Text>
  </div>
);

export default SupplierModalListItem;
