import { useRef, useState, useEffect } from 'react';
import propTypes from 'prop-types';
import cn from 'classnames';
import styles from './dropdown-menu.module.scss';
import { useClickoff } from 'design-system/utils';

function DropdownMenu(props) {
  const {
    triggerElement,
    children,
    enableAutomaticPositioning = false,
    isFixed,
    fullWidth,
    dataCy,
    multiSelect = false,
    disabled,
  } = props;
  const [showMenu, setShowMenu] = useState(false);
  const [fromLeft, setFromLeft] = useState(props.fromLeft || false);
  const [fromTop, setFromTop] = useState(props.fromTop || false);
  const [triggerWidth, setTriggerWidth] = useState();
  const [fixedPosition, setFixedPosition] = useState();

  const containerRef = useRef();
  const triggerRef = useRef();

  useEffect(() => {
    if (enableAutomaticPositioning || showMenu || fullWidth || isFixed) {
      const triggerBoundingRect = triggerRef.current?.getBoundingClientRect();

      if (enableAutomaticPositioning && showMenu) {
        setFromTop(
          triggerBoundingRect.top > document.documentElement.clientHeight / 2
        );
        setFromLeft(
          triggerBoundingRect.left > document.documentElement.clientWidth / 2
        );
      }

      if (fullWidth) {
        triggerBoundingRect?.width &&
          setTriggerWidth(triggerBoundingRect.width);
      }

      if (isFixed) {
        triggerBoundingRect?.bottom &&
          setFixedPosition(triggerBoundingRect.bottom);
      }
    }
  }, [enableAutomaticPositioning, showMenu, fullWidth, isFixed]);

  const handleOptionClick = () => {
    if (multiSelect) {
      return;
    }
    setShowMenu(false);
  };

  useClickoff(containerRef, () => setShowMenu(false));

  return (
    <div
      ref={containerRef}
      className={styles[fullWidth ? 'container-wide' : 'container']}
    >
      <button
        className={styles[showMenu ? 'trigger-active' : 'trigger']}
        ref={triggerRef}
        onClick={() => setShowMenu(!showMenu)}
        aria-label="Click to expand menu"
        aria-expanded={showMenu}
        data-cy={dataCy}
        disabled={disabled}
      >
        {triggerElement}
      </button>
      {showMenu && (
        <ul
          className={cn(
            styles.menu,
            fromLeft && styles['menu-left'],
            fromTop && styles['menu-top'],
            isFixed && styles.fixed
          )}
          style={{
            width: triggerWidth,
            top: fixedPosition,
            maxHeight: enableAutomaticPositioning ? '50vh' : 'none',
          }}
          onClick={handleOptionClick}
        >
          {children}
        </ul>
      )}
    </div>
  );
}

DropdownMenu.propTypes = {
  triggerElement: propTypes.node.isRequired,
  fullWidth: propTypes.bool,
  children: propTypes.node,
  isFixed: propTypes.bool,
  enableAutomaticPositioning: propTypes.bool,
  multiSelect: propTypes.bool,
  disabled: propTypes.bool,
  fromLeft: propTypes.bool,
};

export default DropdownMenu;
