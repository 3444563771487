import { useEffect, useState, useRef } from 'react';
import useFetcher from 'services/api/useFetcher';
import useAuth0 from 'hooks/useAuth0';
import useIsMounted from 'hooks/useIsMounted';
import useStableObject from 'hooks/useStableObject';
import { stringify } from 'utils/querystring';
import handleErrors from './errors/handleErrors';
import RequestRetry from './errors/RequestRetry';
import useErrorHandlers from './errors/useErrorHandlers';

/**
 * @typedef {Object} ApiCall
 * @property {boolean} loading - DEPRECATED: use `isFetching`
 * @property {boolean} isFetching - If we are currently waiting on an asynchronous fetch to complete
 * @property {Object} data - Data returned from the asyncrhronous fetch
 * @property {Object} error - Details about any errors that happened running the asynchronous fetch
 * @property {Function} refetch - A method to refetch data with the same parameters
 */

/**
 * @description A React hook to asynchronously fetch data.
 * - When data has been fetched, components using this hook will automatically re-render with the fetched data.
 * @see https://reactjs.org/docs/hooks-custom.html
 * @arg {string} resource - The path of the resource to fetch
 * @arg {Object} options - Details about how to fetch the data (or whether to fetch the data at all)
 * @return {ApiCall}
 */
export default function useApi(resource, options = {}) {
  const {
    param = {},
    enabled: optEnabled = true,
    fetcherOptions: optFetcherOptions,
    onUnauthenticated,
    errorHandlers,
    ...forwardedOptions
  } = options;

  const enabled = Boolean(optEnabled);
  const { auth0 } = useAuth0();
  const fetcherOptions = useStableObject(optFetcherOptions);
  const { defaultErrorHandlers } = useErrorHandlers();
  const errorHandlersRef = useRef(errorHandlers || defaultErrorHandlers);
  const fetcher = useFetcher(forwardedOptions);
  const refetch = useRef(() => null);
  const lastCallTime = useRef(Date.now());
  const isMounted = useIsMounted();
  const [state, setState] = useState({
    loading: true && enabled,
    data: null,
    error: null,
    refetch: (options) => refetch.current(options),
    // Added to provide compatibility with RTK Query
    isLoading: true && enabled, // In RTK, only runs on initial load
    isFetching: true && enabled, // in RTK, runs every time data is being loaded; equivalent to useApi's `loading`
  });

  const url = new URL(resource, process.env.REACT_APP_API_BASE);
  url.search = stringify(param);

  const endpoint = url.toString();

  useEffect(() => {
    const setMountedState = (...arg) => isMounted.current && setState(...arg);

    async function call(options = {}) {
      const { soft = false, retryTimes } = options;

      const callTime = Date.now();

      // when options.soft is true, load new data quietly
      if (!soft) {
        setMountedState((was) => ({
          ...was,
          loading: true,
          isLoading: true,
          isFetching: true,
        }));
      }

      let apiState;

      try {
        const res = await fetcher(endpoint, { retryTimes, ...fetcherOptions });

        if (res) {
          apiState = {
            data: res,
            loading: false,
            isLoading: false,
            isFetching: false,
            error: null,
          };
        }
      } catch (err) {
        const errResponse = await handleErrors(errorHandlersRef.current, err);

        if (errResponse instanceof RequestRetry) {
          return call({ retryTimes: errResponse.retryTimes });
        }

        apiState = {
          error: err,
          loading: false,
          isLoading: false,
          isFetching: false,
          data: null,
        };
      } finally {
        setMountedState((was) => ({ ...was, ...apiState }));
        lastCallTime.current = callTime;
      }
    }

    refetch.current = call;

    if (enabled) {
      call();
    }
  }, [
    endpoint,
    fetcherOptions,
    enabled,
    isMounted,
    fetcher,
    auth0,
    errorHandlersRef,
  ]);

  return state;
}
