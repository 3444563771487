const possibleCharacters =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export default function randomString(length) {
  return range(length).reduce((str) => {
    return (
      str + possibleCharacters.charAt(random(0, possibleCharacters.length))
    );
  }, '');
}

function random(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function range(size) {
  return Array(Math.round(size)).fill(0);
}
