import { IconName } from 'design-system/components';

import { MenuLink } from './Menu';
import { PATHS } from 'constants/index';
import { useApp } from 'context/AppContext';
import { useAnalyticsEvent } from 'services/analytics/AnalyticsContext';

export function ContractManufacturerLinks() {
  const { user } = useApp();
  const { analyticsWrap } = useAnalyticsEvent();

  return (
    <>
      <MenuLink
        title="Home"
        href={PATHS.base}
        icon={IconName.Package}
        activeExactMatch
        onClick={() => {
          analyticsWrap({
            label: 'navigation',
            category: 'left_nav',
            action: 'click_menu_home',
          })();
        }}
      />
      <MenuLink
        title="Products"
        href={PATHS.projectDashboard}
        icon={IconName.Formula}
        onClick={() => {
          analyticsWrap({
            label: 'navigation',
            category: 'left_nav',
            action: 'click_menu_products',
          })();
        }}
      />
      <MenuLink
        title="Ingredient Screener"
        href={PATHS.ingredient_search}
        icon={IconName.Cluster}
        onClick={() => {
          analyticsWrap({
            label: 'navigation',
            category: 'left_nav',
            action: 'click_menu_ingredient_screener',
          })();
        }}
      />
      {user?.hasFF('sephora_retailer_flow') && (
        <MenuLink
          title="Brand Results"
          href="/sephora/brands"
          icon={IconName.Sephora}
          onClick={() => {
            analyticsWrap({
              label: 'navigation',
              category: 'left_nav',
              action: 'click_menu_sephora_brands',
            })();
          }}
        />
      )}
    </>
  );
}
