import { createContext, useContext, useMemo, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

import SiteHeader from 'components/SiteHeader';
import { header } from 'constants/layout';
import { useApp } from 'context/AppContext';
import { useNavContext } from 'layouts/ConnectedSidenav/context';

import useWindowSize from 'hooks/useWindowSize';
import Menu from './Menu';
import UnverifiedBanner from './UnverifiedBanner';
import { FDA_REGISTRATION_PATH } from 'constants/index';

export const NavContext = createContext();
export const useNav = () => useContext(NavContext);

export const BaseNav = (props) => {
  const { children } = props;
  useNavContext()?.useHiddenNav();

  return (
    <>
      <SiteHeader />
      <Container>
        <Content id="page-scrollbar">{children}</Content>
      </Container>
    </>
  );
};

export default function NoviNav(props) {
  const { children } = props;
  const { user } = useApp();

  const { slim, setSlim } = useIsSlim();
  const [hideMenu, setHideMenu] = useState(false);
  const [showUnverifiedBanner, setShowUnverifiedBanner] = useState(
    user?.is_verified === false
  );

  const context = useMemo(() => {
    return {
      slim,
      setSlim,
      useHiddenNav: () => {
        useEffect(() => {
          setHideMenu(true);
          return () => setHideMenu(false);
        }, []);
      },
      setShowUnverifiedBanner,
    };
  }, [slim, setSlim, setShowUnverifiedBanner]);

  // TODO: really hacky fix for padding on FDA registration URLs
  // Introduced on https://linear.app/novi/issue/ENG-5075
  // !! Please remove when we enable the new brand sidenav for all users
  const { pathname } = useLocation();
  const extraContentStyles = useMemo(() => {
    if (pathname.includes(FDA_REGISTRATION_PATH)) {
      return { padding: '32px 32px' };
    }
  }, [pathname]);

  return (
    <NavContext.Provider value={context}>
      {showUnverifiedBanner && <UnverifiedBanner />}
      <SiteHeader />
      <Container>
        {!hideMenu && <Menu />}
        <Content id="page-scrollbar" style={extraContentStyles}>
          {children}
        </Content>
      </Container>
    </NavContext.Provider>
  );
}

const Container = styled.div`
  display: flex;
  align-content: space-around;
  min-height: calc(100vh - ${header.height});
`;

const Content = styled.div`
  width: 100%;
`;

function useIsSlim() {
  const [slim, setSlim] = useState(false);

  const triggerSlim = useWindowSize().width < 600;

  useEffect(() => {
    if (triggerSlim) {
      setSlim(true);
    } else {
      setSlim(false);
    }
  }, [triggerSlim]);

  return { slim, setSlim };
}
