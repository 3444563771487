import { useMemo } from 'react';
import { ScreeningStatus } from 'design-system/data';

const defaultOptions = {
  pendingConditionKey: 'isPendingRetailerAction',
  filterKey: 'screeningResult',
  tabValues: [
    {
      key: ScreeningStatus.Pass,
      value: [ScreeningStatus.Pass, ScreeningStatus.NotApplicable],
    },
    {
      key: ScreeningStatus.NeedsInformation,
      value: [ScreeningStatus.Fail, ScreeningStatus.NeedsInformation],
    },
    {
      key: ScreeningStatus.Pending,
      value: ScreeningStatus.Pending,
    },
  ],
};

const statusComparator = (activeStatus, statusToCheck) => {
  if (activeStatus instanceof Array) {
    return activeStatus.includes(statusToCheck);
  }
  return statusToCheck === activeStatus;
};

export function filterByConditionGroupAndFallbacks(
  activeFilterStatus,
  intitialDataArray,
  pendingConditionKey,
  filterKey
) {
  const sortItemByIndividualScreeningStatus = (item) => {
    return statusComparator(activeFilterStatus, item[filterKey]);
  };

  if (!activeFilterStatus) {
    return intitialDataArray;
  }

  if (statusComparator(activeFilterStatus, ScreeningStatus.Pending)) {
    return intitialDataArray?.filter((item) => item[pendingConditionKey]);
  }

  return intitialDataArray?.filter((item) => {
    if (!item.conditionGroups || !item.conditionGroups.length) {
      return sortItemByIndividualScreeningStatus(item);
    }
    const conditionGroupsStatusResults = item.conditionGroups.some(
      (conditionGroup) => {
        if (conditionGroup.status === null) {
          return sortItemByIndividualScreeningStatus(item);
        }
        return statusComparator(activeFilterStatus, conditionGroup.status);
      }
    );
    return conditionGroupsStatusResults;
  });
}

export function getQuantityByScreeningStatus(
  intitialDataArray,
  tabValues,
  pendingConditionKey,
  filterKey
) {
  let quantitiesByTabValue = {};
  tabValues.forEach((tabValue) => {
    quantitiesByTabValue[tabValue.key] = filterByConditionGroupAndFallbacks(
      tabValue.value,
      intitialDataArray,
      pendingConditionKey,
      filterKey
    )?.length;
  });
  return quantitiesByTabValue;
}

export default function useFilterByScreeningStatus(
  intitialDataArray,
  activeFilterStatus,
  opts
) {
  const options = { ...defaultOptions, ...opts };
  const { pendingConditionKey, filterKey } = options;
  const quantitiesByScreeningStatus = useMemo(
    () =>
      getQuantityByScreeningStatus(
        intitialDataArray,
        options.tabValues,
        pendingConditionKey,
        filterKey
      ),
    [intitialDataArray, options.tabValues, pendingConditionKey, filterKey]
  );
  const filteredData = useMemo(
    () =>
      filterByConditionGroupAndFallbacks(
        activeFilterStatus,
        intitialDataArray,
        pendingConditionKey,
        filterKey
      ),
    [activeFilterStatus, intitialDataArray, filterKey, pendingConditionKey]
  );
  return {
    filteredData,
    intitialDataArray,
    activeFilterStatus,
    quantitiesByScreeningStatus,
  };
}
