import useApi from 'services/api/useApi';

const useRetailer = (id) => {
  const {
    data: retailer,
    error,
    loading,
  } = useApi(`/api/v4/retailers/${id}`, {
    enabled: Boolean(id),
  });

  return { retailer, error, loading };
};

export default useRetailer;
