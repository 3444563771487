export const StatusLabels = {
  processing: 'Processing',
  supplier_contacted: 'Supplier Contacted',
  preparing_for_shipment: 'Preparing for Shipment',
  shipped_to_downpacking: 'Shipped to Downpacking',
  downpacking: 'Downpacking',
  shipped: 'Shipped',
  out_for_delivery: 'Out for Delivery',
  delivered: 'Delivered',
  cancelled: 'Cancelled',
  test: 'Test',
};
