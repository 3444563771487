export const ScreeningModalTypes = {
  Attestation: 'attestation',
  DocumentUpload: 'document_upload',
};

export const AttestationResponsesExtended = {
  yes: 'Yes',
  no: 'No',
  na: 'Not applicable',
};

export const AttestationResponses = {
  yes: 'Yes',
  no: 'No',
};

export const AttestationTypes = {
  yes_no: 'yes_no',
  yes_no_na: 'yes_no_na',
  yes: 'yes',
};

export const AttestationNoteType = {
  Required: 'required',
  Optional: 'optional',
};

export const attestationResponseExtendedOptions = () => {
  let options = [{ display: 'Please select', value: null }];
  Object.entries(AttestationResponsesExtended).map((entry) => {
    return options.push({ display: entry[1], value: entry[0] });
  });
  return options;
};

export const attestationResponseOptions = () => {
  let options = [{ display: 'Please select', value: null }];
  Object.entries(AttestationResponses).map((entry) => {
    return options.push({ display: entry[1], value: entry[0] });
  });
  return options;
};

export const attestationYesOptions = () => {
  return [
    { display: 'Please select', value: null },
    { display: 'Yes', value: 'yes' },
  ];
};
