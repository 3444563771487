import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  ButtonIconPosition,
  ButtonKind,
  IconName,
  IconDirection,
  Link,
  LinkKind,
  LinkVariant,
  PhosphorIcon,
  Pill,
  TableBodyCell,
  TableRow,
  Tooltip,
  PillColor,
  OpenAxis,
} from 'design-system/components';
import { PolicyRequirementType } from 'design-system/data';

import ScreeningStatusPill from 'components/ScreeningStatusPill';
import styles from './retailer-requirements.module.scss';

const RequirementRow = ({ requirement, onClick, url }) => {
  const history = useHistory();
  const isBrandRequirement =
    requirement.requirementType === PolicyRequirementType.Brand;
  const ctaData = {
    isBrandRequirement,
    to: url,
    text: 'Review',
  };

  const tableRowOnClick = ctaData.isBrandRequirement
    ? onClick
    : () => history.push(ctaData.to);

  const maximumPillCharacters = 40;
  const truncatedPillText = useMemo(() => {
    const text = requirement.category;
    if (typeof text !== 'string') return text;
    return text?.length > maximumPillCharacters
      ? `${text.slice(0, maximumPillCharacters)}...`
      : text;
  }, [requirement.category]);

  return (
    <TableRow onClick={tableRowOnClick}>
      <TableBodyCell>{requirement.name}</TableBodyCell>
      <TableBodyCell>
        <Tooltip
          openAxis={OpenAxis.Y}
          triggerElement={
            <Pill color={PillColor.Default}>{truncatedPillText}</Pill>
          }
          disabled={requirement.category?.length <= maximumPillCharacters}
        >
          {requirement.category}
        </Tooltip>
      </TableBodyCell>
      <TableBodyCell>
        <ScreeningStatusPill withDot status={requirement.screeningStatus} />
      </TableBodyCell>
      <TableBodyCell>
        {ctaData.isBrandRequirement ? (
          <Button
            kind={ButtonKind.Alternate}
            iconName={IconName.ArrowV2}
            iconPosition={ButtonIconPosition.Right}
            iconDirection={IconDirection.Right}
            onClick={onClick}
          >
            {ctaData.text}
          </Button>
        ) : (
          <Link
            kind={LinkKind.Internal}
            variant={LinkVariant.Alternate}
            to={ctaData.to}
          >
            <div className={styles.cta}>
              {ctaData.text}
              <span className={styles['icon-container']}>
                <PhosphorIcon iconName="ArrowRight" size={18} />
              </span>
            </div>
          </Link>
        )}
      </TableBodyCell>
    </TableRow>
  );
};

export default RequirementRow;
