import { Grid, Paper } from '@material-ui/core';
import { Text, TextKind, TextElement } from 'design-system/components';
import styles from './empty-state.module.scss';

import useStyles from './styles';

const EmptyState = ({ illustration, title, subtitle, action }) => {
  const classes = useStyles();

  const illustrationSize = 12;
  const informationSize = 12;

  return (
    <div className={classes.root}>
      <Paper className={classes.container} elevation={0}>
        <Grid container direction="column" justify="center" alignItems="center">
          {illustration && (
            <Grid
              item
              xs={illustrationSize}
              zeroMinWidth
              className={classes.illustration}
            >
              <img alt="EmptyStateIllustration" src={illustration} />
            </Grid>
          )}
          {(title || subtitle || action) && (
            <Grid
              container
              justify="space-around"
              alignItems="stretch"
              item
              xs={informationSize}
              direction="row"
              spacing={2}
              className={classes.information}
            >
              {title && (
                <Grid item xs={12} zeroMinWidth>
                  <Text
                    className={styles.content}
                    kind={TextKind.DisplayMDMedium}
                    element={TextElement.H1}
                  >
                    {title}
                  </Text>
                </Grid>
              )}
              {subtitle && (
                <Grid item xs={12} zeroMinWidth>
                  <Text
                    className={styles.content}
                    kind={TextKind.TextMD}
                    element={TextElement.P}
                  >
                    {subtitle}
                  </Text>
                </Grid>
              )}
              {action && (
                <Grid item xs={12} zeroMinWidth>
                  <div id="action" className={classes.information_actions}>
                    {action}
                  </div>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Paper>
    </div>
  );
};

export default EmptyState;
