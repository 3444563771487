import { useEffect, useRef, useState } from 'react';
import propTypes from 'prop-types';

import { Text, TextKind, TextElement } from 'design-system/components';
import { checkTableChildren } from './utils';
import styles from './table.module.scss';

function Table({ children, caption, disableAutoOverflow }) {
  const rootClassName = 'root';
  const tableRef = useRef();
  const [tableRootClass, setTableRootClass] = useState(rootClassName);

  useEffect(() => {
    if (tableRef) {
      tableRef.current.addEventListener('scroll', () => {
        setTableRootClass(
          tableRef.current.scrollLeft === 0
            ? rootClassName
            : `${rootClassName}-scrolled`
        );
      });
    }
  });
  return (
    <>
      {caption && (
        <Text
          className={styles.caption}
          kind={TextKind.TextSM}
          element={TextElement.P}
          role="caption"
        >
          {caption}
        </Text>
      )}
      <table
        ref={tableRef}
        style={disableAutoOverflow ? { overflow: 'initial' } : {}}
        className={styles[tableRootClass]}
      >
        {children}
      </table>
    </>
  );
}

Table.propTypes = {
  disableAutoOverflow: propTypes.bool,
  caption: propTypes.string,
  children: checkTableChildren(),
};

export default Table;
