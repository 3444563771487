import PropTypes from 'prop-types';

function Cart(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 3H3.27924C3.70967 3 4.09181 3.27543 4.22792 3.68377L4.5 4.5M4.5 4.5L5.79903 9.26312C5.91769 9.69818 6.31284 10 6.7638 10H10.7996C11.2201 10 11.5957 9.73693 11.7394 9.34174L13.0121 5.84174C13.2493 5.18956 12.7663 4.5 12.0723 4.5H4.5Z"
        stroke={props.color.value}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <circle cx="6.5" cy="13" r="1" fill={props.color.value} />
      <circle cx="11.5" cy="13" r="1" fill={props.color.value} />
    </svg>
  );
}

Cart.propTypes = {
  color: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  size: PropTypes.number,
};

export default Cart;
