export default function HeartOutline({ size }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      viewBox="0 0 40 40"
    >
      <path d="m20 35.333-2.125-1.916q-4.375-3.959-7.229-6.855-2.854-2.895-4.563-5.208-1.708-2.312-2.375-4.187-.666-1.875-.666-3.834 0-3.916 2.645-6.562 2.646-2.646 6.521-2.646 2.292 0 4.292 1.021T20 8.083q1.667-2 3.604-2.979 1.938-.979 4.146-.979 3.917 0 6.562 2.646 2.646 2.646 2.646 6.562 0 1.959-.666 3.834-.667 1.875-2.375 4.166-1.709 2.292-4.563 5.209-2.854 2.916-7.229 6.875Zm0-4.166q4.125-3.792 6.792-6.479Q29.458 22 31.021 20q1.562-2 2.167-3.562.604-1.563.604-3.105 0-2.625-1.688-4.333-1.687-1.708-4.312-1.708-2.084 0-3.834 1.25t-2.708 3.375h-2.542q-.916-2.125-2.687-3.375t-3.813-1.25Q9.583 7.292 7.896 9q-1.688 1.708-1.688 4.333 0 1.542.625 3.125.625 1.584 2.188 3.604 1.562 2.021 4.229 4.688T20 31.167Zm0-11.959Z" />
    </svg>
  );
}
