import {
  createContext,
  useContext,
  useMemo,
  useEffect,
  useRef,
  useState,
} from 'react';
import randomString from 'utils/randomString';
import BackDropLoading from '../components/shared/BackDropLoader';
import filterKeyVal from 'utils/filterKeyVal';

export const LoadingContext = createContext();
export const LoadingSetterContext = createContext();
export const LoadingConsumer = LoadingContext.Consumer;
export const LoadingSetterConsumer = LoadingSetterContext.Consumer;
export const useLoadingContext = () => {
  const ctx = useContext(LoadingContext);
  if (!ctx) {
    throw new Error('must be used withing a LoadingContext');
  }
  return ctx;
};
export const useLoadingSetter = () => {
  const ctx = useContext(LoadingSetterContext);
  if (!ctx) {
    throw new Error('must be used withing a LoadingSetterContext');
  }
  return ctx;
};

export function LoadingProvider(props) {
  const { children } = props;
  const [ids, setIds] = useState({});
  const isLoading = useMemo(() => {
    for (let id of Object.keys(ids)) {
      if (ids[id] === true) {
        return true;
      }
    }
    return false;
  }, [ids]);
  return (
    <LoadingContext.Provider value={ids}>
      <LoadingSetterContext.Provider value={setIds}>
        <BackDropLoading open={isLoading} />
        {children}
      </LoadingSetterContext.Provider>
    </LoadingContext.Provider>
  );
}

/* declaratively register a loading condition that when true will cause the
 * loading backdrop to be shown. Each use of useLoading will register a condition
 * to a random id, so all loading states in the app can be set, updated, and
 * tracked separately.
 */
export function useLoading(condition) {
  const setIds = useLoadingSetter();
  const id = useRef(randomString(32));
  useEffect(() => {
    const currentId = id.current;
    setIds((was) => ({ ...was, [currentId]: condition }));
    return () => setIds((ids) => filterKeyVal(ids, currentId));
  }, [condition, setIds]);
}
