import { useMemo } from 'react';
import styled from 'styled-components';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { Color } from 'design-system/data';

const Dialog = styled((props) => {
  const { children, ...rest } = props;

  // avoids the blue backdrop appearing for full screen dialogs, it is distracting
  const [PaperProps, BackdropProps] = useMemo(() => {
    if (!rest.fullScreen) return [];
    return [
      { style: { backgroundColor: Color.Neutral100.value } },
      { style: { backgroundColor: 'transparent' } },
    ];
  }, [rest.fullScreen]);

  return (
    <MuiDialog
      data-fullwidth={rest.fullWidth}
      data-fullscreen={rest.fullScreen}
      PaperProps={PaperProps}
      BackdropProps={BackdropProps}
      {...rest}
    >
      {children}
    </MuiDialog>
  );
})`
  --dialog_padding: 2em;
  --dialog_width: 100%;
  --dialog_max-width: 600px;

  &[data-fullwidth='true'],
  &[data-fullscreen='true'] {
    --dialog_max-width: unset;
    --dialog_padding: unset;
  }

  [class*='MuiPaper'] {
    width: var(--dialog_width);
    max-width: var(--dialog_max-width);
    padding: var(--dialog_padding);
  }

  h2 {
    font-size: 24px;
    font-weight: bold;
  }
`;

Dialog.Title = styled(function DialogTitle(props) {
  const { children, ...rest } = props;
  return <MuiDialogTitle {...rest}>{children}</MuiDialogTitle>;
})``;

Dialog.Content = styled(function DialogContent(props) {
  const { children, ...rest } = props;
  return <MuiDialogContent {...rest}>{children}</MuiDialogContent>;
})``;

export default Dialog;
