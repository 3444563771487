import { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CircularProgress as MuiCircularProgress } from '@material-ui/core';

const CircularProgress = styled(function CircularProgress({
  bgColor = '#ECECF2',
  color = 'var(--brand-color)',
  hideBackground = false,
  size = 40,
  ...props
}) {
  const {
    // custom props
    children,
    // material ui props
    classes,
    disableShrink,
    thickness,
    value,
    variant,
    // rest
    style: overrideStyles,
    ...rest
  } = props;
  const style = useMemo(() => {
    return {
      '--size': typeof size === 'number' ? `${size}px` : size,
      '--color': color,
      '--bg-color': bgColor,
      ...overrideStyles,
    };
  }, [size, color, bgColor, overrideStyles]);
  return (
    <div style={style} {...rest}>
      {!hideBackground && (
        <MuiCircularProgress
          size={size}
          value={100}
          classes={classes}
          disableShrink={disableShrink}
          thickness={thickness}
          variant={variant}
        />
      )}
      <MuiCircularProgress
        size={size}
        value={value}
        classes={classes}
        disableShrink={disableShrink}
        thickness={thickness}
        variant={variant}
      />
      {children}
    </div>
  );
})`
  position: relative;
  height: var(--size);
  width: var(--size);
  & > * {
    position: absolute;
    top: 0;
    left: 0;
  }
  .MuiCircularProgress-root {
    height: var(--size);
    width: var(--size);
  }
  .MuiCircularProgress-root:nth-child(1) {
    color: var(--bg-color);
  }
  .MuiCircularProgress-root:nth-child(2) {
    color: var(--color);
  }
`;

CircularProgress.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  hideBackground: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CircularProgress;
