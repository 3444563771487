import randomString from 'utils/randomString';
import sortBy from 'utils/sortBy';
import * as Sentry from '@sentry/browser';

/**
 * Helper class wrapping window.localStorage
 *
 * @param {object} options
 * @param {string} options.key - specify a key to store teh value at. If not
 *    specified, will be stored at a random key.
 * @param {number} options.cacheTime - specify how long a value in local storage
 *    should remain returnable. If not specified, it will stay in local storage
 *    for as long as the browser keeps it there.
 *
 * @example
 *   // create an instance with a random key and no cacheTime.
 *   var storage = new LocalStorage();
 *   // store a value in local storage
 *   storage.value = { your: 'value' };
 *   // retrieve a value from local storage
 *   var yourVariable = storage.value
 */

export default class LocalStorage {
  constructor(options = {}) {
    this.namespace = String(options.namespace || '');
    this.key = this.namespace + String(options.key || randomString(8));
    this.cacheTime = Number(options.cacheTime); // milliseconds
    this.clearStrategyWhenFull = options.clearStrategyWhenFull || 'clear_all';
  }

  // getters/setters
  get value() {
    if (this.isStale()) {
      this._remove();
      return undefined;
    }
    return this._read().value;
  }
  set value(val) {
    const record = {
      timestamp: Date.now(),
      value: val,
    };
    try {
      return this._write(record);
    } catch (e) {
      this._clearLeastRecentlyUsed();
      try {
        return this._write(record);
      } catch (e) {
        // nuclear option, localStorage seems totally full
        if (this.clearStrategyWhenFull === 'clear_all') {
          Sentry.captureException(e);
          window.localStorage.clear();
          return this._write(record);
        }
      }
    }
  }

  // public methods
  isStale() {
    const record = this._read();
    if (!record) return true;
    if (Number.isNaN(this.cacheTime)) return false;
    const elapsed = Date.now() - record.timestamp;
    return elapsed > this.cacheTime;
  }
  isFresh() {
    return !this.isStale();
  }
  clearCache() {
    this._remove();
  }
  clearNamespace() {
    if (this.namespace) {
      const keyRegex = new RegExp(`^${this.namespace}`);
      const keys = [];
      for (let idx = 0; idx < window.localStorage.length; idx += 1) {
        const key = window.localStorage.key(idx);
        if (keyRegex.test(key)) {
          keys.push(key);
        }
      }
      keys.forEach((key) => {
        window.localStorage.removeItem(key);
      });
    }
  }

  // private methods
  _read() {
    return JSON.parse(window.localStorage.getItem(this.key));
  }
  _write(record) {
    return window.localStorage.setItem(this.key, JSON.stringify(record));
  }
  _remove() {
    window.localStorage.removeItem(this.key);
  }
  _clearLeastRecentlyUsed() {
    // namespaced, so this localstorage is associated with a set of data blobs,
    // now delete the oldest half of those to free up space.
    if (this.namespace) {
      // collect all localStorage keys associated with the namespace
      const keyGroup = [];
      const keyRegex = new RegExp(`^${this.namespace}`);
      for (let idx = 0; idx < window.localStorage.length; idx += 1) {
        const key = window.localStorage.key(idx);
        if (keyRegex.test(key)) {
          const record = window.localStorage.getItem(key);
          keyGroup.push({ key, timestamp: record.timestamp });
        }
      }
      // clear the older half of the keys
      const sortedKeys = keyGroup.sort(
        sortBy({ order: 'asc', prop: 'timestamp' })
      );
      for (let idx = 0; idx < Math.trunc(sortedKeys.length / 2); idx += 1) {
        const { key } = sortedKeys[idx];
        window.localStorage.removeItem(key);
      }
    }
    // no namespace, so this localstorage is associated with a single data blob,
    // remove it so it may be replaced.
    else {
      this._remove();
    }
  }
}
