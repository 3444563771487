import { arrayOf, func, number, shape, string, oneOf } from 'prop-types';
import {
  TextElement,
  TextKind,
  Text,
  Button,
  ButtonIconPosition,
  IconDirection,
  ButtonKind,
  Avatar,
  Progress,
} from 'design-system/components';
import { Color } from 'design-system/data';
import ScreeningStatusPill from 'components/ScreeningStatusPill';

import withLineBreaks from 'utils/withLineBreaks';
import styles from './pillar-overview-card.module.scss';

function PillarOverviewCard({
  id,
  title,
  description,
  imagePath,
  onClick,
  progress = [],
  screeningStatus,
  buttonText,
  buttonKind = ButtonKind.Primary,
}) {
  return (
    <div className={styles.card}>
      <div className={styles['progress-group']}>
        {!!progress.length &&
          progress?.map((p) => (
            <Progress
              key={p.label}
              percentage={p.percentage}
              label={p.label}
              size={p.size}
              color={
                p.percentage === 100
                  ? Color.Green600.value
                  : Color.Purple600.value
              }
            />
          ))}
      </div>
      <div className={styles['card-content']}>
        <div>
          <div className={styles['title-group']}>
            {imagePath && (
              <Avatar src={imagePath} name={title} initials={title[0]} />
            )}
            <Text kind={TextKind.DisplayXSMedium} element={TextElement.H3}>
              {title}
            </Text>
            {screeningStatus && (
              <ScreeningStatusPill status={screeningStatus} />
            )}
          </div>
          <Text kind={TextKind.TextSM} element={TextElement.P}>
            {withLineBreaks(description)}
          </Text>
        </div>
        <Button
          kind={buttonKind}
          onClick={() => onClick(id)}
          usePhosphorIcon
          iconName="ArrowRight"
          iconSize={20}
          iconDirection={IconDirection.Right}
          iconPosition={ButtonIconPosition.Right}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
}

PillarOverviewCard.propTypes = {
  id: number.isRequired,
  title: string.isRequired,
  description: string,
  iamgePath: string,
  onClick: func.isRequired,
  progress: arrayOf(shape({ label: string, percentage: number })),
  screeningStatus: oneOf(['pass', 'fail', 'needs_information', 'pending']),
  buttonText: string.isRequired,
};

export default PillarOverviewCard;
