import { useMemo } from 'react';

const useFilterOptions = (currentTabData) => {
  const uniqueSubcategories = useMemo(
    () =>
      Array.from(
        new Set(
          currentTabData?.map((req) =>
            req.category ? req.category : 'No Subcategory Available'
          )
        )
      ),
    [currentTabData]
  );

  /** Toggle Row Logic:
   * if there's only 1 subcategory filter, don't show the toggle row at all
   */
  const subcategoryFilterOptions = useMemo(
    () =>
      uniqueSubcategories.map((category, i) => ({
        name: category,
        count: currentTabData?.filter((req) => req.category === category)
          .length,
        id: i.toString(),
      })),
    [uniqueSubcategories, currentTabData]
  );

  return subcategoryFilterOptions;
};

export default useFilterOptions;
