import { bool, string, func, array } from 'prop-types';
import { FileUpload, Modal } from 'design-system/components';

function DocumentUploadModal({
  handleUpload,
  show,
  handleCloseModal,
  title,
  files,
  setFiles,
  onChange,
}) {
  return (
    <Modal
      show={show}
      onClick={handleCloseModal}
      onCancel={handleCloseModal}
      title={title}
      onDone={() => handleUpload.call(files[0])}
      showActionButtons
    >
      <FileUpload files={files} setFiles={setFiles} onChange={onChange} />
    </Modal>
  );
}

DocumentUploadModal.propTypes = {
  show: bool.isRequired,
  title: string.isRequired,
  handleCloseModal: func.isRequired,
  handleUpload: func.isRequired,
  files: array.isRequired,
  setFiles: func.isRequired,
  onChange: func.isRequired,
};

export default DocumentUploadModal;
