export default function QuestionMark({ size, color }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 5.90909V5.90909C5 4.30244 6.30244 3 7.90909 3H8.15041C9.72419 3 11 4.2758 11 5.84959V5.84959C11 7.16477 10.0999 8.30911 8.82177 8.61896L8.72727 8.64187C8.30052 8.74533 8 9.12741 8 9.56652V10.25"
        stroke={color.value}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="8" cy="13.25" r="0.75" fill={color.value} />
    </svg>
  );
}
