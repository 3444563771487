import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { orderBy } from 'lodash';

import {
  BrandViewHeader,
  ButtonKind,
  Modal,
  ModalType,
  NoContent,
  NoData,
  NoDataVariants,
  Table,
  TableBody,
  TableBodyCell,
  TableFoot,
  TableHead,
  TableHeadCell,
  TableRow,
} from 'design-system/components';
import PolicyRow from './PolicyRow';
import { usePolicyRequirements } from './usePolicyRequirements';
import styles from './policy-requirements.module.scss';
import {
  TableReducerAction,
  sortDirection,
  useTableSort,
} from 'design-system/utils';
import { useRequirementActions } from 'views/Brands/shared/RequirementActionModalContents/context';
import SlideoutFooter from 'views/Brands/shared/RequirementActionModalContents/SlideoutFooter';
import RequirementActionModalContentsContainer from 'views/Brands/shared/RequirementActionModalContents';

function PolicyRequirementsView() {
  const [currentRow, setCurrentRow] = useState(1);
  const history = useHistory();
  const { brand_id, requirement_group_id, requirement_id } = useParams();

  const {
    data,
    loading: loadingScreeningResults,
    error: errorScreeningResults,
  } = usePolicyRequirements(requirement_id);

  const {
    setToastOpen,
    conditions,
    conditionsLoading,
    conditionsError,
    handleChange,
    triggerValidation,
    handleConditionValidation,
    modalOpen,
    setModalOpen,
    setCurrentProductId,
    submitRetailerResponse,
    canAttest,
  } = useRequirementActions();

  const breadcrumbItems = [
    {
      text: 'Brand Submissions',
      link: '/retailer/brands/submissions',
    },
    {
      text: 'Brand',
      link: `/retailer/brands/submissions/${brand_id}/requirement_groups`,
    },
    {
      text: 'Requirement Group',
      link: `/retailer/brands/submissions/${brand_id}/requirement_groups/${requirement_group_id}/retailer_requirements`,
    },
    {
      text: data?.name || 'SKUs',
      link: history.location.pathname,
    },
  ];

  const tableHeaderData = [
    {
      label: 'SKUs',
      value: 'consumerProductName',
    },
    {
      label: 'Status',
      value: 'screeningStatus',
    },
    {
      label: '',
      value: 'cta',
    },
  ];
  const [tableState, dispatch] = useTableSort({
    initialSortByValue: tableHeaderData[0].value,
    initialSortAsc: false,
  });

  const onReviewClick = (productId) => {
    setCurrentProductId(productId);
  };

  const hasLoadedSuccessfully = !!data;
  const hasData = !!data?.productScreeningResults?.length;

  return (
    <div>
      <Modal
        show={modalOpen}
        title="Review Brand Attestations"
        type={ModalType.SlideOut}
        onClick={() => {
          setModalOpen(false);
          setToastOpen(false);
        }}
        cancelCtaLabel="Cancel"
        cancelCtaKind={ButtonKind.Tertiary}
        dataCyRoot="review-attestation-modal"
        showActionButtons
        footerSlot={
          <SlideoutFooter
            totalItemCount={data?.count}
            currentRequirementNum={currentRow}
            hideNav
          />
        }
      >
        <RequirementActionModalContentsContainer
          conditions={conditions || []}
          loading={conditionsLoading}
          error={conditionsError}
          onChange={handleChange}
          triggerValidation={triggerValidation}
          handleConditionValidation={handleConditionValidation}
          submitRetailerResponse={submitRetailerResponse}
          isRetailer
          canAttest={canAttest}
        />
      </Modal>
      <BrandViewHeader
        title={data?.name || 'SKUs'}
        subTitle=""
        breadcrumbsItems={breadcrumbItems}
        showExportButton={false}
        showFilterButton={false}
      />
      <div className={styles.table}>
        {/* LOADING STATE */}
        {loadingScreeningResults && !errorScreeningResults && (
          <NoContent loading />
        )}
        {/* ERROR STATE */}
        {errorScreeningResults && (
          <NoData
            hasErrorOccurred={!!errorScreeningResults}
            noContentMessage="Something went wrong. Please try again or contact support."
          />
        )}
        {/* NO DATA STATE */}
        {hasLoadedSuccessfully && !hasData && (
          <NoContent variant={NoDataVariants.Default} />
        )}
        {/* HYDRATED TABLE */}
        {hasLoadedSuccessfully && hasData && (
          <>
            <Table>
              <TableHead>
                <TableRow>
                  {tableHeaderData?.map((data) => (
                    <TableHeadCell
                      key={data?.value}
                      enableSort
                      active={data?.value === tableState.sortBy}
                      direction={sortDirection(tableState, data?.value)}
                      onClick={() =>
                        dispatch({
                          type: TableReducerAction.LabelClick,
                          sortBy: data?.value,
                        })
                      }
                    >
                      {data?.label}
                    </TableHeadCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {orderBy(
                  data?.productScreeningResults,
                  tableState.sortBy,
                  sortDirection(tableState)
                ).map((productScreeningResult, i) => (
                  <PolicyRow
                    key={i}
                    policy={productScreeningResult}
                    onClick={() => {
                      setModalOpen(true);
                      setCurrentRow(i + 1);
                      onReviewClick(productScreeningResult.consumerProductId);
                    }}
                  />
                ))}
              </TableBody>
              <TableFoot bgWhite>
                <TableRow>
                  <TableBodyCell>
                    <span className="sr-only" />
                  </TableBodyCell>
                </TableRow>
              </TableFoot>
            </Table>
          </>
        )}
      </div>
    </div>
  );
}

export default PolicyRequirementsView;
