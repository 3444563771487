import { PillColor } from '../components/atoms/pill/constants';

export const ScreeningStatus = {
  Pending: 'pending',
  Pass: 'pass',
  Fail: 'fail',
  NeedsInformation: 'needs_information',
  NotApplicable: 'not_applicable',
  Updating: 'updating',
  Rejected: 'rejected',
  Approved: 'approved', // not a real screening status - but is a real retailer status
  Excluded: 'excluded',
  Unresolved: 'unresolved', // not a real status
  NotStarted: 'not_started', // not a real status
  NeedsAttention: 'needs_attention', // not a real status
  All: 'all', // not a real status
};

export const ScreeningStatusLabel = {
  [ScreeningStatus.Pending]: 'Pending',
  [ScreeningStatus.Pass]: 'Approved',
  [ScreeningStatus.Fail]: 'Does not meet',
  [ScreeningStatus.NeedsInformation]: 'Needs attention',
  [ScreeningStatus.NeedsAttention]: 'Needs attention', // not a real status
  [ScreeningStatus.NotApplicable]: 'Not applicable',
  [ScreeningStatus.Unresolved]: 'Unresolved', // not a real status.
  [ScreeningStatus.NotStarted]: 'Not started', // not a real status
  [ScreeningStatus.Updating]: 'Updating',
  [ScreeningStatus.Excluded]: 'Excluded',
  [ScreeningStatus.Rejected]: 'Rejected',
  Meets: 'Meets',
};

export const ScreeningStatusPillData = {
  [ScreeningStatus.Pending]: {
    label: ScreeningStatusLabel[ScreeningStatus.Pending],
    color: PillColor.Blue,
  },
  [ScreeningStatus.Updating]: {
    label: ScreeningStatusLabel[ScreeningStatus.Pending],
    color: PillColor.Blue,
  },
  [ScreeningStatus.Pass]: {
    label: ScreeningStatusLabel[ScreeningStatus.Pass],
    color: PillColor.Green,
  },
  [ScreeningStatus.Fail]: {
    label: ScreeningStatusLabel[ScreeningStatus.Fail],
    color: PillColor.Red,
  },
  [ScreeningStatus.NeedsInformation]: {
    label: ScreeningStatusLabel[ScreeningStatus.NeedsInformation],
    color: PillColor.Orange,
  },
  [ScreeningStatus.NeedsAttention]: {
    label: ScreeningStatusLabel[ScreeningStatus.NeedsAttention],
    color: PillColor.Orange,
  },
  [ScreeningStatus.NotStarted]: {
    label: ScreeningStatusLabel[ScreeningStatus.NotStarted],
    color: PillColor.Orange,
  },
  [ScreeningStatus.NotApplicable]: {
    label: ScreeningStatusLabel[ScreeningStatus.NotApplicable],
    color: PillColor.Default,
  },
  [ScreeningStatus.Unresolved]: {
    label: ScreeningStatusLabel[ScreeningStatus.Unresolved],
    color: PillColor.Default,
  },
  [ScreeningStatus.Excluded]: {
    label: ScreeningStatusLabel[ScreeningStatus.Excluded],
    color: PillColor.Default,
  },
};

export const FilterDropdownOptions = [
  {
    value: ScreeningStatus.Pending,
    label: ScreeningStatusLabel[ScreeningStatus.Pending],
  },
  { value: ScreeningStatus.Pass, label: ScreeningStatusLabel.Meets },
  {
    value: ScreeningStatus.Fail,
    label: ScreeningStatusLabel[ScreeningStatus.Fail],
  },
  {
    value: ScreeningStatus.NeedsInformation,
    label: ScreeningStatusLabel[ScreeningStatus.NeedsAttention],
  },
  {
    value: ScreeningStatus.NotApplicable,
    label: ScreeningStatusLabel[ScreeningStatus.NotApplicable],
  },
];
