import { Switch, Route } from 'react-router-dom';
import { ToastV2Provider } from 'design-system/components';
import { PATHS } from 'constants/index';
import SubmissionConfirmation from '../Submissions/Confirmation';

import { FDAProductsProvider } from './Context';
import ProductsView from './view';
import { FDASubmissionConfirmationProvider } from '../Submissions/Confirmation/Context';

const Products = () => {
  return (
    <ToastV2Provider>
      <FDAProductsProvider>
        <FDASubmissionConfirmationProvider>
          {/* TODO: We will recycle the submission confirmation when we move to allowing draft state submissions, accessible via the submissions page */}
          <Switch>
            <Route
              path={PATHS.fdaRegistrationProduct}
              component={ProductsView}
              exact
            />
            <Route
              path={PATHS.fdaRegistrationSubmissionConfirmation}
              component={SubmissionConfirmation}
              exact
            />
          </Switch>
        </FDASubmissionConfirmationProvider>
      </FDAProductsProvider>
    </ToastV2Provider>
  );
};

export default Products;
