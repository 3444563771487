import { UrlParam } from 'design-system/data';
import { convertSnakeToCamelCase } from 'design-system/utils';

import useQueryParams from 'hooks/useQueryParams';
import useApi from 'services/api/useApi';

export const useProductScreeningResults = (policyId, screeningStatus = '') => {
  const params = useQueryParams(Object.values(UrlParam));
  const { data, loading, error, refetch } = useApi(
    `/api/v4/requirements/${policyId}/product_screening_results`,
    {
      param: {
        ...params._asValues(),
        'screening_status': screeningStatus,
      },
    }
  );
  return {
    data: convertSnakeToCamelCase(data),
    loading,
    error,
    refetch,
  };
};
