import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    height: '100vh',
  },
}));

const NoNav = (props) => {
  const { children, title } = props;

  if (title) {
    document.title = title;
  }
  const classes = useStyles();
  return <main className={classes.root}>{children}</main>;
};

export default NoNav;
