import { useState, useRef } from 'react';
import { arrayOf, bool, shape, string, number, func } from 'prop-types';
import {
  Table,
  TableHead,
  TableHeadCell,
  NoData,
  TableBody,
  TableRow,
  Toast,
  ToastKind,
} from 'design-system/components';
import {
  TableReducerAction,
  sortDirection,
  useTableSort,
} from 'design-system/utils';
import { orderBy } from 'lodash';
import useAsyncCall from 'services/api/useAsyncCall';
import fetcher from 'services/api/fetcher';
import DocumentUploadModal from 'components/DocumentUploadModal';
import styles from './documents-table.module.scss';
import DocumentsTableRow from './documents-table-row';
import RetailerNotesModal from 'components/RetailerNotesModal';

function DocumentsTable({ documents, loading, refetchDocuments, error }) {
  const toastRef = useRef();
  const [files, setFiles] = useState([]);
  const [showDocumentModal, setShowDocumentModal] = useState({
    show: false,
    document: null,
  });

  const [showRetailerNotesModal, setShowRetailerNotesModal] = useState({
    show: false,
    document: null,
  });

  const [tableState, dispatch] = useTableSort({
    initialSortByValue: 'documentName',
  });

  const makeDocumentUploadUrl = () => {
    const { document } = showDocumentModal;

    switch (document.documentType) {
      case 'consumer_product':
        return `/api/v4/consumer_products/${document.consumerProductId}/consumer_product_policy_conditions/${document.policyCondition?.id}/condition_document`;
      case 'consumer_brand':
        return `/api/v4/brands/${document.consumerBrandId}/consumer_brand_policy_conditions/${document.policyCondition?.id}/condition_document`;
      case 'consumer_package':
        return `/api/product_design/v1/consumer_products/${document.consumerProductId}/consumer_package_policy_conditions/${document.policyCondition?.id}/condition_document`;
      case 'formulation':
        return `/api/v4/user_actions/${document.id}/upload_document`;
      default:
        return null;
    }
  };

  const handleCloseDocumentModal = () => {
    setShowDocumentModal({ show: false, document: null });
    setFiles([]);
  };

  const handleDocumentSubmit = useAsyncCall(async (document) => {
    const url = makeDocumentUploadUrl();
    const formdata = new FormData();
    formdata.append('document', document);
    await fetcher(url, { method: 'post', body: formdata });
    toastRef.current.launchToast();
    refetchDocuments();
    handleCloseDocumentModal();
  });

  if (!documents.length) {
    return (
      <NoData
        isLoading={loading}
        hasErrorOccurred={!!error}
        noContentMessage="There's nothing much here right now"
      >
        There's nothing much here right now
      </NoData>
    );
  }

  const handleDocumentChange = (file) => {
    setFiles([...files, file]);
  };

  return (
    <>
      <DocumentUploadModal
        show={showDocumentModal.show}
        files={files}
        onChange={handleDocumentChange}
        setFiles={setFiles}
        handleCloseModal={handleCloseDocumentModal}
        title="Re-upload document"
        handleUpload={handleDocumentSubmit}
      />
      <RetailerNotesModal
        title="Retailer notes"
        show={showRetailerNotesModal.show}
        documentName={showRetailerNotesModal?.document?.documentName}
        retailerNotes={showRetailerNotesModal?.document?.retailerNotes}
        handleCloseModal={() => setShowRetailerNotesModal({ show: false })}
      />
      <div className={styles.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell
                enableSort
                active
                onClick={() =>
                  dispatch({
                    type: TableReducerAction.LabelClick,
                    sortBy: 'documentName',
                  })
                }
                direction={sortDirection(tableState, 'documentName')}
              >
                Document
              </TableHeadCell>
              <TableHeadCell>Retailer</TableHeadCell>
              <TableHeadCell>Product</TableHeadCell>
              <TableHeadCell>Policy</TableHeadCell>
              <TableHeadCell>
                <span className="sr-only">Document status</span>
              </TableHeadCell>
              <TableHeadCell>
                <span className="sr-only">Document actions</span>
              </TableHeadCell>
            </TableRow>
          </TableHead>
          {!!documents.length && (
            <TableBody>
              {orderBy(
                documents,
                tableState.sortBy,
                sortDirection(tableState)
              ).map((document) => (
                <DocumentsTableRow
                  document={document}
                  setShowDocumentModal={setShowDocumentModal}
                  setShowRetailerNotesModal={setShowRetailerNotesModal}
                />
              ))}
            </TableBody>
          )}
        </Table>
      </div>
      <Toast ref={toastRef} kind={ToastKind.Success} title="Success">
        Document successfully uploaded!
      </Toast>
    </>
  );
}

DocumentsTable.propTypes = {
  documents: arrayOf(
    shape({
      name: string,
      createdAt: string,
      documentName: string,
      id: number,
      policy: shape({
        name: string,
        id: number,
      }),
      retailer: shape({
        id: number,
        name: string,
        image: string,
      }),
      status: string,
      updatedAt: string,
    })
  ).isRequired,
  loading: bool,
  refetchDocuments: func,
};

export default DocumentsTable;
