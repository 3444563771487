import { array, bool, func } from 'prop-types';
import {
  NoData,
  TextElement,
  TextKind,
  Text,
  ButtonKind,
  PillarOverviewCard,
} from 'design-system/components';

import styles from './policies.module.scss';
import ActionButtons from '../../components/ReviewModal/ActionButtons';

const hasNonEmptyRequirements = (requirementGroup) =>
  requirementGroup?.totalRequirements !== 0;

const RequirementGroups = ({
  loading,
  policies,
  error,
  handleReviewLink,
  handleReviewAction,
  setReviewType,
}) => {
  const RGNoData = () => (
    <NoData
      hasErrorOccurred={!!error}
      errorMessage="Something went wrong while loading your submissions. Please check back later"
      noContentMessage="Nothing to see here yet!"
      description="No SKUs have been uploaded and the brand has not started the attestation process"
      isLoading={loading}
    />
  );

  if (!policies || loading) {
    return <RGNoData />;
  }

  const orderedPolicies = policies.sort((a, b) => {
    if (a.totalRequirements === 0) {
      return 1;
    }
    if (b.totalRequirements === 0) {
      return -1;
    }
    return 0;
  });

  return (
    !!orderedPolicies.length &&
    orderedPolicies.map((policy) => {
      return (
        <div key={policy.id} className={styles.policy}>
          <div className={styles.title}>
            <Text element={TextElement.H2} kind={TextKind.DisplayXSMedium}>
              {policy.name}
            </Text>
            {policy.totalRequirements !== 0 && (
              <ActionButtons
                // className={styles['action-button-container']}
                submissionStatus={policy?.submissionStatus}
                policySubmissionId={policy?.policySubmission?.id}
                setReviewType={setReviewType}
                handleReviewAction={handleReviewAction}
              />
            )}
          </div>
          {policy.totalRequirements !== 0 &&
            policy.retailerPolicyRequirementGroups
              .filter(hasNonEmptyRequirements)
              .map((group) => {
                const progress = [
                  { label: 'Complete', percentage: group.percentComplete },
                ];
                return (
                  <PillarOverviewCard
                    key={group.id}
                    id={group.id}
                    title={group.name}
                    description={group.description}
                    imagePath={group.imagePath}
                    progress={progress}
                    screeningStatus={group.screeningResult}
                    onClick={handleReviewLink}
                    buttonKind={ButtonKind.Alternate}
                    buttonText="Review"
                  />
                );
              })}

          {policy.totalRequirements === 0 && <RGNoData />}
        </div>
      );
    })
  );
};

RequirementGroups.propTypes = {
  loading: bool.isRequired,
  policies: array.isRequired,
  error: bool.isRequired,
  handleReview: func.isRequired,
  handleApprove: func.isRequired,
  handleReject: func.isRequired,
};

export default RequirementGroups;
