function Caret(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 10L4 6H12L8 10Z" fill={props.color.value} />
    </svg>
  );
}

export default Caret;
