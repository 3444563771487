import styled from 'styled-components';

const DialogStyles = styled.div`
  display: content;

  .button-box {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    button {
      display: flex;
      align-items: center;
    }

    &.with-padding {
      padding: 20px;
    }
  }

  .content {
    width: 60%;
    margin: 0 auto;
    margin-bottom: 50px;
    font-family: Circular;

    .MuiSelect-root {
      font-weight: 400;
    }
  }

  .title {
    font-weight: 700;
    font-size: 26px;
    margin-bottom: 40px;
  }

  .subtitle {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 25px;

    &.resolve-subtitle {
      margin-top: 30px;
    }
  }

  .input {
    width: 100%;
    margin-bottom: 20px;
    color: black;
    font-size: 14px;
  }

  label {
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 10px;
  }

  .styled-select {
    background-color: white;
    width: 100%;
    font-size: 14px;
    letter-spacing: normal;
    color: black;
    font-size: 14px;
    font-weight: 400;
    &[data-active='true'] {
      color: gray;
    }
    & [class*='MuiSelect-select'] {
      &:focus {
        background-color: hsla(
          var(--brand-color-h),
          var(--brand-color-s),
          var(--brand-color-l),
          0.05
        );
        border: none;
        outline: none;
      }
    }
    & [class*='MuiSelect-outlined'] {
      padding: 10px 16px;
    }
    & [class*='MuiOutlinedInput-notchedOutline'] {
      border-color: #ececf2;
    }
  }
`;

export default DialogStyles;
