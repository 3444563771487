import styles from './warning-rings.module.scss';
import { PhosphorIcon } from 'design-system/components';
import { Color } from 'design-system/data';

function WarningRings({ iconName, iconColor }) {
  return (
    <div className={styles['ring-container']}>
      <div className={styles['ring-one']}>
        <div className={styles['warning-icon-container']}>
          <PhosphorIcon
            iconName={iconName || 'Warning'}
            color={iconColor || Color.Orange600.value}
            size={24}
          />
          <span className={styles['ring-two']} />
          <span className={styles['ring-three']} />
          <span className={styles['ring-four']} />
        </div>
      </div>
    </div>
  );
}

export default WarningRings;
