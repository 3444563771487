import { useState } from 'react';
import propTypes from 'prop-types';
import { PATHS } from 'constants/index';
import {
  Breadcrumbs,
  Link,
  LinkKind,
  LinkVariant,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';
import { useApp } from 'context/AppContext';
import styles from './material-content.module.scss';
import SupplierMaterialLiterature from '../literature';
import SupplierMaterialFileUpload from '../file-upload';
import SupplierMaterialDocumentation from '../documentation';
import { PLACEHOLDER } from 'design-system/data';

export default function SupplierMaterialContent({ data }) {
  const { user } = useApp();
  const hasPolicyVettingAccess = user.hasFF('vetting_in_supplier_portal');
  const [files, setFiles] = useState(
    data?.documents.length ? data.documents : []
  );

  return (
    <div className={styles.container}>
      <Breadcrumbs
        items={[
          { text: 'Listings Management', link: PATHS.supplierListings },
          { text: data?.name || PLACEHOLDER, link: '' },
        ]}
      />
      <Text
        className={styles.title}
        kind={TextKind.DisplaySMBold}
        element={TextElement.H1}
        data-cy="supplier-material-page-title"
      >
        {data?.name || '-'}
      </Text>
      {hasPolicyVettingAccess && (
        <div className={styles['policies-link']}>
          <Text kind={TextKind.TextSM} element={TextElement.P}>
            See screening results of this material against available policies
          </Text>
          <Link
            kind={LinkKind.Internal}
            variant={LinkVariant.Primary}
            to={`${PATHS.supplierMaterial}/${data?.id}/policies`}
          >
            Check policies now
          </Link>
        </div>
      )}
      <div className={styles.split}>
        <Text kind={TextKind.Display2XSBold} element={TextElement.H2}>
          Product Details
        </Text>
        <SupplierMaterialLiterature data={data} />
      </div>
      <SupplierMaterialDocumentation data={files} />
      <SupplierMaterialFileUpload
        materialID={data?.id}
        onSuccess={(data) => setFiles([...files, data])}
      />
    </div>
  );
}

SupplierMaterialContent.propTypes = {
  data: propTypes.object,
};
