import propTypes from 'prop-types';
import styles from './pill.module.scss';
import { PillColor } from './constants';

function Pill(props) {
  let classNames = styles.root;
  if (props.color && props.color !== PillColor.Default) {
    classNames += ` ${styles[props.color]}`;
  }
  if (props.isRound) {
    classNames += ` ${styles.round}`;
  }
  return (
    <div className={classNames} data-cy={props.dataCy}>
      {props.children}
    </div>
  );
}

Pill.propTypes = {
  children: propTypes.oneOfType([
    propTypes.string,
    propTypes.number,
    propTypes.node,
  ]).isRequired,
  color: propTypes.oneOf(Object.values(PillColor)),
  isRound: propTypes.bool,
  dataCy: propTypes.string,
};

export default Pill;
