export const isRetailerWithQuicksight = (retailerID = null) => {
  const RETAILER_IDS_WITH_QUICKSIGHT_ACCESS = [
    36, // Credo
    37, // Ulta
    100, // Macys
    134, // Sorette
    67, // Sepohra
  ];
  return RETAILER_IDS_WITH_QUICKSIGHT_ACCESS.includes(retailerID);
};
