import { useEffect, useState, useMemo } from 'react';
import propTypes from 'prop-types';
import {
  DropdownMenu,
  Icon,
  IconDirection,
  IconName,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';
import { Color } from 'design-system/data';
import FilterModalDropdownItem from './filter-modal-dropdown-item';
import styles from './filter-modal-dropdown.module.scss';
import {
  getPluralFormFromSingular,
  getSingularFormFromPlural,
} from 'design-system/utils';

const FilterModalDropdown = ({
  title,
  options,
  activeIDs,
  onChange,
  dataCyRoot,
  isSingleSelect,
}) => {
  const [selectedFilters, setSelectedFilters] = useState(activeIDs);

  useEffect(() => {
    setSelectedFilters(activeIDs);
  }, [activeIDs]);

  const determinePlaceholderText = useMemo(() => {
    const lowerCaseTitle = title.toLowerCase();
    const numberOfSelectedFilters = selectedFilters?.length;

    if (!numberOfSelectedFilters) {
      return `Select ${lowerCaseTitle}...`;
    }

    let formattedTitle = lowerCaseTitle.substring(0, lowerCaseTitle.length);

    if (numberOfSelectedFilters > 1) {
      formattedTitle = getPluralFormFromSingular(formattedTitle);
    } else {
      formattedTitle = getSingularFormFromPlural(formattedTitle);
    }

    if (isSingleSelect) {
      return selectedFilters[0].name;
    }

    return `${numberOfSelectedFilters} ${formattedTitle} selected`;
  }, [selectedFilters, isSingleSelect, title]);

  function updateFilters(selection, isActive) {
    let newValue;
    if (isActive && !selectedFilters.includes(selection)) {
      newValue = isSingleSelect ? [selection] : [...selectedFilters, selection];
    } else {
      newValue = selectedFilters.filter((item) => item.id !== selection.id);
    }
    onChange(newValue);
  }

  const mappedSelectedFilters = useMemo(() => {
    return selectedFilters.map((filter) => (filter.id ? filter.id : filter));
  }, [selectedFilters]);

  return (
    <div className={styles.container}>
      <Text
        kind={TextKind.TextXSBold}
        element={TextElement.H3}
        className={styles.title}
      >
        {title}
      </Text>
      <DropdownMenu
        isFixed
        fullWidth
        multiSelect
        enableAutomaticPositioning
        triggerElement={
          <div
            className={styles.trigger}
            data-cy={`${dataCyRoot}-${title.toLowerCase()}-dropdown`}
          >
            <Text
              kind={TextKind.TextMD}
              element={TextElement.Span}
              color={Color.Neutral500}
            >
              {determinePlaceholderText}
            </Text>
            <Icon
              name={IconName.Chevron}
              direction={IconDirection.Down}
              size={10}
            ></Icon>
          </div>
        }
      >
        {options.map((option, index) => (
          <FilterModalDropdownItem
            key={`${option?.name}-${index}`}
            value={option?.name}
            isActive={mappedSelectedFilters?.includes(option?.id)}
            onClick={(isSelected) => updateFilters(option, isSelected)}
            data-cy={`${dataCyRoot}-${title.toLowerCase()}-option-${index}`}
          />
        ))}
      </DropdownMenu>
    </div>
  );
};

FilterModalDropdown.propTypes = {
  title: propTypes.string.isRequired,
  options: propTypes.array.isRequired,
  activeIDs: propTypes.array,
  onChange: propTypes.func,
  dataCyRoot: propTypes.string,
  isSingleSelect: propTypes.bool,
  singularTitle: propTypes.string,
};

export default FilterModalDropdown;
