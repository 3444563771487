import { convertSnakeToCamelCase } from 'design-system/utils';
import { useApp } from 'context/AppContext';
import fetcher from 'services/api/fetcher';

export function usePolicies(brandId) {
  const { useApi, user } = useApp();
  const retailerId = user?.organization?.in_orgable_id;

  const url = `/api/v4/retailers/${retailerId}/consumer_brands/${brandId}/policies`;

  const { data, loading, error } = useApi(url, {
    enabled: !!retailerId && !!brandId,
  });
  return {
    data: convertSnakeToCamelCase(data),
    loading,
    error,
  };
}

export async function reviewBrand(policySubmissionId, response, noteValue) {
  const url = `/api/v4/policy_submissions/${policySubmissionId}/respond`;
  await fetcher(url, {
    method: 'put',
    body: {
      status: response,
      retailer_notes: noteValue,
    },
  });
}
