import { createContext, useContext, useEffect, useMemo, useState } from 'react';

const NavContext = createContext(null);

export const useNavContext = () => {
  const context = useContext(NavContext);
  if (!context) {
    throw new Error('Must be used with a NavProvider');
  }
  return context;
};

export const NavProvider = ({ children }) => {
  const [hideNav, setHideNav] = useState(false);
  const [hideMobileNav, setHideMobileNav] = useState(true);

  const context = useMemo(() => {
    return {
      useHiddenNav: () => {
        useEffect(() => {
          setHideNav(true);
          return () => setHideNav(false);
        }, []);
      },
      useToggleMobileNav: () => {
        useEffect(() => {
          return setHideMobileNav((prev) => !prev);
        }, []);
      },
      hideMobileNav,
      hideNav,
      setHideMobileNav,
    };
  }, [hideMobileNav, hideNav]);

  return <NavContext.Provider value={context}>{children}</NavContext.Provider>;
};
