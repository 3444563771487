function Delete(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.25 3.5H3.75C3.33579 3.5 3 3.83579 3 4.25C3 4.38807 3.11193 4.5 3.25 4.5H12.75C12.8881 4.5 13 4.38807 13 4.25C13 3.83579 12.6642 3.5 12.25 3.5Z"
        fill={props.color.value}
      />
      <path
        d="M4 12.5V5.5H12V12.5C12 13.0523 11.5523 13.5 11 13.5H5C4.44772 13.5 4 13.0523 4 12.5Z"
        fill={props.color.value}
      />
      <path d="M10 7.5L6 11.5" stroke="white" />
      <path
        d="M6 3.5V4H10V3.5C10 2.94772 9.55228 2.5 9 2.5H7C6.44772 2.5 6 2.94772 6 3.5Z"
        fill={props.color.value}
      />
      <path d="M6 7.5L10 11.5" stroke="white" />
    </svg>
  );
}

export default Delete;
