import { useEffect, useRef, useState } from 'react';
import propTypes from 'prop-types';
import cn from 'classnames';
import { Icon, IconName, LoadingSpinner } from 'design-system/components';
import { KeyboardCode, useClickoff } from 'design-system/utils';
import SearchItem from './search-item';
import styles from './search-bar.module.scss';

const SearchBar = ({
  placeholder,
  displayValue,
  results,
  onChange,
  onClick,
  multiSelect,
  alwaysShowResults,
  suppressResults,
  onBlur,
  onFocus,
  onEnter,
  disabled,
  activeIDs,
  isLoading = false,
  [`data-cy`]: dataCy,
}) => {
  const containerRef = useRef();
  const inputRef = useRef();
  const [showResults, setShowResults] = useState(alwaysShowResults);

  useClickoff(containerRef, () => {
    if (showResults && !alwaysShowResults) {
      setShowResults(false);
    }
  });

  const handleOnFocus = () => {
    setShowResults(true);
    onFocus && onFocus();
  };

  useEffect(() => {
    inputRef.current.value = displayValue || '';
  }, [displayValue]);

  const handleChange = (event) => {
    const inputValue = event.target.value;
    if (!alwaysShowResults) {
      setShowResults(
        inputValue?.length > 1 &&
          !(event?.key === KeyboardCode.Backspace && inputValue.length === 1)
      );
    }
    onChange && onChange(inputValue);
  };

  const handleClick = (result) => {
    onClick && onClick(result);
  };

  return (
    <div
      ref={containerRef}
      className={cn([styles.container, disabled ? styles.disabled : ''])}
    >
      <div className={styles.input}>
        {isLoading ? (
          <LoadingSpinner darkMode />
        ) : (
          <Icon name={IconName.Search} size={24} />
        )}
        <input
          disabled={disabled}
          onFocus={handleOnFocus}
          onBlur={onBlur}
          ref={inputRef}
          placeholder={placeholder}
          onKeyDown={(event) => {
            if (event.key === KeyboardCode.Enter) {
              onEnter && onEnter(event.target.value);
            }
          }}
          onChange={handleChange}
          data-cy={dataCy}
        />
      </div>
      {showResults && !!results?.length && !suppressResults && (
        <ul className={styles.results} data-cy="search-results-ul">
          {results?.map((result, i) => {
            if (typeof result === 'object') {
              return (
                <SearchItem
                  key={result.id + i}
                  value={result.id}
                  displayValue={result.name}
                  hasCheckbox={multiSelect}
                  onClick={() => handleClick(result)}
                  isActive={activeIDs.some(
                    (activeFilter) => activeFilter.name === result.name
                  )}
                />
              );
            }
            return (
              <SearchItem
                key={result + i}
                value={result}
                hasCheckbox={multiSelect}
                onClick={() => handleClick(result)}
              />
            );
          })}
        </ul>
      )}
    </div>
  );
};

SearchBar.propTypes = {
  placeholder: propTypes.string.isRequired,
  displayValue: propTypes.string,
  results: propTypes.array,
  onChange: propTypes.func,
  onClick: propTypes.func,
  multiSelect: propTypes.bool,
  alwaysShowResults: propTypes.bool,
  suppressResults: propTypes.bool,
  onBlur: propTypes.func,
  onFocus: propTypes.func,
  onEnter: propTypes.func,
  disabled: propTypes.bool,
  activeIDs: propTypes.arrayOf(propTypes.string),
  isLoading: propTypes.bool,
  [`data-cy`]: propTypes.string,
};

export default SearchBar;
