import cn from 'classnames';
import {
  Link,
  LinkKind,
  LinkVariant,
  Text,
  TextKind,
  TextElement,
  PhosphorIcon,
} from 'design-system/components';

import magnifyingGlassSvg from 'assets/illustrations/magnifying-glass.svg';
import styles from '../retailer-brand-dashboard.module.scss';

const ContactSupportCard = () => {
  return (
    <div
      className={cn([styles.resources, styles.panel, styles['full-height']])}
    >
      <div>
        <div className={styles.xl}>
          <Text kind={TextKind.DisplayXS} element={TextElement.H3}>
            Can&apos;t find what you&apos;re looking for?
          </Text>
        </div>
        <Text
          className={styles.subtext}
          kind={TextKind.TextSM}
          element={TextElement.P}
        >
          We're here to help! Please visit our{' '}
          <Link
            kind={LinkKind.External}
            href="https://noviconnect.notion.site/MoCRA-Knowledge-Nook-d4a5d2a1f3304a7d941f0c2c0e0ca1f5"
          >
            Knowledge Nook
          </Link>{' '}
          or contact us with any questions at{' '}
          <Text
            className={styles['contact-text']}
            kind={TextKind.TextSMBold}
            element={TextElement.Span}
          >
            mocra@noviconnect.com
          </Text>
        </Text>
        <Link
          variant={LinkVariant.Primary}
          kind={LinkKind.External}
          href="https://knowledgebase.noviconnect.com/mocra-support"
        >
          Get in touch <PhosphorIcon iconName="ArrowRight" />
        </Link>
      </div>
      <img
        src={magnifyingGlassSvg}
        alt="magnifying glass"
        onError={() => <span className={styles.fallback} />}
        style={{ height: '7.5rem' }}
      />
    </div>
  );
};

export default ContactSupportCard;
