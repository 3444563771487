export const IconName = {
  AddUser: 'add-user',
  Arrow: 'arrow',
  Alert: 'alert',
  AlertRound: 'alert-round',
  Caret: 'caret',
  Certs: 'certs',
  Check: 'check',
  CheckGreenRound: 'check-green-round',
  Chevron: 'chevron',
  Close: 'close',
  Document: 'document',
  Download: 'download',
  ExtLink: 'ext-link',
  Info: 'info',
  Filter: 'filter',
  NoContent: 'no-content',
  Success: 'success',
  Subdirectory: 'subdirectory',
  Kebab: 'kebab',
  Credo: 'credo',
  Sephora: 'sephora',
  Target: 'target',
  Edit: 'edit',
  Delete: 'delete',
  Plus: 'plus',
  Formula: 'formula',
  Package: 'package',
  Products: 'products',
  Cart: 'cart',
  Grid: 'grid',
  Upload: 'upload',
  ApiError: 'api-error',
  List: 'list',
  DollarSign: 'dollar-sign',
  Time: 'time',
  Building: 'building',
  Bullhorn: 'bullhorn',
  Gear: 'gear',
  Search: 'search',
  ChatColorful: 'chat-colorful',
  Cluster: 'cluster',
  Node: 'node',
  QuestionMark: 'question-mark',
  Hyphen: 'hyphen',
  ExclamationMark: 'exclamation-mark',
  Status: 'status',
  Ingredient: 'ingredient',
  Heart: 'heart',
  HeartOutline: 'heart-outline',
  Star: 'star',
  StarOutline: 'star-outline',
  SpecialOffers: 'special-offers',
  InnovativeIngredient: 'innovative-ingredient',
  Packaging: 'packaging',
  ArrowV2: 'arrow-v2',
  CancelCircleFilled: 'cancel-circle-filled',
};

export const IconVersion = {
  Small: 'small',
  Large: 'large',
};

export const IconDirection = {
  Up: 'up',
  Right: 'right',
  Down: 'down',
  Left: 'left',
};
