import { useState, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Button,
  ButtonKind,
  Text,
  TextKind,
  TextElement,
  PhosphorIcon,
} from 'design-system/components';
import { Color } from 'design-system/data';

import styles from './sku-upload-steps.module.scss';
import { useUploadSkuContext } from '../context/UploadSkuContext';
import { usePaywallContext } from '../context/PaywallContext';
import { MAX_PRODUCTS } from '../upload-validations/constants';

// Containerize button so ref instantiates properly.
const SeymoreButton = ({ onClick, showAll }) => {
  const buttonRef = useRef(null);
  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current?.focus();
    }
  }, []);
  return (
    <Button
      refProp={buttonRef}
      kind={ButtonKind.Alternate}
      onClick={onClick}
      className={styles['fixed-button']}
    >
      {showAll ? 'See less' : 'See more'}
    </Button>
  );
};

export const SkuUploadSteps = () => {
  const { onDrop, shouldOpenPaywall, validationErrors } = useUploadSkuContext();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'text/csv': ['.csv'],
    },
  });
  const { openPaywall } = usePaywallContext();
  const rootProps = getRootProps();
  const MINIMIZED_ERROR_ROWS = 3;
  const [showAll, setShowAll] = useState(false);

  const visibleErrors = showAll
    ? validationErrors
    : validationErrors.slice(0, MINIMIZED_ERROR_ROWS);
  return (
    <div>
      <div className={styles['upload-row']}>
        <div className={styles['upload-row-item']}>
          <Text
            kind={TextKind.TextSM}
            element={TextElement.P}
            color={Color.Neutral600}
          >
            Step 1
          </Text>
          <a
            className={styles['download-link']}
            href={`${process.env.PUBLIC_URL}/templates/bulk_upload_template.csv`}
          >
            <div className={styles['upload-box']}>
              <div className={styles['icon-box']}>
                <PhosphorIcon
                  color={Color.Neutral500.value}
                  iconName="CloudArrowDown"
                  size={20}
                />
              </div>
              <Text
                className={styles['center-text']}
                kind={TextKind.TextSM}
                element={TextElement.P}
                color={Color.Neutral600}
              >
                <Text
                  kind={TextKind.TextSMSemibold}
                  element={TextElement.Span}
                  color={Color.Purple700}
                >
                  Click to download
                </Text>{' '}
                the template to format your CSV{' '}
                <Text kind={TextKind.TextXS} element={TextElement.Span}>
                  (max {MAX_PRODUCTS} rows)
                </Text>
              </Text>
            </div>
          </a>
        </div>
        <div className={styles['upload-row-item']}>
          <input {...getInputProps()} />
          <Text
            kind={TextKind.TextSM}
            element={TextElement.P}
            color={Color.Neutral600}
          >
            Step 2
          </Text>
          <div
            {...rootProps}
            className={`${styles['upload-box']} ${
              isDragActive && styles['drag-active']
            }`}
            onClick={(e) =>
              shouldOpenPaywall ? openPaywall() : rootProps.onClick(e)
            }
            onDrop={(e) =>
              shouldOpenPaywall ? openPaywall() : rootProps.onDrop(e)
            }
          >
            <div className={styles['icon-box']}>
              <PhosphorIcon
                color={Color.Neutral500.value}
                iconName="CloudArrowUp"
                size={20}
              />
            </div>
            <Text
              className={styles['center-text']}
              kind={TextKind.TextSM}
              element={TextElement.P}
              color={Color.Neutral600}
            >
              <Text
                kind={TextKind.TextSMSemibold}
                element={TextElement.Span}
                color={Color.Purple700}
              >
                Click to upload
              </Text>{' '}
              or drag and drop CSV{' '}
              <Text kind={TextKind.TextXS} element={TextElement.Span}>
                (max {MAX_PRODUCTS} rows)
              </Text>
            </Text>
          </div>
        </div>
      </div>
      {validationErrors?.length ? (
        <div className={styles['error-row']}>
          <Text
            kind={TextKind.TextSMBold}
            element={TextElement.P}
            color={Color.Red500}
          >
            Some Errors were found in the contents of the CSV upload. Please
            update and re-upload.
          </Text>
          {visibleErrors.map((error, index) => (
            <div key={index}>
              <br />
              <ul>
                {error.map((err, i) => {
                  if (i === 0) {
                    return (
                      <li key={i}>
                        <Text kind={TextKind.TextSM} element={TextElement.P}>
                          {index + 1}.) {err}
                        </Text>
                      </li>
                    );
                  }
                  return (
                    <li key={i}>
                      <Text
                        key={i}
                        kind={TextKind.TextSM}
                        element={TextElement.P}
                      >
                        {err}
                      </Text>
                    </li>
                  );
                })}
              </ul>
            </div>
          ))}
          {validationErrors.length > MINIMIZED_ERROR_ROWS && (
            <SeymoreButton
              onClick={() => setShowAll(!showAll)}
              showAll={showAll}
            />
          )}
        </div>
      ) : null}
    </div>
  );
};
