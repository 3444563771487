function Close(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5355 4.46436L4.46448 11.5354M4.46448 4.46436L11.5355 11.5354"
        stroke={props.color.value}
        strokeWidth="1.5"
      />
    </svg>
  );
}

export default Close;
