function AddUser(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 13.5C2.5 10.3 4.5 9.5 6.5 9.5C8.5 9.5 10.5 10.3 10.5 13.5"
        stroke={props.color.value}
        strokeWidth="1.5"
      />
      <circle
        cx="6.5"
        cy="5"
        r="2.5"
        stroke={props.color.value}
        strokeWidth="1.5"
      />
      <path
        d="M12.5 4V8M10.5 6H14.5"
        stroke={props.color.value}
        strokeWidth="1.5"
      />
    </svg>
  );
}

export default AddUser;
