import { ORGANIZATION_TYPES, ROLES } from 'constants/index';

export default class User {
  constructor(user) {
    if (user) {
      Object.entries(user).forEach(([key, val]) => {
        this[key] = val;
      });
      this.canQuote =
        this.hasRole(ROLES.formulator) && this.permissions?.canQuote;
    }
  }

  hasRole(roles) {
    // As of this PR, backend organization types do not match the previously used
    // user.role types. Instead, we need to re-map and compare.
    roles = [].concat(roles);
    return roles.some(
      (role) => role === ORGANIZATION_TYPES[this.organization?.type]
    );
  }

  hasFF(flags, options = {}) {
    flags = [].concat(flags);
    const isInUser = (ff) => this.feature_flags.includes(ff);
    const conjuction = options.every ? 'every' : 'some';
    return flags[conjuction](isInUser);
  }

  hasSubscription(status, options = {}) {
    status = [].concat(status);
    const conjunction = options.every ? 'every' : 'some';
    const isInStatus = (stat) =>
      this.organization.subscriptions[stat].length > 0;
    return status[conjunction](isInStatus);
  }
}
