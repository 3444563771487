import { UrlParam } from 'design-system/data';
import { appendParameter, sortDirection } from 'design-system/utils';

export function setUrlSearchParameters(
  tableState,
  currentPage,
  itemsPerPage,
  history,
  productName,
  statusFilter
) {
  const params = new URLSearchParams();
  appendParameter(params, UrlParam.Sort, tableState.sortBy);
  appendParameter(params, UrlParam.Direction, sortDirection(tableState));
  appendParameter(params, UrlParam.Page, currentPage);
  appendParameter(params, UrlParam.Items, itemsPerPage);
  appendParameter(params, 'product_name', productName);
  appendParameter(params, 'status', statusFilter);
  history.push({ search: params?.toString() });
}

/**
 * Updates the state object with the provided field-value pairs or a single field-value pair.
 *
 * @param {function} setState - The state setter function.
 * @param {Array|String} fieldOrPairs - The field or an array of field-value pairs to update.
 * @param {*} value - The value to set for the field(s).
 */
export function handleStateChange(setState, fieldOrPairs, value) {
  if (Array.isArray(fieldOrPairs)) {
    setState((prevState) => ({
      ...prevState,
      ...fieldOrPairs.reduce((acc, [field, value]) => {
        acc[field] = value;
        return acc;
      }, {}),
    }));
  } else {
    setState((prevState) => ({ ...prevState, [fieldOrPairs]: value }));
  }
}
