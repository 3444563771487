import PropTypes from 'prop-types';

import { Text, TextElement, TextKind, Pill } from 'design-system/components';
import styles from './pageHeader.module.scss';
import { Color } from 'design-system/data';

const PageHeader = ({
  breadcrumbs,
  title,
  subtitle,
  actions,
  pillText,
  pillColor,
}) => {
  return (
    <div className={styles.root}>
      {breadcrumbs}
      <div className={styles.content}>
        <div>
          <div className={styles.title}>
            <Text kind={TextKind.DisplaySMMedium} element={TextElement.H1}>
              {title}
            </Text>
            {pillText && <Pill color={pillColor}>{pillText}</Pill>}
          </div>
          <Text
            kind={TextKind.TextMD}
            element={TextElement.P}
            color={Color.Neutral600}
          >
            {subtitle}
          </Text>
        </div>
        {actions}
      </div>
    </div>
  );
};

PageHeader.propTypes = {
  title: PropTypes.elementType,
  subtitle: PropTypes.elementType,
  breadcrumbs: PropTypes.element,
  actions: PropTypes.element,
  pillText: PropTypes.string,
  pillColor: PropTypes.string,
};

export default PageHeader;
