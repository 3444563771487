import { useParams } from 'react-router';

import { useApp } from 'context/AppContext';
import SKULevelStatusPageView from 'views/Brands/RetailerBrandEligibility/SKULevelStatusPage/view';
import { SKUEligibilityProvider } from 'views/Brands/RetailerBrandEligibility/SKULevelStatusPage/context/SKUEligibilityContext';

const RetailerBrandSubmissionsSKUs = () => {
  const { brand_id, policy_id } = useParams();
  const { user } = useApp();
  const retailerId = user?.organization?.in_orgable_id;

  return (
    <SKUEligibilityProvider
      retailerId={retailerId}
      brandId={brand_id}
      policyId={policy_id}
    >
      <SKULevelStatusPageView isRetailer />
    </SKUEligibilityProvider>
  );
};

export default RetailerBrandSubmissionsSKUs;
