function Sephora(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1578_49372)">
        <rect width="24" height="24" rx="12" fill="black" />
        <path
          d="M12.9362 2C12.9362 2 10.1231 6.64411 10.8158 11.5804C11.343 15.3339 12.67 17.6292 11.1692 22C11.1692 22 13.6431 18.8837 13.6431 14.3515C13.6425 11.7141 11.3023 6.19413 12.9362 2Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1578_49372">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Sephora;
