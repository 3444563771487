import cn from 'classnames';
import styles from './policies-content.module.scss';
import {
  Accordion,
  AccordionDetail,
  NoData,
  Pill,
  PolicyIcon,
  PillColor,
} from 'design-system/components';
import { SUPPLIER_VETTING_STATUS } from 'constants/index';
import SupplierPolicyAction from './policy-action';

const VettingPillData = {
  [SUPPLIER_VETTING_STATUS.PASS]: {
    title: 'Policies met',
    pillLabel: 'Meets',
    color: PillColor.Green,
  },
  [SUPPLIER_VETTING_STATUS.UNSETTLED]: {
    title: 'Policies needing more information',
    pillLabel: 'Needs more information',
    color: PillColor.Orange,
  },
  [SUPPLIER_VETTING_STATUS.FAILED]: {
    title: 'Policies not met',
    pillLabel: 'Does not meet',
    color: PillColor.Red,
  },
};

const PoliciesList = ({ policies, materialID }) => {
  const noPoliciesFound =
    !policies || !Object.values(policies).some((p) => p.length);
  if (noPoliciesFound)
    return (
      <NoData
        hasErrorOccurred={false}
        noContentMessage="No policies found at this time."
      />
    );
  return (
    <div>
      {Object.keys(policies).map((key, i) => {
        const policiesByStatus = policies[key];
        const pillData = VettingPillData[key];
        if (!policiesByStatus?.length) return null;
        return (
          <Accordion
            key={i}
            defaultOpen
            title={
              <div className={styles.status}>
                <div className={cn([styles.dot, styles[pillData.color]])} />
                {pillData.title} ({policiesByStatus?.length})
              </div>
            }
          >
            <AccordionDetail>
              {policiesByStatus?.map((policy, i) => (
                <div key={i} className={styles.policy}>
                  {policy.imagePath && (
                    <PolicyIcon src={policy.imagePath} alt={policy.name} />
                  )}
                  <div className={styles['policy-name']}>{policy.name}</div>
                  <Pill color={pillData.color}>{pillData.pillLabel}</Pill>
                  <SupplierPolicyAction
                    policy={policy}
                    materialID={materialID}
                  />
                </div>
              ))}
            </AccordionDetail>
          </Accordion>
        );
      })}
    </div>
  );
};

export default PoliciesList;
