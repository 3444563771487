import SupplierListingsView from './view';
import { SupplierListingsProvider } from './context';

export default function SupplierListings() {
  return (
    <SupplierListingsProvider>
      <SupplierListingsView />
    </SupplierListingsProvider>
  );
}
