import {
  Breadcrumbs,
  Pill,
  PillColor,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';
import PageHeader from '../../shared/components/PageHeader';
import SubmissionCard from './components/SubmissionCard';
import { Color } from 'design-system/data';
import { useFDASubmissionContext } from './Context';
import { useHistory } from 'react-router-dom';
import { formatDate } from '../utils';

const STATE_MAPPINGS = {
  ready_to_submit: 'Ready to submit',
  submitted: 'Submitted',
};

const SubmissionsListView = () => {
  const { allSubmissions } = useFDASubmissionContext();
  const history = useHistory();

  const baseLink = `/fda-registration/submissions`;
  const breadcrumbsItems = [
    {
      text: 'Submissions',
      link: baseLink,
    },
  ];

  return (
    <main>
      <PageHeader
        title="Submission history"
        subtitle={
          <>
            You can find a record of all the listings submissions you've created
            here.
          </>
        }
        breadcrumbs={<Breadcrumbs items={breadcrumbsItems} />}
      />
      {allSubmissions?.map((submission) => (
        <SubmissionCard
          title={`Submission #${submission.externalId}`}
          badge={`${submission.listingCounts} listings`}
          buttonLink={() =>
            history.push(`/fda-registration/submissions/${submission.id}`)
          }
          key={submission.externalId}
          stats={[
            {
              label: 'Status',
              value: (
                <Pill color={PillColor.Orange}>
                  {STATE_MAPPINGS[submission.submissionState]}
                </Pill>
              ),
            },
            {
              label: 'Submitted on',
              value: (
                <Text
                  kind={TextKind.TextSM}
                  color={Color.Neutral500}
                  element={TextElement.Span}
                >
                  {formatDate(submission.submissionDate)}
                </Text>
              ),
            },
          ]}
        />
      ))}
    </main>
  );
};

export default SubmissionsListView;
