import DashboardView from './view';

const FDARegistrationDashboard = () => {
  return (
    <>
      <DashboardView />
    </>
  );
};

export default FDARegistrationDashboard;
