import {
  Children,
  cloneElement,
  isValidElement,
  useState,
  useRef,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { elementOfType } from 'design-system/utils/type-checker';
import styles from './tab-group.module.scss';

function TabGroup(props) {
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);

  const tabsRef = useRef([]);

  useEffect(() => {
    function setTabPosition() {
      const currentTab = tabsRef.current[props.activeTabIndex];
      setTabUnderlineLeft(currentTab ? currentTab.offsetLeft : 0);
      setTabUnderlineWidth(currentTab ? currentTab.clientWidth : 0);
    }

    setTabPosition();
    window.addEventListener('resize', setTabPosition);

    return () => window.removeEventListener('resize', setTabPosition);
  }, [props.activeTabIndex, props.children.length]);

  return (
    <div className={styles['root-container']}>
      <div
        className={styles.root}
        role="tablist"
        aria-labelledby={props.ariaLabelledBy}
      >
        <>
          {Children.map(props.children, (child, index) => {
            if (isValidElement(child)) {
              return cloneElement(child, {
                ref: (element) => (tabsRef.current[index] = element),
              });
            }
          })}
          <span
            className={styles.slider}
            style={{
              left: tabUnderlineLeft,
              width: tabUnderlineWidth,
            }}
          />
        </>
      </div>
      {props.additionalHeader !== undefined && (
        <div className={styles['additional-header']}>
          {props.additionalHeader}
        </div>
      )}
    </div>
  );
}

TabGroup.propTypes = {
  children: elementOfType('Tab'),
  ariaLabelledBy: PropTypes.string.isRequired,
  activeTabIndex: PropTypes.number || PropTypes.string,
  additionalHeader: Element,
};

export default TabGroup;
