import { useState, Children } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  Text,
  TextElement,
  TextKind,
  PhosphorIcon,
} from 'design-system/components';
import styles from './accordion.module.scss';
import { Color } from 'design-system/data';

// TODO: Create a method that will allow Accordion Groups to be controlled [ENG-1694]

function Accordion(props) {
  const [isOpen, setIsOpen] = useState(props.defaultOpen);
  const rootClass = props.alternateStyle ? 'alternate' : 'root';
  function toggleOpen() {
    setIsOpen(!isOpen);
  }
  const isTitleString = typeof props.title === 'string';
  const rootClasses = cn([
    isOpen ? styles[`${rootClass}-open-title`] : styles[rootClass],
    props.active ? styles.active : null,
  ]);

  return (
    <div className={rootClasses}>
      <button
        className={styles['title-bar']}
        onClick={toggleOpen}
        data-cy={props[`data-cy`]}
      >
        <span>
          {props.iconName && (
            <PhosphorIcon
              iconName={props.iconName}
              size={props.iconSize || 24}
              color={
                props.alternateStyle
                  ? Color.Black.value
                  : isOpen
                    ? Color.Neutral800.value
                    : Color.Neutral500.value
              }
            />
          )}
          <Text
            kind={
              props.alternateStyle
                ? TextKind.TextMDBold
                : TextKind.TextMDSemibold
            }
            element={isTitleString ? TextElement.P : TextElement.Span}
            className={styles['accordion-title']}
          >
            {props.title}
          </Text>
        </span>
        <span className={styles.icon}>
          <PhosphorIcon iconName={isOpen ? 'CaretUp' : 'CaretDown'} size={12} />
        </span>
      </button>
      <ul className={isOpen ? styles.open : styles.close}>
        {Children.map(props.children, (child) => (
          <li className={styles.child}>{child}</li>
        ))}
      </ul>
    </div>
  );
}

Accordion.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  iconName: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  defaultOpen: PropTypes.bool,
  alternateStyle: PropTypes.bool,
  iconSize: PropTypes.number,
  [`data-cy`]: PropTypes.string,
  active: PropTypes.bool,
};

export default Accordion;
