import { useMemo } from 'react';
import { PLACEHOLDER } from './constants';

export function usePagination(totalItemCount, itemsPerPage, currentPage) {
  const range = useMemo(() => {
    const paginationRange = getPaginationRange(
      totalItemCount,
      currentPage,
      itemsPerPage
    );
    return paginationRange;
  }, [totalItemCount, itemsPerPage, currentPage]);
  return range;
}

export const getPaginationRange = (totalItems, currentPage, itemsPerPage) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const leftSiblingIndex = currentPage - 1;
  const rightSiblingIndex = currentPage + 1;
  const [showRightPlaceholder, showLeftPlaceholder] = [
    rightSiblingIndex < totalPages - 2,
    leftSiblingIndex > 2,
  ];

  function makeRangeArray(start, end) {
    const length = end - start + 1;
    return Array.from({ length: length }, (_, i) => i + start);
  }

  if (totalPages <= 1) {
    return [];
  }

  if (totalPages <= 6) {
    return makeRangeArray(1, totalPages);
  }

  if (showRightPlaceholder && !showLeftPlaceholder) {
    const leftRange = makeRangeArray(1, 5);
    return [...leftRange, PLACEHOLDER, totalPages];
  }

  if (showLeftPlaceholder && !showRightPlaceholder) {
    const rightRange = makeRangeArray(totalPages - 4, totalPages);
    return [1, PLACEHOLDER, ...rightRange];
  }

  if (showRightPlaceholder && showLeftPlaceholder) {
    const midRange = makeRangeArray(leftSiblingIndex, rightSiblingIndex);
    return [1, PLACEHOLDER, ...midRange, PLACEHOLDER, totalPages];
  }
};
