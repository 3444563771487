import { createContext, useContext, useMemo, useState } from 'react';
import { useApp } from 'context/AppContext';
import styled from 'styled-components';
import MuiDialog from '@material-ui/core/Dialog';

export const NoviEmailOnlyContext = createContext();
export const useNoviEmailOnly = () => useContext(NoviEmailOnlyContext);
export const NoviEmailOnlyConsumer = NoviEmailOnlyContext.Consumer;

export function NoviEmailOnlyProvider(props) {
  const { children } = props;
  const [isModalOpen, setModalOpen] = useState(false);
  const { user } = useApp();

  const context = useMemo(() => {
    return {
      openModal() {
        setModalOpen(true);
      },
      closeModal() {
        setModalOpen(false);
      },
      toggleModal() {
        setModalOpen((was) => !was);
      },
    };
  }, []);

  return (
    <NoviEmailOnlyContext.Provider value={context}>
      {children}
      <NoviEmailOnlyDialog
        open={isModalOpen && user.is_novi_email}
        onClose={context.closeModal}
      />
    </NoviEmailOnlyContext.Provider>
  );
}

const NoviEmailOnlyDialog = styled(function NoviEmailOnlyDialog(props) {
  const { children, ...rest } = props;
  const { user, auth0 } = useApp();
  return (
    <MuiDialog {...rest}>
      <h1>Novi Users Only Dialog</h1>
      <h2>Current Auth0 Subject Token</h2>
      <pre>{user?.sub}</pre>
      <h2>Current Authentication Token</h2>
      <pre>{auth0?.token}</pre>
      {children}
    </MuiDialog>
  );
})`
  .MuiPaper-root {
    padding: 24px;
  }

  h1 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5;
    margin: 0.5em 0;
  }

  h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
    margin: 0.5em 0;
  }

  pre {
    white-space: pre-wrap;
    word-break: break-all;
    border-radius: 8px;
    padding: 8px 12px;
    background-color: hsl(var(--brand-color-h), var(--brand-color-s), 95%);
  }
`;
