import { Button, ButtonKind } from 'design-system/components';
import { useApp } from 'context/AppContext';
import CircularProgress from 'components/CircularProgress';
import Row from './Row';
import styled from 'styled-components';

const IngredientResolver = styled(function IngredientResolver(props) {
  const { entityId, ingredient_names, stepNumber, onSubmit, className } = props;

  const { useApi } = useApp();
  const formulationApi = useApi(
    `/api/product_design/v1/formulations/${entityId}`,
    { enabled: Boolean(entityId) }
  );

  const loading = formulationApi.loading;

  const readyToResolve = Boolean(entityId && !loading);

  const stepLabel = `${stepNumber ? `${stepNumber}.` : ''} Resolve Ingredients`;

  if (!readyToResolve) {
    return (
      <>
        <div className="subtitle resolve-subtitle" data-cy="resolve-subtitle">
          {stepLabel}
        </div>
        <CircularProgress size={64} />
      </>
    );
  }

  const ingredients = formulationApi.data.chemical_ingredients.map((ing) => {
    return {
      name: ing.name,
      id: ing.id,
      unresolved: !ing.chemical_compound_id,
    };
  });

  // we want the user to resolve any ingredients in the same order they
  // were presented in the ingredient list
  const sortOrder = ingredient_names
    .split(', ')
    .reduce((map, ingredientName, position) => {
      map[ingredientName.toLowerCase()] = position;
      return map;
    }, {});

  const sortedIngredients = ingredients.sort((a, b) => {
    const a_name = a.name.toLowerCase();
    const b_name = b.name.toLowerCase();
    return sortOrder[a_name] - sortOrder[b_name];
  });

  return (
    <div className={className}>
      <div className="subtitle resolve-subtitle" data-cy="resolve-subtitle">
        {stepLabel}
      </div>

      <div style={{ marginBottom: '40px' }}>
        We couldn't find some of the ingredients you uploaded. To get your
        results instantly, please review and resolve the ingredients below. If
        you’re not able to resolve them now, you can continue with your upload
        and resolve later.
      </div>

      <div className="highlights">
        <p className="heading">Your Ingredients</p>
        <div className="outlined-text">
          {sortedIngredients?.map((ing) => (
            <span key={ing.id} data-active={ing.unresolved}>
              {ing.name},
            </span>
          ))}
        </div>
      </div>

      <div className="resolve-table">
        {sortedIngredients
          ?.filter((i) => i.unresolved)
          ?.map((ing) => {
            return (
              <Row
                key={ing.id}
                entityId={entityId}
                ingredientId={ing.id}
                ingredientName={ing.name}
              />
            );
          })}
      </div>

      <div className="button-box">
        <Button
          kind={ButtonKind.Primary}
          onClick={onSubmit}
          data-cy="submit-resolved-ingredients-btn"
        >
          Next
        </Button>
      </div>
    </div>
  );
})`
  display: content;

  .highlights {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: white;
    border: 1px solid black;
    flex-grow: 1;
    border: 1px solid #ececf2;
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.08);
    border-radius: 7px;
    padding: 25px;
    width: 100%;

    .heading {
      margin-bottom: 20px;
      width: 100%;
      font-weight: bold;
    }

    .outlined-text {
      width: 100%;
      padding: 18.5px 14px;
      border: 1px solid rgba(0, 0, 0, 0.23);
      border-radius: 4px;
      overflow-wrap: anywhere;
    }

    span {
      margin-right: 1ex;
      &[data-active='true'] {
        background-color: rgba(239, 49, 23, 0.2);
        border: 1px solid rgba(239, 49, 23, 0.5);
        border-radius: 4px;
        padding: 2px 5px;
      }
    }
  }
`;

export default IngredientResolver;
