import propTypes from 'prop-types';
import styles from './tabs-and-table.module.scss';

export default function TabsAndTable({ tabs, toggle, table }) {
  return (
    <div className={styles.container}>
      <div className={styles.tabs}>{tabs}</div>
      {toggle && <div className={styles.toggle}>{toggle}</div>}
      <div className={styles.table}>{table}</div>
    </div>
  );
}

TabsAndTable.propTypes = {
  tabs: propTypes.element,
  toggle: propTypes.element,
  table: propTypes.element,
};
