import { FC } from 'react';
import {
  GridItem,
  Text,
  TextKind,
  TextElement,
} from 'design-system/components';
import { Color } from 'design-system/data';

import styles from './Disclaimer.module.scss';

export const Disclaimer: FC<{ text: string }> = ({ text }) => {
  return (
    <GridItem span={4} responsiveSpan={{ tablet: 6, desktop: 10 }}>
      <div className={styles['three-xl-top']}>
        <Text
          kind={TextKind.TextSM}
          element={TextElement.P}
          className={styles.disclaimer}
          color={Color.Neutral600}
        >
          {text}
        </Text>
      </div>
    </GridItem>
  );
};
