import { useHistory } from 'react-router-dom';
import {
  BrandViewHeader,
  PhosphorIconWeight,
  PhosphorIcon,
  Text,
  TextKind,
  TextElement,
  DropdownRadioMenu,
  FilterDropdown,
} from 'design-system/components';
import { useStatefulQueryParams } from 'design-system/utils';
import { UrlParam, Color } from 'design-system/data';

import { FormInput } from 'views/FDARegistration/shared/components/Form';
import { useApp } from 'context/AppContext';
import { useSKUEligibilityContext } from '../context/SKUEligibilityContext';
import { getSortByOptions } from '../data/constants';
import styles from './header-styles.module.scss';
import { Dispatch, SetStateAction } from 'react';
import { ReviewType } from 'views/Retailer/Submissions/types';
import ActionButtons from 'views/Retailer/Submissions/components/ReviewModal/ActionButtons';

interface HeaderProps {
  policyName: string;
  breadcrumbItems?: { text: string; link: string }[];
  brandName?: string;
  isRetailer?: boolean;
  caption?: any;
  policy: any;
  handleReviewAction: (id: number) => void;
  setReviewType: Dispatch<SetStateAction<undefined | ReviewType>>;
}

const FILTER_DROPDOWN_OPTIONS = [
  { value: 'pending', label: 'Pending' },
  { value: 'pass', label: 'Meets' },
  { value: 'fail', label: 'Does not meet' },
  { value: 'needs_information', label: 'Needs attention' },
  { value: 'not_applicable', label: 'Not applicable' },
];

const RetailerBrandEligibilityHeader = ({
  policyName,
  breadcrumbItems,
  brandName,
  isRetailer,
  caption,
  policy,
  handleReviewAction,
  setReviewType,
}: HeaderProps) => {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const { selectedValues, setSelectedValues, data } =
    useSKUEligibilityContext();

  const { user, retailerBrand } = useApp();
  const retailerName = isRetailer
    ? user?.organization?.name
    : retailerBrand?.retailer?.name;

  // Determine which sort options to display based on the retailer
  const sortOptionsToDisplay = getSortByOptions(retailerName).reduce(
    (acc, option) => ({
      ...acc,
      [option.value]: option.label,
    }),
    {}
  );

  const [queryParams, setQueryParams] = useStatefulQueryParams({
    [UrlParam.Direction]: params.get(UrlParam.Direction) || '',
    [UrlParam.Sort]: params.get(UrlParam.Sort) || '',
    [UrlParam.Search]: params.get(UrlParam.Search) || '',
  });

  const search = queryParams[UrlParam.Search];
  const setSearch = (value: string) => {
    setQueryParams({ [UrlParam.Search]: value });
  };

  const activeSort =
    queryParams[UrlParam.Sort] && queryParams[UrlParam.Direction]
      ? `${queryParams[UrlParam.Sort]}__${queryParams[UrlParam.Direction]}`
      : 'product_name__asc';
  const setActiveSort = (value: string) => {
    const [criteria, direction] = value.split('__');
    setQueryParams({
      [UrlParam.Sort]: criteria,
      [UrlParam.Direction]: direction,
    });
  };

  const title = isRetailer && brandName ? brandName : policyName;
  const subtitle =
    isRetailer && brandName
      ? `
    View the SKUs for the ${brandName} submission to the ${policyName} program here.
  `
      : `View the eligibility status of your SKUs ${
          policyName ? `for ${policyName} ` : ''
        }here.`;

  return (
    <>
      <BrandViewHeader
        title={title}
        subTitle={subtitle}
        showExportButton={false}
        showFilterButton={false}
        breadcrumbsItems={breadcrumbItems}
        headerActions={
          isRetailer && policy?.totalRequirements !== 0 ? (
            <ActionButtons
              className={styles['action-button-container']}
              submissionStatus={policy?.submissionStatus}
              policySubmissionId={policy?.policySubmission?.id}
              setReviewType={setReviewType}
              handleReviewAction={handleReviewAction}
            />
          ) : null
        }
      />
      {caption && caption()}
      <div className={styles['options-row']}>
        <FormInput
          type="text"
          // @ts-ignore
          value={search}
          // @ts-ignore
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search"
          // @ts-ignore
          icon={
            <PhosphorIcon
              iconName="MagnifyingGlass"
              size="1.5rem"
              weight={
                PhosphorIconWeight.Light as keyof typeof PhosphorIconWeight
              }
              color={Color.Neutral600.value}
            />
          }
        />
        <div className={styles['filters-wrapper']}>
          <FilterDropdown
            accordionOptions={[
              {
                title: policyName,
                options: FILTER_DROPDOWN_OPTIONS,
              },
              ...((isRetailer === false ? data?.pillars : undefined) || []).map(
                (pillar) => ({
                  title: pillar.name,
                  options: FILTER_DROPDOWN_OPTIONS.map((option) => ({
                    ...option,
                    value: `${pillar.id}:${option.value}`,
                  })),
                })
              ),
            ]}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
          />
          <DropdownRadioMenu
            fromLeft={true}
            options={sortOptionsToDisplay}
            reverse
            activeValue={activeSort}
            setActiveValue={setActiveSort}
            triggerElement={
              <div className={styles['trigger-container']}>
                <PhosphorIcon iconName="ArrowsDownUp" size={20} />
                <Text
                  kind={TextKind.TextSMSemibold}
                  element={TextElement.P}
                  color={Color.Neutral600}
                  className={styles.nowrap}
                >
                  {activeSort
                    ? `Sort by: ${
                        sortOptionsToDisplay[
                          activeSort as keyof typeof sortOptionsToDisplay
                        ]
                      }`
                    : 'Sort by'}
                </Text>
              </div>
            }
          />
        </div>
      </div>
    </>
  );
};

export default RetailerBrandEligibilityHeader;
