import styled, { keyframes } from 'styled-components';

const widen = keyframes`
  to {
    width: 1.25em;
  }
`;

const Ellipsis = styled((props) => {
  const { children } = props;
  return (
    <span {...props}>
      {children}
      <Div>
        <Span />
      </Div>
    </span>
  );
})``;

const Div = styled.div`
  width: 1.25em;
  text-align: left;
  display: inline-block;
`;

const Span = styled.span`
  &:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    animation: ${widen} steps(4, end) 1.2s infinite;
    content: '…'; /* ellipsis character */
    width: 0px;
    margin-right: auto;
  }
`;

export default Ellipsis;
