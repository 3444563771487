import React from 'react';
import { string } from 'prop-types';
import { Pill } from 'design-system/components';
import { DocumentStatuses } from '../../constants';

function RequirementStatusPill({
  status,
}: {
  status: string;
}): React.ReactElement | null {
  switch (status) {
    case null:
      return <Pill color="orange">Pending</Pill>;
    case DocumentStatuses.Approved:
      return <Pill color="green">Approved</Pill>;
    case DocumentStatuses.Rejected:
      return <Pill color="red">Rejected</Pill>;
    default:
      return null;
  }
}

RequirementStatusPill.propTypes = {
  status: string,
};

export default RequirementStatusPill;
