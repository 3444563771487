export function elementOfType(typeName) {
  return (props, propName, componentName) => {
    const error = new Error(
      `Invalid prop '${propName}' supplied to '${componentName}' - should be of type '${typeName}'. Validation failed.`
    );
    if (props[propName].length > 1) {
      if (
        !props[propName].every((element) =>
          element?.type?.name ? element.type.name === typeName : true
        )
      ) {
        return error;
      }
    } else if (
      props[propName]?.type?.name !== typeName &&
      props[propName][0]?.type?.name !== typeName
    ) {
      return error;
    }
  };
}

export const isPositiveWholeNumber = (val) => val >= 1 && val % 1 === 0;

export const isNumeric = (val) => !isNaN(val) && !isNaN(parseFloat(val));

export const isRealPercentage = (val) =>
  isNumeric(val) && val >= 0 && val <= 100;
