import { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import {
  Text,
  TextElement,
  TextKind,
  SearchBar,
  Toggle,
} from 'design-system/components';
import styles from './filter-modal-search.module.scss';

const FilterModalSearch = ({
  title,
  options,
  activeIDs,
  onChange,
  dataCyRoot,
  dynamicFetch = false,
  toggleOptions,
  onSearchChange = (val, toggleValue) => {},
  isLoading,
}) => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [activeToggleOption, setActiveToggleOption] = useState(
    toggleOptions ? toggleOptions[0] : null
  );

  useEffect(() => {
    if (dynamicFetch) {
      setFilteredOptions(options);
    }
  }, [options, dynamicFetch]);

  const handleSearchChange = (value) => {
    if (dynamicFetch) {
      onSearchChange(value, activeToggleOption);
    } else {
      const updatedOptions = options.filter((option) =>
        option.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredOptions(updatedOptions);
    }
  };

  const handleOptionClick = (option) => {
    let filtersToStage = [...activeIDs];
    if (filtersToStage.some((filter) => filter.id === option.id)) {
      filtersToStage = filtersToStage.filter(
        (filter) => filter.id !== option.id
      );
    } else {
      filtersToStage.push(option);
    }
    onChange(filtersToStage);
  };

  const getPlaceholderText = () => {
    const filterTitle = title?.toLowerCase() || '';
    if (toggleOptions?.length) {
      return `Search ${filterTitle} by ${activeToggleOption.toLowerCase()}`;
    }
    return `Search ${filterTitle}`;
  };

  const placeholderText = getPlaceholderText();

  return (
    <div className={styles.container}>
      <div className={styles['title-toggle-group']}>
        <Text
          kind={TextKind.TextXSBold}
          element={TextElement.H3}
          className={styles.title}
        >
          {title}
        </Text>
        {toggleOptions?.length && (
          <Toggle
            options={toggleOptions}
            activeOption={activeToggleOption}
            onClick={(value) => setActiveToggleOption(value)}
          />
        )}
      </div>
      <SearchBar
        placeholder={placeholderText}
        results={filteredOptions}
        onChange={handleSearchChange}
        onClick={handleOptionClick}
        multiSelect
        data-cy={`${dataCyRoot}-search-bar`}
        activeIDs={activeIDs}
        isLoading={isLoading}
      />
    </div>
  );
};

FilterModalSearch.propTypes = {
  title: propTypes.string.isRequired,
  options: propTypes.array.isRequired,
  activeIDs: propTypes.array,
  onChange: propTypes.func,
  dataCyRoot: propTypes.string,
  dynamicFetch: propTypes.bool,
  onSearchChange: propTypes.func,
};

export default FilterModalSearch;
