// @param policies [Array]
// @return [String]
export function getTitleCopy(policies) {
  if (!policies || !policies?.length) return;
  let policyNames = [];

  policies.forEach((policy) => {
    const hasManyPillars = policy.retailerPolicyRequirementGroups.length > 1;
    if (hasManyPillars) {
      return policyNames.push(policy.name);
    }
    const pillarName = policy.retailerPolicyRequirementGroups[0]?.name;
    return policyNames.push(pillarName);
  });
  if (policyNames.length === 1) {
    return `Let's get you verified for ${policyNames[0]}`;
  }
  const lastEntry = policyNames.pop();
  const withCommas = policyNames.join(', ');
  return `Let's get you verified for ${withCommas} and ${lastEntry} programs`;
}
