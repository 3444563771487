import cn from 'classnames';
import propTypes from 'prop-types';
import styles from './dropdown-menu-item.module.scss';

const DropdownMenuItem = ({
  children,
  onClick = () => {},
  [`data-cy`]: dataCy,
  disabled,
}) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onClick();
    }
  };

  return (
    <li
      className={cn([styles.root, disabled && styles.disabled])}
      onClick={!disabled ? onClick : () => {}}
      tabIndex={!disabled ? 0 : -1}
      data-cy={dataCy}
      onKeyDown={handleKeyDown}
    >
      {children}
    </li>
  );
};

DropdownMenuItem.propTypes = {
  children: propTypes.node.isRequired,
  onClick: propTypes.func,
  [`data-cy`]: propTypes.string,
  disabled: propTypes.bool,
};

export default DropdownMenuItem;
