import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { UrlParam } from 'design-system/data';
import { convertSnakeToCamelCase } from 'design-system/utils';

import useQueryParams from 'hooks/useQueryParams';
import useApi from 'services/api/useApi';

export const usePillarRequirements = (
  policyRequirementId,
  screeningStatus = '',
  category = ''
) => {
  const params = useQueryParams(Object.values(UrlParam));
  const { requirement_group_id } = useParams();
  const {
    data: rawData,
    loading,
    error,
    refetch,
  } = useApi(
    `/api/v4/retailer_policy_requirement_groups/${requirement_group_id}/policy_requirements/${policyRequirementId}/requirements`,
    {
      param: {
        ...params._asValues(),
        'screening_status': screeningStatus,
        'category': category,
      },
    }
  );
  const data = convertSnakeToCamelCase(rawData);

  const currentTabData = useMemo(
    () => data?.requirements,
    [data?.requirements]
  );
  const statusQuantities = useMemo(
    () => data?.screeningStatusCounts || {},
    [data?.screeningStatusCounts]
  );
  const hasLoadedSuccessfully = !loading && !error;

  return {
    data,
    loading,
    error,
    refetch,
    currentTabData,
    statusQuantities,
    hasLoadedSuccessfully,
  };
};

export const useBrandRequirementConditions = (
  requirementId,
  isBrandRequirement
) => {
  const { data, loading, error } = useApi(
    `/api/v4/requirements/${requirementId}/actions`,
    {
      enabled: isBrandRequirement,
    }
  );
  return {
    data: convertSnakeToCamelCase(data?.actions || []),
    loading,
    error,
  };
};
