import propTypes from 'prop-types';
import {
  Link,
  LinkKind,
  TableBodyCell,
  TableRow,
} from 'design-system/components';
import { PATHS } from 'constants/index';
import { PLACEHOLDER } from 'design-system/data';

export default function SupplierListingsTableRow({ data, index }) {
  return (
    <TableRow>
      <TableBodyCell data-cy={`supplier-listings-table-name-cell-${index}`}>
        <Link
          kind={LinkKind.Internal}
          to={`${PATHS.supplierMaterial}/${data.id}`}
          data-cy={`link-to-supplier-material-${data.id}`}
        >
          {data?.name || PLACEHOLDER}
        </Link>
      </TableBodyCell>
      <TableBodyCell>{data.documentCount || PLACEHOLDER}</TableBodyCell>
    </TableRow>
  );
}

SupplierListingsTableRow.propTypes = {
  data: propTypes.object,
  index: propTypes.number.isRequired,
};
