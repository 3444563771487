import { number } from 'prop-types';
import { Divider } from 'design-system/components';
import styles from './product-stats.module.scss';
import cn from 'classnames';

function LabelAndTextGroup() {
  return (
    <div>
      <span
        className={cn(
          styles['loading-bar-small'],
          styles['shimmer-background']
        )}
      />
      <span
        className={cn(
          styles['loading-bar-large'],
          styles['shimmer-background']
        )}
      />
    </div>
  );
}

function LoadingSkeletons({ statsArrayLength = 6 }) {
  const loadingStats = [];
  while (--statsArrayLength) {
    loadingStats.push(<LabelAndTextGroup />);
  }
  return (
    <div className={styles.root}>
      <LabelAndTextGroup />
      <LabelAndTextGroup />
      <Divider />
      {loadingStats}
    </div>
  );
}

LoadingSkeletons.propTypes = {
  statsArrayLength: number,
};

export default LoadingSkeletons;
