import { useMemo } from 'react';
import {
  Text,
  TextElement,
  TextKind,
  SelectV2,
  SelectV2Item,
  PhosphorIcon,
  Link,
  LinkKind,
} from 'design-system/components';
import { Color } from 'design-system/data';
import styles from './sku-details-form.module.scss';

import {
  FormInput,
  FormColumn,
  SectionHeader,
} from '../../shared/components/Form';
import { useFDAProductContext } from '../Detail/Context';
import countries from '../../shared/data/countries.json';
import provinces from '../../shared/data/provinces.json';

const ManufacturerForm = () => {
  const {
    manufacturerFormState,
    handleManufacturerStateChange,
    manufacturerRef,
  } = useFDAProductContext();

  const provinceOptions = useMemo(() => {
    if (!manufacturerFormState.country) return [];
    // TODO: (maintainability) get the state { country_alpha2, name } from the backend
    return provinces.filter(
      (province) => province.country_alpha2 === manufacturerFormState.country
    );
  }, [manufacturerFormState.country]);

  return (
    <form
      className={styles.root}
      ref={manufacturerRef}
      onSubmit={(event) => event.preventDefault()}
    >
      <SectionHeader
        noDivider
        noMargin
        title="Small business exemption"
        subtitle={
          <div className={styles['checkbox-container']}>
            <input
              type="checkbox"
              id="subscribe_to_emails"
              value={manufacturerFormState.isSmallBusiness}
              checked={manufacturerFormState.isSmallBusiness}
              onChange={(e) =>
                handleManufacturerStateChange(
                  'isSmallBusiness',
                  e.target.checked
                )
              }
            />
            <div>
              <Text
                htmlFor="subscribe_to_emails"
                id="input-label-subscribe_to_emails"
                element={TextElement.Label}
                kind={TextKind.TextSM}
                color={Color.Neutral600}
                className={styles['pb-md']}
              >
                This business qualifies as a small business according to the
                guidelines set forth by the FDA.
              </Text>
              <Text
                kind={TextKind.TextXS}
                color={Color.Neutral600}
                element={TextElement.P}
                className={styles.italic}
              >
                The term “small business” are those with an average gross annual
                sales in the United States of cosmetic products for the previous
                3-year period is less than $1,000,000, adjusted for inflation
                and who do not engage in the manufacturing or processing of
                certain cosmetic products described in section 612(b) of the
                FD&C Act.
              </Text>
              <div className={styles['small-biz-box']}>
                <div className={styles['small-biz-title']}>
                  <PhosphorIcon
                    size={20}
                    color={Color.Orange600.value}
                    iconName="Warning"
                  />
                  <Text
                    color={Color.Neutral900}
                    kind={TextKind.TextXSSemibold}
                    element={TextElement.P}
                  >
                    Certain small businesses are still required to list with the
                    FDA
                  </Text>
                </div>
                <Text kind={TextKind.TextXS} element={TextElement.P}>
                  {`Please note a business is not exempt if a responsible part or facility engages in the manufacturing or processing of any of the following products that 1) regularly come into contact with mucus membrane of eye (ex: mascara), 2) are injected (tattoo ink), 3) are intended for internal use (ex: mouthwash), 4) are intended to alter appearance for more than 24 hours (ex: certain nail polishes, some hair products, some eyebrow dyes). `}
                  <Link
                    style={{ color: Color.Purple700.value }}
                    kind={LinkKind.External}
                    href="https://noviconnect.notion.site/Confirm-you-qualify-for-the-Small-Business-Exemption-1b66a73c553246d0a1f1ce860ecf37bf"
                  >
                    Learn more
                  </Link>
                </Text>
              </div>
            </div>
          </div>
        }
      />
      <FormColumn
        title="Name"
        fieldId="manufacturer__name"
        field={
          <FormInput
            type="text"
            id="manufacturer__name"
            placeholder="Manufacturer name"
            value={manufacturerFormState.name || ''}
            onChange={(e) =>
              handleManufacturerStateChange('name', e.target.value)
            }
          />
        }
      />

      {manufacturerFormState.isSmallBusiness ? (
        <>
          <SectionHeader noMargin title="Contact info" noDivider />
          <FormColumn
            title="Address"
            fieldId="manufacturer__address"
            field={
              <FormInput
                type="text"
                id="manufacturer__address"
                placeholder="Street address"
                value={manufacturerFormState.addressLine1}
                onChange={(e) =>
                  handleManufacturerStateChange('addressLine1', e.target.value)
                }
                required={false}
              />
            }
          />

          <FormColumn
            title="Address line 2"
            fieldId="manufacturer__address-line-2"
            field={
              <FormInput
                type="text"
                id="manufacturer__address-line-2"
                placeholder="Suite, building, floor"
                value={manufacturerFormState.addressLine2}
                required={false}
                onChange={(e) =>
                  handleManufacturerStateChange('addressLine2', e.target.value)
                }
              />
            }
          />

          <FormColumn
            title="Country and postal code"
            fieldId="manufacturer__postal-code"
            field={
              <>
                <SelectV2
                  placeholder="Select country..."
                  value={manufacturerFormState.country}
                  onValueChange={(value) =>
                    handleManufacturerStateChange([
                      ['country', value],
                      ['state', undefined],
                    ])
                  }
                  container={manufacturerRef?.current}
                  required
                >
                  {countries.map((country) => (
                    <SelectV2Item
                      key={country.name}
                      value={country.alpha2.toUpperCase()}
                    >
                      <Text
                        kind={TextKind.TextMDMedium}
                        element={TextElement.Span}
                      >
                        {country.name}
                      </Text>
                    </SelectV2Item>
                  ))}
                </SelectV2>
                <FormInput
                  type="text"
                  id="manufacturer__postal-code"
                  placeholder="Postal code"
                  value={manufacturerFormState.postalCode}
                  onChange={(e) =>
                    handleManufacturerStateChange('postalCode', e.target.value)
                  }
                />
              </>
            }
          />

          <FormColumn
            title="City and state"
            fieldId="manufacturer__city"
            field={
              <>
                <FormInput
                  type="text"
                  id="manufacturer__city"
                  placeholder="City"
                  value={manufacturerFormState.city}
                  onChange={(e) =>
                    handleManufacturerStateChange('city', e.target.value)
                  }
                />
                {provinceOptions.length > 0 && (
                  <div className={styles['relative-parent']}>
                    <SelectV2
                      placeholder="Select state..."
                      value={manufacturerFormState.region}
                      onValueChange={(value) =>
                        handleManufacturerStateChange('region', value)
                      }
                      container={manufacturerRef?.current}
                      required
                    >
                      {provinceOptions.map((province) => (
                        <SelectV2Item key={province.name} value={province.name}>
                          <Text
                            kind={TextKind.TextMDMedium}
                            element={TextElement.Span}
                          >
                            {province.name}
                          </Text>
                        </SelectV2Item>
                      ))}
                    </SelectV2>
                  </div>
                )}
              </>
            }
          />
        </>
      ) : (
        <FormColumn
          title="FEI number"
          fieldId="manufacturer__fei-number"
          description="Contact your manufacturer to obtain their facility registration number (FEI)"
          field={
            <FormInput
              type="text"
              id="manufacturer__fei-number"
              placeholder="FEI number"
              value={manufacturerFormState.feiNumber || ''}
              onChange={(e) =>
                handleManufacturerStateChange('feiNumber', e.target.value)
              }
            />
          }
        />
      )}
    </form>
  );
};

export default ManufacturerForm;
