import {
  Text,
  TextKind,
  TextElement,
  Modal,
  WarningRings,
  Button,
  ModalType,
} from 'design-system/components';
import styles from './confirmation-dialogs.module.scss';
import { Color } from 'design-system/data';
import { useFDASubmissionConfirmationContext } from '../Context';

export const SuccessDialog = () => {
  const {
    submissionConfirmedDialogOpen,
    directToSubmissions,
    directToProducts,
  } = useFDASubmissionConfirmationContext();

  return (
    <Modal
      show={submissionConfirmedDialogOpen}
      type={ModalType.Small}
      onClick={() => directToProducts()}
    >
      <WarningRings />
      <div className={styles['confirmation-message']}>
        <Text kind={TextKind.DisplayXSMedium} element={TextElement.H2}>
          Submission confirmed
        </Text>
        <Text
          color={Color.Neutral600}
          kind={TextKind.TextSM}
          element={TextElement.P}
        >
          You should have received a confirmation email. Someone from Novi
          should be reaching out with an invoice soon. Once we have received
          payment, your information will be automatically sent to the FDA on
          your behalf.
        </Text>
      </div>
      <div className={styles.actions}>
        <Button onClick={() => directToProducts()}>Cancel</Button>
        <Button onClick={() => directToSubmissions()}>View submissions</Button>
      </div>
    </Modal>
  );
};
