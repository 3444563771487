import { useState, useLayoutEffect } from 'react';
import debounce from 'utils/debounce';

// TODO store window size in a store, instead of attaching multiple event listeners

export default function useWindowSize(options = {}) {
  const { delay = 100 } = options;

  const [state, setState] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    outerWidth: window.outerWidth,
    outerHeight: window.outerHeight,
  });

  useLayoutEffect(() => {
    const onResize = () =>
      debounce(
        setState({
          width: window.innerWidth,
          height: window.innerHeight,
          outerWidth: window.outerWidth,
          outerHeight: window.outerHeight,
        }),
        delay
      );
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [delay]);

  return state;
}
