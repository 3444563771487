import propTypes from 'prop-types';
import { Text, TextElement, TextKind } from 'design-system/components';
import { convertToTitleCase } from 'design-system/utils';
import { PLACEHOLDER } from 'design-system/data';
import styles from './material-literature-stats.module.scss';
import withLineBreaks from 'utils/withLineBreaks';

export default function SupplierMaterialLiteratureStats({
  data,
  alternateLayout,
}) {
  function isValueNull(value) {
    return !value || value === 'unknown' || value.length === 0;
  }

  function determineValue(key, value) {
    switch (key) {
      case 'productBenefits':
        return withLineBreaks(value);
      default:
        return (
          <Text kind={TextKind.TextMD} element={TextElement.P}>
            {isValueNull(value) ? PLACEHOLDER : value}
          </Text>
        );
    }
  }

  function formatKey(key) {
    const formattedKey = convertToTitleCase(key);
    switch (formattedKey) {
      case 'Supply Chain Sourcing':
        return 'Supply Chain/Sourcing Data';
      case 'Other Statements String':
        return 'Other Statements';
      case 'Bse Tse':
        return 'BSE/TSE Compliant';
      case 'Recommended Use':
        return 'Recommended Use Level';
      case 'Differentiators':
        return 'Sustainability Features';
      case 'Effective Ph Range':
        return 'pH';
      default:
        return formattedKey;
    }
  }

  return (
    <div className={styles.container}>
      {Object.entries(data).map(
        (item) =>
          !item[0]?.includes('origins') &&
          !item[0]?.includes('claimBenefits') &&
          !item[0]?.includes('viscosities') && (
            <div
              key={item[0]}
              className={
                styles[alternateLayout ? 'section-alternate' : 'section']
              }
            >
              <Text kind={TextKind.TextMDBold} element={TextElement.P}>
                {formatKey(item[0])}
              </Text>
              {determineValue(item[0], item[1])}
            </div>
          )
      )}
    </div>
  );
}

SupplierMaterialLiteratureStats.propTypes = {
  data: propTypes.object,
  alternateLayout: propTypes.bool,
};
