import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import {
  DropdownMenu,
  DropdownMenuItem,
  Text,
  TextElement,
  TextKind,
  Icon,
  IconName,
  IconDirection,
} from 'design-system/components';

import styles from './search-on-keystroke.module.scss';

function TriggerElement({ display, placeholder }) {
  return (
    <div className={styles['add-function-modal-input']}>
      <Text element={TextElement.Span} kind={TextKind.TextMD}>
        {display ? display : placeholder}
      </Text>
      <Icon name={IconName.Chevron} direction={IconDirection.Down} size={12} />
    </div>
  );
}

function SearchOnKeystroke({
  onChange,
  value,
  onSearch,
  options,
  placeholder,
  disabled,
}) {
  const onSelect = (value) => {
    if (disabled) return;
    onChange(value);
  };

  return (
    <DropdownMenu
      fullWidth
      triggerElement={
        <TriggerElement display={value?.name} placeholder={placeholder} />
      }
      disabled={disabled}
    >
      <DropdownMenuItem>
        <input
          className={styles['add-function-modal-input']}
          onChange={debounce((e) => onSearch(e.target.value), 500)}
          placeholder="Search ingredients"
          onClick={(e) => e.stopPropagation()}
          readOnly={disabled}
        />
      </DropdownMenuItem>
      {!!options?.length &&
        options.map((result) => (
          <DropdownMenuItem onClick={() => onSelect(result)} key={result.id}>
            {result.name}
          </DropdownMenuItem>
        ))}
    </DropdownMenu>
  );
}

SearchOnKeystroke.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  onSearch: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

export default SearchOnKeystroke;
