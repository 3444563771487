import {
  Grid,
  GridItem,
  Text,
  TextKind,
  TextElement,
  NoviLogo,
  PhosphorIcon,
  Link,
  LinkKind,
  LinkVariant,
} from 'design-system/components';
import { Color } from 'design-system/data';
import styles from './welcome.module.scss';
import { PATHS } from 'constants/index';
import cn from 'classnames';
import magnifyingGlassSvg from 'assets/illustrations/magnifying-glass.svg';

const pricingRows = [
  { label: '1 SKU', price: '$495' },
  { label: '2-10 SKUs', price: '$2495' },
  { label: '11-25 SKUs', price: '$4995' },
  { label: '26-100 SKUs', price: '$7995' },
  { label: '101-500 SKUs', price: '$10,995' },
  { label: '501-1000 SKUs', price: '$19,995' },
];

const FDARegistrationWelcome = () => {
  return (
    <div
      className={styles.container}
      style={{ backgroundColor: Color.Purple700.value }}
    >
      <div className={styles['logo-container']}>
        <a href={PATHS.portalSelection} className={styles.logo}>
          <NoviLogo />
        </a>
      </div>
      <div className={styles.content}>
        <Grid>
          <GridItem span={12} responsiveSpan={{ tablet: 8, desktop: 12 }}>
            <div className={styles.banner}>
              <Text
                kind={TextKind.DisplaySMMedium}
                element={TextElement.H1}
                color={Color.Neutral900}
              >
                Let's get your Cosmetic Products listed with the FDA.
              </Text>
              <Text
                kind={TextKind.TextMD}
                element={TextElement.H2}
                className={styles['sub-title']}
                color={Color.Neutral700}
              >
                In order to list your products with the FDA, you will be
                required to provide information about your SKUs, manufacturers,
                and business.
              </Text>
            </div>
          </GridItem>
        </Grid>
        <Grid>
          <GridItem span={4} responsiveSpan={{ tablet: 8, desktop: 12 }}>
            <Text
              element={TextElement.H2}
              kind={TextKind.Display2XSMedium}
              color={Color.Neutral900}
            >
              Before you begin
            </Text>
            <Grid nestedCols>
              <GridItem span={4} responsiveSpan={{ tablet: 8, desktop: 12 }}>
                <div className={styles.card}>
                  <div className={styles['card-content']}>
                    <div>
                      <div className={styles['title-group']}>
                        <PhosphorIcon
                          size={26}
                          iconName="MagicWand"
                          color={Color.Neutral600.value}
                        />
                        <Text
                          kind={TextKind.Display2XSMedium}
                          element={TextElement.H3}
                          color={Color.Neutral900}
                        >
                          Check out the MoCRA Wizard
                        </Text>
                      </div>
                      <Text
                        kind={TextKind.TextSM}
                        element={TextElement.P}
                        color={Color.Neutral700}
                      >
                        Many regulations under the Modernization of Cosmetics
                        Regulation Act (MoCRA) are new to cosmetic companies,
                        such as facility registration, product listings, U.S.
                        Agent requirements, and adverse event reporting. The
                        MoCRA Wizard will help you determine what requirements
                        impact your business.
                      </Text>
                    </div>
                    <Link
                      href="https://www.registrarcorp.com/mocra-wizard-noviconnect/"
                      kind={LinkKind.External}
                      variant={LinkVariant.Alternate}
                    >
                      Enter <PhosphorIcon iconName="ArrowRight" />
                    </Link>
                  </div>
                </div>
              </GridItem>
              <GridItem span={4} responsiveSpan={{ tablet: 8, desktop: 12 }}>
                <div className={styles.card}>
                  <div className={styles['card-content']}>
                    <div>
                      <div className={styles['title-group']}>
                        <PhosphorIcon
                          size={26}
                          iconName="ClipboardText"
                          color={Color.Neutral600.value}
                        />
                        <Text
                          kind={TextKind.Display2XSMedium}
                          element={TextElement.H3}
                          color={Color.Neutral900}
                        >
                          Review the MoCRA Product Listing Checklist
                        </Text>
                      </div>
                      <Text
                        kind={TextKind.TextSM}
                        element={TextElement.P}
                        color={Color.Neutral700}
                      >
                        Before starting the listing process, make sure to review
                        the MoCRA Product Listing Checklist and have all items
                        readily available.
                      </Text>
                    </div>
                    <Link
                      href="https://noviconnect.notion.site/Product-listing-checklist-559e37b7b379469eaba5e1896edc7f5e"
                      kind={LinkKind.External}
                      variant={LinkVariant.Alternate}
                    >
                      Enter <PhosphorIcon iconName="ArrowRight" />
                    </Link>
                  </div>
                </div>
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem span={4} responsiveSpan={{ tablet: 8, desktop: 12 }}>
            <Text
              element={TextElement.H2}
              kind={TextKind.Display2XSMedium}
              color={Color.Neutral900}
            >
              Things to know
            </Text>
            <Grid nestedCols>
              <GridItem span={4} responsiveSpan={{ tablet: 4, desktop: 6 }}>
                <div className={styles.pricing}>
                  <div className={styles['title-wrapper']}>
                    <Text
                      kind={TextKind.Display2XS}
                      element={TextElement.P}
                      color={Color.Neutral900}
                    >
                      Pricing
                    </Text>
                    <Text
                      kind={TextKind.TextSM}
                      element={TextElement.P}
                      color={Color.Neutral500}
                    >
                      Novi Connect's MoCRA product listings pricing is
                      calculated by SKU count, listed below.
                    </Text>
                  </div>
                  <div className={styles['pricing-rows']}>
                    {pricingRows.map((row) => {
                      return (
                        <div className={styles['pricing-row']}>
                          <Text
                            kind={TextKind.TextSM}
                            element={TextElement.P}
                            color={Color.Neutral900}
                          >
                            {row.label}
                          </Text>
                          <Text
                            kind={TextKind.TextSMMedium}
                            element={TextElement.P}
                            color={Color.Neutral900}
                          >
                            {row.price}
                          </Text>
                        </div>
                      );
                    })}
                    <div className={styles['pricing-row']}>
                      <Text kind={TextKind.TextSM} element={TextElement.P}>
                        1001+ SKUs
                      </Text>
                      <Text
                        kind={TextKind.TextXS}
                        element={TextElement.P}
                        color={Color.Neutral600}
                      >
                        Please contact us at{' '}
                        <Text
                          className={styles['contact-text']}
                          kind={TextKind.TextXSBold}
                          element={TextElement.Span}
                        >
                          mocra@noviconnect.com
                        </Text>
                      </Text>
                    </div>
                  </div>
                </div>
              </GridItem>
              <GridItem span={4} responsiveSpan={{ tablet: 4, desktop: 6 }}>
                <div
                  className={cn([
                    styles.resources,
                    styles.panel,
                    styles['full-height'],
                  ])}
                >
                  <div>
                    <div className={styles.xl}>
                      <Text kind={TextKind.Display2XS} element={TextElement.H3}>
                        Can&apos;t find what you&apos;re looking for?
                      </Text>
                    </div>
                    <Text
                      className={styles.subtext}
                      kind={TextKind.TextSM}
                      element={TextElement.P}
                      color={Color.Neutral500}
                    >
                      We're here to help! Please visit our{' '}
                      <Link
                        kind={LinkKind.External}
                        href="https://noviconnect.notion.site/MoCRA-Knowledge-Nook-d4a5d2a1f3304a7d941f0c2c0e0ca1f5"
                      >
                        Knowledge Nook
                      </Link>{' '}
                      or contact us with any questions at{' '}
                      <Text
                        className={styles['contact-text']}
                        kind={TextKind.TextSMBold}
                        element={TextElement.Span}
                      >
                        mocra@noviconnect.com
                      </Text>
                    </Text>
                    <Link
                      variant={LinkVariant.Alternate}
                      kind={LinkKind.External}
                      href="https://knowledgebase.noviconnect.com/mocra-support"
                    >
                      Get in touch <PhosphorIcon iconName="ArrowRight" />
                    </Link>
                  </div>
                  <img
                    src={magnifyingGlassSvg}
                    alt="magnifying glass"
                    onError={() => <span className={styles.fallback} />}
                    style={{ height: '7.5rem' }}
                  />
                </div>
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
        <Grid>
          <GridItem
            span={4}
            responsiveSpan={{ tablet: 1, desktop: 1 }}
            responsiveOffset={{ tablet: 12, desktop: 12 }}
          >
            <div className={styles['continue-container']}>
              <Link
                kind={LinkKind.Internal}
                variant={LinkVariant.Primary}
                to={PATHS.fdaRegistrationDashboard}
              >
                Continue <PhosphorIcon iconName="ArrowRight" />
              </Link>
            </div>
          </GridItem>
        </Grid>
      </div>
    </div>
  );
};

export default FDARegistrationWelcome;
