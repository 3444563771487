import { PLACEHOLDER } from 'design-system/data';
const locale = 'en-US';

export function applyCurrencyFormat(amount) {
  const currencyFormatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'USD',
  });

  return amount ? currencyFormatter.format(amount) : '$0.00';
}

export function applyDateFormat(date) {
  if (!date) {
    return null;
  }

  const dateObj = new Date(date);
  return dateObj.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone: 'UTC',
  });
}

export function applyLongDateFormat(date) {
  if (!date) {
    return null;
  }

  const dateObj = new Date(date);
  return dateObj.toLocaleDateString(locale, {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: 'UTC',
  });
}

export function applyQuantityFormat(quantity) {
  return quantity ? parseFloat(quantity).toLocaleString(locale) : '0';
}

export function applyPercentageFormat(value) {
  return value ? `${parseFloat(value).toFixed(1)}%` : `n/a`;
}

export function applyShortFormCurrencyFormat(rawValue) {
  if (rawValue) {
    const value = rawValue.toString().split('.')[0];
    if (value.length <= 3) {
      return `$${value}`;
    }
    if (value.length <= 6) {
      const keyValue = value.substring(0, value.length - 3);
      return `$${keyValue}.${value.charAt(keyValue.length)}k`;
    }
    if (value.length <= 9) {
      const keyValue = value.substring(0, value.length - 6);
      return `$${keyValue}.${value.charAt(keyValue.length)}M`;
    }
    if (value.length <= 12) {
      const keyValue = value.substring(0, value.length - 9);
      return `$${keyValue}.${value.charAt(keyValue.length)}B`;
    }
    return `>$1T`;
  }
  return '-';
}

export function determineInitials(name, fallback) {
  if (!name) {
    return fallback || PLACEHOLDER;
  }
  let initials;
  const wordArray = name.split(' ');
  if (wordArray.length > 1) {
    initials = `${wordArray[0].charAt(0)}${wordArray[1].charAt(0)}`;
  } else {
    initials = name.substring(0, 2);
  }
  return initials.toUpperCase();
}

export const applyRangeFormat = (min, max) => {
  if (min && max) {
    return `${applyQuantityFormat(min)} - ${applyQuantityFormat(max)}`;
  } else if (min) {
    return `${applyQuantityFormat(min)}`;
  } else if (max) {
    return `${applyQuantityFormat(max)}`;
  }
  return PLACEHOLDER;
};

// Plural vs. Singular formatters

const commonExceptions = [
  {
    plural: 'policies',
    singular: 'policy',
  },
];

export const getSingularFormFromPlural = (unformattedValue) => {
  if (!unformattedValue) return '';
  const value = unformattedValue.toLowerCase().trim();

  const commonExceptionIndex = commonExceptions
    .map((exception) => exception.plural)
    .indexOf(value);

  if (commonExceptionIndex >= 0) {
    return commonExceptions[commonExceptionIndex].singular;
  }

  const isPlural = value.slice(-2) === 'es' || value.slice(-1) === 's';
  if (!isPlural) return value;

  if (value.slice(-1) === 's') {
    if (value.slice(-2) === 'es') {
      return value.substr(0, value.length - 2);
    }
    return value.substr(0, value.length - 1);
  }
};

export const getPluralFormFromSingular = (unformattedValue) => {
  if (!unformattedValue) return '';
  const irregularCases = ['s', 'sh', 'ch', 'x', 'z'];
  let value = unformattedValue.toLowerCase().trim();

  const commonExceptionIndex = commonExceptions
    .map((exception) => exception.singular)
    .indexOf(value);
  if (commonExceptionIndex >= 0) {
    return commonExceptions[commonExceptionIndex].plural;
  }

  const isPlural = value.slice(-2) === 'es';
  if (isPlural) return value;

  const isIrregularCase =
    irregularCases.includes(unformattedValue.slice(-2)) ||
    irregularCases.includes(unformattedValue.slice(-1));
  if (isIrregularCase) {
    return (value += 'es');
  }
  return (value += 's');
};
