export default function Ingredient({ size, color }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.06503 11.4277C6.60774 11.4277 5.42168 10.2159 5.42168 8.7263C5.42168 7.23698 6.60749 6.02518 8.06503 6.02518C9.52206 6.02518 10.7076 7.23698 10.7076 8.7263C10.7074 10.2161 9.52206 11.4277 8.06503 11.4277ZM14.2812 13.0717C13.2523 13.0717 12.4152 12.2348 12.4152 11.2057C12.4152 10.1765 13.2521 9.33965 14.2812 9.33965C15.3103 9.33965 16.1472 10.1768 16.1472 11.2059C16.1472 12.235 15.3103 13.0717 14.2812 13.0717ZM1.86601 7.85814C0.836879 7.85814 0 7.02126 0 5.99213C0 4.963 0.836879 4.12612 1.86601 4.12612C2.89514 4.12612 3.73202 4.963 3.73202 5.99213C3.73202 7.02126 2.89489 7.85814 1.86601 7.85814ZM8.65894 3.73202C7.63006 3.73202 6.79293 2.89514 6.79293 1.86601C6.79293 0.836879 7.62981 0 8.65894 0C9.68807 0 10.525 0.836879 10.525 1.86601C10.525 2.89514 9.68782 3.73202 8.65894 3.73202ZM3.16838 15C2.1395 15 1.30237 14.1631 1.30237 13.134C1.30237 12.1054 2.13925 11.2682 3.16838 11.2682C4.19752 11.2682 5.0344 12.1051 5.0344 13.134C5.03414 14.1629 4.19726 15 3.16838 15ZM8.91124 5.84453V3.9654C8.82849 3.97675 8.74472 3.98458 8.65894 3.98458C8.57316 3.98458 8.48939 3.97675 8.40664 3.9654V5.77792C8.57972 5.78348 8.74825 5.80568 8.91124 5.84453ZM10.9095 9.4991C10.8772 9.66738 10.829 9.82987 10.7674 9.98553L12.2083 10.5494C12.2454 10.3819 12.3044 10.2229 12.3831 10.0756L10.9095 9.4991ZM5.69012 10.5817L4.64888 11.4809C4.77579 11.5919 4.88756 11.7193 4.9804 11.8609L6.02291 10.9607C5.90054 10.8454 5.78928 10.7185 5.69012 10.5817ZM5.45195 7.26599L3.91898 6.72481C3.87483 6.89032 3.80847 7.04649 3.72345 7.19106L5.27206 7.73779C5.31748 7.57354 5.37803 7.41611 5.45195 7.26599Z"
        fill={color.value}
      />
    </svg>
  );
}
