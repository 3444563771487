import DocumentsContainer from './DocumentsContainer';
import DocumentsProvider from './context';

function DocumentsView() {
  return (
    <DocumentsProvider>
      <DocumentsContainer />
    </DocumentsProvider>
  );
}

export default DocumentsView;
