function Plus(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 3V8V13" stroke={props.color.value} strokeWidth="1.5" />
      <path d="M3 8H13" stroke={props.color.value} strokeWidth="1.5" />
    </svg>
  );
}

export default Plus;
