import { useState, useMemo } from 'react';
import Dialog from 'components/Dialog';

/**
 * useDialog hook
 *
 * Usage:
 * function Component() {
 *   const { Dialog, openDialog, closeDialog } = useDialog(
 *     function ExampleDialog({ Dialog, open, setOpen }) {
 *       return (
 *         <Dialog>
 *           <h1>Title</h1>
 *           <div>Content</div>
 *         </Dialog>
 *       )
 *     }
 *   )
 *
 *   return (
 *     <div>
 *       <button type="button" onClick={openDialog}>Open Dialog</button>
 *       <Dialog fullWidth />
 *     </div>
 *   );
 * }
 */

export default function useDialog(Component) {
  const [open, setOpen] = useState(false);

  const Instance = useMemo(() => {
    function DialogInstance(props) {
      const { children, ...rest } = props;
      return (
        <Dialog open={open} onClose={() => setOpen(false)} {...rest}>
          {children}
        </Dialog>
      );
    }

    DialogInstance.Title = Dialog.Title;
    DialogInstance.Content = Dialog.Content;

    return DialogInstance;
  }, [open]);

  return useMemo(
    () => ({
      openDialog() {
        setOpen(true);
      },
      closeDialog() {
        setOpen(false);
      },

      Dialog(props) {
        return (
          <Component
            {...props}
            open={open}
            setOpen={setOpen}
            Dialog={Instance}
          />
        );
      },
    }),
    [Instance, open]
  );
}
