import { useContext, createContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ToastKind } from 'design-system/components';
import { convertSnakeToCamelCase } from 'design-system/utils';
import { useApp } from 'context/AppContext';
import { SUPPLIER_VETTING_STATUS, POLICY_CATEGORIES } from 'constants/index';

export function SupplierMaterialProvider(props) {
  const { children } = props;

  const [toastData, setToastData] = useState([]);
  const { useApi } = useApp();

  const { materialId } = useParams();

  const { data, loading, error } = useApi(
    `/api/v4/suppliers/listings/${materialId}`,
    {}
  );

  const materialData = useMemo(() => {
    return loading || error ? data : convertSnakeToCamelCase(data);
  }, [data, loading, error]);

  const policies = useMemo(() => {
    if (!loading && materialData?.policies?.length) {
      const vettingStatusPolicies = Object.values(
        SUPPLIER_VETTING_STATUS
      ).reduce((acc, status) => {
        acc[status] = [];
        return acc;
      }, {});
      materialData.policies.forEach((policy) => {
        if (policy.category === POLICY_CATEGORIES.RETAILER_LIST) {
          if (vettingStatusPolicies[policy.status]) {
            vettingStatusPolicies[policy.status].push(policy);
          } else {
            setToastData((t) => [
              ...t,
              {
                kind: ToastKind.Error,
                title: 'Uh oh!',
                message: 'Unrecognized policy vetting status.',
              },
            ]);
          }
        }
      });
      return vettingStatusPolicies;
    }
  }, [materialData, loading]);

  const context = useMemo(
    () => ({
      data: materialData,
      loading,
      error,
      policies,
      toastData,
    }),
    [materialData, loading, error, policies, toastData]
  );

  return (
    <SupplierMaterialContext.Provider value={context}>
      {children}
    </SupplierMaterialContext.Provider>
  );
}

const SupplierMaterialContext = createContext(null);
export const useSupplierMaterialContext = () => {
  const ctx = useContext(SupplierMaterialContext);
  if (!ctx) {
    throw new Error('Must be used with a SupplierMaterialProvider');
  }
  return ctx;
};
