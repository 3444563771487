import { useApp } from 'context/AppContext';
import { ReportsTable } from './components/ReportsTable';
import { Text, TextKind, TextElement } from 'design-system/components';
import useQueryParams from 'hooks/useQueryParams';
import styles from './reports.module.scss';
import { convertSnakeToCamelCase } from 'design-system/utils';
import useFetcher from 'services/api/useFetcher';
import { enqueueSnackbar } from 'notistack';

export interface QueryParam {
  value: any;
  push: (val: any) => void;
}
// TODO: Would be great to define these in the query param file, but to do so now would mean typing everything, yikes
interface QueryParamsSpecialMethods {
  _asValues: () => { [key: string]: any };
}

type QueryParams = {
  [key: string]: QueryParam;
} & QueryParamsSpecialMethods;

interface ErrorObject {
  message: string;
}

const isErrorObject = (e: unknown): e is ErrorObject => {
  return typeof e === 'object' && e !== null && 'message' in e;
};

const Reports = () => {
  const { useApi, user } = useApp();
  const retailerId = user.organization.in_orgable_id;
  const params = useQueryParams(['page', 'items']) as QueryParams;
  const fetcher = useFetcher();

  const { data, loading, error } = useApi(
    `/api/v4/retailers/${retailerId}/brands`,
    {
      param: { ...params._asValues() },
    }
  );

  const generateReport = (brandId: number) => {
    try {
      fetcher(
        `/api/v4/retailers/${retailerId}/brands/${brandId}/generate_report`
      );
      enqueueSnackbar(
        `Your report is being generated and will be emailed to ${user.email} when complete.`,
        { variant: 'success' }
      );
    } catch (e: unknown) {
      enqueueSnackbar(
        isErrorObject(e) ? e.message : 'An unexpected error occurred',
        { variant: 'error' }
      );
    }
  };

  return (
    <>
      <div className={styles['title-row']}>
        <Text kind={TextKind.DisplaySMMedium} element={TextElement.H1}>
          Downloadable Reports
        </Text>
      </div>
      <ReportsTable
        data={convertSnakeToCamelCase(data)}
        loading={loading}
        error={error}
        pageParam={params.page || ''}
        generateReport={generateReport}
      />
    </>
  );
};

export default Reports;
