import SiteHeader from 'components/SiteHeader';
import { useNavContext } from 'layouts/ConnectedSidenav/context';

function NoSideBarNav({ children }) {
  useNavContext()?.useHiddenNav();

  return (
    <>
      <SiteHeader />
      {children}
    </>
  );
}

export default NoSideBarNav;
