import { useParams } from 'react-router-dom';
import { Text, TextElement, TextKind } from 'design-system/components';
import { useApp } from 'context/AppContext';
import { useMetabaseDashboardData } from 'views/Retailer/Hooks/useMetabaseDashboardData.tsx';
import MetabaseDashboard from 'components/MetabaseDashboard';
import styles from './additional-reports.module.scss';

function RetailerAdditionalReports() {
  const { user } = useApp();
  const { id } = useParams();
  const retailerID = user.organization.in_orgable_id;

  const dashboardType = 'additional-reports';
  const hasAccess = user.hasFF('metabase_dashboard_view');

  const { title } = useMetabaseDashboardData(
    dashboardType,
    retailerID,
    hasAccess,
    id
  );

  return (
    <>
      <Text
        element={TextElement.H1}
        kind={TextKind.TextMDBold}
        className={styles['text']}
      >
        {title}
      </Text>
      <MetabaseDashboard
        dashboardType={dashboardType}
        retailerID={retailerID}
        dashId={id}
      />
    </>
  );
}

export default RetailerAdditionalReports;
