// * ------------------------------------------------------------------------------------------ * //
// * If any of these change, please update the values in `design-system/styles/_variables.scss` * //
// * ------------------------------------------------------------------------------------------ * //
const shadowColor = '0deg 0% 56%';
export const Shadow = {
  ElevationLow: `0px 0.3px 0.5px hsl(${shadowColor} / 0),
    0px 0.6px 0.9px hsl(${shadowColor} / 0.28),
    0px 1.4px 2.1px hsl(${shadowColor} / 0.55)`,
  ElevationMedium: `  0px 0.3px 0.5px hsl(${shadowColor} / 0),
    0px 1.7px 2.6px hsl(${shadowColor} / 0.22),
    0px 3.4px 5.1px hsl(${shadowColor} / 0.44),
    -0.1px 6.9px 10.4px hsl(${shadowColor} / 0.65)`,
  ElevationHigh: `0px 0.3px 0.5px hsl(${shadowColor} / 0),
    -0.1px 3.9px 5.9px hsl(${shadowColor} / 0.1),
    -0.1px 6.9px 10.4px hsl(${shadowColor} / 0.2),
    -0.1px 10.1px 15.2px hsl(${shadowColor} / 0.3),
    -0.2px 14.2px 21.3px hsl(${shadowColor} / 0.4),
    -0.3px 19.7px 29.6px hsl(${shadowColor} / 0.51),
    -0.4px 27.3px 41px hsl(${shadowColor} / 0.61),
    -0.6px 37.8px 56.7px hsl(${shadowColor} / 0.71)`,
  // Shadow V2
  ShadowXS: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
  ShadowSM:
    '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
  ShadowMD:
    '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)',
  ShadowLG:
    '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
  ShadowXL: '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
  Shadow2XL: '0px 24px 48px -12px rgba(16, 24, 40, 0.18)', // same as xl - check with design
  Shadow3XL: '0px 32px 64px -12px rgba(16, 24, 40, 0.14)',
};
