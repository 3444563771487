import { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';

export default function useStableObject(obj) {
  const [state, setState] = useState(obj);
  useEffect(() => {
    setState((oldState) => {
      if (oldState !== obj && !isEqual(oldState, obj)) {
        return obj;
      }
      return oldState;
    });
  }, [obj]);
  return state;
}
