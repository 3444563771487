import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Color } from 'design-system/data';
import { PATHS } from 'constants/index';
import { useAppContext } from 'context/AppContext';
import Icon from 'components/Icon';
import { useAnalyticsEvent } from 'services/analytics/AnalyticsContext';

const MainDashboardCMs = styled(function MainDashboardCMs(props) {
  const { className } = props;
  const { user } = useAppContext();
  const initials = user.name.substring(0, 2).toUpperCase();
  const name = user.nickname;
  const { analyticsWrap } = useAnalyticsEvent();

  return (
    <div className={className}>
      <div className="heading-container">
        <div className="heading">
          <span className="avatar">
            <svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
              <circle cx="25" cy="25" r="25" />
            </svg>
            <span>{initials}</span>
          </span>
          <h1>Hi {name}!</h1>
        </div>

        <p>
          Novi helps you build clean, transparent, retail-ready products with
          full visibility, on your terms. Choose an option to get started.
        </p>
      </div>

      <div className="card gradient-180">
        <div className="cover orange">
          <div className="inset">
            <div className="icon">
              <Icon name="dashboard-vetting" />
            </div>
            <div className="copy">
              <h2>Verify your products</h2>
              <p>
                Assess whether your product meets specific policies and claims,
                and learn what steps to take to become fully verified.
              </p>
            </div>
            <div className="button-box">
              <Link
                className="button filled size-l"
                to={PATHS.projectDashboard}
                onClick={() =>
                  analyticsWrap({
                    label: 'home',
                    category: 'main_dashboard',
                    action: 'click_verify_products',
                  })()
                }
              >
                Start Verifying{' '}
                <Icon name="round-arrow-forward" height="1em" width="1em" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
})`
  --orange: hsla(21deg, 100%, 91%, 0.8);
  --blue: hsla(216deg, 100%, 87%, 0.7);
  --rose: hsla(299deg, 100%, 83%, 0.7);
  --green: hsla(130deg, 100%, 89%, 0.8);

  --num-boxes: 3;

  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  justify-content: center;
  gap: 1.25rem;
  margin-top: 1.25rem;
  display: grid;
  grid-template-rows: min-content repeat(2, 1fr);
  grid-template-columns: repeat(2, minmax(100px, 500px));
  font-family: var(--font-sans-serif);
  margin: 1.25rem 0.5rem 0 0.25rem;

  @media screen and (max-width: 1024px) {
    & {
      grid-template-rows: min-content repeat(var(--num-boxes), 1fr);
    }
    & > .card {
      grid-column: 1 / span 2;
      margin: 0 auto;
    }
  }

  & > .card {
    flex: 1 1 500px;
  }

  .hide {
    display: none;
  }

  .card button {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    font-family: var(--font-sans-serif);
  }

  .card,
  .cover,
  .inset {
    border-radius: var(--radius-2);
  }

  .heading-container {
    display: flex;
    flex-flow: column nowrap;
    grid-column: 1 / span 2;

    p {
      margin: 1em 0;
      font-size: 16px;
      font-weight: 400;
      max-width: 80em;
    }
  }

  .heading {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5em;
  }

  h1 {
    font-size: 28px;
    word-break: break-word;
    font-size: clamp(1.5rem, 6vw, 2.5rem);
  }

  h2 {
    font-size: 22px;
    line-height: 1.75;
  }

  .avatar {
    display: inline;
    border-radius: 100%;
    font-size: 1.1rem;
    font-weight: 500;
    letter-spacing: -0.05;
    position: relative;
    height: 2.5em;
    width: 2.5em;

    svg {
      position: absolute;
      fill: ${Color.Red200.value};
    }

    span {
      z-index: 10;
      position: absolute;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
  }

  .card {
    filter: saturate(130%);
    background-blend-mode: screen;
    max-width: 500px;
  }

  .cover {
    height: 100%;
    width: 100%;
    display: flex;
    background-blend-mode: screen;
    padding: 0.5em;
  }

  .inset {
    height: 100%;
    width: 100%;
    background-color: hsla(360deg, 100%, 100%, 0.8);
    padding: 1em;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    gap: 1rem;
  }

  .gradient-0 {
    background: linear-gradient(180deg, red, transparent),
      linear-gradient(325deg, lime, transparent),
      linear-gradient(40deg, blue, transparent);
  }

  .gradient-90 {
    background: linear-gradient(270deg, red, transparent),
      linear-gradient(25deg, lime, transparent),
      linear-gradient(130deg, blue, transparent);
  }

  .gradient-180 {
    background: linear-gradient(0deg, red, transparent),
      linear-gradient(115deg, lime, transparent),
      linear-gradient(220deg, blue, transparent);
  }

  .gradient-270 {
    background: linear-gradient(90deg, red, transparent),
      linear-gradient(205deg, lime, transparent),
      linear-gradient(310deg, blue, transparent);
  }

  .rose {
    background: var(--rose);
  }

  .blue {
    background: var(--blue);
  }

  .orange {
    background: var(--orange);
  }

  .green {
    background: var(--green);
  }

  .icon {
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  .icon svg {
    height: 100%;
    width: 100%;
  }

  .button-box {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    margin-top: auto;
    gap: 0.5rem;
  }

  .button-box > :is(button, a) {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  .button-box svg {
    margin-left: 0.25rem;
    flex: 0 0 auto;
  }

  a.unstyled {
    text-decoration: none;
    color: inherit;
    &:visited {
      color: inherit;
    }
  }

  button {
    cursor: pointer;
  }
`;

export default MainDashboardCMs;
