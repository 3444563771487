import propTypes from 'prop-types';
import { CloudinaryImg, Icon, IconName } from 'design-system/components';
import styles from './policy-icon.module.scss';

const PolicyIcon = ({ src, alt, name, size = 24, width, height }) => {
  const iconWidth = width || height || size;
  const iconHeight = height || width || size;

  return (
    <CloudinaryImg
      src={src || ''}
      alt={alt || `${name || 'Policy'} icon`}
      height={iconHeight}
      width={iconWidth}
      fallbackElement={
        <span
          className={styles.fallback}
          style={{
            height: iconHeight,
            width: iconWidth,
            minHeight: iconHeight,
            minWidth: iconWidth,
          }}
        >
          <Icon name={IconName.QuestionMark} size={16} />
        </span>
      }
    />
  );
};

PolicyIcon.propTypes = {
  src: propTypes.string,
  alt: propTypes.string,
  name: propTypes.string,
  size: propTypes.number,
  width: propTypes.number,
  height: propTypes.number,
};

export default PolicyIcon;
