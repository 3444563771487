import { ModalType } from 'design-system/components';

const rootModalData = {
  size: ModalType.Large,
};

export const resolveModalData = {
  ...rootModalData,
  title: 'Add document(s)',
  showActionButtons: true,
  cancelCtaLabel: 'Cancel',
  doneCtaLabel: 'Done',
  dataCyRoot: 'add-document-modal',
};

export const viewModalData = {
  ...rootModalData,
  title: 'Requirements',
  dataCyRoot: 'requirement-modal',
};
