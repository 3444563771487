import {
  createContext,
  useMemo,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { useLoading } from 'context/Loading';
import { ORGANIZATION_TYPES, ROLES } from 'constants/index';
import useApi from 'services/api/useApi';
import useErrorHandlers from 'services/api/errors/useErrorHandlers';
import { analyticsRegisterUser } from 'services/analytics/AnalyticsVendor';
import User from 'classes/User';
import useAuth0 from 'hooks/useAuth0';
import noop from 'utils/noop';
import useHubSpot from 'hooks/useHubSpot';
import useRetailerBrand from 'hooks/useRetailerBrand';
import useSubscriptionTiers from 'hooks/useSubscriptionTiers';

export const AppContext = createContext();
export const useAppContext = () => useContext(AppContext);
export const useApp = () => useContext(AppContext);
export const AppConsumer = AppContext.Consumer;

export const PoliciesContext = createContext();
export const PoliciesConsumer = PoliciesContext.Consumer;

export function AppProvider({ children }) {
  const { auth0, initAuth0 } = useAuth0();
  const { retryRequest, displayMessage } = useErrorHandlers();
  const { loadVerifiedWidget } = useHubSpot();
  const [hasRetailerBrandV2Flag, setHasRetailerBrandV2Flag] = useState(false);
  const [selectedRetailerBrandId, setSelectedRetailerBrandId] =
    useState(undefined);

  useEffect(() => {
    initAuth0();
  }, [initAuth0]);

  const authorizedUser = useApi('/api/v4/users', {
    enabled: Boolean(auth0?.isAuthenticated),
    errorHandlers: [
      {
        handler: retryRequest(noop, 2),
      },
    ],
  });

  useEffect(() => {
    if (!authorizedUser.loading && authorizedUser.data?.user) {
      loadVerifiedWidget({
        email: authorizedUser.data.user.email,
        firstName: authorizedUser.data.user.firstName,
        lastName: authorizedUser.data.user.lastName,
      });
    }
  }, [authorizedUser, loadVerifiedWidget]);

  const policies = useApi('/api/v4/policies', {
    enabled: Boolean(auth0?.isAuthenticated),
    errorHandlers: [
      {
        handler: retryRequest(
          displayMessage('There was an error loading your policies'),
          2
        ),
      },
    ],
  });

  const appLoading =
    !auth0 ||
    (auth0.isAuthenticated && !authorizedUser.data && !authorizedUser.error);
  const polLoading = !auth0 || (auth0.isAuthenticated && policies.loading);

  useLoading(appLoading);

  const policiesRefetch = useRef();
  policiesRefetch.current = (...args) => policies.refetch(...args);

  const [hasStartedLoadingRetailerBrand, setHasStartedLoadingRetailerBrand] =
    useState(false);

  const {
    lastSelectedRetailerBrand: retailerBrand,
    loading: isRetailerBrandLoading,
  } = useRetailerBrand(selectedRetailerBrandId);

  const { subscriptionTiers } = useSubscriptionTiers(selectedRetailerBrandId);

  const isRetailerIdLoading = useMemo(() => {
    if (!hasStartedLoadingRetailerBrand && isRetailerBrandLoading) {
      setHasStartedLoadingRetailerBrand(true);
    }
    return isRetailerBrandLoading;
  }, [isRetailerBrandLoading, hasStartedLoadingRetailerBrand]);

  const hasRetailerBrandResponseFinished = useMemo(() => {
    // TODO remove flag check when fully migrating away from legacy retailer brand portal ENG-4826
    if (!hasRetailerBrandV2Flag) {
      return true;
    }
    return !isRetailerIdLoading && hasStartedLoadingRetailerBrand;
  }, [
    isRetailerIdLoading,
    hasStartedLoadingRetailerBrand,
    hasRetailerBrandV2Flag,
  ]);
  const selectedRetailerId = useMemo(() => {
    return retailerBrand?.retailer?.id;
  }, [retailerBrand]);

  const appCtx = useMemo(() => {
    let user;
    let organization;
    let isInMoCRAPortal;

    if (!appLoading && auth0.isAuthenticated && !authorizedUser.error) {
      datadogRum.setUser({
        id: authorizedUser?.data?.user?.id,
        email: auth0?.user?.email,
      });
      analyticsRegisterUser({
        organizationType:
          ORGANIZATION_TYPES[authorizedUser?.data?.organization?.type] ??
          'unknown',
        userIdentifier: authorizedUser?.data?.user?.sub,
        distinctID: authorizedUser?.data?.user?.id,
      });
      user = new User({
        ...authorizedUser?.data?.user,
        ...auth0?.user,
        organization: authorizedUser?.data?.organization,
        consumer_brand: authorizedUser?.data?.consumer_brand,
      });

      organization = authorizedUser?.data?.organization;
      isInMoCRAPortal =
        user.hasRole(ROLES.brand) &&
        user.display_mocra_portal &&
        !selectedRetailerBrandId;
      const hasOnboardingFlag = user.hasFF('retailer_brand_onboarding');
      setHasRetailerBrandV2Flag(hasOnboardingFlag);
      if (hasOnboardingFlag && !selectedRetailerBrandId) {
        setSelectedRetailerBrandId(user.last_selected_retailer_brand_id);
      }
    }

    return {
      auth0,
      user,
      errorLoadingUser: authorizedUser.error,
      organization,
      loading: appLoading,
      useApi,
      async refetchUser(options) {
        return Promise.all([
          authorizedUser.refetch(options),
          policiesRefetch.current(options),
        ]);
      },
      selectedRetailerBrandId,
      setSelectedRetailerBrandId,
      selectedRetailerId,
      hasRetailerBrandResponseFinished,
      isInMoCRAPortal,
      retailerBrand,
      subscriptionTiers,
    };
  }, [
    authorizedUser,
    auth0,
    appLoading,
    selectedRetailerBrandId,
    setSelectedRetailerBrandId,
    selectedRetailerId,
    hasRetailerBrandResponseFinished,
    retailerBrand,
    subscriptionTiers,
  ]);

  const policiesCtx = useMemo(() => {
    return {
      loading: polLoading,
      error: policies.error,
      policies: policies.data?.policies,
      async refetchPolicies(options) {
        return policies.refetch(options);
      },
    };
  }, [polLoading, policies]);

  return (
    <AppContext.Provider value={appCtx}>
      <PoliciesContext.Provider value={policiesCtx}>
        {children}
      </PoliciesContext.Provider>
    </AppContext.Provider>
  );
}

export function usePolicies() {
  const { user } = useApp();
  const polCtx = useContext(PoliciesContext);
  return useMemo(() => {
    let accessiblePolicies;

    if (polCtx.policies && user) {
      accessiblePolicies = user.organization.accessible_policy_ids.map((id) => {
        return polCtx.policies.find((p) => p.id == id); // eslint-disable-line eqeqeq
      });
    }

    return {
      ...polCtx,
      accessiblePolicies,
    };
  }, [polCtx, user]);
}
