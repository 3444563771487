function Download(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 7V6C14 5.44772 13.5523 5 13 5H7.61803C7.23926 5 6.893 4.786 6.72361 4.44721L6.27639 3.55279C6.107 3.214 5.76074 3 5.38197 3H3C2.44772 3 2 3.44772 2 4V11C2 11.5523 2.44772 12 3 12H8"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 14L13.5 12.5M12 14L10.5 12.5M12 14L12 9"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Download;
