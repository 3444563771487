import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  PhosphorIcon,
  PhosphorIconWeight,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';
import styles from './breadcrumbs.module.scss';
import { Color } from 'design-system/data';
import { Link } from 'react-router-dom';

function Breadcrumbs(props) {
  const { items } = props;
  const [showAllBreadcrumbs, setShowAllBreadcrumbs] = useState(
    items.length <= 3
  );
  let isEllipsisDisplayed = false;

  const DividerComponent = (
    <PhosphorIcon
      iconName="CaretRight"
      size={12}
      color={Color.Neutral500.value}
      weight={PhosphorIconWeight.Bold}
    />
  );

  return (
    <nav aria-label="Breadcrumb">
      <ol className={styles.list}>
        {items.map((item, index) => {
          const isFirst = index === 0;
          const isLast = index === items.length - 1;
          const isSecondToLast = index === items.length - 2;
          const key = `${item.text}-${index}`;

          if (isLast) {
            return (
              <li key={key}>
                <Text
                  kind={TextKind.TextSM}
                  element={TextElement.Span}
                  color={Color.Black}
                >
                  {item.text}
                </Text>
              </li>
            );
          } else if (isFirst || isSecondToLast || showAllBreadcrumbs) {
            return (
              <li key={key}>
                <Link to={item.link} className={styles.link}>
                  <Text
                    kind={TextKind.TextSM}
                    element={TextElement.Span}
                    color={Color.Neutral500}
                  >
                    {item.text}
                  </Text>
                </Link>
                {DividerComponent}
              </li>
            );
          } else if (!isEllipsisDisplayed) {
            isEllipsisDisplayed = true;
            return (
              <li key="show-more">
                <button
                  className={styles.ellipsis}
                  onClick={() => setShowAllBreadcrumbs(true)}
                >
                  ...
                </button>
                {DividerComponent}
              </li>
            );
          }
          return null;
        })}
      </ol>
    </nav>
  );
}

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Breadcrumbs;
