import { ScreeningStatus } from 'design-system/data';

import {
  AttestationTypes,
  attestationResponseExtendedOptions,
  attestationResponseOptions,
  attestationYesOptions,
} from 'components/ScreeningModals/constants';

export const RequirementActionContentType = {
  ResolveIngredient: 'resolve ingredient',
  Function: 'ingredient function',
  AddIngredient: 'add-ingredient',
  Attestation: 'attestation',
  Percentage: 'ingredient percent',
  ProductLabeling: 'product labeling',
  ProductTesting: 'product testing',
  DocumentUpload: 'upload documentation',
};

export const tabStatuses = [
  '', // All
  `${ScreeningStatus.NeedsInformation},${ScreeningStatus.Updating}`,
  ScreeningStatus.Pass,
  `${ScreeningStatus.Fail},${ScreeningStatus.Rejected}`,
  ScreeningStatus.Pending,
  ScreeningStatus.NotApplicable,
];

export const tableHeaderData = [
  {
    label: 'SKUs',
    value: 'consumerProductName',
  },
  {
    label: 'Status',
    value: 'screeningStatus',
  },
  {
    label: '',
    value: 'cta',
  },
];

export const getAttestationOptions = (attestationType) => {
  if (attestationType === AttestationTypes.yes_no_na) {
    return attestationResponseExtendedOptions();
  }
  if (attestationType === AttestationTypes.yes_no) {
    return attestationResponseOptions();
  }
  if (attestationType === AttestationTypes.yes) {
    return attestationYesOptions();
  }
  return attestationResponseOptions();
};

export const AttestationActionType = {
  IngredientPercent: 'INGREDIENT_PERCENT',
  IngredientFunction: 'INGREDIENT_FUNCTION',
  Attestation: 'ATTESTATION',
  Documentation: 'DOCUMENT',
  UnresolvedIngredent: 'UNRESOLVED_INGREDIENT',
  AlternateIngredient: 'ALTERNATE_INGREDIENT',
  Composable: 'COMPOSABLE',
  EditProduct: 'EDIT_PRODUCT',
};
