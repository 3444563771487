import propTypes from 'prop-types';
import {
  Toast,
  ToastKind,
  Text,
  TextKind,
  TextElement,
} from 'design-system/components';
import styles from './toast-manager.module.scss';

const ToastManager = ({ data }) => {
  return (
    <div className={styles.container}>
      {data.map((item, i) => {
        return (
          <div key={i}>
            <Toast
              autoLaunch
              kind={item.kind}
              title={item.title}
              autoDismiss={item.autoDismiss}
            >
              <Text kind={TextKind.TextMD} element={TextElement.P}>
                {item.message}
              </Text>
            </Toast>
          </div>
        );
      })}
    </div>
  );
};

ToastManager.propTypes = {
  data: propTypes.arrayOf(
    propTypes.shape({
      kind: propTypes.oneOf(Object.values(ToastKind)),
      title: propTypes.string,
      message: propTypes.string,
    })
  ),
  autoDismiss: propTypes.bool,
};

export default ToastManager;
