import { useCallback, useEffect, useState } from 'react';
import { bool, func, object } from 'prop-types';

import {
  SearchOnKeystroke,
  Text,
  TextKind,
  TextElement,
} from 'design-system/components';
import { Color } from 'design-system/data';

import fetcher from 'services/api/fetcher';
import {
  AttestationActionType,
  RequirementActionContentType,
} from 'views/Brands/RetailerBrandRequirementSummary/constants';

import styles from '../../cta-modals.module.scss';

function AddFunction({
  condition,
  onChange,
  triggerValidation,
  onValidate,
  canAttest,
  setHasChanged,
}) {
  const [searchResults, setSearchResults] = useState([]);
  const [selectedFunction, setSelectedFunction] = useState(
    condition?.attestationProof?.responseValue || null
  );
  const [error, setError] = useState(null);

  const handleSearch = async (searchTerm) => {
    const url = `/api/v4/autocomplete/ingredient_functions?term=${searchTerm}`;
    const { results } = await fetcher(url);
    setSearchResults(results);
  };

  const handleChange = (value) => {
    setSelectedFunction(value);
    setHasChanged();
    onChange(
      condition.id,
      AttestationActionType.IngredientFunction,
      {
        value,
        noteValue: null,
        conditionType: RequirementActionContentType.Function,
      },
      condition?.policyRequirementId
    );
  };

  const validate = useCallback(() => {
    if (!selectedFunction) {
      setError('You must provide a function.');
      return false;
    }
    setError(null);
    return true;
  }, [selectedFunction]);

  useEffect(() => {
    if (triggerValidation) {
      const isValid = validate();
      onValidate(condition?.policyRequirementId, isValid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerValidation]);

  return (
    <>
      <Text
        kind={TextKind.TextSMBold}
        element={TextElement.P}
        className={styles['function-header']}
      >
        Ingredient's intended function
      </Text>
      <SearchOnKeystroke
        placeholder="Add ingredient function..."
        onSearch={handleSearch}
        onChange={handleChange}
        value={selectedFunction}
        options={searchResults}
        disabled={!canAttest}
      />
      {error && (
        <Text
          kind={TextKind.TextSM}
          element={TextElement.P}
          color={Color.Orange600}
        >
          {error}
        </Text>
      )}
    </>
  );
}

AddFunction.propTypes = {
  condition: object.isRequired,
  onChange: func.isRequired,
  triggerValidation: bool,
  onValidate: func,
  canAttest: bool,
};

export default AddFunction;
