function Edit(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 13V11L9 5L11 7L5 13H3Z" fill={props.color.value} />
      <path
        d="M10.2929 3.70711L9.5 4.5L11.5 6.5L12.2929 5.70711C12.6834 5.31658 12.6834 4.68342 12.2929 4.29289L11.7071 3.70711C11.3166 3.31658 10.6834 3.31658 10.2929 3.70711Z"
        fill={props.color.value}
      />
    </svg>
  );
}

export default Edit;
