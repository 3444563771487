import PropTypes from 'prop-types';
import { CloudinaryImg } from 'design-system/components';
import { AvatarColor, AvatarKind } from './constants';
import styles from './avatar.module.scss';

const Avatar = ({
  initials,
  color = AvatarColor.Purple,
  kind = AvatarKind.Large,
  src,
  name,
}) => {
  const text = initials?.toString();
  const size = kind === AvatarKind.Small ? 24 : 32;
  return (
    <CloudinaryImg
      src={src || ''}
      alt={`${name || initials} icon`}
      height={size}
      width={size}
      fallbackElement={
        <span
          style={{ height: `${size}px`, width: `${size}px` }}
          className={styles.avatar}
        >
          <svg
            className={styles[color]}
            height={size}
            width={size}
            viewBox={`0 0 ${size * 2} ${size * 2}`}
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx={size} cy={size} r={size} />
          </svg>
          <p className={styles[kind]}>{text.slice(0, 2).toUpperCase()}</p>
        </span>
      }
    />
  );
};

Avatar.propTypes = {
  initials: PropTypes.string.isRequired,
  color: PropTypes.oneOf(Object.values(AvatarColor)),
  kind: PropTypes.oneOf(Object.values(AvatarKind)),
  src: PropTypes.string,
  name: PropTypes.string,
};

export default Avatar;
