import propTypes from 'prop-types';
import { IconName, IconVersion, IconDirection } from './constants';
import { Color } from 'design-system/data';
import AddUser from './icons/add-user';
import Alert from './icons/alert';
import AlertRound from './icons/alert-round';
import Caret from './icons/caret';
import Certs from './icons/certs';
import Check from './icons/check';
import CheckGreenRound from './icons/check-green-round';
import Chevron from './icons/chevron';
import Close from './icons/close';
import Document from './icons/document';
import Download from './icons/download';
import ExtLink from './icons/ext-link';
import Filter from './icons/filter';
import Info from './icons/info';
import NoContent from './icons/no-content';
import Success from './icons/success';
import Arrow from './icons/arrow';
import Subdirectory from './icons/subdirectory';
import Kebab from './icons/kebab';
import Credo from './icons/credo';
import Sephora from './icons/sephora';
import Target from './icons/target';
import Edit from './icons/edit';
import Delete from './icons/delete';
import Plus from './icons/plus';
import Formula from './icons/formula';
import Package from './icons/package';
import Cart from './icons/cart';
import Products from './icons/products';
import Grid from './icons/grid';
import Upload from './icons/upload';
import ApiError from './icons/api-error';
import List from './icons/list';
import DollarSign from './icons/dollar-sign';
import Time from './icons/time';
import Building from './icons/building';
import Bullhorn from './icons/bullhorn';
import Gear from './icons/gear';
import Search from './icons/search';
import ChatColorful from './icons/chat-colorful';
import Cluster from './icons/cluster';
import Node from './icons/node';
import QuestionMark from './icons/question-mark';
import Hyphen from './icons/hyphen';
import ExclamationMark from './icons/exclamation-mark';
import Status from './icons/status';
import Ingredient from './icons/ingredient';
import Heart from './icons/heart';
import Star from './icons/star';
import SpecialOffers from './icons/special-offers';
import InnovativeIngredient from './icons/innovative-ingredient';
import Packaging from './icons/packaging';
import ArrowV2 from './icons/arrow-v2';
import StarOutline from './icons/star-outline';
import HeartOutline from './icons/heart-outline';
import CancelCircleFilled from './icons/cancel-circle-filled';

function Icon(props) {
  const size = props.size ? props.size : 24;
  const color = props.color ? props.color : Color.Black;
  const version = size >= 16 ? IconVersion.Small : IconVersion.Large;
  const direction = props.direction ? props.direction : IconDirection.Up;

  switch (props.name) {
    case IconName.Caret:
      return <Caret size={size} color={color} direction={direction} />;
    case IconName.Document:
      return (
        <Document
          size={size}
          color={color}
          version={version}
          direction={direction}
        />
      );
    case IconName.Certs:
      return <Certs size={size} color={color} />;
    case IconName.Check:
      return <Check size={size} color={color} />;
    case IconName.Close:
      return <Close size={size} color={color} version={version} />;
    case IconName.Chevron:
      return <Chevron size={size} color={color} direction={direction} />;
    case IconName.Download:
      return <Download size={size} color={color} direction={direction} />;
    case IconName.ExtLink:
      return <ExtLink size={size} color={color} />;
    case IconName.Filter:
      return <Filter size={size} color={color} />;
    case IconName.Info:
      return <Info size={size} color={color} />;
    case IconName.Success:
      return <Success size={size} />;
    case IconName.AddUser:
      return <AddUser size={size} color={color} />;
    case IconName.NoContent:
      return <NoContent size={size} />;
    case IconName.Alert:
      return <Alert size={size} color={color} />;
    case IconName.AlertRound:
      return <AlertRound size={size} />;
    case IconName.CheckGreenRound:
      return <CheckGreenRound size={size} />;
    case IconName.Arrow:
      return <Arrow size={size} color={color} direction={direction} />;
    case IconName.Subdirectory:
      return <Subdirectory size={size} color={color} />;
    case IconName.Kebab:
      return <Kebab size={size} color={color} />;
    case IconName.Credo:
      return <Credo size={size} />;
    case IconName.Sephora:
      return <Sephora size={size} />;
    case IconName.Target:
      return <Target size={size} />;
    case IconName.Edit:
      return <Edit size={size} color={color} />;
    case IconName.Delete:
      return <Delete size={size} color={color} />;
    case IconName.Plus:
      return <Plus size={size} color={color} />;
    case IconName.Formula:
      return <Formula size={size} color={color} />;
    case IconName.Package:
      return <Package size={size} color={color} />;
    case IconName.Cart:
      return <Cart size={size} color={color} />;
    case IconName.Products:
      return <Products size={size} color={color} />;
    case IconName.Grid:
      return <Grid size={size} color={color} />;
    case IconName.Upload:
      return <Upload size={size} color={color} />;
    case IconName.ApiError:
      return <ApiError size={size} />;
    case IconName.List:
      return <List size={size} color={color} />;
    case IconName.DollarSign:
      return <DollarSign size={size} color={color} />;
    case IconName.Time:
      return <Time size={size} color={color} />;
    case IconName.Building:
      return <Building size={size} color={color} />;
    case IconName.Bullhorn:
      return <Bullhorn size={size} color={color} />;
    case IconName.Gear:
      return <Gear size={size} color={color} />;
    case IconName.Search:
      return <Search size={size} color={color} />;
    case IconName.ChatColorful:
      return <ChatColorful size={size} />;
    case IconName.Cluster:
      return <Cluster size={size} color={color} />;
    case IconName.Node:
      return <Node size={size} color={color} />;
    case IconName.QuestionMark:
      return <QuestionMark size={size} color={color} />;
    case IconName.Hyphen:
      return <Hyphen size={size} color={color} />;
    case IconName.ExclamationMark:
      return <ExclamationMark size={size} color={color} />;
    case IconName.Status:
      return <Status size={size} color={color} />;
    case IconName.Ingredient:
      return <Ingredient size={size} color={color} />;
    case IconName.Heart:
      return <Heart size={size} color={color} />;
    case IconName.HeartOutline:
      return <HeartOutline size={size} />;
    case IconName.Star:
      return <Star size={size} color={color} />;
    case IconName.StarOutline:
      return <StarOutline size={size} color={color} />;
    case IconName.SpecialOffers:
      return <SpecialOffers size={size} color={color} />;
    case IconName.InnovativeIngredient:
      return <InnovativeIngredient size={size} color={color} />;
    case IconName.Packaging:
      return <Packaging size={size} color={color} />;
    case IconName.ArrowV2:
      return <ArrowV2 size={size} color={color} direction={direction} />;
    case IconName.CancelCircleFilled:
      return <CancelCircleFilled size={size} />;

    default:
      return null;
  }
}

Icon.propTypes = {
  name: propTypes.oneOf(Object.values(IconName)).isRequired,
  size: propTypes.number,
  color: propTypes.oneOf(Object.values(Color)),
  version: propTypes.oneOf(Object.values(IconVersion)),
  direction: propTypes.oneOf(Object.values(IconDirection)),
};

export default Icon;
