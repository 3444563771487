import { Text, TextElement, TextKind } from 'design-system/components';
import { Color } from 'design-system/data';
import PercentageDifference from './percentage-difference';
import styles from './stat-card.module.scss';
import propTypes from 'prop-types';
import { convertToKebabCase } from 'design-system/utils';

function StatCard(props) {
  const { title, total, percentageDifference, timeFrame } = props;
  const dataCyRoot = `${convertToKebabCase(title)}-stat-card`;

  return (
    <div className={styles.container}>
      <Text
        className={styles.title}
        kind={TextKind.TextMD}
        element={TextElement.H3}
        data-cy={`${dataCyRoot}-title`}
      >
        {title}
      </Text>
      <div className={styles.data}>
        <Text
          kind={TextKind.DisplaySMBold}
          element={TextElement.P}
          data-cy={`${dataCyRoot}-total`}
        >
          {total}
        </Text>
        <div className={styles.difference}>
          <PercentageDifference
            value={percentageDifference}
            data-cy={`${dataCyRoot}-percentage`}
          />
          <Text
            kind={TextKind.TextSM}
            element={TextElement.P}
            color={Color.Neutral700}
            data-cy={`${dataCyRoot}-time-frame`}
          >
            {timeFrame}
          </Text>
        </div>
      </div>
    </div>
  );
}

StatCard.propTypes = {
  title: propTypes.string.isRequired,
  total: propTypes.oneOfType([propTypes.string, propTypes.number]).isRequired,
  percentageDifference: propTypes.number,
  timeFrame: propTypes.string.isRequired,
};

export default StatCard;
