import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  Icon,
  IconName,
  Text,
  TextKind,
  TextElement,
} from 'design-system/components';
import { Color } from 'design-system/data';
import { InputKind } from './constants';
import styles from './input.module.scss';

const Input = ({
  value,
  id = 0,
  name = undefined,
  label = undefined,
  handleInputChange,
  disabled = false,
  hasError = false,
  errorMessage = undefined,
  placeholder = undefined,
  className = undefined,
  type = InputKind.Text,
  step = undefined,
  min = undefined,
  max = undefined,
  endAdornment = undefined,
  startAdornment = undefined,
  required = false,
  ...rest
}) => {
  return (
    <div className={cn([className, styles.container])}>
      <label htmlFor={id} id={`input-label-${id}`}>
        {label}
        {required && <span className={styles.required}>*</span>}
      </label>
      <div className={styles['adornment-wrapper']}>
        {startAdornment && (
          <div className={styles.adornment}>{startAdornment}</div>
        )}
        <div
          className={cn([
            disabled && styles.disabled,
            styles[`input-container${hasError ? '-error' : ''}`],
          ])}
        >
          <input
            required={required}
            className={styles.input}
            disabled={disabled}
            name={name || id}
            id={id.toString()}
            aria-describedby={hasError ? `error-${id}` : `input-label-${id}`}
            placeholder={placeholder}
            onChange={handleInputChange}
            value={value}
            type={type}
            step={step}
            min={min}
            max={max}
            {...rest}
          />
        </div>
        {endAdornment && <div className={styles.adornment}>{endAdornment}</div>}
      </div>
      {hasError && (
        <div
          className={styles.error}
          role="alert"
          id={`error-${id}`}
          aria-live="polite"
        >
          <Icon name={IconName.Alert} size={16} color={Color.Orange800} />
          <Text
            kind={TextKind.TextSM}
            element={TextElement.Span}
            color={Color.Orange800}
          >
            {errorMessage}
          </Text>
        </div>
      )}
    </div>
  );
};

Input.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  label: PropTypes.string,
  handleInputChange: PropTypes.func,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  endAdornment: PropTypes.string,
  startAdornment: PropTypes.string,
  required: PropTypes.bool,
};

export default Input;
