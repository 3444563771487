import { ToastV2Provider } from 'design-system/components';
import { RequirementActionsProvider } from 'views/Brands/shared/RequirementActionModalContents/context';
import RetailerRequirementsView from './view';

const RetailerRequirements = () => {
  return (
    <ToastV2Provider>
      <RequirementActionsProvider>
        <RetailerRequirementsView />
      </RequirementActionsProvider>
    </ToastV2Provider>
  );
};

export default RetailerRequirements;
