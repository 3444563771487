import { useState, useEffect } from 'react';
import { useFDAProductsContext } from '../Context';
import {
  Table,
  TableHead,
  TableRow,
  TableHeadCell,
  TableBody,
  TableBodyCell,
  Checkbox,
  Text,
  TextKind,
  TextElement,
  ButtonKind,
  IconName,
  Modal,
  ModalType,
  WarningRings,
  PhosphorIcon,
  PhosphorIconWeight,
  Link,
  LinkKind,
} from 'design-system/components';
import { FormInput } from '../../shared/components/Form';
import styles from './import-dialog.module.scss';
import { Color } from 'design-system/data';

export const ImportDialog = () => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedSkus, setSelectedSkus] = useState([]);
  const {
    importOpen,
    setImportOpen,
    productsForImport,
    importProducts,
    importSearch,
    setImportSearch,
  } = useFDAProductsContext();

  const onCancel = () => setImportOpen(false);
  const onContinueToConfirmation = () => setShowConfirmation(true);
  const onBackToTable = () => setShowConfirmation(false);
  const onComplete = () => {
    importProducts(selectedSkus);
    setShowConfirmation(false);
    setImportOpen(false);
  };

  // state-dependent
  const getModalType = showConfirmation ? ModalType.Small : ModalType.Large;
  const modalTitle = showConfirmation ? null : 'Select SKUs';
  const cancelButtonOnClick = showConfirmation ? onBackToTable : onCancel;
  const cancelButtonLabel = showConfirmation ? 'Go back' : 'Cancel';
  const cancelButtonKind = showConfirmation
    ? ButtonKind.Tertiary
    : ButtonKind.Error;
  const cancelButtonIcon = showConfirmation ? null : IconName.Close;
  const doneButtonOnClick = showConfirmation
    ? onComplete
    : onContinueToConfirmation;
  const doneButtonLabel = showConfirmation ? 'Continue' : 'Import SKUs';
  const doneButtonIcon = showConfirmation ? null : IconName.Download;

  const toggleAllCheckboxes = () => {
    if (selectedSkus.length === productsForImport?.products?.length) {
      setSelectedSkus([]);
    } else {
      setSelectedSkus(
        productsForImport?.products?.map((product) => product.id)
      );
    }
  };

  const onCheckboxChange = (skuId) => {
    setSelectedSkus((prev) => {
      if (prev.includes(skuId)) {
        return prev.filter((id) => id !== skuId);
      }
      return [...prev, skuId];
    });
  };

  useEffect(() => {
    if (!importOpen) {
      setSelectedSkus([]);
    }
  }, [importOpen]);

  return (
    <Modal
      show={importOpen}
      title={modalTitle}
      type={getModalType}
      onClick={onCancel}
      showActionButtons
      dataCyRoot="attestation-modal"
      cancelCtaLabel={cancelButtonLabel}
      cancelCtaKind={cancelButtonKind}
      cancelCtaIcon={cancelButtonIcon}
      onCancel={cancelButtonOnClick}
      doneCtaLabel={doneButtonLabel}
      doneCtaKind={ButtonKind.Primary}
      doneCtaIcon={doneButtonIcon}
      onDone={doneButtonOnClick}
    >
      {showConfirmation === false ? (
        <>
          <Text
            color={Color.Neutral600}
            kind={TextKind.TextSM}
            element={TextElement.P}
          >
            Select the SKUS you would like to list with the FDA for MoCRA
            compliance. Products that qualify as a drug (ex: foundation with
            SPF, antidandruff shampoos, and acne creams), are not required to be
            listed as cosmetics with the FDA.
            <Link
              kind={LinkKind.External}
              href="https://noviconnect.notion.site/Products-considered-drugs-by-the-FDA-f7e7a40eed4a40d6bdc286011b574e34"
              style={{ color: Color.Purple700.value }}
            >
              Learn more
            </Link>
          </Text>
          <div className={styles['search-container']}>
            <FormInput
              type="text"
              value={importSearch}
              onChange={(e) => setImportSearch(e.target.value)}
              placeholder="Search for a SKU..."
              icon={
                <PhosphorIcon
                  iconName="MagnifyingGlass"
                  size="1.5rem"
                  weight={PhosphorIconWeight.Light}
                  color={Color.Neutral600.value}
                />
              }
            />
          </div>
          <div className={styles.table}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadCell>
                    <Checkbox
                      onClick={toggleAllCheckboxes}
                      isActive={
                        productsForImport?.products?.length ===
                        selectedSkus.length
                      }
                    />
                  </TableHeadCell>
                  <TableHeadCell>SKU</TableHeadCell>
                  {/* <TableHeadCell>Source</TableHeadCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {productsForImport?.products?.map((product) => {
                  return (
                    <TableRow key={product.id}>
                      <TableBodyCell>
                        <Checkbox
                          onClick={() => onCheckboxChange(product.id)}
                          isActive={selectedSkus.includes(product.id)}
                        />
                      </TableBodyCell>
                      <TableBodyCell>{product.productName}</TableBodyCell>
                      {/* <TableBodyCell>Test</TableBodyCell> */}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </>
      ) : (
        <>
          <WarningRings />
          <div className={styles['confirmation-message']}>
            <Text kind={TextKind.DisplayXSMedium} element={TextElement.H2}>
              Importing SKUs
            </Text>
            <Text
              color={Color.Neutral600}
              kind={TextKind.TextSM}
              element={TextElement.P}
            >
              The MoCRA portal is separate from all other portals in Novi
              Connect. The edits you make to SKUs here will not affect these
              SKUs in other portals.
            </Text>
          </div>
        </>
      )}
    </Modal>
  );
};
