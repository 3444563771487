import { bool, number, string } from 'prop-types';

export const ProgressVariant = {
  Bar: 'bar',
  Ring: 'ring',
};

export const ProgressSize = {
  Sm: 'sm',
  Lg: 'lg',
};

export const progressComponentBasePropTypes = {
  percentage: number.isRequired,
  label: string,
  color: string,
  loading: bool,
};
