import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  AvatarColor,
  Modal,
  ModalType,
  Text,
  TextKind,
  TextElement,
} from 'design-system/components';
import styles from './user-list.module.scss';

const UserList = ({ users = [], title = 'Users' }) => {
  const [showModal, setShowModal] = useState(false);
  const colorCount = Object.keys(AvatarColor).length;
  // cycle through available colors
  const getColor = (i) => Object.values(AvatarColor)[i % colorCount];

  return (
    <>
      <div className={styles['user-list']} onClick={() => setShowModal(true)}>
        {users?.length &&
          users.map((user, i) => (
            <div
              key={`${user?.name || user}-${i}`}
              className={styles['avatar-wrapper']}
              style={{ '--z-index': users.length + i }}
              tabIndex={0}
            >
              <Avatar
                initials={user?.name || user}
                color={getColor(i)}
                src={user?.imagePath ? user.imagePath : ''}
              />
            </div>
          ))}
      </div>
      <Modal
        title={title}
        type={ModalType.Large}
        show={showModal}
        onClick={() => setShowModal(false)}
      >
        <div
          className={styles['modal-user-list']}
          onClick={() => setShowModal(true)}
        >
          {users.map((user, i) => (
            <div
              key={`${user?.name || user}-${i}`}
              className={styles['modal-user-wrapper']}
              style={{ zIndex: users.length - i }}
            >
              <Avatar
                initials={user?.name || user}
                color={getColor(i)}
                src={''}
              />
              <Text kind={TextKind.TextSM} element={TextElement.P}>
                {user?.name || user}
              </Text>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

UserList.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ).isRequired,
  title: PropTypes.string,
};

export default UserList;
