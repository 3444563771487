export default function StarOutline({ size, color }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8,4.9l0.2,0.4l0.4,0.1l3.1,0.4L9.4,7.9L9.1,8.2l0.1,0.4l0.5,3L7,10.2L6.6,10l-0.3,0.2l-2.7,1.4l0.5-3.1  l0.1-0.4L3.9,7.9L1.7,5.7l3.1-0.4l0.4-0.1l0.2-0.4l1.4-2.8L8,4.9z"
        stroke={color.value}
        strokeWidth="1.5"
      />
    </svg>
  );
}
