import { useState } from 'react';
import orderBy from 'lodash/orderBy';

import {
  BrandViewHeader,
  NoData,
  Pagination,
  Table,
  TableRow,
  TableHead,
  TableHeadCell,
  TableBody,
  TableBodyCell,
  Button,
  ButtonKind,
  ToastManager,
  Pill,
} from 'design-system/components';
import {
  sortDirection,
  TableReducerAction,
  useTableSort,
  useInitialQueryParams,
  useSetUrlSearchParameters,
} from 'design-system/utils';
import useBrandSubmissions from './useBrandSubmissions';
import styles from './retailerBrandSubmissions.module.scss';
import { useApp } from 'context/AppContext';
import { generateBrandPolicyExport } from 'views/Retailer/Submissions/views/BrandSubmissions/useBrandSubmissions';
import { SUBMISSION_MAPPINGS } from 'design-system/data';

function RetailerBrandSubmissions() {
  const { data, loading, error } = useBrandSubmissions();
  const { user, selectedRetailerId: retailerId, retailerBrand } = useApp();
  const [toastData, setToastData] = useState([]);
  const consumerBrandId = user?.organization?.in_orgable_id;

  let tableHeaderData = [
    {
      label: 'Program Name',
      value: 'programName',
    },
    {
      label: 'Status',
      value: 'status',
    },
    {
      label: 'Retailer Note',
      value: 'retailerNote',
    },
    {
      label: 'Actions',
      value: 'actions',
    },
  ];
  const [tableState, dispatch] = useTableSort({
    initialSortByValue: tableHeaderData[0].value,
    initialSortAsc: false,
  });
  const [currentPage, setCurrentPage] = useState(
    useInitialQueryParams('page', 1)
  );
  const [itemsPerPage, setItemsPerPage] = useState(
    useInitialQueryParams('items', 25)
  );
  useSetUrlSearchParameters(tableState, currentPage, itemsPerPage);

  const hasData = data?.policySubmissions?.length;
  const supportsPagination = data?.count > data?.items;

  const canSeeExport = user.hasFF('policy_export_button_visible');
  if (!canSeeExport) {
    tableHeaderData = tableHeaderData.filter(
      (header) => header.value !== 'actions'
    );
  }

  const onExportPolicy = (policyId) => {
    const onSuccess = () => {
      setToastData((prevToastData) => [
        ...prevToastData,
        {
          kind: 'success',
          title: 'Success',
          message:
            'Policy export started. You should receive an email shortly.',
          autoDismiss: true,
        },
      ]);
    };
    const onFailure = () => {
      setToastData((prevToastData) => [
        ...prevToastData,
        {
          kind: 'error',
          title: 'Error',
          message: 'Policy export failed. Please try again or contact support.',
          autoDismiss: true,
        },
      ]);
    };
    generateBrandPolicyExport({
      onSuccess,
      onFailure,
      retailerId,
      consumerBrandId,
      policyId,
    });
  };

  return (
    <main>
      <BrandViewHeader
        title={`Policy Submissions for ${
          retailerBrand?.retailer?.name || 'Retailer'
        }`}
        subTitle={'Policy Submissions Table'}
        showExportButton={false}
        showFilterButton={false}
        editableTitle={false}
      />
      {error || loading || !hasData ? (
        <NoData
          isLoading={loading}
          hasErrorOccurred={!!error}
          noContentMessage="No submissions yet found!"
        />
      ) : (
        <div className={styles.table}>
          <Table>
            <TableHead>
              <TableRow>
                {tableHeaderData.map((header) => (
                  <TableHeadCell
                    key={header.value}
                    enableSort
                    active={data.value === tableState.sortBy}
                    direction={sortDirection(tableState, data.value)}
                    onClick={() =>
                      dispatch({
                        type: TableReducerAction.LabelClick,
                        sortBy: data.value,
                      })
                    }
                  >
                    {header.label}
                  </TableHeadCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {orderBy(
                data.policySubmissions,
                tableState.sortBy,
                sortDirection(tableState)
              ).map((submission, i) => (
                <TableRow key={i}>
                  <TableBodyCell>{submission.policyName}</TableBodyCell>
                  <TableBodyCell>
                    <Pill
                      color={
                        // @ts-ignore
                        SUBMISSION_MAPPINGS[submission.status]?.color
                      }
                    >
                      {
                        // @ts-ignore
                        SUBMISSION_MAPPINGS[submission.status]?.label
                      }
                    </Pill>
                  </TableBodyCell>
                  <TableBodyCell>{submission.retailerNotes}</TableBodyCell>
                  {canSeeExport && (
                    <TableBodyCell>
                      <Button
                        kind={ButtonKind.Primary}
                        data-cy="submit-entity-button"
                        onClick={() => onExportPolicy(submission.policyId)}
                      >
                        Export
                      </Button>
                    </TableBodyCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {supportsPagination && (
            <Pagination
              onNextPage={() => setCurrentPage(currentPage + 1)}
              onPreviousPage={() => setCurrentPage(currentPage - 1)}
              onPageSelect={setCurrentPage}
              onChangePageSize={setItemsPerPage}
              currentPage={data?.page}
              totalItemCount={data?.count}
              itemsPerPage={data?.items}
            />
          )}
        </div>
      )}
      <ToastManager data={toastData} />
    </main>
  );
}

export default RetailerBrandSubmissions;
