import { CSSProperties } from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TableRow,
} from 'design-system/components';
import { sortDirection, TableReducerAction } from 'design-system/utils';
import SupplierListingsTableRow from './table-row';
import styles from './listings-table.module.scss';

interface TableState {
  sortBy: string;
  sortAsc: boolean;
}
interface SupplierListingsTableProps {
  data: Array<{ id: string }>;
  state: TableState;
  onClick: (action: { type: string; sortBy: string }) => void;
}
export default function SupplierListingsTable({
  data,
  state,
  onClick,
}: SupplierListingsTableProps) {
  const materialNameValue = 'name';

  const colSpan = 2;
  const tableStyle = {
    '--col-span': colSpan.toString(),
  } as CSSProperties;

  return (
    <div className={styles.table} style={tableStyle}>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell
              enableSort
              key={materialNameValue}
              active={materialNameValue === state.sortBy}
              direction={sortDirection(state, materialNameValue)}
              onClick={() =>
                onClick({
                  type: TableReducerAction.LabelClick,
                  sortBy: materialNameValue,
                })
              }
              data-cy={`supplier-listings-table-name-label`}
            >
              Material name
            </TableHeadCell>
            <TableHeadCell>Documents provided</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <SupplierListingsTableRow key={item.id} data={item} index={index} />
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
