import Papa from 'papaparse';

import {
  MAX_PRODUCTS,
  MAX_UNIQUE_ID_LENGTH,
  MAX_PRODUCT_NAME_LENGTH,
  MAX_UPC_NUMBER_LENGTH,
  PRODUCT_TYPE_BPC,
  additionalProductDetailsOptions,
  allowedColumns,
  fileTypeError,
  maxProductsError,
  multipleFileError,
  noDataError,
  productFormatOptions,
  requiredColumns,
  validIntendedApplications,
  validProductTypes,
} from './constants';

export const isValidLength = (value, minLength, maxLength) => {
  return value.length >= minLength && value.length <= maxLength;
};

export const isValidPattern = (value, regex) => {
  return regex.test(value);
};

export const isValidOption = (value, options) => {
  const lowerCaseOptions = options.map((option) => option.toLowerCase());
  const lowerCaseValue = value.toLowerCase().trim();

  return lowerCaseOptions.includes(lowerCaseValue);
};

export const isValidProductType = (productType) => {
  return isValidOption(productType, validProductTypes);
};

export const isValidUniqueId = (uniqueId) => {
  return (
    isValidLength(uniqueId, 1, MAX_UNIQUE_ID_LENGTH) &&
    isValidPattern(uniqueId, /^[a-zA-Z0-9-]+$/)
  );
};

export const isValidUPC = (upcNumber) => {
  return (
    isValidLength(upcNumber, 0, MAX_UPC_NUMBER_LENGTH) &&
    isValidPattern(upcNumber, /^[0-9]*$/)
  );
};

export const isValidProductName = (productName) => {
  return isValidLength(productName, 1, MAX_PRODUCT_NAME_LENGTH);
};

export const isValidProductLine = (productLine) => {
  return isValidLength(productLine, 0, MAX_PRODUCT_NAME_LENGTH);
};

export const isValidSalesChannels = (salesChannels) => {
  if (!salesChannels.trim()) return true;
  const inputArray = salesChannels.split(',');
  return inputArray.every((item) =>
    isValidOption(item, ['retail', 'ecommerce'])
  );
};

export const isValidIntendedUse = (intendedUse) => {
  return isValidOption(intendedUse, ['leave on', 'rinse off']);
};

export const isValidIntendedApplication = (intendedApplication) => {
  // Must select one or more
  const inputArray = intendedApplication.split(',');
  return (
    Boolean(inputArray) &&
    inputArray.every((item) => isValidOption(item, validIntendedApplications))
  );
};

export const isValidProductFormat = (productFormat) => {
  return isValidOption(productFormat, productFormatOptions);
};

export const isValidAdditionalProductDetails = (additionalProductDetails) => {
  // Optional, can select one or more
  if (!additionalProductDetails.trim()) return true;
  const inputArray = additionalProductDetails.split(',');
  return inputArray.every((item) =>
    isValidOption(item, additionalProductDetailsOptions)
  );
};

export const isYesOrNo = (input) => {
  return isValidOption(input, ['yes', 'no']);
};

export const isValidFlavor = (flavor) => {
  return isYesOrNo(flavor);
};

export const isValidFragrance = (fragrance) => {
  return isYesOrNo(fragrance);
};

export const isValidIngredients = (ingredients) => {
  // Must be present and not empty whitespace.
  return Boolean(ingredients && ingredients.trim() !== '');
};

const formatError = (rowNumber, productName, column, value, message) => [
  `Product: ${productName}`,
  `Column: ${column}`,
  `Value: ${value}`,
  `CSV Row ${rowNumber}, ${column} column value is not valid. ${message}`,
];

export const validateCSV = async (
  acceptedFiles,
  { ingredientsOptional = false } = {}
) => {
  if (acceptedFiles.length === 0) {
    // Drag and drop error
    return [[fileTypeError]];
  }

  if (acceptedFiles.length > 1) {
    return [[multipleFileError]];
  }

  const file = acceptedFiles[0];
  if (file?.type !== 'text/csv') {
    return [[fileTypeError]];
  }

  const {
    data: csvRows,
    errors: globalErrors,
    meta,
  } = await new Promise((resolve, reject) => {
    Papa.parse(file, {
      delimiter: ',',
      header: true,
      skipEmptyLines: true,
      complete: resolve,
      error: reject,
    });
  });

  if (globalErrors.length) {
    return globalErrors.map((error) => [
      `CSV Row ${error.row + 1}. ${error.message}`,
    ]);
  }

  if (csvRows.length === 0) {
    return [[noDataError]];
  }

  if (csvRows.length > MAX_PRODUCTS) {
    return [[maxProductsError]];
  }

  const quotedValidProductTypes = validProductTypes.map(
    (option) => `"${option}"`
  );

  const csvErrors = [];

  for (const column of requiredColumns) {
    if (!meta.fields.includes(column)) {
      csvErrors.push([`CSV Header name ${column} is not correct or missing.`]);
    }
  }

  for (const column of meta.fields) {
    if (!allowedColumns.includes(column)) {
      csvErrors.push([`CSV Header name ${column} is not valid.`]);
    }
  }

  if (csvErrors.length) {
    return csvErrors;
  }

  for (let i = 0; i < csvRows.length; i++) {
    const rowNumber = i + 2;
    const row = csvRows[i];

    const productName = row['Product Name'];
    if (!isValidProductName(productName)) {
      csvErrors.push(
        formatError(
          rowNumber,
          productName,
          'Product Name',
          productName,
          `Please enter a Product Name between 1 and ${MAX_PRODUCT_NAME_LENGTH} characters.`
        )
      );
    }

    const productType = row['Product Type'] ?? PRODUCT_TYPE_BPC;
    if (!isValidProductType(productType)) {
      csvErrors.push(
        formatError(
          rowNumber,
          productName,
          'Product Type',
          productType,
          `Please enter one or more of the following: ${quotedValidProductTypes.join(
            ', '
          )}`
        )
      );
    }

    const isBPC = productType.toLowerCase() === PRODUCT_TYPE_BPC.toLowerCase();

    const uniqueId = row['Unique ID'];
    if (!isValidUniqueId(uniqueId)) {
      csvErrors.push(
        formatError(
          rowNumber,
          productName,
          'Unique ID',
          uniqueId,
          `Please enter a Unique ID between 1 and ${MAX_UNIQUE_ID_LENGTH} characters. Only letters, numbers, and dashes are allowed.`
        )
      );
    }

    const upcNumber = row['UPC'] ?? '';
    if (!isValidUPC(upcNumber)) {
      csvErrors.push(
        formatError(
          rowNumber,
          productName,
          'UPC',
          upcNumber,
          `Please enter a valid UPC number between 1 and ${MAX_UPC_NUMBER_LENGTH} digits, or leave blank.`
        )
      );
    }

    const productLine = row['Product Line'] ?? '';
    if (!isValidProductLine(productLine)) {
      csvErrors.push(
        formatError(
          rowNumber,
          productName,
          'Product Line',
          productLine,
          `Please enter a Product Line between 1 and ${MAX_PRODUCT_NAME_LENGTH} characters, or leave blank.`
        )
      );
    }

    const salesChannels = row['Sales Channels'] ?? '';
    if (!isValidSalesChannels(salesChannels)) {
      csvErrors.push(
        formatError(
          rowNumber,
          productName,
          'Sales Channels',
          salesChannels,
          'Please enter either or both of "retail" and "ecommerce", or leave blank.'
        )
      );
    }

    const intendedUse = row['Intended Use'] ?? '';
    if (isBPC && !isValidIntendedUse(intendedUse)) {
      csvErrors.push(
        formatError(
          rowNumber,
          productName,
          'Intended Use',
          intendedUse,
          'Please enter either "Leave on" or "Rinse off".'
        )
      );
    }

    const intendedApplication = row['Intended Application'] ?? '';
    if (isBPC && !isValidIntendedApplication(intendedApplication)) {
      const quotedOptions = validIntendedApplications.map(
        (option) => `"${option}"`
      );
      csvErrors.push(
        formatError(
          rowNumber,
          productName,
          'Intended Application',
          intendedApplication,
          `Please enter one or more of the following:
          ${quotedOptions.join(', ')}
        `
        )
      );
    }

    const productFormat = row['Product Format'] ?? '';
    if (isBPC && !isValidProductFormat(productFormat)) {
      const quotedOptions = productFormatOptions.map((option) => `"${option}"`);
      csvErrors.push(
        formatError(
          rowNumber,
          productName,
          'Product Format',
          productFormat,
          `Please enter one of the following:
          ${quotedOptions.join(', ')}
        `
        )
      );
    }

    const additionalProductDetails = row['Additional Product Details'];
    if (!isValidAdditionalProductDetails(additionalProductDetails)) {
      const quotedOptions = additionalProductDetailsOptions.map(
        (option) => `"${option}"`
      );
      csvErrors.push(
        formatError(
          rowNumber,
          productName,
          'Product Format',
          productFormat,
          `Please enter one or more of the following, or leave blank:
          ${quotedOptions.join(', ')}
        `
        )
      );
    }

    const flavor = row['Flavor'] ?? '';
    if (isBPC && !isValidFlavor(flavor)) {
      csvErrors.push(
        formatError(
          rowNumber,
          productName,
          'Flavor',
          flavor,
          'Please enter either "Yes" or "No".'
        )
      );
    }

    const fragrance = row['Fragrance'] ?? '';
    if (isBPC && !isValidFragrance(fragrance)) {
      csvErrors.push(
        formatError(
          rowNumber,
          productName,
          'Fragrance',
          fragrance,
          'Please enter either "Yes" or "No".'
        )
      );
    }

    const ingredients = row['Ingredients'];
    if (!ingredientsOptional && !isValidIngredients(ingredients)) {
      csvErrors.push(
        formatError(
          rowNumber,
          productName,
          'Ingredients',
          ingredients,
          'Please enter a list of comma separated ingredients.'
        )
      );
    }
  }

  return csvErrors;
};
