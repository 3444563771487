import { BlobUpload } from '@rails/activestorage/src/blob_upload';
import { FileChecksum } from '@rails/activestorage/src/file_checksum';
import fetcher from 'services/api/fetcher';

async function uploadFile(file) {
  // calculate file metadata
  const checksum = await new Promise((resolve, reject) => {
    FileChecksum.create(file, (error, checksum) => {
      if (error) {
        reject(error);
      } else {
        resolve(checksum);
      }
    });
  });

  // let the back-end tell us where to upload the file
  const { direct_upload } = await fetcher('/api/v4/upload/file_blob', {
    method: 'post',
    body: {
      checksum,
      filename: file.name,
      content_type: file.type,
      byte_size: file.size,
    },
    preventAbort: true,
  });

  // upload the file to its final destination
  await new Promise((resolve, reject) => {
    new BlobUpload({
      file,
      directUploadData: {
        url: direct_upload?.url,
        headers: JSON.parse(direct_upload?.headers),
      },
    }).create((error) => {
      if (error) {
        reject(error);
      } else {
        resolve();
      }
    });
  });

  // return the blob id so it may be used in further api calls
  return direct_upload?.signed_blob_id;
}

export default uploadFile;
