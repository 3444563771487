import { forwardRef, ForwardRefRenderFunction } from 'react';
import cn from 'classnames';
import {
  Text,
  TextKind,
  TextElement,
  Pill,
  IconName,
  Icon,
} from 'design-system/components';
import { PillColor } from '../../../atoms/pill/constants';
import { Color } from 'design-system/data';
import styles from './tab.module.scss';

interface TabProps {
  children: string;
  chipValue?: string | number;
  onClick: () => void;
  isActive: boolean;
  ariaId?: string;
  ariaControls?: string;
  iconName?: keyof typeof IconName;
  iconColor?: keyof typeof Color;
  pillColor?: keyof typeof PillColor;
  'data-cy'?: string;
}

const Tab: ForwardRefRenderFunction<HTMLButtonElement, TabProps> = (
  props,
  ref
) => {
  const { chipValue } = props;
  const chipNumberValue = Number(chipValue);
  return (
    <button
      role="tab"
      id={props.ariaId}
      data-cy={props['data-cy']}
      aria-selected={props.isActive}
      aria-controls={props.ariaControls}
      className={cn(styles.root, props.isActive && styles['root-active'])}
      onClick={props.onClick}
      ref={ref}
    >
      {props.iconName && (
        <Icon
          name={props.iconName}
          color={props.iconColor ? Color[props.iconColor] : Color.Black}
        />
      )}

      <Text kind={TextKind.TextSMBold} element={TextElement.Span}>
        {props.children}
      </Text>

      {(chipNumberValue || chipNumberValue === 0) && (
        <span>
          <Pill color={props.isActive ? PillColor.Purple : props.pillColor}>
            {chipNumberValue}
          </Pill>
        </span>
      )}
    </button>
  );
};

const ForwardedTab = forwardRef(Tab);

export default ForwardedTab;
