import { useState, FC } from 'react';
import cn from 'classnames';
import {
  InputV2,
  FilterDropdown,
  DropdownRadioMenu,
  Text,
  TextElement,
  TextKind,
  PhosphorIcon,
} from 'design-system/components';
import { useStatefulQueryParam } from 'design-system/utils';
import { UrlParam, Color } from 'design-system/data';

import styles from './search-filter.module.scss';
import { Option } from '../filter-dropdown';

export type SearchFilterProps = {
  filterOptions?: Array<Option>;
  sortOptions?: { [key: string]: string };
};

const SearchFilter: FC = ({
  filterOptions,
  sortOptions,
}: SearchFilterProps) => {
  const searchParams = new URLSearchParams();

  const [search, setSearch] = useStatefulQueryParam(
    UrlParam.Search,
    searchParams.get(UrlParam.Search) || ''
  );

  const [activeSort, setActiveSort] = useStatefulQueryParam(
    UrlParam.Direction,
    searchParams.get(UrlParam.Direction) || ''
  );

  const [statusFilterParams, setStatusFilterParams] = useStatefulQueryParam(
    UrlParam.Status,
    searchParams.get(UrlParam.Status) || ([] as Array<string>)
  );

  const [error] = useState<string>('');
  const handleSearch = (e: any) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  return (
    <div className={cn(styles['search-filter'])}>
      <InputV2
        type="text"
        name="search"
        value={search}
        placeholder="Search"
        handleInputChange={handleSearch}
        errorMessage={error}
        hasError={error.length > 0}
      />
      <div className={cn(styles['drop-down-menus'])}>
        {filterOptions && (
          <div>
            <FilterDropdown
              accordionOptions={[
                {
                  title: 'Status',
                  options: Array.isArray(filterOptions) ? filterOptions : [],
                },
              ]}
              selectedValues={statusFilterParams as string[]}
              setSelectedValues={setStatusFilterParams}
            />
          </div>
        )}
        {sortOptions && (
          <div>
            <DropdownRadioMenu
              fromLeft={true}
              options={sortOptions || {}}
              reverse
              activeValue={activeSort}
              setActiveValue={setActiveSort}
              triggerElement={
                <div className={styles['trigger-container']}>
                  <PhosphorIcon
                    iconName="ArrowsDownUp"
                    title="sort-by"
                    size={20}
                  />
                  <Text
                    kind={TextKind.TextSMSemibold}
                    element={TextElement.Span}
                    color={Color.Neutral600}
                    className={styles.nowrap}
                  >
                    {activeSort && sortOptions
                      ? `Sort by: ${sortOptions[activeSort]}`
                      : 'Sort by'}
                  </Text>
                </div>
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default SearchFilter;
