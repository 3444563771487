import { PhosphorIcon } from 'design-system/components';
import { Color } from 'design-system/data';

export default function TableRowMenuTrigger() {
  return (
    <PhosphorIcon
      iconName="DotsThreeVertical"
      size={24}
      color={Color.Neutral400.value}
    />
  );
}
