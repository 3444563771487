import { Button, ButtonKind, IconName } from 'design-system/components';

function CloseDialogButton(props) {
  const { onClick } = props;

  return (
    <div className="button-box with-padding">
      <Button
        kind={ButtonKind.Tertiary}
        onClick={onClick}
        iconName={IconName.Close}
      >
        Cancel
      </Button>
    </div>
  );
}

export default CloseDialogButton;
