import PropTypes from 'prop-types';
import {
  Text,
  TextKind,
  TextElement,
  Icon,
  IconName,
  IconDirection,
} from 'design-system/components';
import styles from './pagination.module.scss';
import { paginationOptions, PLACEHOLDER } from './constants';
import Select from '../../molecules/select';
import Option from '../../atoms/option';
import { usePagination } from './utils';
import { Color } from 'design-system/data';

function Pagination({
  totalItemCount = 0,
  currentPage = 1,
  itemsPerPage = 10,
  ...props
}) {
  const range = usePagination(totalItemCount, itemsPerPage, currentPage);

  function handleNextPage() {
    if (currentPage === range?.[range?.length - 1]) return;
    return props.onNextPage();
  }

  function handlePreviousPage() {
    if (currentPage === 1) return;
    return props.onPreviousPage();
  }

  function handlePageSelect(page) {
    if (typeof page !== 'number') return;
    return props.onPageSelect(page);
  }

  function paginationCopy() {
    const lowerValue = (a, b) => {
      return a < b ? a : b;
    };
    return `Showing ${itemsPerPage * (currentPage - 1) + 1} to ${lowerValue(
      itemsPerPage * currentPage,
      totalItemCount
    )} of ${totalItemCount} items`;
  }

  return (
    <div className={styles.root}>
      <Select value={itemsPerPage} options={paginationOptions}>
        {paginationOptions.map((option) => (
          <Option
            key={option.value}
            ariaSelected={option.value === props.value}
            onClick={() => props.onChangePageSize(option.value)}
          >
            <Text kind={TextKind.TextMDBold} element={TextElement.Span}>
              {option.value}
            </Text>
            <Text kind={TextKind.TextSM} element={TextElement.Span}>
              {' '}
              / page
            </Text>
          </Option>
        ))}
      </Select>
      <div className={styles['button-section']}>
        <Text
          kind={TextKind.TextSM}
          element={TextElement.P}
          color={Color.Neutral500}
        >
          {paginationCopy()}
        </Text>
        {!!range?.length && (
          <div className={styles['pagination-buttons']}>
            <button
              data-testid="previous-page-button"
              className={styles['button-nav']}
              onClick={handlePreviousPage}
            >
              <Icon
                name={IconName.Chevron}
                size={12}
                direction={IconDirection.Left}
              />
            </button>
            {range.map((page, index) => {
              if (page === PLACEHOLDER) {
                return (
                  <span key={`${page}-${index}`} data-testid="placeholder-span">
                    {page}
                  </span>
                );
              }
              return (
                <button
                  data-testid="page-button"
                  key={`page-${page}`}
                  className={
                    currentPage === page
                      ? styles['button-active']
                      : styles.button
                  }
                  onClick={() => handlePageSelect(page)}
                >
                  {page}
                </button>
              );
            })}
            <button
              data-testid="next-page-button"
              className={styles['button-nav']}
              onClick={handleNextPage}
            >
              <Icon
                name={IconName.Chevron}
                size={12}
                direction={IconDirection.Right}
              />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

Pagination.propTypes = {
  totalItemCount: PropTypes.number,
  currentPage: PropTypes.number,
  itemsPerPage: PropTypes.number,
  onNextPage: PropTypes.func,
  onPreviousPage: PropTypes.func,
  onPageSelect: PropTypes.func,
  onChangePageSize: PropTypes.func,
};

export default Pagination;
