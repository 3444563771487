import {
  BrandViewHeader,
  LoadingOverlay,
  NoData,
} from 'design-system/components';
import { useApp } from 'context/AppContext';
import RequirementCard from './components/RequirementCard';
import Disclaimer from '../shared/Disclaimer';
import { useHistory, useParams } from 'react-router-dom';
import useRequirementGroupDetail from './useRequirementGroupDetail';
import withLineBreaks from 'utils/withLineBreaks';
import { PATHS } from 'constants/index';

function RetailerBrandRequirementGroupsDetail() {
  const { requirement_group_id } = useParams();
  const { retailerBrand } = useApp();
  const history = useHistory();
  const { data, error, loading } =
    useRequirementGroupDetail(requirement_group_id);

  // TODO: update when breadcrumbs are ready to be hydrated by BE data
  const baseLink = PATHS.retailerBrandRequirementGroups;
  const items = [
    {
      text: 'Pillars',
      link: baseLink,
    },
    {
      text: data?.name || 'Retailer Requirements',
      link: `${baseLink}/${requirement_group_id}/retailer_requirements`,
    },
  ];

  const hasRequirements = !!data?.policyRequirements?.length && !error;

  return (
    <main>
      <LoadingOverlay show={loading} />
      <BrandViewHeader
        title={data?.name || 'Retailer Requirements'}
        subTitle={withLineBreaks(data?.description)}
        breadcrumbsItems={items}
        showExportButton={false}
        showFilterButton={false}
        editableTitle={false}
      />
      {hasRequirements &&
        data?.policyRequirements?.map((requirement, i) => {
          const screeningStatusCounts = requirement?.screeningStatusCounts;
          const url = `${history.location.pathname}/${requirement.id}/policy_requirements`;
          return (
            <RequirementCard
              key={i}
              title={requirement.name}
              description={requirement.description}
              linkUrl={url}
              screeningStatusCounts={screeningStatusCounts || {}}
            />
          );
        })}
      {!hasRequirements && (
        <NoData
          hasErrorOccurred={!!error}
          noContentMessage={'There is nothing to see here yet!'}
        />
      )}
      {retailerBrand?.retailer?.disclaimer && (
        <Disclaimer text={retailerBrand.retailer.disclaimer} />
      )}
    </main>
  );
}

export default RetailerBrandRequirementGroupsDetail;
