import { SelectOption } from './index';

export const determineSelectedOptions = (
  selectedOption: SelectOption,
  selectedOptions: SelectOption[]
) => {
  const updatedSelectedOptions = [...selectedOptions];
  if (updatedSelectedOptions.includes(selectedOption)) {
    updatedSelectedOptions.splice(
      updatedSelectedOptions.indexOf(selectedOption),
      1
    );
  } else {
    updatedSelectedOptions.push(selectedOption);
  }
  return updatedSelectedOptions;
};

export const determineSearchResults = (
  event: { target: { value: string } },
  options: SelectOption[]
) => {
  const searchInput = event.target.value.toLowerCase();
  return options.filter(({ name }) =>
    name?.toLowerCase().includes(searchInput)
  );
};
