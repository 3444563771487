import { ELLIPSIS } from 'design-system/data';

export const determinePagesToDisplay = (currentPage, numberOfPages) => {
  const lastThreePages = [numberOfPages - 2, numberOfPages - 1, numberOfPages];
  const isCurrentPageWithinTheLastThreePages = numberOfPages - currentPage < 3;
  const isCurrentPageWithinTheLastSixPages = numberOfPages - currentPage < 6;

  if (isCurrentPageWithinTheLastThreePages) {
    return [1, 2, 3, ELLIPSIS, ...lastThreePages];
  } else if (isCurrentPageWithinTheLastSixPages) {
    return [
      ELLIPSIS,
      numberOfPages - 5,
      numberOfPages - 4,
      numberOfPages - 3,
      ...lastThreePages,
    ];
  }
  return [
    currentPage,
    currentPage + 1,
    currentPage + 2,
    ELLIPSIS,
    ...lastThreePages,
  ];
};

export const shouldPageBeDisplayed = (page, numberOfPages, displayedPages) => {
  let result = true;
  if (page < 1 || displayedPages.includes(page) || page > numberOfPages) {
    result = false;
  } else {
    displayedPages.push(page);
  }
  return result;
};
