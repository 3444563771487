import { useContext, createContext, useMemo } from 'react';
import { useApp } from 'context/AppContext';
import useQueryParams from 'hooks/useQueryParams';
import { convertSnakeToCamelCase } from 'design-system/utils';
import { UrlParam } from 'design-system/data';

export function SupplierListingsProvider(props) {
  const { children } = props;
  const { useApi } = useApp();

  const params = useQueryParams(Object.values(UrlParam));

  const { data, loading, error } = useApi(`/api/v4/suppliers/listings`, {
    param: {
      ...params._asValues(),
    },
  });

  const listingsData = useMemo(() => {
    return loading || error ? data : convertSnakeToCamelCase(data);
  }, [data, loading, error]);

  const context = useMemo(
    () => ({
      data: listingsData,
      loading,
      error,
    }),
    [listingsData, loading, error]
  );

  return (
    <SupplierListingsContext.Provider value={context}>
      {children}
    </SupplierListingsContext.Provider>
  );
}

const SupplierListingsContext = createContext(null);
export const useSupplierListingsContext = () => {
  const ctx = useContext(SupplierListingsContext);
  if (!ctx) {
    throw new Error('Must be used with a SupplierListingsProvider');
  }
  return ctx;
};
