import cn from 'classnames';
import PropTypes from 'prop-types';
import { handleKeyboardEvent, KeyboardCode } from 'design-system/utils';
import styles from './option.module.scss';

function Option(props) {
  function handleEnterPress(event) {
    handleKeyboardEvent(event, [KeyboardCode.Enter], props.onClick);
  }

  return (
    <div
      role="option"
      aria-selected={props.ariaSelected}
      className={cn([styles.root, props.ariaSelected ? styles.selected : ''])}
      onClick={props.onClick}
      tabIndex={props.tabIndex || 0}
      onKeyDown={handleEnterPress}
      data-cy={props.dataCy}
    >
      {props.children}
    </div>
  );
}

Option.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  ariaSelected: PropTypes.bool.isRequired,
  dataCy: PropTypes.string,
  tabIndex: PropTypes.number,
};

export default Option;
