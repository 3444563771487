import React from 'react';
import cn from 'classnames';
import styles from './table-row.module.scss';

interface TableRowProps {
  className?: string;
  children: React.ReactNode | React.ReactNode[];
  onClick?: () => void;
}

function TableRow(props: TableRowProps) {
  const className =
    cn(props.className, props.onClick && styles.clickable) || undefined;
  return (
    <tr className={className} onClick={props.onClick}>
      {props.children}
    </tr>
  );
}

export default TableRow;
