export default function Node({ size, color }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="2" fill={color.value} />
      <path
        d="M7.37016 2.23568C7.75937 2.00865 8.24064 2.00865 8.62984 2.23568L12.6298 4.56902C13.0139 4.79303 13.25 5.20416 13.25 5.64874V10.3513C13.25 10.7958 13.0139 11.207 12.6298 11.431L8.62984 13.7643C8.24063 13.9914 7.75936 13.9914 7.37016 13.7643L3.37016 11.431L2.99226 12.0788L3.37016 11.431C2.98614 11.207 2.75 10.7958 2.75 10.3513V5.64874C2.75 5.20416 2.98614 4.79303 3.37016 4.56902L7.37016 2.23568Z"
        stroke={color.value}
        strokeWidth="1.5"
      />
    </svg>
  );
}
