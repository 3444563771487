import {
  Link,
  LinkKind,
  PhosphorIcon,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';
import { Color } from 'design-system/data';

import styles from '../sku-requirements.module.scss';
import { PATHS } from 'constants/index';

const BrandRequirementNote = () => {
  return (
    <div className={styles['brand-req-note']}>
      <div>
        <PhosphorIcon
          size={20}
          iconName="WarningCircle"
          color={Color.Orange600.value}
        />{' '}
        <Text kind={TextKind.TextXSSemibold} element={TextElement.H4}>
          Brand-level attestation
        </Text>
      </div>
      <Text kind={TextKind.TextXS} element={TextElement.P}>
        Brand-level attestations cannot be completed at the single SKU level,
        since they apply to all of a brand&apos;s SKUs. To complete brand-level
        attestations, go to the{' '}
        <Link
          kind={LinkKind.Internal}
          to={PATHS.retailerBrandRequirementGroups}
        >
          Pillars page.
        </Link>
      </Text>
    </div>
  );
};

export default BrandRequirementNote;
