import Proptypes from 'prop-types';
import {
  DropdownMenu,
  DropdownMenuItem,
  PhosphorIcon,
  Text,
  TextElement,
  TextKind,
  TableRowMenuTrigger,
} from 'design-system/components';
import styles from './table-row-dropdown.module.scss';
import { useFDAProductsContext } from '../Context';

function TableRowDropdown({ skuId, disabled }) {
  const { setDeleteConfirmationOpen } = useFDAProductsContext();

  return (
    <DropdownMenu
      disabled={disabled}
      isFixed
      triggerElement={<TableRowMenuTrigger />}
    >
      <DropdownMenuItem onClick={() => setDeleteConfirmationOpen(skuId)}>
        <div className={styles['table-row-dropdown-item']}>
          <PhosphorIcon iconName="Trash" size={20} />
          <Text kind={TextKind.TextSM} element={TextElement.Span}>
            Delete
          </Text>
        </div>
      </DropdownMenuItem>
    </DropdownMenu>
  );
}

TableRowDropdown.propTypes = {
  skuId: Proptypes.string.isRequired,
};

export default TableRowDropdown;
