import { Route, Switch } from 'react-router-dom';

import { PATHS } from 'constants/index';
import { useApp } from 'context/AppContext';
import { SupplierMaterialProvider } from './context';
import SupplierMaterialView from './view';
import SupplierMaterialPolicies from './Policies';

export default function SupplierMaterial() {
  const { user } = useApp();
  const hasPolicyVettingAccess = user.hasFF('vetting_in_supplier_portal');
  return (
    <SupplierMaterialProvider>
      <Switch>
        <Route
          path={PATHS.supplierMaterialId}
          component={SupplierMaterialView}
          exact
        />
        {hasPolicyVettingAccess && (
          <Route
            path={PATHS.supplierMaterialPolicies}
            component={SupplierMaterialPolicies}
            exact
          />
        )}
      </Switch>
    </SupplierMaterialProvider>
  );
}
