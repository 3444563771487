export const GridColumns = {
  Mobile: 4,
  Tablet: 8,
  Desktop: 12,
};

export const GridBreakpoints = {
  Mobile: 'mobile',
  Tablet: 'tablet',
  Desktop: 'desktop',
};
