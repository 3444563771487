export const BannerKind = {
  Info: 'info',
  Warning: 'warning',
  Offer: 'offer',
  Default: 'default',
  OfferOrange: 'offer-orange',
  Welcome: 'welcome',
};

export const BannerSize = {
  Small: 'small',
  Large: 'large',
};
