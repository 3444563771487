import styles from './table-head.module.scss';
import { elementOfType } from 'design-system/utils';

function TableHead(props) {
  return <thead className={styles.root}>{props.children}</thead>;
}

TableHead.propTypes = {
  children: elementOfType('TableRow'),
};

export default TableHead;
