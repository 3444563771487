export const AvatarColor = {
  Purple: 'purple',
  Blue: 'blue',
  Green: 'green',
  Orange: 'orange',
  Default: 'default',
};

export const AvatarKind = {
  Small: 'small',
  Large: 'large',
};
