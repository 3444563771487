function Success(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="success-icon"
    >
      <g clipPath="url(#clip0_763_34327)">
        <circle cx="48" cy="48" r="24" fill="#1E1786" />
        <path d="M38 49L44 55L58 41" stroke="white" strokeWidth="4" />
        <path d="M54 0H46L50 22L54 0Z" fill="#FF5353" />
        <path d="M54 96H46L50 76L54 96Z" fill="#FFA551" />
        <path
          d="M70.7721 13.5455L66.3633 11L62.2041 23.2949L70.7721 13.5455Z"
          fill="#98C5F2"
        />
        <path d="M95 53L95 46L75 49.5L95 53Z" fill="#FFA551" />
        <path
          d="M90.7283 38.407L88.7612 35L80.9838 41.7617L90.7283 38.407Z"
          fill="#FF5353"
        />
        <path
          d="M89.0871 66.5782L92.4746 60.7109L75.6936 54.9339L89.0871 66.5782Z"
          fill="#98C5F2"
        />
        <path
          d="M65.0688 85.5962L68.5957 82.0693L57.7632 74.7637L65.0688 85.5962Z"
          fill="#98C5F2"
        />
        <path
          d="M19.0003 73.4852L21.9292 76.4141L27.9497 67.4646L19.0003 73.4852Z"
          fill="#98C5F2"
        />
        <path
          d="M14.0721 29L13 33.001L23.7607 33.7402L14.0721 29Z"
          fill="#98C5F2"
        />
        <path
          d="M-0.0511555 61.3077L2.20361 69.7227L22.581 59.753L-0.0511555 61.3077Z"
          fill="#FF5353"
        />
        <path
          d="M14.7003 7.99994L8 14.7002L28.473 28.4729L14.7003 7.99994Z"
          fill="#FFA551"
        />
      </g>
      <defs>
        <clipPath id="clip0_763_34327">
          <rect width="96" height="96" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Success;
