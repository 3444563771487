import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: 'transparent',
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    height: '100%',
  },
  illustration: {
    marginTop: '50px',
    '&& img': {
      height: '200px',
    },
  },
  containerImageOnly: {
    maxWidth: 250,
    maxHeight: 260,
    borderRadius: 12,
    backgroundColor: 'hsl(210,11%,71%)', // gray
    marginTop: '1.25rem',
  },
  information: {
    padding: '1.25rem',
  },
  information_actions: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&& button': {
      backgroundColor: 'hsl(222, 22%, 5%)', // off black
      color: 'hsl(0, 0%, 100%)', // white
    },
  },
}));

export default useStyles;
