import PropTypes from 'prop-types';
import {
  Icon,
  IconName,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';
import styles from './policy-status-card.module.scss';
import { Color } from 'design-system/data';

function PolicyStatusCard({ label, target, current }) {
  const StatusIconName =
    target > current ? IconName.AlertRound : IconName.CheckGreenRound;
  return (
    <div className={styles.container}>
      <Text
        kind={TextKind.TextSMBold}
        element={TextElement.P}
        className={styles.caption}
      >
        {label}
      </Text>
      <div className={styles.content}>
        <Text
          kind={TextKind.Display2XSBold}
          element={TextElement.P}
          className={styles.target}
        >
          {target}%
        </Text>
        <Icon name={StatusIconName} size={24} />
      </div>
      <Text
        kind={TextKind.TextSM}
        element={TextElement.P}
        color={Color.Neutral500}
        className={styles.current}
      >
        Current: {current}%
      </Text>
    </div>
  );
}

PolicyStatusCard.propTypes = {
  label: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  current: PropTypes.string.isRequired,
};

export default PolicyStatusCard;
