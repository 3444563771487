import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './grid-item.module.scss';
import { GridColumns, GridBreakpoints } from '../constants.js';

const GridItem = ({
  children,
  span = 1,
  responsiveSpan = {
    [GridBreakpoints.Tablet]: 1,
    [GridBreakpoints.Desktop]: 1,
  },
  offset,
  responsiveOffset,
  className,
}) => {
  // scale from mobile as fallback
  const tabletSpan =
    responsiveSpan.tablet || (span * GridColumns.Tablet) / GridColumns.Mobile;
  // scale up from mobile as fallback
  const mobileToDesktopSpan =
    responsiveSpan.desktop || (span * GridColumns.Desktop) / GridColumns.Mobile;

  const hasOffset = offset > 1 || responsiveOffset !== undefined;
  // scale from mobile as fallback
  const mobileOffset = offset || 1;
  const tabletOffset =
    responsiveOffset?.tablet ||
    (offset * GridColumns.Tablet) / GridColumns.Mobile;
  // scale from mobile as fallback
  const desktopOffset =
    responsiveOffset?.desktop ||
    (offset * GridColumns.Desktop) / GridColumns.Mobile;

  const itemStyle = {
    '--mobile-span': span,
    '--tablet-span': tabletSpan,
    '--desktop-span': mobileToDesktopSpan,
    ...(hasOffset && { '--mobile-offset': mobileOffset }),
    ...(hasOffset && { '--tablet-offset': tabletOffset }),
    ...(hasOffset && { '--desktop-offset': desktopOffset }),
  };
  return (
    <div
      // @ts-ignore
      style={itemStyle}
      className={cn([
        styles['grid-item'],
        { [styles.offset]: hasOffset },
        className,
      ])}
    >
      {children}
    </div>
  );
};

GridItem.propTypes = {
  children: PropTypes.node.isRequired,
  span: PropTypes.number,
  responsiveSpan: PropTypes.shape({
    [GridBreakpoints.Tablet]: PropTypes.number,
    [GridBreakpoints.Desktop]: PropTypes.number,
  }),
  offset: PropTypes.number,
  responsiveOffset: PropTypes.shape({
    [GridBreakpoints.Tablet]: PropTypes.number,
    [GridBreakpoints.Desktop]: PropTypes.number,
  }),
  className: PropTypes.string,
};

export default GridItem;
