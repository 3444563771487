import { useState } from 'react';

export const FilterReducerAction = {
  ApplyFilters: 'apply-filters',
  ClearFilters: 'clear-filters',
};

const initialFilterState = {};

export function filterReducer(action, state) {
  switch (action.type) {
    case FilterReducerAction.ApplyFilters:
      return action.filters;
    case FilterReducerAction.ClearFilters:
      return initialFilterState;
    default:
      return state;
  }
}

export function useFilters() {
  const [state, setState] = useState(initialFilterState);

  function dispatch(action) {
    const nextState = filterReducer(action, state);
    setState(nextState);
  }

  return [state, dispatch];
}
