import propTypes from 'prop-types';
import { Text, TextElement, TextKind } from 'design-system/components';
import styles from './material-documentation.module.scss';
import SupplierMaterialDocument from './document';

export default function SupplierMaterialDocumentation({ data }) {
  return (
    <div className={styles.container}>
      <Text
        className={styles.title}
        kind={TextKind.Display2XSBold}
        element={TextElement.H1}
      >
        Documentation
      </Text>
      <Text
        className={styles.subtitle}
        kind={TextKind.TextSM}
        element={TextElement.P}
      >
        If you want to change any permissions to your document downloads, please
        reach out to support@noviconnect.com
      </Text>
      {!data?.length ? (
        <div className={styles.placeholder}>
          <Text kind={TextKind.TextSMBold} element={TextElement.P}>
            No files have been uploaded yet
          </Text>
        </div>
      ) : (
        data?.map((documents) => {
          const documentArray = Array.isArray(documents)
            ? documents
            : [documents];
          return documentArray?.map((document) => (
            <SupplierMaterialDocument
              key={`${document?.name}-${document?.id}`}
              data={document}
            />
          ));
        })
      )}
    </div>
  );
}

SupplierMaterialDocumentation.propTypes = {
  data: propTypes.array,
};
