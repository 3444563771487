import { useParams } from 'react-router';

import { useApp } from 'context/AppContext';
import { SKUEligibilityProvider } from './context/SKUEligibilityContext';
import SKULevelStatusPageView from './view';

const SKULevelStatusPage = () => {
  const { policy_id } = useParams();
  const { retailerBrand, user } = useApp();
  const retailerId = retailerBrand?.retailer?.id;
  const brandId = user?.organization?.in_orgable_id;

  return (
    <SKUEligibilityProvider
      retailerId={retailerId}
      brandId={brandId}
      policyId={policy_id}
    >
      <SKULevelStatusPageView retailerBrand={retailerBrand} />
    </SKUEligibilityProvider>
  );
};

export default SKULevelStatusPage;
