import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { PATHS } from 'constants/index';
import { useApp } from 'context/AppContext';
import BrandSubmissions from './views/BrandSubmissions';
import Policies from './views/Policies';
import RetailerRequirements from './views/RetailerRequirements';
import PolicyRequirements from './views/PolicyRequirements';
import RetailerBrandSubmissionSKUs from './views/SKUs';
import RetailerBrandSubmissionSKUReview from './views/SKUReview';

function RetailerBrandSubmissions() {
  const { path } = useRouteMatch();
  const { user } = useApp();
  const hasRetailerSKUReview = user?.hasFF('tmp_retailer_sku_review');

  return (
    <div>
      <Switch>
        <Route exact path={path} component={BrandSubmissions} />
        <Route
          exact
          path={`${path}/:brand_id/requirement_groups`}
          component={Policies}
        />
        <Route
          exact
          path={`${path}/:brand_id/requirement_groups/:requirement_group_id/retailer_requirements`}
          component={RetailerRequirements}
        />
        <Route
          exact
          path={`${path}/:brand_id/requirement_groups/:requirement_group_id/retailer_requirements/:requirement_id/policy_requirements`}
          component={PolicyRequirements}
        />
        {hasRetailerSKUReview && (
          <>
            <Route
              exact
              path={PATHS.retailerBrandSubmissionSKUs}
              component={RetailerBrandSubmissionSKUs}
            />
            <Route
              exact
              path={PATHS.retailerBrandSubmissionSKUReview}
              component={RetailerBrandSubmissionSKUReview}
            />
          </>
        )}
      </Switch>
    </div>
  );
}

export default RetailerBrandSubmissions;
