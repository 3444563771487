import Divider from 'components/Divider';
import styled from 'styled-components';

import { Icon, IconName } from 'design-system/components';
import { Color } from 'design-system/data';
import links from 'constants/links';
import { useNav } from 'layouts/NoviNav';
import CircularProgress from 'components/CircularProgress';
import { useAnalyticsEvent } from 'services/analytics/AnalyticsContext';

import { ListItem, IconWrapper } from 'layouts/NoviNav/Menu';
import { useUserInvitation } from 'context/UserInvitationContext';
import CallsToAction from 'layouts/BrandCertificationSidebar/CallsToAction';

export default function Help(props) {
  const { children, ...rest } = props;
  const { slim: isSlim, setSlim } = useNav();
  const { analyticsWrap } = useAnalyticsEvent();
  const { openModal: openInvitationModal } = useUserInvitation();
  return isSlim ? (
    <>
      <Divider size={1} color="#DCDCE8" margin="0px 0" />
      <HelpIcon onClick={() => setSlim((was) => !was)} />
    </>
  ) : (
    <Container {...rest}>
      <Divider size={1} color="#DCDCE8" margin="0px 0" />
      <a href={links.helpDesk}>
        <HelpItem>Help Desk</HelpItem>
      </a>
      <a href={links.mailtoSupport}>
        <HelpItem>Contact Us</HelpItem>
      </a>
      <CallToAction
        variant="outlined"
        title="Invite Your Team to Novi"
        text="Easily collaborate on your products"
        onClick={() => {
          analyticsWrap(
            {
              label: 'user_invitation_modal',
              category: 'left_nav',
              action: 'click_user_invitation_cta',
            },
            openInvitationModal
          )();
        }}
      />
      <div style={{ padding: '8px 0' }}>
        <CallsToAction />
      </div>
      {children}
    </Container>
  );
}

const HelpIcon = (props) => (
  <ListItem {...props}>
    <IconWrapper>
      <Icon name={IconName.QuestionMark} size={20} />
    </IconWrapper>
  </ListItem>
);

const HelpItem = styled.div`
  font-size: 14px;
  line-height: 24px;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: hsla(
      var(--brand-color-h),
      var(--brand-color-s),
      var(--brand-color-l),
      0.04
    );
  }
`;

const Container = styled.div`
  padding: 0 4px;
  display: flex;
  flex-flow: column nowrap;
  gap: 6px;

  a {
    color: inherit;
    text-decoration: none;
  }
`;

const CallToAction = styled((props) => {
  const { title, text, children, loading, variant, ...rest } = props;
  return (
    <div data-variant={variant} {...rest}>
      <div>
        {title && <b>{title}</b>}
        {text && <em>{text}</em>}
        {children}
      </div>
      {loading ? (
        <CircularProgress size={12} />
      ) : (
        <Icon
          name={IconName.Chevron}
          direction="right"
          size={14}
          color={Color.Purple300}
        />
      )}
    </div>
  );
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  border-radius: 4px;
  background-color: hsla(
    var(--brand-color-h),
    var(--brand-color-s),
    var(--brand-color-l),
    0.1
  );
  padding: 8px 10px;
  cursor: pointer;
  transition:
    background-color 100ms ease-out,
    box-shadow 100ms ease-out;

  &:hover {
    background-color: hsla(
      var(--brand-color-h),
      var(--brand-color-s),
      calc(var(--brand-color-l) + 40%),
      1
    );
  }

  > div:nth-child(1) {
    color: black;
    display: flex;
    flex-direction: column;

    > em {
      color: var(--brand-color);
      font-style: normal;
    }
  }

  > svg {
    flex: 0 0 auto;
  }

  &[data-variant='outlined'] {
    border: 1px solid var(--brand-color);
    background-color: transparent;
    > div:nth-child(1) {
      b {
        color: var(--brand-color);
      }
      em {
        color: black;
      }
    }

    &:hover {
      background-color: hsla(
        var(--brand-color-h),
        calc(var(--brand-color-s) + 20%),
        calc(var(--brand-color-l) + 49%),
        1
      );
    }
  }
`;
