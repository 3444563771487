import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import useStableObject from 'hooks/useStableObject';

const identityFn = (val) => val;
const parse = (query) => qs.parse(query, { arrayFormat: 'bracket' });
const stringify = (params) => qs.stringify(params, { arrayFormat: 'bracket' });

/* subscribe to one or more values from the current URL's query string
 * @param {string|string[]} param - one or more query parameters
 * @param {object} options -
 * @param {function|object<param:function>} options.format - a single formatting
 * function or an object keyed by the query parameter with its formatting function
 * as a value.
 * @returns {object} all the query names as keys with an object as a value. The
 * object will have a value prop containing the current value, and two helper functions,
 * push and replace, mirroring the history.push and history.replace functionality,
 * except that updating the value to null or undefined will remove it from the URL
 * @example
 * let {
 *  foo: {
 *    value, // current value of "foo" in url
 *    push, // history.push new value of "foo" to url
 *    replace, // history.replace value of "foo" in url
 *  },
 *  bar: { value, push, replace }
 * } = useQueryParams(['foo', 'bar'])
 */
export default function useQueryParams(argParam, options = {}) {
  const { format = identityFn } = options;
  const history = useHistory();
  const location = useLocation();
  const param = useStableObject(argParam);

  return useMemo(() => {
    const url = new URL(
      location.pathname + location.search + location.hash,
      window.location.href
    );
    const params = parse(location.search);

    const update = (key, val) => {
      return val === undefined || val === null
        ? delete params[key]
        : (params[key] = val);
    };

    const pushUrl = () => {
      url.search = stringify(params);
      history.push(url.pathname + url.search + url.hash);
    };

    const replaceUrl = () => {
      url.search = stringify(params);
      history.replace(url.pathname + url.search + url.hash);
    };

    return [].concat(param).reduce(
      (obj, key) => {
        obj[key] = {
          value:
            typeof format === 'object'
              ? (format[key] || identityFn)(params[key])
              : format(params[key]),
          push: (val) => {
            update(key, val);
            pushUrl();
          },
          replace: (val) => {
            update(key, val);
            replaceUrl();
          },
        };
        return obj;
      },
      Object.defineProperties(
        {},
        {
          _asValues: {
            value() {
              return Object.keys(this).reduce((obj, key) => {
                obj[key] = obj[key].value;
                return obj;
              }, Object.assign({}, this));
            },
            enumerable: false,
          },
          _bulkPush: {
            value(params) {
              Object.entries(params).forEach(([key, val]) => update(key, val));
              pushUrl();
            },
            enumerable: false,
          },
          _bulkReplace: {
            value(params) {
              Object.entries(params).forEach(([key, val]) => update(key, val));
              replaceUrl();
            },
            enumerable: false,
          },
        }
      )
    );
  }, [param, history, location, format]);
}
