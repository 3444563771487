import PropTypes from 'prop-types';
import _ from 'lodash';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Checkbox } from 'design-system/components';
import Input from 'components/Input';
import MultiSelectAutocomplete from 'views/ConsumerProducts/components/MultiSelectAutocomplete';
import {
  COUNTRIES,
  COUNTRY_REGION_MAP,
  PRODUCT_CATEGORIES,
  SALES_CHANNELS,
} from './constants';

import styles from './sephoraproductfields.module.scss';

const SephoraProductFields = (props) => {
  const onChange = (inputName, value) => {
    props.onChange({ [inputName]: value });
  };

  const makeOnChange = (inputName) => (e) =>
    onChange(inputName, e.target.value);

  const activeRegions = _.uniq(
    props.values.countries?.map((code) => COUNTRY_REGION_MAP[code]) || []
  );
  const { isLegacySephoraFlow } = props;

  return (
    <>
      {isLegacySephoraFlow && (
        <>
          <label htmlFor="brandLine">Brand Line</label>
          <Input
            name="brandLine"
            className="input"
            value={props.values.brandLine || ''}
            onChange={makeOnChange('brandLine')}
          />

          <label htmlFor="productCategory">Product Category *</label>
          <Select
            name="productCategory"
            className="styled-select input"
            required
            data-active={!props.values.productCategory}
            value={props.values.productCategory || ''}
            style={{ backgroundColor: 'white' }}
            variant="outlined"
            onChange={makeOnChange('productCategory')}
          >
            <MenuItem value="skin">Skin</MenuItem>
            <MenuItem value="hair">Hair</MenuItem>
            <MenuItem value="makeup">Makeup</MenuItem>
            <MenuItem value="fragrance">Fragrance</MenuItem>
            <MenuItem value="sephora_collection">Sephora Collection</MenuItem>
          </Select>
        </>
      )}

      <label htmlFor="countries">Country *</label>
      <MultiSelectAutocomplete
        required
        name="countries"
        className="styled-select input"
        options={COUNTRIES.map(({ code, display }) => ({
          id: code,
          name: display,
        }))}
        onChange={(_, value) =>
          onChange(
            'countries',
            value.map((v) => v.id)
          )
        }
      />

      <div className={styles['launch-dates-container']}>
        <div>
          <label>Launch Dates</label>
        </div>

        {activeRegions.map((region) => (
          <div className={styles['launch-date-container']} key={region}>
            <label htmlFor={`launchDates.${region}`}>{region}</label>
            <Input
              type="date"
              value={props.values.launchDates[region] || ''}
              onChange={(e) =>
                onChange('launchDates', {
                  ...props.values.launchDates,
                  [region]: e.target.value,
                })
              }
            />
          </div>
        ))}
      </div>

      {isLegacySephoraFlow && (
        <>
          {' '}
          <label htmlFor="parentCompany">Parent Company Name</label>
          <Input
            name="parentCompany"
            className="input"
            value={props.values.parentCompany || ''}
            onChange={makeOnChange('parentCompany')}
          />
          <label htmlFor="salesChannel">Sales Channel *</label>
          <MultiSelectAutocomplete
            required
            name="salesChannel"
            className="styled-select input"
            options={SALES_CHANNELS}
            onChange={(_, value) =>
              onChange(
                'salesChannel',
                value.map((v) => v.id)
              )
            }
          />
          <div className={styles['share-container']}>
            <label id="sephora-share-checkbox-label">
              Share product with sephora
            </label>
            <Checkbox
              ariaLabelledBy="sephora-share-checkbox-label"
              isActive={props.values.shareWithSephora}
              onClick={() =>
                onChange('shareWithSephora', !props.values.shareWithSephora)
              }
            />
          </div>
        </>
      )}
    </>
  );
};

SephoraProductFields.propTypes = {
  values: PropTypes.shape({
    brandLine: PropTypes.string,
    productCategory: PropTypes.oneOf(
      PRODUCT_CATEGORIES.map((cat) => cat.value)
    ),
    countries: PropTypes.arrayOf(PropTypes.oneOf(COUNTRIES.map((c) => c.code))),
    launchDates: PropTypes.object,
    parentCompany: PropTypes.string,
    salesChannel: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  isLegacySephoraFlow: PropTypes.bool,
};

export default SephoraProductFields;
