import {
  TextElement,
  TextKind,
  Text,
  PhosphorIcon,
} from 'design-system/components';
import styles from './step-card.module.scss';

function StepCard({ title, description, iconName, link }) {
  return (
    <div className={styles.card}>
      <div className={styles['card-content']}>
        <div>
          <div className={styles['title-group']}>
            <PhosphorIcon size={26} iconName={iconName} />
            <Text kind={TextKind.DisplayXSMedium} element={TextElement.H3}>
              {title}
            </Text>
          </div>
          <Text kind={TextKind.TextSM} element={TextElement.P}>
            {description}
          </Text>
        </div>
        {link}
      </div>
    </div>
  );
}

export default StepCard;
