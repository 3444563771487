import { useParams } from 'react-router-dom';
import { ToastV2Provider } from 'design-system/components';

import { useApp } from 'context/AppContext';
import { PATHS } from 'constants/index';
import { RequirementActionsProvider } from 'views/Brands/shared/RequirementActionModalContents/context';
import SKURequirementsPageView from './view';
import useApi from 'services/api/useApi';
import { useMemo } from 'react';

const SKURequirementsPage = () => {
  const { policy_id } = useParams();
  const { retailerBrand, user } = useApp();
  const retailerId = retailerBrand?.retailer?.id;
  const brandId = user?.organization?.in_orgable_id;
  const policyRes: any = useApi(
    `/api/v4/retailers/${retailerId}/consumer_brands/${brandId}/policies`,
    {
      enabled: Boolean(retailerId && brandId),
    }
  );

  const currentPolicy = useMemo(() => {
    const policies = policyRes?.data?.policies;
    return policies?.find(
      (policy: any) => parseInt(policy.id) === parseInt(policy_id)
    );
  }, [policyRes?.data?.policies, policy_id]);
  const breadcrumbBase = [
    {
      text: 'Eligibility',
      link: PATHS.retailerBrandEligibility,
    },
    {
      text: currentPolicy?.name || 'Policy',
      link: PATHS.retailerBrandSKULevelStatus.replace(':policy_id', policy_id),
    },
  ];

  return (
    <ToastV2Provider>
      <RequirementActionsProvider>
        <SKURequirementsPageView
          brandId={brandId}
          retailerId={retailerId}
          retailerBrand={retailerBrand}
          breadcrumbBase={breadcrumbBase}
        />
      </RequirementActionsProvider>
    </ToastV2Provider>
  );
};

export default SKURequirementsPage;
