import { useCallback, useState } from 'react';
import { initializeAuth0 } from 'utils/auth0';
import { sentry } from 'utils/sentry';
import { setToken } from 'utils/token';

const useAuth0 = () => {
  const [auth0, setAuth0] = useState();

  const initAuth0 = useCallback(async () => {
    try {
      const auth0 = await initializeAuth0();
      setToken(auth0.token);
      setAuth0(auth0);
    } catch (e) {
      sentry.captureException(e);
    }
  }, []);

  return {
    auth0,
    setAuth0,
    initAuth0,
  };
};

export default useAuth0;
