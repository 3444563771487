import { useEffect, useState } from 'react';
import cn from 'classnames';

import { isEmpty } from 'lodash';
import InputField from 'design-system/components/organisms/composable-conditions-modal/input-field';

import styles from '../../cta-modals.module.scss';
import { AttestationActionType } from 'views/Brands/RetailerBrandRequirementSummary/constants';
import {
  PhosphorIcon,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';

import { VETTING_STATUS } from 'constants/index';

export const isValidStepValue = (step, value) => {
  if (step < 1) {
    const [, stepDec] = step.toString().split('.');
    // necessary to avoid float-rounding errors
    const adjustedStep = step * 10 ** stepDec.length;
    const adjustedValue = value * 10 ** stepDec.length;
    return adjustedValue % adjustedStep === 0;
  }
  return value % step === 0;
};

const CalloutCard = ({ status, headerText, body }) => {
  return (
    <div
      className={cn(
        styles['callout-card'],
        status === 'pass'
          ? styles['callout-card-pass']
          : styles['callout-card-fail']
      )}
    >
      <Text
        element={TextElement.P}
        kind={TextKind.TextXSSemibold}
        className={styles['callout-card-header']}
      >
        <PhosphorIcon
          iconName={status === 'pass' ? 'CheckCircle' : 'XCircle'}
          size={20}
        />
        {headerText}
      </Text>
      {body}
    </div>
  );
};

function Composable({
  condition,
  onChange,
  triggerValidation,
  onValidate,
  isReadOnly = false,
  setHasChanged,
}) {
  // type checking
  if (!Array.isArray(condition?.actionDetail?.composableData?.inputs)) {
    throw new Error(
      'Invalid condition object provided to Composable component'
    );
  }

  const [error, setError] = useState({});

  const initialValues = condition.actionDetail.composableData.inputs.reduce(
    (acc, input) => {
      acc[input.name] = input.currentValue;
      return acc;
    },
    {}
  );

  const [newValues, setNewValues] = useState(initialValues);

  const setFieldValue = (inputName, value) => {
    setError({});
    setHasChanged();

    const newInputs = {};
    condition.actionDetail.composableData.inputs.forEach((input) => {
      newInputs[input.name] =
        input.name === inputName ? Number(value) : newValues[input.name];
    });

    onChange(
      condition?.conditionId,
      AttestationActionType.Composable,
      {
        value: newInputs,
      },
      condition?.policyRequirementId
    );

    setNewValues((prevState) => {
      return {
        ...prevState,
        [inputName]: value ? Number(value) : value,
      };
    });
  };

  const validate = () => {
    condition.actionDetail.composableData.inputs.forEach((input) => {
      const newValueForInput = newValues[input.name];
      if (newValueForInput === undefined) {
        error[input.name] = `You must enter a value for ${input.label}`;
      }
      if (!isValidStepValue(input.step, newValueForInput)) {
        error[
          input.name
        ] = `You must enter a value that is a multiple of ${input.step}`;
      }
      if (
        newValueForInput > input.maximumValue ||
        newValueForInput < input.minimumValue
      ) {
        error[
          input.name
        ] = `You must enter a value between ${input.minimumValue} and ${input.maximumValue} for "${input.label}"`;
      }
    });
    setError(error);
    const isValid = isEmpty(error);
    return isValid;
  };

  useEffect(() => {
    if (triggerValidation) {
      const isValid = validate();
      onValidate(condition.conditionId, isValid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerValidation]);

  const getErrorMessage = (inputName) => {
    if (!isEmpty(error)) {
      return error[inputName];
    }
  };

  const screeningResultStatus =
    condition.actionDetail.composableData.outputs?.screeningResult ===
    VETTING_STATUS.PASS
      ? 'pass'
      : 'fail';

  return (
    <>
      <div className={styles['input-grid']}>
        {condition.actionDetail.composableData.inputs.map((input) => {
          return (
            <InputField
              key={input.name}
              currentValue={input.currentValue}
              onChange={setFieldValue}
              isReadOnly={isReadOnly}
              placeholder={input.currentValue}
              inputName={input.name}
              inputDescription={input.description}
              inputLabel={input.label}
              inputSuffix={input.suffix}
              errorMessage={getErrorMessage(input.name)}
              value={isReadOnly ? null : newValues[input.name]}
              step={input.step}
            />
          );
        })}
      </div>
      {condition.actionDetail.composableData.outputs && (
        <div className={styles['spacing-top-xl']}>
          <CalloutCard
            status={screeningResultStatus}
            headerText={
              screeningResultStatus === 'pass' ? 'Meets' : 'Does not meet'
            }
            body={
              <div className={styles['flex-column-gap-spacing-xl']}>
                <Text element={TextElement.P} kind={TextKind.TextXSMedium}>
                  Calculated result ={' '}
                  <Text element={TextElement.Span} kind={TextKind.TextXS}>
                    {condition.actionDetail.composableData.outputs.resultValue}
                  </Text>
                </Text>
                <Text element={TextElement.P} kind={TextKind.TextXS}>
                  {
                    condition.actionDetail.composableData.outputs
                      .composableMessage
                  }
                </Text>
              </div>
            }
          />
        </div>
      )}
    </>
  );
}

export default Composable;
