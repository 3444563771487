import styles from '../../retailerBrandRequirementGroupsDetail.module.scss';
import { Pill, PillColor } from 'design-system/components';
import { number, oneOf, string } from 'prop-types';

function ScreeningStatusItem({ name, count, color }) {
  return (
    <li className={styles['status-item']}>
      {name}
      <Pill color={color || PillColor.Default}>{count || 0}</Pill>
    </li>
  );
}

ScreeningStatusItem.propTypes = {
  name: string.isRequired,
  count: number.isRequired,
  color: oneOf(Object.values(PillColor)),
};

export default ScreeningStatusItem;
