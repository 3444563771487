export const KeyboardCode = {
  Space: 'Space',
  Enter: 'Enter',
  Escape: 'Escape',
  Tab: 'Tab',
  ArrowUp: 'ArrowUp',
  ArrowDown: 'ArrowDown',
  Backspace: 'Backspace',
};

export function handleKeyboardEvent(
  keyboardEvent: {
    code: unknown;
    preventDefault: () => void;
    stopPropagation: () => void;
  },
  codesToListenFor: { [s: string]: unknown } | ArrayLike<unknown>,
  methodToExecute: (arg0?: undefined) => any,
  methodArgument = undefined,
  preventDefault = true
) {
  if (Object.values(codesToListenFor).includes(keyboardEvent.code)) {
    if (preventDefault) {
      keyboardEvent.preventDefault();
    }
    keyboardEvent.stopPropagation();
    methodArgument ? methodToExecute(methodArgument) : methodToExecute();
  }
}
