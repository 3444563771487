import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './grid.module.scss';
import { GridColumns, GridBreakpoints } from './constants.js';

const Grid = ({ children, nestedCols = undefined, className = undefined }) => {
  const isNested = nestedCols !== undefined;
  const mobileXMargin = isNested ? 0 : '32px';
  const tabletXMargin = isNested ? 0 : '48px';
  const desktopXMargin = isNested ? 0 : '60px';
  const containerStyleVars = {
    '--mobile-cols': nestedCols?.mobile || GridColumns.Mobile,
    '--tablet-cols': nestedCols?.tablet || GridColumns.Tablet,
    '--desktop-cols': nestedCols?.desktop || GridColumns.Desktop,
    '--mobile-xmargin': mobileXMargin,
    '--tablet-xmargin': tabletXMargin,
    '--desktop-xmargin': desktopXMargin,
  };
  return (
    <div
      // @ts-ignore
      style={containerStyleVars}
      className={cn([styles['grid-container'], className])}
    >
      {children}
    </div>
  );
};

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  nestedColumns: PropTypes.shape({
    [GridBreakpoints.Mobile]: PropTypes.number,
    [GridBreakpoints.Tablet]: PropTypes.number,
    [GridBreakpoints.Desktop]: PropTypes.number,
  }),
  className: PropTypes.string,
};

export default Grid;
