import { useState } from 'react';
import propTypes from 'prop-types';
import { Text, TextElement, TextKind } from 'design-system/components';
import { KeyboardCode, handleKeyboardEvent } from 'design-system/utils';
import styles from './search-item.module.scss';

const SearchItem = ({
  value,
  hasCheckbox,
  onClick,
  displayValue,
  isActive,
}) => {
  const [checked, setChecked] = useState(isActive);

  const handleEvent = () => {
    setChecked(!checked);
    onClick(hasCheckbox ? !checked : null);
  };

  return (
    <li
      className={styles.root}
      onClick={handleEvent}
      onKeyDown={(event) => {
        handleKeyboardEvent(
          event,
          [KeyboardCode.Enter, KeyboardCode.Space],
          handleEvent
        );
      }}
      tabIndex={0}
      data-cy="search-results-item"
    >
      {hasCheckbox ? (
        <span className={styles.checkbox}>
          <input
            readOnly
            type="checkbox"
            id={value}
            name={value}
            value={value}
            checked={checked}
            tabIndex={-1}
          />
          <label htmlFor={value}>{displayValue || value}</label>
        </span>
      ) : (
        <Text kind={TextKind.TextSM} element={TextElement.P}>
          {displayValue || value}
        </Text>
      )}
    </li>
  );
};

SearchItem.propTypes = {
  value: propTypes.string.isRequired,
  displayValue: propTypes.string,
  hasCheckbox: propTypes.bool,
  onClick: propTypes.func,
  isActive: propTypes.bool,
};

export default SearchItem;
