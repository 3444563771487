import { oneOf } from 'prop-types';
import { ProgressSize, progressComponentBasePropTypes } from '../constants';
import { Text, TextElement, TextKind } from 'design-system/components';
import styles from '../progress.module.scss';

const RingProgress = ({
  percentage = 5,
  size = ProgressSize.Sm,
  label = 'Complete',
  color,
  loading = false,
}) => {
  const radius = size === ProgressSize.Sm ? 29 : 64;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset =
    circumference - (percentage / 100) * circumference || 0;

  return (
    <div className={styles['ring-container']}>
      <svg className={styles[`ring-root-${size}`]}>
        <circle className={styles['circle']} r={radius} cx="50%" cy="50%" />
        <circle
          className={loading ? styles.loading : styles.indicator}
          r={radius}
          cx="50%"
          cy="50%"
          style={{
            strokeDasharray: circumference,
            strokeDashoffset: strokeDashoffset,
            stroke: color,
          }}
        />
        <text x="50%" y="50%" dy={size === ProgressSize.Sm && '0.3rem'}>
          {loading ? '–' : percentage}%
        </text>
        {size === ProgressSize.Lg && (
          <text x="50%" y="50%" dy="1.6em" className={styles['label-text']}>
            {label}
          </text>
        )}
      </svg>
      {size === ProgressSize.Sm && (
        <Text element={TextElement.P} kind={TextKind.TextXSMedium}>
          {label}
        </Text>
      )}
    </div>
  );
};

RingProgress.propTypes = {
  ...progressComponentBasePropTypes,
  size: oneOf(Object.values(ProgressSize)),
};

export default RingProgress;
