import { useState } from 'react';
import { isEmpty } from 'lodash';

export const isValidStepValue = (step, value) => {
  if (step < 1) {
    const [, stepDec] = step.toString().split('.');
    // necessary to avoid float-rounding errors
    const adjustedStep = step * 10 ** stepDec.length;
    const adjustedValue = value * 10 ** stepDec.length;
    return adjustedValue % adjustedStep === 0;
  }
  return value % step === 0;
};

function useComposableConditionModalState() {
  const [show, setShow] = useState(false);
  const [condition, setCondition] = useState({});
  const [newValues, setNewValues] = useState({});
  const [error, setError] = useState({});

  function setFieldValue(inputName, value) {
    setError({});
    return setNewValues((prevState) => {
      return {
        ...prevState,
        [inputName]: value ? Number(value) : value,
      };
    });
  }

  function validate() {
    condition.inputs.forEach((input) => {
      const newValueForInput = newValues[input.name];
      if (newValueForInput === undefined) {
        error[input.name] = `You must enter a value for ${input.label}`;
      }
      if (!isValidStepValue(input.step, newValueForInput)) {
        error[
          input.name
        ] = `You must enter a value that is a multiple of ${input.step}`;
      }
      if (
        newValueForInput > input.maximumValue ||
        newValueForInput < input.minimumValue
      ) {
        error[
          input.name
        ] = `You must enter a value between ${input.minimumValue} and ${input.maximumValue} for "${input.label}"`;
      }
    });
    setError(error);
  }

  function closeModal() {
    setCondition({});
    setNewValues({});
    setError({});
    return setShow(false);
  }

  return {
    show,
    newValues,
    condition,
    error,
    hasError: !isEmpty(error),
    showModal: () => setShow(true),
    setFieldValue,
    closeModal,
    setCondition,
    setError,
    validate,
  };
}

export default useComposableConditionModalState;
