import { useState } from 'react';
import {
  LoadingOverlay,
  NoData,
  Pagination,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';
import { useSetUrlSearchParameters, useTableSort } from 'design-system/utils';
import SupplierListingsTable from './components/table';
import { useSupplierListingsContext } from './context';
import styles from './supplier-listings.module.scss';

export default function SupplierListingsView() {
  const { data, loading, error } = useSupplierListingsContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [tableState, tableStateDispatch] = useTableSort({
    initialSortByValue: 'name',
  });

  useSetUrlSearchParameters(tableState, currentPage, itemsPerPage);

  return loading ? (
    <LoadingOverlay show />
  ) : (
    <div className={`page-layout ${styles.container}`}>
      <div className={styles.header}>
        <Text
          kind={TextKind.DisplaySMBold}
          element={TextElement.H1}
          data-cy="supplier-listings-page-title"
        >
          Listings
        </Text>
      </div>
      {!data?.materials?.length ? (
        <NoData
          hasErrorOccurred={!!error}
          noContentMessage="There are currently no listings."
        />
      ) : (
        <>
          <SupplierListingsTable
            data={data.materials}
            state={tableState}
            onClick={(data) => tableStateDispatch(data)}
          />
          <Pagination
            onNextPage={() => setCurrentPage(currentPage + 1)}
            onPreviousPage={() => setCurrentPage(currentPage - 1)}
            onPageSelect={setCurrentPage}
            onChangePageSize={setItemsPerPage}
            currentPage={data.currentPage}
            totalItemCount={data.totalEntries}
            itemsPerPage={data.perPage}
          />
        </>
      )}
    </div>
  );
}
