import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './hamburger.module.scss';

const Hamburger = ({ onClick, isOpen = false, className }) => {
  return (
    <button
      className={cn([className, styles['hamburger-button']])}
      aria-label={isOpen ? 'Close menu' : 'Open menu'}
      aria-expanded={isOpen ? 'true' : 'false'}
      onClick={onClick}
    >
      <div className={cn([styles.line, isOpen ? styles.open : ''])}></div>
      <div className={cn([styles.line, isOpen ? styles.open : ''])}></div>
      <div className={cn([styles.line, isOpen ? styles.open : ''])}></div>
    </button>
  );
};

Hamburger.propTypes = {
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default Hamburger;
