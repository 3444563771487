import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  BrandViewHeader,
  ToastManager,
  ToastKind,
} from 'design-system/components';

import { useApp } from 'context/AppContext';
import { usePolicies } from './usePolicies';
import RequirementGroups from './RequirementGroups';
import { generateBrandPolicyExport } from '../BrandSubmissions/useBrandSubmissions';
import ReviewModal from '../../components/ReviewModal';

function Policies() {
  const { brand_id } = useParams();
  const { data, loading, error } = usePolicies(brand_id);
  const history = useHistory();
  const { user } = useApp();
  const [toastData, setToastData] = useState([]);
  const [submissionId, setSubmissionId] = useState();
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [reviewType, setReviewType] = useState();

  const consumerBrand = data?.consumerBrand;
  const policies = data?.policies;
  const breadcrumbItems = [
    {
      text: 'Brand Submissions',
      link: '/retailer/brands/submissions',
    },
    {
      text: consumerBrand?.name,
      link: history.location.pathname,
    },
  ];

  const handleReviewLink = (requirement_group_id) =>
    history.push(
      `/retailer/brands/submissions/${brand_id}/requirement_groups/${requirement_group_id}/retailer_requirements`
    );

  const userHasExportEnabled = user.hasFF('policy_export_button_visible');

  const brandInProgress =
    policies?.every(({ policySubmission }) => {
      return !policySubmission || policySubmission.id === null;
    }) ?? true;

  const canSeeExport = userHasExportEnabled && !brandInProgress;

  const retailerId = user?.organization?.in_orgable_id;
  const onExportPolicy = () => {
    const onSuccess = () => {
      // @ts-ignore
      setToastData((prevToastData) => [
        ...prevToastData,
        {
          kind: ToastKind.Success,
          title: 'Success',
          message:
            'Policies export started. You should receive an email shortly.',
          autoDismiss: true,
        },
      ]);
    };
    const onFailure = () => {
      // @ts-ignore
      setToastData((prevToastData) => [
        ...prevToastData,
        {
          kind: ToastKind.Error,
          title: 'Error',
          message: 'Policy export failed. Please try again or contact support.',
          autoDismiss: true,
        },
      ]);
    };
    generateBrandPolicyExport({
      onSuccess,
      onFailure,
      retailerId,
      consumerBrandId: brand_id,
    });
  };

  return (
    <div>
      <ReviewModal
        reviewType={reviewType}
        setToastData={setToastData}
        submissionId={submissionId}
        openReviewModal={openReviewModal}
        setOpenReviewModal={setOpenReviewModal}
      />
      <ToastManager data={toastData} />
      <BrandViewHeader
        title={consumerBrand?.name}
        subTitle=""
        breadcrumbsItems={breadcrumbItems}
        showFilterButton={false}
        showExportButton={canSeeExport}
        onExportClick={onExportPolicy}
      />
      <RequirementGroups
        loading={loading}
        policies={policies}
        error={error}
        handleReviewLink={handleReviewLink}
        handleReviewAction={(id) => {
          setSubmissionId(id);
          setOpenReviewModal(true);
        }}
        setReviewType={setReviewType}
      />
    </div>
  );
}

export default Policies;
