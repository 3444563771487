import {
  Text,
  TextKind,
  TextElement,
  Modal,
  WarningRings,
  Button,
  ModalType,
} from 'design-system/components';
import styles from './confirmation-dialogs.module.scss';
import { Color } from 'design-system/data';
import { useFDASubmissionConfirmationContext } from '../Context';

export const ConfirmationDialog = () => {
  const {
    submissionConfirmationDialogOpen,
    setSubmissionConfirmationDialogOpen,
    handleSubmit,
  } = useFDASubmissionConfirmationContext();

  return (
    <Modal
      show={submissionConfirmationDialogOpen}
      type={ModalType.Small}
      onClick={() => setSubmissionConfirmationDialogOpen(false)}
    >
      <WarningRings />
      <div className={styles['confirmation-message']}>
        <Text kind={TextKind.DisplayXSMedium} element={TextElement.H2}>
          Are you sure?
        </Text>
        <Text
          color={Color.Neutral600}
          kind={TextKind.TextSM}
          element={TextElement.P}
        >
          Once your invoice has been generated and paid, you will not be able to
          make changes to your submission and the selected SKUs will be
          submitted for listing with the FDA.
        </Text>
      </div>
      <div className={styles.actions}>
        <Button onClick={() => setSubmissionConfirmationDialogOpen(false)}>
          Cancel
        </Button>
        <Button onClick={() => handleSubmit()}>Confirm submission</Button>
      </div>
    </Modal>
  );
};
