import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

const logLevel =
  process.env.REACT_APP_DATADOG_ENVIRONMENT === 'production_aws'
    ? 'warn'
    : 'debug';

datadogLogs.init({
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  forwardConsoleLogs: [logLevel],
  forwardReports: 'all',
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,

  env: process.env.REACT_APP_DATADOG_ENVIRONMENT,
  service: 'webapp',
  version: process.env.REACT_APP_GITHUB_SHA,
});

datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackResources: true,
  trackLongTasks: true,
  trackUserInteractions: true,
  defaultPrivacyLevel: 'allow',
  allowedTracingUrls: [process.env.REACT_APP_DATADOG_ALLOWED_TRACING_ORIGIN],
  beforeSend: (event, context) => {
    // collect a RUM resource's response headers
    if (event.type === 'resource' && event.resource.type === 'fetch') {
      event.context.responseHeaders = Object.fromEntries(
        context?.response?.headers
      );
    }
    return true;
  },

  env: process.env.REACT_APP_DATADOG_ENVIRONMENT,
  service: 'webapp',
  version: process.env.REACT_APP_GITHUB_SHA,
});

datadogRum.startSessionReplayRecording();
