import SubmissionsListView from './List/view';
import FDASubmissionProvider from './List/Context';
import { Switch, Route } from 'react-router-dom';
import { PATHS } from 'constants/index';
import { FDASubmissionDetailProvider } from './Detail/Context';
import SubmissionDetail from './Detail';

const FDARegistrationSubmissions = () => {
  return (
    <FDASubmissionProvider>
      <Switch>
        <Route
          path={PATHS.fdaRegistrationSubmissions}
          component={SubmissionsListView}
          exact
        />
        <FDASubmissionDetailProvider>
          <Route
            path={PATHS.fdaRegistrationSubmissionDetail}
            component={SubmissionDetail}
            exact
          />
        </FDASubmissionDetailProvider>
      </Switch>
    </FDASubmissionProvider>
  );
};

export default FDARegistrationSubmissions;
