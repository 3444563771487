import styles from '../icon.module.scss';

function Info(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles[`root-${props.direction}`]}
    >
      <path
        d="M8 7.5V11.5"
        stroke={props.color.value}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <circle cx="8" cy="4" r="1" fill={props.color.value} />
      <rect
        x="0.5"
        y="0.5"
        width="15"
        height="15"
        rx="7.5"
        stroke={props.color.value}
      />
    </svg>
  );
}

export default Info;
