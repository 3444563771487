import { FDABusinessProvider } from './Context';
import BusinessView from './view';

const Products = () => {
  return (
    <FDABusinessProvider>
      <BusinessView />
    </FDABusinessProvider>
  );
};

export default Products;
