import {
  element,
  func,
  oneOfType,
  arrayOf,
  string,
  number,
  bool,
} from 'prop-types';
import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import styles from '../radio.module.scss';

function RadioGroup({
  onChange,
  children,
  defaultValue,
  name,
  value,
  errorMessage,
  hasError = false,
  disabled = false,
  required = false,
}) {
  return (
    <>
      <RadixRadioGroup.Root
        defaultValue={defaultValue}
        className={styles['radio-group']}
        value={value}
        onValueChange={onChange}
        name={name}
        disabled={disabled}
        required={required}
      >
        {children}
      </RadixRadioGroup.Root>
      {hasError && (
        <div className={styles['error-container']}>
          {errorMessage ||
            'An error has occurred, please check back with us later'}
        </div>
      )}
    </>
  );
}

RadioGroup.propTypes = {
  name: string.isRequired,
  onChange: func.isRequired,
  children: oneOfType([element, arrayOf(element)]).isRequired,
  defaultValue: oneOfType([string, number]),
  value: oneOfType([string, number]),
  disabled: bool,
  errorMessage: string,
  hasError: bool,
  required: bool,
};

export default RadioGroup;
