// @ts-nocheck
import { useMemo, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  BrandViewHeader,
  ButtonKind,
  DropdownRadioMenu,
  Modal,
  ModalType,
  NoContent,
  NoData,
  NoDataVariants,
  PaginationV2,
  PhosphorIcon,
  Tab,
  TabGroup,
  Table,
  TableBody,
  TableBodyCell,
  TableFoot,
  TableHead,
  TableHeadCell,
  TableRow,
  TabsAndTable,
  Text,
  TextElement,
  TextKind,
  PhosphorIconWeight,
  FilterDropdown,
} from 'design-system/components';
import { FormInput } from 'views/FDARegistration/shared/components/Form';
import { useStatefulQueryParam } from 'design-system/utils';
import {
  Color,
  UrlParam,
  ScreeningStatus,
  ScreeningStatusLabel,
} from 'design-system/data';

import { useRequirementActions } from 'views/Brands/shared/RequirementActionModalContents/context';
import RequirementActionModalContentsContainer from 'views/Brands/shared/RequirementActionModalContents';
import SlideoutFooter from 'views/Brands/shared/RequirementActionModalContents/SlideoutFooter';
import { useRetailerRequirements } from './useRetailerRequirements';
import styles from './retailer-requirements.module.scss';
import RequirementRow from './RequirementRow';
import { SORT_OPTIONS } from 'views/Brands/RetailerBrandPolicyRequirements/data/constants';

const FILTER_DROPDOWN_OPTIONS = [
  {
    value: ScreeningStatus.Pending,
    label: ScreeningStatusLabel[ScreeningStatus.Pending],
  },
  { value: ScreeningStatus.Pass, label: ScreeningStatusLabel.Meets },
  {
    value: ScreeningStatus.Fail,
    label: ScreeningStatusLabel[ScreeningStatus.Fail],
  },
  {
    value: ScreeningStatus.NeedsInformation,
    label: ScreeningStatusLabel[ScreeningStatus.NeedsAttention],
  },
  {
    value: ScreeningStatus.NotApplicable,
    label: ScreeningStatusLabel[ScreeningStatus.NotApplicable],
  },
];

function RetailerRequirementsView() {
  const { brand_id, requirement_group_id } = useParams();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [activeTabCategoryId, setActiveTabCategoryId] = useState();
  const [statusFilterValues, setStatusFilterValues] = useState([]);

  const { data, loading, error } = useRetailerRequirements(
    requirement_group_id,
    activeTabCategoryId,
    brand_id,
    statusFilterValues
  );

  const {
    setToastOpen,
    conditions,
    conditionsLoading,
    conditionsError,
    handleChange,
    triggerValidation,
    handleConditionValidation,
    modalOpen,
    setModalOpen,
    setSelectedRequirement,
    submitRetailerResponse,
    canAttest,
  } = useRequirementActions();
  const searchParams = new URLSearchParams();
  const [activeSort, setActiveSort] = useStatefulQueryParam(
    UrlParam.Direction,
    searchParams.get(UrlParam.Direction) || ''
  );
  const [search, setSearch] = useStatefulQueryParam(
    UrlParam.Search,
    searchParams.get(UrlParam.Search) || ''
  );

  const [currentPage, setCurrentPage] = useStatefulQueryParam(UrlParam.Page, 1);

  const tableHeaderData = [
    {
      label: 'Requirement',
      value: 'requirement_name',
      hasSort: true,
    },
    {
      label: 'Subcategory',
      value: 'subcategory',
      hasSort: true,
    },
    {
      label: 'Status',
      value: 'status',
    },
  ];

  const breadcrumbItems = [
    {
      text: 'Brand Submissions',
      link: '/retailer/brands/submissions',
    },
    {
      text: 'Brand',
      link: `/retailer/brands/submissions/${brand_id}/requirement_groups`,
    },
    {
      text: data?.name || 'Requirement Group',
      link: `/retailer/brands/submissions/${brand_id}/requirement_groups/${requirement_group_id}/retailer_requirements`,
    },
  ];

  const tabData = useMemo(() => {
    const totalCount = data?.policyRequirementCounts?.reduce(
      (acc, count) => acc + count.count,
      0
    );
    const totalTab = {
      id: 0,
      label: 'All',
      value: 0,
      quantity: totalCount,
    };
    const individualCounts = data?.policyRequirementCounts?.map((count, i) => ({
      id: count.id,
      label: count.name,
      value: i + 1,
      quantity: count.count,
    }));
    return [totalTab, ...(individualCounts?.length ? individualCounts : [])];
  }, [data?.policyRequirementCounts]);

  useEffect(() => {
    // circumvent circular dependency with endpoint, where the requirement id that's used to filter the request is only present from the result of the request.
    if (tabData.length > 1 && activeTabIndex !== 0) {
      setActiveTabCategoryId(tabData[activeTabIndex]?.id);
    }
    if (activeTabIndex === 0) {
      setActiveTabCategoryId(undefined);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabIndex]);

  const currentTabData = useMemo(
    () => data?.requirements,
    [data?.requirements]
  );

  const hasData = currentTabData?.length > 0 && !loading;

  return (
    <div>
      <Modal
        show={modalOpen}
        title="Attest to conditions"
        type={ModalType.SlideOut}
        onClick={() => {
          setModalOpen(false);
          setToastOpen(false);
        }}
        cancelCtaLabel="Cancel"
        cancelCtaKind={ButtonKind.Tertiary}
        dataCyRoot="retailer-attestation-modal"
        showActionButtons
        footerSlot={<SlideoutFooter hideNav />}
      >
        <RequirementActionModalContentsContainer
          conditions={conditions || []}
          loading={conditionsLoading}
          error={conditionsError}
          onChange={handleChange}
          triggerValidation={triggerValidation}
          handleConditionValidation={handleConditionValidation}
          submitRetailerResponse={submitRetailerResponse}
          isRetailer
          canAttest={canAttest}
        />
      </Modal>
      <BrandViewHeader
        title={data?.name}
        subTitle=""
        breadcrumbsItems={breadcrumbItems}
        showExportButton={false}
        showFilterButton={false}
        onFilterClick={() => {}}
        onExportClick={() => {}}
        editableTitle={false}
        onTitleChange={() => {}}
        bannerColor=""
        bannerText=""
      />
      <div className={styles['options-row']}>
        <FormInput
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search"
          icon={
            <PhosphorIcon
              iconName="MagnifyingGlass"
              size="1.5rem"
              weight={PhosphorIconWeight.Light}
              color={Color.Neutral600.value}
            />
          }
        />
        <div className={styles['filters-wrapper']}>
          <div>
            <FilterDropdown
              accordionOptions={[
                {
                  title: 'Status',
                  options: FILTER_DROPDOWN_OPTIONS,
                },
              ]}
              selectedValues={statusFilterValues}
              setSelectedValues={setStatusFilterValues}
            />
          </div>
          <DropdownRadioMenu
            fromLeft={true}
            options={SORT_OPTIONS}
            reverse
            activeValue={activeSort}
            setActiveValue={setActiveSort}
            triggerElement={
              <div className={styles['trigger-container']}>
                <PhosphorIcon iconName="ArrowsDownUp" size={20} />
                <Text
                  kind={TextKind.TextSMSemibold}
                  element={TextElement.P}
                  color={Color.Neutral600}
                  className={styles.nowrap}
                >
                  {activeSort
                    ? `Sort by: ${SORT_OPTIONS[activeSort]}`
                    : 'Sort by'}
                </Text>
              </div>
            }
          />
        </div>
      </div>
      <TabsAndTable
        tabs={
          <TabGroup
            activeTabIndex={activeTabIndex}
            ariaLabelledBy="tabs-and-filter-header-subtitle"
          >
            {tabData?.map((tab, index) => (
              <Tab
                key={index}
                chipValue={tab.quantity || 0}
                isActive={activeTabIndex === tab.value}
                onClick={() => {
                  setActiveTabIndex(tab.value);
                }}
              >
                {tab.label}
              </Tab>
            ))}
          </TabGroup>
        }
        table={
          <>
            {/* LOADING STATE */}
            {loading && !error && <NoData isLoading />}
            {/* ERROR STATE */}
            {error && !loading && (
              <NoData
                hasErrorOccurred={!!error}
                noContentMessage="Something went wrong. Please try again or contact support."
              />
            )}
            {/* NO DATA STATE */}
            {!hasData && !loading && (
              <NoContent variant={NoDataVariants.Default} />
            )}
            {/* HYDRATED TABLE */}
            {hasData && (
              <div className={styles.table}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {tableHeaderData?.map((data) => {
                        return (
                          <TableHeadCell key={data.value}>
                            {data.label}
                          </TableHeadCell>
                        );
                      })}
                      <TableHeadCell>
                        <span className="sr-only">Action</span>
                      </TableHeadCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentTabData?.map((requirement, i) => (
                      <RequirementRow
                        key={i}
                        requirement={requirement}
                        onClick={() => {
                          setSelectedRequirement(requirement);
                          setModalOpen(true);
                        }}
                        url={`/retailer/brands/submissions/${brand_id}/requirement_groups/${requirement_group_id}/retailer_requirements/${requirement.id}/policy_requirements`}
                      />
                    ))}
                  </TableBody>
                  <TableFoot bgWhite>
                    <TableRow>
                      <TableBodyCell colSpan={4}>
                        <PaginationV2
                          onNextPage={() => setCurrentPage(currentPage + 1)}
                          onPreviousPage={() => setCurrentPage(currentPage - 1)}
                          onPageSelect={(page) => setCurrentPage(page)}
                          totalItemCount={data?.count}
                          currentPage={currentPage}
                          itemsPerPage={data?.items}
                        />
                      </TableBodyCell>
                    </TableRow>
                  </TableFoot>
                </Table>
              </div>
            )}
          </>
        }
      />
    </div>
  );
}

export default RetailerRequirementsView;
