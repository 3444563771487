import { Text, TextElement, TextKind } from 'design-system/components';
import { progressComponentBasePropTypes } from '../constants';
import styles from '../progress.module.scss';

const BarProgress = ({ percentage = 5, label, color, loading = false }) => {
  return (
    <div className={styles['bar-container']}>
      <div className={styles['bar-root']}>
        <span
          className={styles['bar-rail']}
          style={{
            width: `${loading ? 0 : percentage}%`,
            background: color,
          }}
        />
      </div>
      <div className={styles['label']}>
        <Text element={TextElement.P} kind={TextKind.TextSMMedium}>
          {label}
        </Text>
        <Text element={TextElement.Span} kind={TextKind.TextSMMedium}>
          {loading ? '- ' : percentage}%
        </Text>
      </div>
    </div>
  );
};

BarProgress.propTypes = progressComponentBasePropTypes;

export default BarProgress;
