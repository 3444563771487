import { Pill, PillColor } from 'design-system/components';
import { ScreeningStatus } from 'design-system/data';
import styles from './screening-status-pill.module.scss';

export type ScreeningStatusTypes =
  (typeof ScreeningStatus)[keyof typeof ScreeningStatus];

interface RequirementStatusPillProps {
  status: ScreeningStatusTypes;
  withDot?: boolean;
}

export const ScreeningStatusMappings = {
  [ScreeningStatus.Pass]: { color: PillColor.Success, label: 'Meets' },
  [ScreeningStatus.Fail]: {
    color: PillColor.ErrorColor,
    label: 'Does not meet',
  },
  [ScreeningStatus.Pending]: { color: PillColor.Blue, label: 'Pending' },
  [ScreeningStatus.NeedsInformation]: {
    color: PillColor.Warning,
    label: 'Needs attention',
  },
  [ScreeningStatus.NeedsAttention]: {
    color: PillColor.Warning,
    label: 'Needs attention',
  },
  [ScreeningStatus.NotStarted]: {
    color: PillColor.Warning,
    label: 'Not started',
  },
  [ScreeningStatus.NotApplicable]: {
    color: PillColor.Default,
    label: 'Not applicable',
  },
  [ScreeningStatus.Unresolved]: {
    color: PillColor.Default,
    label: 'Unresolved',
  },
  [ScreeningStatus.Updating]: { color: PillColor.Blue, label: 'Updating' },
  [ScreeningStatus.Rejected]: {
    color: PillColor.ErrorColor,
    label: 'Rejected',
  },
  [ScreeningStatus.Approved]: { color: PillColor.Success, label: 'Approved' },
  [ScreeningStatus.Excluded]: { color: PillColor.Default, label: 'Excluded' },
};

export default function RequirementStatusPill({
  status,
  withDot,
}: RequirementStatusPillProps) {
  const pillStatus = ScreeningStatusMappings[status];
  if (!pillStatus) {
    return <span />;
  }
  return (
    <Pill color={pillStatus.color}>
      {withDot && <span className={styles.dot} />}
      {pillStatus.label}
    </Pill>
  );
}
