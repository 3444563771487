import { useMemo } from 'react';
import { useApp } from 'context/AppContext';
import { UserFlow } from 'classes/UserFlow';

export default function useUserFlow() {
  const { user } = useApp();

  const flow = useMemo(() => new UserFlow(user), [user]);

  return flow;
}
