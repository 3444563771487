import * as RadioGroup from '@radix-ui/react-radio-group';
import { bool, number, oneOfType, string } from 'prop-types';
import styles from './radio.module.scss';

function Radio({ value, label, disabled = false, reverse = false, ...props }) {
  return (
    <RadioGroup.Item
      value={value}
      className={styles['radio-item']}
      disabled={disabled}
      style={props.style}
    >
      {reverse ? (
        <>
          <label className={styles.pointer}>{label}</label>
          <RadioGroup.Indicator
            className={styles['radio-indicator']}
            forceMount
          />
        </>
      ) : (
        <>
          <RadioGroup.Indicator
            className={styles['radio-indicator']}
            forceMount
          />
          <label>{label}</label>
        </>
      )}
    </RadioGroup.Item>
  );
}

Radio.propTypes = {
  value: oneOfType([string, number]),
  label: string,
  disabled: bool,
  reverse: bool,
};

export default Radio;
