export class UserFlow {
  constructor(user) {
    this.user = user;
  }

  get special_case_credo() {
    return this.user.organization.credo_sponsored_company;
  }

  get legacy_sephora_flow() {
    return this.user.organization.sephora_sponsored_company;
  }
}
