export default function ExclamationMark({ size, color }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.79989 9.25166L9.16685 3.74723C9.21185 3.07225 8.67648 2.5 8 2.5C7.32352 2.5 6.78815 3.07225 6.83315 3.74723L7.20011 9.25166C7.22818 9.67277 7.57795 10 8 10C8.42205 10 8.77182 9.67277 8.79989 9.25166Z"
        fill={color.value}
      />
      <circle cx="8" cy="12.5" r="1" fill={color.value} />
    </svg>
  );
}
