function Grid(props) {
  const rectSize = Math.floor(props.size / 5);
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.5"
        y="1.5"
        width={rectSize}
        height={rectSize}
        fill={props.color.value}
      />
      <rect
        x="1.5"
        y="6.5"
        width={rectSize}
        height={rectSize}
        fill={props.color.value}
      />
      <rect
        x="1.5"
        y="11.5"
        width={rectSize}
        height={rectSize}
        fill={props.color.value}
      />
      <rect
        x="6.5"
        y="1.5"
        width={rectSize}
        height={rectSize}
        fill={props.color.value}
      />
      <rect
        x="6.5"
        y="6.5"
        width={rectSize}
        height={rectSize}
        fill={props.color.value}
      />
      <rect
        x="6.5"
        y="11.5"
        width={rectSize}
        height={rectSize}
        fill={props.color.value}
      />
      <rect
        x="11.5"
        y="1.5"
        width={rectSize}
        height={rectSize}
        fill={props.color.value}
      />
      <rect
        x="11.5"
        y="6.5"
        width={rectSize}
        height={rectSize}
        fill={props.color.value}
      />
      <rect
        x="11.5"
        y="11.5"
        width={rectSize}
        height={rectSize}
        fill={props.color.value}
      />
    </svg>
  );
}

export default Grid;
