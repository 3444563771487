import { bool, func, string } from 'prop-types';
import {
  Modal,
  Text,
  TextElement,
  TextKind,
  Icon,
  IconName,
} from 'design-system/components';
import styles from './retailer-notes-modal.module.scss';

function RetailerNotesModal({
  show,
  title,
  handleCloseModal,
  documentName,
  retailerNotes,
}) {
  return (
    <Modal show={show} onClick={handleCloseModal} title={title}>
      <Text element={TextElement.P} kind={TextKind.TextSMBold}>
        Document
      </Text>
      <div className={styles['document-section']}>
        <Icon name={IconName.Document} size={16} />
        <Text element={TextElement.P} kind={TextKind.TextMDBold}>
          {documentName}
        </Text>
      </div>
      <Text element={TextElement.P} kind={TextKind.TextSMBold}>
        Notes
      </Text>
      <div className={styles['notes-container']}>
        <Text element={TextElement.P} kind={TextKind.TextMD}>
          {retailerNotes || 'There are no retailer notes at this time'}
        </Text>
      </div>
    </Modal>
  );
}

RetailerNotesModal.propTypes = {
  documentName: string.isRequired,
  show: bool.isRequired,
  handleCloseModal: func.isRequired,
  retailerNotes: string.isRequired,
  title: string.isRequired,
};

export default RetailerNotesModal;
