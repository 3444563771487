import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import {
  ButtonKind,
  Modal,
  ModalType,
  PhosphorIcon,
  Text,
  TextKind,
  TextElement,
  ToastKind,
  ToastManager,
} from 'design-system/components';
import { Color } from 'design-system/data';

import { PATHS } from 'constants/index';
import fetcher from 'services/api/fetcher';
import styles from '../retailerBrandDashboard.module.scss';
import SubmissionCard from './SubmissionCard';

const SubmissionNextSteps = ({
  policy,
  retailerName = 'the retailer',
  retailerId,
  loading,
  canSubmit,
  noSkuData,
}) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [toastData, setToastData] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const {
    name,
    percentComplete,
    submissionStatus,
    id,
    submissionFlow,
    someSkusQualify,
    policySubmission,
  } = policy;

  const handleSubmit = async () => {
    const url = `/api/v4/retailers/${retailerId}/policies/${id}/policy_submissions`;
    try {
      await fetcher(url, {
        method: 'post',
      });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    } finally {
      // @ts-ignore
      setToastData((prevToastData) => [
        ...prevToastData,
        {
          kind: ToastKind.Success,
          title: 'Success',
          message: `Your attestation responses have been successfully submitted to ${retailerName}! Refresh your page to see the updated submission status.`,
          autoDismiss: false,
        },
      ]);
      setModalOpen(false);
    }
  };

  const handleDeleteSubmission = async () => {
    const url = `/api/v4/policy_submissions/${policySubmission?.id}`;
    try {
      await fetcher(url, {
        method: 'delete',
        enabled: !!policySubmission?.id,
      });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    } finally {
      history.push(PATHS.retailerBrandRequirementGroups);
      // @ts-ignore
      setToastData((prevToastData) => [
        ...prevToastData,
        {
          kind: ToastKind.Success,
          title: 'Success',
          message: `Your policy submission has been unlocked. You can now continue to make attestations.`,
          autoDismiss: false,
        },
      ]);
    }
  };

  return (
    <>
      <Modal
        show={modalOpen}
        title=""
        type={ModalType.Small}
        onClick={() => setModalOpen(false)}
        showActionButtons
        cancelCtaLabel="Make changes"
        cancelCtaKind={ButtonKind.Tertiary}
        onCancel={() => setModalOpen(false)}
        doneCtaLabel="Submit"
        doneCtaKind={ButtonKind.Primary}
        dataCyRoot="attestation-modal"
        onDone={handleSubmit}
      >
        <div className={styles['ring-container']}>
          <div className={styles['ring-one']}>
            <div className={styles['warning-icon-container']}>
              <PhosphorIcon
                iconName="Warning"
                color={Color.Orange600.value}
                size={24}
              />
              <span className={styles['ring-two']} />
              <span className={styles['ring-three']} />
              <span className={styles['ring-four']} />
            </div>
          </div>
        </div>
        <div className={styles['modal-content']}>
          <Text kind={TextKind.TextMDSemibold} element={TextElement.H2}>
            Are you sure?
          </Text>
          <Text kind={TextKind.TextSM} element={TextElement.P}>
            Once you submit your attestations to {retailerName} you will not be
            able to edit your responses. After {retailerName} has reviewed your
            responses you will be alerted of your status within the {name}{' '}
            Program on your Novi dashboard.
          </Text>
        </div>
      </Modal>
      <SubmissionCard
        retailerName={retailerName}
        policyName={name}
        policyId={id}
        submissionFlow={submissionFlow}
        percentComplete={percentComplete}
        submissionStatus={submissionStatus}
        loading={loading}
        canSubmit={canSubmit}
        setModalOpen={setModalOpen}
        someSkusQualify={someSkusQualify}
        noSkuData={noSkuData}
        handleDeleteSubmission={handleDeleteSubmission}
      />
      <ToastManager data={toastData} />
    </>
  );
};

export default SubmissionNextSteps;
