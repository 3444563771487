import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import {
  CloudinaryImg,
  PhosphorIcon,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';
import { PolicyRequirementType } from 'design-system/data';

import SlideoutModal from 'views/Brands/RetailerBrandPolicyRequirements/components/SlideoutModal';
import Disclaimer from 'views/Brands/shared/Disclaimer';
import { useRequirementActions } from 'views/Brands/shared/RequirementActionModalContents/context';
import { tabStatuses, retailerTabStatuses } from './data/constants';
import Header from './components/Header';
import TabsAndTable from './components/TabsAndTable';
import BrandRequirementNote from './components/BrandRequirementNote';
import useSKURequirements from './hooks/useSKURequirements';
import styles from './sku-requirements.module.scss';
import useApi from 'services/api/useApi';

interface SKURequirementsPageViewProps {
  brandId: number;
  retailerId: number;
  retailerBrand?: any;
  isRetailer?: boolean;
  breadcrumbBase: { text: string; link: string }[];
}

const SKURequirementsPageView = ({
  brandId,
  retailerId,
  retailerBrand,
  isRetailer,
  breadcrumbBase,
}: SKURequirementsPageViewProps) => {
  const { policy_id, product_id, brand_id } = useParams();
  const [currentRow, setCurrentRow] = useState(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const currentTabStatuses = isRetailer ? retailerTabStatuses : tabStatuses;
  const response = useSKURequirements(
    retailerId,
    brandId,
    policy_id,
    product_id,
    currentTabStatuses[activeTabIndex]
  );

  const policyRes: any = useApi(
    `/api/v4/retailers/${retailerId}/consumer_brands/${brand_id}/policies`,
    {
      enabled: Boolean(isRetailer && retailerId && brand_id),
    }
  );

  const currentPolicy = useMemo(() => {
    const policies = policyRes?.data?.policies;
    return policies?.find(
      (policy: any) => parseInt(policy.id) === parseInt(policy_id)
    );
  }, [policyRes?.data?.policies, policy_id]);

  const [previousActive, setPreviousActive] = useState(false);
  const [nextActive, setNextActive] = useState(false);

  const {
    setModalOpen,
    modalOpen,
    setSelectedRequirement,
    setCurrentProductId,
    selectedRequirement,
  } = useRequirementActions();

  useEffect(() => {
    // selectedRequirement is un-nullable, so setting current product id to null on mount instead to prevent actions api from being called.
    // prioritizing keeping context logic more stable.
    (setCurrentProductId as Dispatch<SetStateAction<any>>)(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentFilteredData = response?.data?.requirements;
  const modalNavNextLogic = (i: number): void => {
    setNextActive(i < currentFilteredData?.length - 1);
  };

  const modalNavPrevLogic = (i: number): void => {
    setPreviousActive(i > 0);
  };

  const caption = () => {
    return isRetailer ? (
      <div className={styles.caption}>
        {currentPolicy?.image_path ? (
          <>
            <CloudinaryImg
              fallbackElement={
                <PhosphorIcon size={40} iconName="ShieldCheck" />
              }
              src={currentPolicy.image_path}
            />{' '}
          </>
        ) : null}
        <Text kind={TextKind.DisplayXSMedium} element={TextElement.Span}>
          {response?.data?.retailerPolicyName
            ? response?.data?.retailerPolicyName
            : 'Policy'}{' '}
          Requirements
        </Text>
      </div>
    ) : null;
  };

  const breadcrumbs = [
    ...breadcrumbBase,
    {
      text: response?.data?.consumerProductName || 'SKU',
      link: '',
    },
  ];

  return (
    <main>
      <SlideoutModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        currentRow={currentRow}
        setCurrentRow={setCurrentRow}
        currentFilteredData={currentFilteredData}
        modalNavPrevLogic={modalNavPrevLogic}
        modalNavNextLogic={modalNavNextLogic}
        previousActive={previousActive}
        nextActive={nextActive}
        totalItemCount={currentFilteredData?.length}
        expectedRequirementType={undefined}
        hideSubmit={
          (selectedRequirement as any)?.requirementType ===
            PolicyRequirementType.Brand || isRetailer
        }
        hideSubmitNote={!isRetailer && <BrandRequirementNote />}
        isRetailer={isRetailer}
      />
      <Header
        policyName={response?.data?.retailerPolicyName}
        skuName={response?.data?.consumerProductName}
        status={response?.data?.screeningStatus}
        pillars={response?.data?.pillars}
        caption={caption}
        breadcrumbItems={breadcrumbs}
      />
      <TabsAndTable
        response={response}
        setSelectedRequirement={setSelectedRequirement}
        setModalOpen={setModalOpen}
        setCurrentRow={setCurrentRow}
        setCurrentProductId={setCurrentProductId}
        modalNavPrevLogic={modalNavPrevLogic}
        modalNavNextLogic={modalNavNextLogic}
        currentRow={currentRow}
        modalOpen={modalOpen}
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
        isRetailer={isRetailer}
      />
      {retailerBrand?.retailer?.disclaimer && (
        <Disclaimer text={retailerBrand?.retailer?.disclaimer || ''} />
      )}
    </main>
  );
};

export default SKURequirementsPageView;
