function Search({ size, color }) {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11" cy="11" r="7" stroke={color.value} strokeWidth="2" />
      <path d="M16 16L20 20" stroke={color.value} strokeWidth="2" />
    </svg>
  );
}

export default Search;
