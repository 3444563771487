import { createContext, useContext, useMemo } from 'react';
import { useApp } from 'context/AppContext';
import { useParams } from 'react-router-dom';
import { convertSnakeToCamelCase } from 'design-system/utils/case';

const FDASubmissionDetailContext = createContext(null);

export const useFDASubmissionDetailContext = () => {
  const ctx = useContext(FDASubmissionDetailContext);
  if (!ctx) {
    throw new Error('Must be used within an FDASubmissionDetailContext');
  }
  return ctx;
};

export const FDASubmissionDetailProvider = ({ children }) => {
  const { useApi } = useApp();
  const { submissionId } = useParams();

  const submission = useApi(
    `api/v4/certification_cosmetic_product_listings/${submissionId}`,
    { enabled: Boolean(submissionId) }
  );

  const submissionData = useMemo(() => {
    if (!submission.data) return {};

    const convertedData = convertSnakeToCamelCase(submission.data);
    const normalizedData = {
      ...convertedData,
      phoneNumber: `(${
        convertedData.contactPhoneAreaCode
      }) ${convertedData.contactPhoneExchange.slice(
        0,
        3
      )}-${convertedData.contactPhoneExchange.slice(3, 7)}`,
      responsiblePersonPhoneNumber: `(${
        convertedData.responsiblePersonPhoneAreaCode
      }) ${convertedData.responsiblePersonPhoneExchange.slice(
        0,
        3
      )}-${convertedData.responsiblePersonPhoneExchange.slice(3, 7)}`,
    };
    return normalizedData;
  }, [submission.data]);

  const context = useMemo(() => {
    return {
      submissionData,
    };
  }, [submissionData]);

  return (
    <FDASubmissionDetailContext.Provider value={context}>
      {children}
    </FDASubmissionDetailContext.Provider>
  );
};
