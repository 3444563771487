import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import {
  Breadcrumbs,
  Button,
  ButtonKind,
  Link,
  LinkKind,
  LinkVariant,
} from 'design-system/components';
import PageHeader from '../../shared/components/PageHeader';
import SkuDetailsForm from '../components/SkuDetailsForm';
import styles from './view.module.scss';
import { useFDAProductContext } from './Context';

const ActionsRow = () => {
  const history = useHistory();
  const { handleSave, directToProducts } = useFDAProductContext();

  const onSave = async () => {
    const saveResponseSuccessful = await handleSave();
    if (saveResponseSuccessful) {
      directToProducts();
    }
  };

  return (
    <div className={styles.actions}>
      <Button onClick={() => history.goBack()} kind={ButtonKind.Tertiary}>
        Cancel
      </Button>
      <Button onClick={onSave} kind={ButtonKind.Primary}>
        Save
      </Button>
    </div>
  );
};

function FDARegistrationProductsDetail() {
  const { product_id } = useParams();
  const { formState } = useFDAProductContext();

  const baseLink = `/fda-registration/product-registration`;
  const formBreadcrumbsItems = [
    {
      text: 'SKUs',
      link: baseLink,
    },
    {
      text: 'Edit SKU details',
      link: `${baseLink}/${product_id}`,
    },
  ];
  const detailBreadcrumbItems = [
    {
      text: 'Submissions',
      link: '/fda-registration/submissions',
    },
    {
      text: 'SKU Details',
      link: `${baseLink}/${product_id}`,
    },
  ];

  return (
    <main>
      {formState.status === 'submitted' ? (
        <>
          <PageHeader
            title="SKU Details"
            breadcrumbs={<Breadcrumbs items={detailBreadcrumbItems} />}
          />
          <SkuDetailsForm disabled={true} />
        </>
      ) : (
        <>
          <PageHeader
            title={
              formState.status === 'submitted'
                ? 'SKU Details'
                : 'Edit SKU details'
            }
            subtitle={
              formState.status === 'submitted' ? null : (
                <>
                  Update your SKU details that are required for FDA Cosmetic
                  Product Listings. Products that qualify as a drug (ex:
                  foundation with SPF, antidandruff shampoos, and acne creams),
                  are not required to be registered as cosmetics.{' '}
                  <Link
                    kind={LinkKind.External}
                    variant={LinkVariant.Default}
                    href={
                      'https://noviconnect.notion.site/Products-considered-drugs-by-the-FDA-f7e7a40eed4a40d6bdc286011b574e34'
                    }
                  >
                    Learn more
                  </Link>{' '}
                  <br /> <br />
                </>
              )
            }
            breadcrumbs={<Breadcrumbs items={formBreadcrumbsItems} />}
            actions={formState.status !== 'submitted' ? <ActionsRow /> : null}
          />
          <SkuDetailsForm />
          <div className={styles['actions-bottom-row']}>
            <ActionsRow />
          </div>
        </>
      )}
    </main>
  );
}

export default FDARegistrationProductsDetail;
