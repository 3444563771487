import { SortDirection } from 'design-system/components';
import { useState } from 'react';
import { useStatefulQueryParam } from '../hooks';
import { UrlParam } from 'design-system/data';

export const TableReducerAction = {
  LabelClick: 'label-click',
};

export function tableReducer(state, action) {
  const sortAscByDefault = action.sortDescFirst ? false : true;
  switch (action.type) {
    case TableReducerAction.LabelClick:
      return {
        ...state,
        sortBy: action.sortBy,
        sortAsc:
          action.sortBy === state.sortBy ? !state.sortAsc : sortAscByDefault,
      };
    default:
      return state;
  }
}

export function useTableSort({ initialSortByValue, initialSortAsc = true }) {
  const [state, setState] = useState({
    sortBy: initialSortByValue ? initialSortByValue : null,
    sortAsc: initialSortAsc,
  });

  function dispatch(action) {
    const nextState = tableReducer(state, action);
    setState(nextState);
  }

  return [state, dispatch];
}

export function usePaginatedTableSort({
  initialSortByValue,
  initialSortAsc = true,
}) {
  const getDirection = (sortAsc) => {
    return sortAsc ? SortDirection.Ascending : SortDirection.Descending;
  };

  const [sort, setSort] = useStatefulQueryParam(
    UrlParam.Sort,
    initialSortByValue
  );
  const [direction, setDirection] = useStatefulQueryParam(
    UrlParam.Direction,
    getDirection(initialSortAsc)
  );

  const tableState = {
    sortBy: sort,
    sortAsc: direction === SortDirection.Ascending,
  };

  const updateState = (direction, sort) => {
    setSort(sort);
    setDirection(direction);
  };

  function dispatch(action) {
    const nextState = tableReducer(tableState, action);
    updateState(getDirection(nextState.sortAsc), nextState.sortBy);
  }

  return [tableState, dispatch];
}

export function sortDirection(tableState, label, sortDescFirst = false) {
  const labelCheck = !label || label === tableState.sortBy;
  if (sortDescFirst) {
    return labelCheck && tableState.sortAsc
      ? SortDirection.Ascending
      : SortDirection.Descending;
  }
  return labelCheck && !tableState.sortAsc
    ? SortDirection.Descending
    : SortDirection.Ascending;
}
