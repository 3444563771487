import propTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TableRow,
} from 'design-system/components';
import { PolicyRequirementAction } from './constants';
import { ScreeningStatus } from 'design-system/data';
import PolicyRequirementsTableRow from './policy-requirements-table-row';
import styles from './policy-requirements-table.module.scss';

const PolicyRequirementsTable = ({ data, headerTitle }) => {
  return (
    <div className={styles.table}>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>{headerTitle || 'Requirement'}</TableHeadCell>
            <TableHeadCell>Status</TableHeadCell>
            <TableHeadCell>
              <span className="sr-only">Action</span>
            </TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <PolicyRequirementsTableRow
              key={`${item.id}-${index}`}
              data={item}
              onClick={item.methodToExecute}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

PolicyRequirementsTable.propTypes = {
  data: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.number,
      name: propTypes.string,
      imgSrc: propTypes.string,
      status: propTypes.oneOf(Object.values(ScreeningStatus)),
      action: propTypes.oneOf(Object.values(PolicyRequirementAction)),
      methodToExecute: propTypes.func,
    })
  ).isRequired,
  headerTitle: propTypes.string,
};

export default PolicyRequirementsTable;
