import PropTypes from 'prop-types';

function Upload(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1043_59321)">
        <path
          d="M17.5 10.75V9.5C17.5 8.67157 16.8284 8 16 8H11.6731C11.2666 8 10.901 7.75248 10.75 7.375V7.375C10.599 6.99752 10.2334 6.75 9.82685 6.75H8C7.17157 6.75 6.5 7.42157 6.5 8.25V13.75C6.5 14.5784 7.17157 15.25 8 15.25H13.5"
          stroke={props.color.value}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.5 11.75L14 13.25M15.5 11.75L17 13.25M15.5 11.75V16.75"
          stroke={props.color.value}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

Upload.propTypes = {
  color: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  size: PropTypes.number,
};

export default Upload;
