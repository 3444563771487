import styled from 'styled-components';
import { compose, color, space, typography, layout } from 'styled-system';

export default function Text(props) {
  const { children, ...rest } = props;
  return <El {...rest}>{children}</El>;
}

const El = styled.span`
  font-family: inherit;
  ${compose(typography, color, space, layout)}
`;
