// * ------------------------------------------------------------------------------------------ * //
// * If any of these change, please update the values in `design-system/styles/_variables.scss` * //
// * ------------------------------------------------------------------------------------------ * //

export const Color = {
  // Mono
  Black: { name: 'black', value: '#000' },
  White: { name: 'white', value: '#fff' },
  // Neutral
  Neutral25: { name: 'neutral-25', value: '#fcfcfd' },
  Neutral50: { name: 'neutral-50', value: '#f9fafb' },
  Neutral100: { name: 'neutral-100', value: '#f2f4f7' },
  Neutral200: { name: 'neutral-200', value: '#eaecf0' },
  Neutral300: { name: 'neutral-300', value: '#d0d5dd' },
  Neutral400: { name: 'neutral-400', value: '#98a2b3' },
  Neutral500: { name: 'neutral-500', value: '#667085' },
  Neutral600: { name: 'neutral-600', value: '#475467' },
  Neutral700: { name: 'neutral-700', value: '#344054' },
  Neutral800: { name: 'neutral-800', value: '#182230' },
  Neutral900: { name: 'neutral-900', value: '#101828' },
  Neutral1000: { name: 'neutral-1000', value: '#0c111d' },
  // Purple
  Purple25: { name: 'purple-25', value: '#f6f4fc' },
  Purple50: { name: 'purple-50', value: '#ece9fc' },
  Purple100: { name: 'purple-100', value: '#d8d3fa' },
  Purple200: { name: 'purple-200', value: '#b1a6f4' },
  Purple300: { name: 'purple-300', value: '#8D7AEF' },
  Purple400: { name: 'purple-400', value: '#644DE9' },
  Purple500: { name: 'purple-500', value: '#3D21E5' },
  Purple600: { name: 'purple-600', value: '#311AB6' },
  Purple700: { name: 'purple-700', value: '#251489' },
  Purple800: { name: 'purple-800', value: '#180D5B' },
  Purple900: { name: 'purple-900', value: '#0C072E' },
  Purple1000: { name: 'purple-1000', value: '#050314' },
  // Blue
  Blue100: { name: 'blue-100', value: '#f2f8ff' },
  Blue200: { name: 'blue-200', value: '#c5dff8' },
  Blue300: { name: 'blue-300', value: '#98c5f2' },
  Blue400: { name: 'blue-400', value: '#5ca3ea' },
  Blue500: { name: 'blue-500', value: '#2081e2' },
  Blue600: { name: 'blue-600', value: '#1971c9' },
  Blue700: { name: 'blue-700', value: '#145799' },
  Blue800: { name: 'blue-800', value: '#0e3d6c' },
  Blue900: { name: 'blue-900', value: '#0a2c4e' },
  Blue1000: { name: 'blue-1000', value: '#041321' },
  // Orange
  Orange25: { name: 'orange-25', value: '#FFFCF5' },
  Orange50: { name: 'orange-50', value: '#FFFAEB' },
  Orange100: { name: 'orange-100', value: '#FEF0C7' },
  Orange200: { name: 'orange-200', value: '#FEDF89' },
  Orange300: { name: 'orange-300', value: '#FEC84B' },
  Orange400: { name: 'orange-400', value: '#FDB022' },
  Orange500: { name: 'orange-500', value: '#F79009' },
  Orange600: { name: 'orange-600', value: '#DC6803' },
  Orange700: { name: 'orange-700', value: '#B54708' },
  Orange800: { name: 'orange-800', value: '#93370D' },
  Orange900: { name: 'orange-900', value: '#7A2E0E' },
  Orange1000: { name: 'orange-1000', value: '#4E1D09' },
  // Peach
  BrandOrange: { name: 'brand-orange', value: '#FF9879' },
  // Red
  Red25: { name: 'red-25', value: '#fff5f5' },
  Red50: { name: 'red-50', value: '#FEF3F2' },
  Red100: { name: 'red-100', value: '#FEE4E2' },
  Red200: { name: 'red-200', value: '#FECDCA' },
  Red300: { name: 'red-300', value: '#FDA29B' },
  Red400: { name: 'red-400', value: '#F97066' },
  Red500: { name: 'red-500', value: '#F04438' },
  Red600: { name: 'red-600', value: '#D92D20' },
  Red700: { name: 'red-700', value: '#B42318' },
  Red800: { name: 'red-800', value: '#912018' },
  Red900: { name: 'red-900', value: '#7A271A' },
  Red1000: { name: 'red-1000', value: '#55160C' },
  // Green
  Green25: { name: 'green-25', value: '#F6FEF9' },
  Green50: { name: 'green-50', value: '#ECFDF3' },
  Green100: { name: 'green-100', value: '#DCFAE6' },
  Green200: { name: 'green-200', value: '#ABEFC6' },
  Green300: { name: 'green-300', value: '#75E0A7' },
  Green400: { name: 'green-400', value: '#47CD89' },
  Green500: { name: 'green-500', value: '#17B26A' },
  Green600: { name: 'green-600', value: '#079455' },
  Green700: { name: 'green-700', value: '#067647' },
  Green800: { name: 'green-800', value: '#085D3A' },
  Green900: { name: 'green-900', value: '#074D31' },
  Green1000: { name: 'green-1000', value: '#053321' },
  // Aqua
  Aqua25: { name: 'aqua-25', value: '#F6FEFE' },
  Aqua50: { name: 'aqua-50', value: '#E9FEFD' },
  Aqua100: { name: 'aqua-100', value: '#D4FCFB' },
  Aqua200: { name: 'aqua-200', value: '#A8F9F8' },
  Aqua300: { name: 'aqua-300', value: '#7DF7F4' },
  Aqua400: { name: 'aqua-400', value: '#51F4F1' },
  Aqua500: { name: 'aqua-500', value: '#26F1ED' },
  Aqua600: { name: 'aqua-600', value: '#1EC1BE' },
  Aqua700: { name: 'aqua-700', value: '#17918E' },
  Aqua800: { name: 'aqua-800', value: '#0F605F' },
  Aqua900: { name: 'aqua-900', value: '#08302F' },
  // Peach
  Peach25: { name: 'peach-25', value: '#FFF9F7' },
  Peach50: { name: 'peach-50', value: '#FFF2EE' },
  Peach100: { name: 'peach-100', value: '#FFE5DE' },
  Peach200: { name: 'peach-200', value: '#FFCBBC' },
  Peach300: { name: 'peach-300', value: '#FFB29B' },
  Peach400: { name: 'peach-400', value: '#FF9879' },
  Peach500: { name: 'peach-500', value: '#FF7E58' },
  Peach600: { name: 'peach-600', value: '#CC6546' },
  Peach700: { name: 'peach-700', value: '#994C35' },
  Peach800: { name: 'peach-800', value: '#663223' },
  Peach900: { name: 'peach-900', value: '#331912' },
  // Slate
  Slate25: { name: 'slate-25', value: '#F0F0F0' },
  Slate50: { name: 'slate-50', value: '#E8EAF0' },
  Slate100: { name: 'slate-100', value: '#D0D5E0' },
  Slate200: { name: 'slate-200', value: '#A1AAC1' },
  Slate300: { name: 'slate-300', value: '#7280A2' },
  Slate400: { name: 'slate-400', value: '#435583' },
  Slate500: { name: 'slate-500', value: '#142B64' },
  Slate600: { name: 'slate-600', value: '#102250' },
  Slate700: { name: 'slate-700', value: '#0C1A3C' },
  Slate800: { name: 'slate-800', value: '#081128' },
  Slate900: { name: 'slate-900', value: '#040914' },
  // Lapis
  Lapis25: { name: 'lapis-25', value: '#F4F5FC' },
  Lapis50: { name: 'lapis-50', value: '#E9EBFC' },
  Lapis100: { name: 'lapis-100', value: '#D3D6FA' },
  Lapis200: { name: 'lapis-200', value: '#A6AEF4' },
  Lapis300: { name: 'lapis-300', value: '#7A85EF' },
  Lapis400: { name: 'lapis-400', value: '#4D5DE9' },
  Lapis500: { name: 'lapis-500', value: '#2134E4' },
  Lapis600: { name: 'lapis-600', value: '#1A2AB6' },
  Lapis700: { name: 'lapis-700', value: '#141F89' },
  Lapis800: { name: 'lapis-800', value: '#0D155B' },
  Lapis900: { name: 'lapis-900', value: '#070A2E' },
};
