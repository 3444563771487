export const salesChannelOptions = [
  {
    label: 'retail',
    value: 'retail',
  },
  {
    label: 'ecommerce',
    value: 'ecommerce',
  },
];

export const MAX_PRODUCT_NAME_LENGTH = 1000;
export const MAX_SKU_NUMBER_LENGTH = 60;
export const MAX_UPC_NUMBER_LENGTH = 12;

// Common fields used across multiple categories
const commonSkuFields = [
  'product_category_id',
  'retailer_sales_channel',
  'sku_id',
  'product_line',
  'product_name',
  'upc_number',
  'product_type_id',
];

const commonFormulationFields = ['product_claim_ids'];

const commonRequiredFields = ['product_name', 'product_type_id'];

export const fragranceOptions = [
  {
    label: 'Has fragrance',
    value: true,
  },
  {
    label: 'Does not have fragrance',
    value: false,
  },
];

export const flavorOptions = [
  {
    label: 'Has flavor',
    value: true,
  },
  {
    label: 'Does not have flavor',
    value: false,
  },
];

// SKU Payload parameters
export const skuPayloadParametersFromForm = {
  'BPC': [...commonSkuFields],
  'Food': [...commonSkuFields],
  'Other': [...commonSkuFields],
};

// Formulation Payload parameters
export const formulationPayloadParametersFromForm = {
  'BPC': [
    'application_time',
    'product_use_ids',
    'product_form_id',
    'fragrance',
    'flavor',
    ...commonFormulationFields,
  ],
  'Food': [...commonFormulationFields],
  'Other': [],
};

// Required form fields
export const requiredFormFields = {
  'BPC': [
    'application_time',
    'product_use_ids',
    'product_form_id',
    'fragrance',
    'flavor',
    ...commonRequiredFields,
  ],
  'Food': [...commonRequiredFields],
  'Other': [...commonRequiredFields],
};

export const productTypeFormalNameMap = {
  'BPC': 'Beauty and Personal Care',
  'Food': 'Food',
  'Other': 'Other',
};
