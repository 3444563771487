import {
  Button,
  DropdownMenu,
  DropdownMenuItem,
  PhosphorIcon,
  Text,
  TextKind,
  TextElement,
} from 'design-system/components';
import { Color } from 'design-system/data';

import { useApp } from 'context/AppContext';
import styles from './upload-button.module.scss';
import { useUploadSkuContext } from '../context/UploadSkuContext';
import { useYourProductContext } from '../context';
import useRetailer from 'layouts/ConnectedSidenav/useRetailer';

export const UploadButton = () => {
  const { openAddProductDialog } = useYourProductContext();
  const { setBulkUploadOpen } = useUploadSkuContext();
  const { selectedRetailerId } = useApp();
  const { retailer } = useRetailer(selectedRetailerId);

  if (!retailer?.allows_publishing_brand_products) {
    return null;
  }

  return (
    <DropdownMenu
      fromLeft={true}
      triggerElement={
        <Button className={styles.button}>
          <PhosphorIcon iconName="Plus" /> Add SKUs
        </Button>
      }
    >
      <DropdownMenuItem onClick={() => setBulkUploadOpen(true)}>
        <DropdownItemContent iconName="UploadSimple" text="Bulk upload" />
      </DropdownMenuItem>
      <DropdownMenuItem onClick={openAddProductDialog}>
        <DropdownItemContent iconName="NotePencil" text="Manual upload" />
      </DropdownMenuItem>
    </DropdownMenu>
  );
};

const DropdownItemContent = ({ iconName, text }) => {
  return (
    <div className={styles['menu-item-content']}>
      <PhosphorIcon iconName={iconName} />
      <Text
        kind={TextKind.TextSMMedium}
        element={TextElement.P}
        color={Color.Neutral700}
      >
        {text}
      </Text>
    </div>
  );
};
