import propTypes from 'prop-types';
import {
  TableRow,
  TableBodyCell,
  Button,
  ButtonKind,
  IconDirection,
  ButtonIconPosition,
  IconName,
  PolicyIcon,
  Pill,
} from 'design-system/components';
import { ScreeningStatusPillData } from 'design-system/data';
import styles from './policy-requirements-table-row.module.scss';

const PolicyRequirementsTableRow = ({ data, onClick }) => {
  const pillData = ScreeningStatusPillData[data.status];
  return (
    <TableRow>
      <TableBodyCell>
        <span className={styles.policy}>
          <PolicyIcon src={data?.imgSrc || ''} name={data?.name} />
          <span>{data?.name || '-'}</span>
        </span>
      </TableBodyCell>
      <TableBodyCell>
        {pillData && <Pill color={pillData.color}>{pillData.label}</Pill>}
      </TableBodyCell>
      <TableBodyCell>
        {data?.action ? (
          <Button
            className={styles.button}
            kind={ButtonKind.Pill}
            iconName={IconName.Chevron}
            iconPosition={ButtonIconPosition.Right}
            iconDirection={IconDirection.Right}
            iconSize={12}
            onClick={onClick}
          >
            {data.action}
          </Button>
        ) : (
          <span className="sr-only">No action required</span>
        )}
      </TableBodyCell>
    </TableRow>
  );
};

PolicyRequirementsTableRow.propTypes = {
  data: propTypes.object,
  onClick: propTypes.func,
};

export default PolicyRequirementsTableRow;
