function CheckGreenRound(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="check-green-round-icon"
    >
      <circle cx="12" cy="12.5" r="12" fill="#EBF9E6" />
      <path
        d="M7 13L10 16L17 8"
        stroke="#095F1C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CheckGreenRound;
