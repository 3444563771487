import qs from 'query-string';

const stringify = (params) =>
  qs.stringify(params, { arrayFormat: 'bracket', skipNull: true });

export default function buildUrl(options = {}) {
  const { origin, path, param, hash } = options;

  const url = path || '';
  const base = origin || window.location.origin;
  const urlObject = new URL(url, base);

  if (param) urlObject.search = stringify(param);
  if (hash) urlObject.hash = hash;

  // absolute urls
  if (origin) {
    return urlObject.toString();
  }

  // relative urls
  return urlObject.toString().replace(base, '');
}
