import PropTypes from 'prop-types';
import { useFDAProductsContext } from '../../Products/Context';
import {
  Button,
  ButtonKind,
  PhosphorIconWeight,
} from 'design-system/components';
import styles from './actions-row.module.scss';
import { Color } from 'design-system/data';

const ActionsRow = ({ variant = 'default' }) => {
  const { setImportOpen, openAddProductDialog } = useFDAProductsContext();

  const uploadButtonCopy =
    variant === 'initial' ? 'Manually upload a SKU' : 'Upload SKU';
  const importButtonCopy =
    variant === 'initial' ? 'Import SKUs from Novi' : 'Import SKUs';

  return (
    <div className={styles.actions}>
      <Button
        onClick={() => openAddProductDialog()}
        kind={ButtonKind.Tertiary}
        usePhosphorIcon
        iconName="Plus"
        iconWeight={PhosphorIconWeight.Light}
        iconColor={Color.Neutral700}
      >
        {uploadButtonCopy}
      </Button>
      <Button
        onClick={() => setImportOpen(true)}
        kind={ButtonKind.Primary}
        usePhosphorIcon
        iconName="DownloadSimple"
        iconSize={18}
        iconWeight={PhosphorIconWeight.Light}
      >
        {importButtonCopy}
      </Button>
    </div>
  );
};

ActionsRow.propTypes = {
  variant: PropTypes.oneOf(['initial', 'default']),
};
export default ActionsRow;
