import propTypes from 'prop-types';
import {
  Text,
  TextKind,
  TextElement,
  ApiError,
  NoContent,
  NoDataVariants,
} from 'design-system/components';
import styles from './no-data.module.scss';

const NoData = ({
  hasErrorOccurred,
  errorMessage,
  noContentMessage,
  isLoading,
  variant = NoDataVariants.Default,
  description,
}) => {
  if (hasErrorOccurred) {
    return (
      <ApiError>
        <Text kind={TextKind.TextMD} element={TextElement.P}>
          {errorMessage ||
            'Something went wrong loading results, please try again later'}
        </Text>
      </ApiError>
    );
  }

  if (isLoading) {
    return <NoContent loading />;
  }

  return (
    <NoContent variant={variant}>
      <>
        <Text kind={TextKind.TextLGSemibold} element={TextElement.P}>
          {noContentMessage}
        </Text>
        {description && (
          <Text
            kind={TextKind.TextSM}
            element={TextElement.P}
            className={styles.description}
          >
            {description}
          </Text>
        )}
      </>
    </NoContent>
  );
};

NoData.propTypes = {
  isLoading: propTypes.bool,
  hasErrorOccurred: propTypes.bool,
  errorMessage: propTypes.string,
  noContentMessage: propTypes.string.isRequired || propTypes.element.isRequired,
  description: propTypes.string,
};

export default NoData;
