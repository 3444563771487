import { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export type QueryParam = string | Array<string> | number | null;
// Single query parameter hook
const useStatefulQueryParam = <T extends QueryParam>(
  paramName: string,
  initialValue: T = null as T
): [T, (value: T) => void] => {
  const history = useHistory();
  const { search } = useLocation();
  const [paramValue, setParamValue] = useState<T>(initialValue);

  const setQueryParamValue = useCallback(
    (value: T) => {
      const queryParams = new URLSearchParams(search);
      if (value === null) {
        queryParams.delete(paramName); // Remove the param if value is null
      } else if (Array.isArray(value)) {
        queryParams.delete(paramName);
        (value as string[]).forEach((param) =>
          queryParams.append(paramName, param)
        );
      } else {
        queryParams.set(paramName, value.toString());
      }
      history.push({ search: queryParams.toString() });
      setParamValue(value);
    },
    [paramName, history, search]
  );

  return [paramValue, setQueryParamValue];
};

// Multiple query parameters hook
type QueryParams = Record<string, QueryParam>;

const useStatefulQueryParams = <T extends QueryParams>(
  initialValues: T = {} as T
): [T, (newParams: Partial<T>) => void] => {
  const history = useHistory();
  const { search } = useLocation();
  const [paramValues, setParamValues] = useState<T>(initialValues);

  const setQueryParamsValues = useCallback(
    (newParams: Partial<T>) => {
      const queryParams = new URLSearchParams(search);

      const updatedValues = { ...paramValues, ...newParams };
      Object.keys(updatedValues).forEach((key) => {
        const value = updatedValues[key];
        if (value !== null && value !== undefined) {
          queryParams.set(key, value.toString());
        } else if (Array.isArray(value)) {
          queryParams.delete(key);
          (value as string[]).forEach((param: string) =>
            queryParams.append(key, param)
          );
        } else {
          queryParams.delete(key); // Remove the key if null or undefined
        }
      });

      history.push({ search: queryParams.toString() });

      // remove undefined and null values
      Object.keys(updatedValues).forEach((key) => {
        if (updatedValues[key] === null || updatedValues[key] === undefined) {
          delete updatedValues[key];
        }
      });
      setParamValues(updatedValues);
    },
    [history, search]
  );

  return [paramValues, setQueryParamsValues];
};

export { useStatefulQueryParam, useStatefulQueryParams };
