/* Keys are the "CLDR Short Name" for an emoji symbol
 * Values are the corresponding unicode escape sequence
 * reference for key/val: https://unicode.org/emoji/charts/full-emoji-list.html
 *
 * Note: If an emoji defaults to the text representation, you may have to add
 * the "\uFE0F" codepoint to the unicode sequence to display the emoji representation.
 */

export default {
  thumbs_up: '\u{1F44D}', // 👍
  cross_mark: '\u274C', // ❌
  no_entry: '\u26D4', // ⛔️
  information: '\u2139\uFE0F', // ℹ️
  stopwatch: '\u23F1\uFE0F', // ⏱
  writing_hand: '\u270D\uFE0F', // ✍
  warning: '\u26A0\uFE0F', // ⚠️
  raised_hand: '\u{270B}', // ✋
  waving_hand: '\u{1F44B}', // 👋
  red_heart: '\u2764\uFE0F', // ❤️
  folded_hands: '\u{1F64F}', // 🙏
  party_popper: '\u{1F389}', // 🎉
  cherry_blossom: '\u{1F338}', // 🌸
  high_voltage: '\u{26A1}', // ⚡
  sparkles: '\u{2728}', // ✨
  thumbs_down: '\u{1F44E}', // 👎
  check_mark_button: '\u{2705}', // ✅
  backhand_index_pointing_right: '\u{1F449}', // 👉
  pouting_face: '\u{1F621}', // 😡
  tired_face: '\u{1F62B}', // 😩
  scream_face: '\u{1F631}', // 😱
  knocked_out_face: '\u{1F635}', // 😵
  confused_face: '\u{1F615}', // 😕
  neutral_face: '\u{1F610}', // 😐
  slightly_smiling_face: '\u{1F642}', // 🙂
  relieved_face: '\u{1F60C}', // 😌
  hugging_face: '\u{1F917}', // 🤗
  heart_eyes_face: '\u{1F60D}', // 😍
  grinning_face_with_smiling_eyes: '\u{1F604}', // 😄
  hourglass_not_done: '\u23F3', // ⏳
  money_bag: '\u{1F4B0}', // 💰
  star: '\u{2B50}', // ⭐
  lightning: '\u{26A1}', // ⚡
};
