import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

const IngredientListForm = styled(function IngredientListForm(props) {
  const { className, onChange, dataCy } = props;
  // TODO: RADIX
  return (
    <div className={className}>
      <p>Please separate ingredients with a comma</p>

      <TextField
        label="Your Ingredients"
        multiline={true}
        placeholder="Example: Water, Glycerin, Dipropylene Glycol, etc"
        fullWidth
        margin="normal"
        data-cy={dataCy}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          style: {
            height: 'var(--input-height)',
          },
        }}
        variant="outlined"
      />
    </div>
  );
})`
  --input-height: 240px;

  margin-bottom: 20px;

  background-color: white;
  border: 1px solid black;
  flex-grow: 1;
  border: 1px solid #ececf2;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.08);
  border-radius: 7px;
  padding: 25px;
  width: 100%;

  p {
    font-weight: bold;
    margin-bottom: 20px;
  }
`;

export default IngredientListForm;
