import {
  Button,
  ButtonKind,
  PhosphorIcon,
  TableBodyCell,
  TableRow,
} from 'design-system/components';
import ScreeningStatusPill from 'components/ScreeningStatusPill';
import styles from './policy-requirements.module.scss';

const PolicytRow = ({ policy, onClick }) => {
  return (
    <TableRow key={policy.consumerProductId} onClick={() => onClick(policy)}>
      <TableBodyCell>{policy.consumerProductName}</TableBodyCell>
      <TableBodyCell>
        <ScreeningStatusPill withDot status={policy.screeningStatus} />
      </TableBodyCell>
      <TableBodyCell>
        <Button kind={ButtonKind.Alternate} onClick={() => onClick(policy)}>
          <div className={styles.cta}>
            Review
            <span className={styles['icon-container']}>
              <PhosphorIcon iconName="ArrowRight" size={18} />
            </span>
          </div>
        </Button>
      </TableBodyCell>
    </TableRow>
  );
};

export default PolicytRow;
