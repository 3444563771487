import { useCallback } from 'react';
import {
  Link,
  LinkKind,
  LinkSize,
  PhosphorIcon,
  Text,
  TextKind,
  TextElement,
  TableBodyCell,
  TableRow,
  Tooltip,
  OpenAxis,
  LinkVariant,
} from 'design-system/components';
import ScreeningStatusPill from 'components/ScreeningStatusPill';
import { IdentifierKeys, PillarResult, Result } from '../data/types';
import styles from '../sku-level-status.module.scss';
import { useRetailerBrandPaywall } from 'views/Brands/shared/RetailerBrandPaywall/context';

const SKULevelRow = ({
  data,
  pillarNames,
  url,
  identifiersToDisplay,
}: {
  data: Result;
  pillarNames?: string[];
  url: string;
  identifiersToDisplay: IdentifierKeys[];
}) => {
  const maximumNameCharacters = 20;
  const maximumIdCharacters = 10;

  const { cannotReviewRequirements } = useRetailerBrandPaywall();

  const truncatedText = useCallback((dataText: string, charLength: number) => {
    const text = dataText;
    if (typeof text !== 'string') return text;
    return text?.length > charLength ? `${text.slice(0, charLength)}...` : text;
  }, []);

  // EG: { 'Vegan Pillar': { pillarData }, 'Cruelty-Free Pillar': { pillarData } }
  const pillarMap: { [key: string]: PillarResult } = data.pillarResults.reduce(
    (acc: { [key: string]: PillarResult }, pillar: PillarResult) => {
      acc[pillar.name] = pillar;
      return acc;
    },
    {}
  );

  // Ensure pillar results show in correct column order
  const alignedPillarResults = pillarNames?.map(
    (pillarName) => pillarMap[pillarName] || null
  );

  return (
    <TableRow
    // onClick={tableRowOnClick}
    >
      {identifiersToDisplay.map((identifier) => (
        <TableBodyCell key={identifier}>
          <Tooltip
            openAxis={OpenAxis.Y}
            triggerElement={
              <Text kind={TextKind.TextSMMedium} element={TextElement.P}>
                {truncatedText(
                  data[identifier]?.toString(),
                  maximumIdCharacters
                )}
              </Text>
            }
            disabled={
              data[identifier]?.toString().length <= maximumIdCharacters
            }
          >
            {data[identifier]}
          </Tooltip>
        </TableBodyCell>
      ))}
      <TableBodyCell>
        <Tooltip
          openAxis={OpenAxis.Y}
          triggerElement={
            <Text kind={TextKind.TextSMMedium} element={TextElement.P}>
              {truncatedText(data.name, maximumNameCharacters)}
            </Text>
          }
          disabled={data.name?.length <= maximumNameCharacters}
        >
          {data.name}
        </Tooltip>
      </TableBodyCell>
      <TableBodyCell>
        <ScreeningStatusPill withDot status={data.screeningStatus} />
      </TableBodyCell>
      {alignedPillarResults?.length &&
        alignedPillarResults.map((pillar: PillarResult, i) => {
          return pillar?.screeningStatus ? (
            <TableBodyCell key={`${pillar.requirementGroupId}-${i}`}>
              <ScreeningStatusPill withDot status={pillar.screeningStatus} />
            </TableBodyCell>
          ) : (
            // If no pillar result exists for some reason, show placeholder
            <TableBodyCell key={i}>-</TableBodyCell>
          );
        })}
      <TableBodyCell>
        <div className={styles['cta-container']}>
          <Tooltip
            disabled={!cannotReviewRequirements}
            triggerElement={
              <Link
                kind={LinkKind.Internal}
                variant={LinkVariant.Alternate}
                size={LinkSize.Small}
                to={url}
                disabled={cannotReviewRequirements}
              >
                <div className={styles.cta}>
                  Review
                  <span className={styles['icon-container']}>
                    <PhosphorIcon iconName="ArrowRight" size={18} />
                  </span>
                </div>
              </Link>
            }
          >
            <Text kind={TextKind.TextSMBold} element={TextElement.Span}>
              In order to review these requirements, please see the Complete
              Payment prompt in the Dashboard.
            </Text>
          </Tooltip>
        </div>
      </TableBodyCell>
    </TableRow>
  );
};
export default SKULevelRow;
