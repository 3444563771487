function Target(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1019_48118)">
        <circle cx="12" cy="12" r="12" fill="white" />
        <circle cx="12" cy="12" r="10" stroke="#E50024" strokeWidth="4" />
        <circle cx="12" cy="12" r="4" fill="#E50024" />
      </g>
      <defs>
        <clipPath id="clip0_1019_48118">
          <rect width="24" height="24" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Target;
