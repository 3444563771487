import { memo } from 'react';
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 2000,
  },
  circularProgress: {
    color: '#fff',
  },
}));

const BackDropLoader = memo(function BackDropLoader({ open }) {
  const classes = useStyles();
  return (
    <Backdrop
      className={classes.backdrop}
      open={open}
      transitionDuration={{ exit: 250 }}
    >
      <CircularProgress color="primary" className={classes.circularProgress} />
    </Backdrop>
  );
});

export default BackDropLoader;
