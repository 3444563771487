import styles from './table-body.module.scss';
import PropTypes from 'prop-types';

function TableBody(props) {
  return <tbody className={styles.root}>{props.children}</tbody>;
}

TableBody.propTypes = {
  children: PropTypes.node,
};

export default TableBody;
