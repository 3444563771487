import React from 'react';
import { CloudinaryImg } from 'design-system/components';
import styles from './metabase-dashboard.module.scss';
import { useMetabaseDashboardData } from 'views/Retailer/Hooks/useMetabaseDashboardData.tsx';
import { useApp } from 'context/AppContext';

interface MetabaseDashboardProps {
  dashboardType: string;
  retailerID: number;
  dashId?: number;
}

const MetabaseDashboard: React.FC<MetabaseDashboardProps> = ({
  dashboardType,
  retailerID,
  dashId,
}) => {
  const { user } = useApp();
  const hasAccess = user.hasFF('metabase_dashboard_view');
  const { hasDash, embedUrl, loading } = useMetabaseDashboardData(
    dashboardType,
    retailerID,
    hasAccess,
    dashId
  );

  if (loading) {
    return null;
  }

  const prodImg = 'v1716419273/demo-retailer-dashboard_maznau.png';
  const nonProdImg = 'v1716418969/demo-retailer-dashboard_znptzj.png';
  const demoImg =
    process.env.REACT_APP_API_BASE === 'http://localhost:3000/'
      ? nonProdImg
      : prodImg;

  if (!hasDash) {
    return (
      <CloudinaryImg
        src={demoImg}
        alt="Retailer Dashboard"
        className={styles.image}
        fallbackElement={<div />}
      />
    );
  }

  return (
    <div id="metabase-container" className={styles.container as string}>
      {embedUrl && (
        <iframe
          src={embedUrl}
          frameBorder="0"
          width="100%"
          height="100%"
          allowTransparency
          title="Metabase Dashboard"
        />
      )}
    </div>
  );
};

export default MetabaseDashboard;
