import YourProductsProvider from '../YourProducts/context';
import { PaywallProvider } from '../YourProducts/context/PaywallContext';
import CreateProductsContainer from './CreateProductsContainer';
import { CreateProductProvider } from './context/CreateProductContext';

function YourProducts() {
  return (
    <PaywallProvider>
      <YourProductsProvider>
        <CreateProductProvider>
          <CreateProductsContainer />
        </CreateProductProvider>
      </YourProductsProvider>
    </PaywallProvider>
  );
}

export default YourProducts;
