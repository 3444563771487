import { SortDirection } from 'design-system/components';

export const defaultPaginationState = (sort, options) => {
  const defaultOptions = {
    direction: SortDirection.Ascending,
    page: '1',
    items: '10',
  };
  const paramsObject = { sort, ...defaultOptions, ...options };
  const searchParams = new URLSearchParams(paramsObject);
  const paramsString = searchParams.toString();

  return {
    searchParams,
    paramsString,
  };
};
