import { FDAProductProvider } from './Context';
import DetailsProductsView from './view';

const Products = () => {
  return (
    <FDAProductProvider>
      <DetailsProductsView />
    </FDAProductProvider>
  );
};

export default Products;
