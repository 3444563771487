import { KeyboardEvent } from 'react';

import { PhosphorIcon } from 'design-system/components';
import styles from './multi-select.module.scss';

interface InputPillListItemProps {
  pill: string;
  index: number;
  onRemove: (item: number) => void;
  handlePillKeyDown: (e: KeyboardEvent<HTMLLIElement>, index: number) => void;
  pillRefs: any;
}

const InputPillListItem = ({
  pill,
  index,
  onRemove,
  handlePillKeyDown,
  pillRefs,
}: InputPillListItemProps) => {
  const focusPill = (index: number) => {
    pillRefs.current[index]?.focus();
  };

  const navigateLeft = (currentIndex: number | null) => {
    if (currentIndex && currentIndex > 0) {
      focusPill(currentIndex - 1);
    }
  };

  const navigateRight = (currentIndex: number | null) => {
    if (currentIndex === null) {
      return;
    }
    focusPill(currentIndex + 1);
  };

  const onPillKeyDown = (
    event: KeyboardEvent<HTMLLIElement>,
    index: number
  ) => {
    handlePillKeyDown(event, index);
    if (
      event.key === 'Backspace' ||
      event.key === 'Delete' ||
      event.key === 'Enter'
    ) {
      event.preventDefault();
      onRemove(index);
      navigateLeft(index);
    } else if (event.key === 'ArrowRight') {
      event.preventDefault();
      navigateRight(index);
    } else if (event.key === 'ArrowLeft') {
      event.preventDefault();
      navigateLeft(index);
    }
  };
  return (
    <li
      key={index}
      ref={(el) => (pillRefs.current[index] = el)}
      tabIndex={0}
      className={styles.pill}
      onKeyDown={(e) => onPillKeyDown(e, index)}
      onClick={() => onRemove(index)}
    >
      {pill}
      <span
        role="button"
        aria-label={`Remove ${pill}`}
        className={styles['pill-delete']}
      >
        <PhosphorIcon iconName="X" size={14} />
      </span>
    </li>
  );
};

export default InputPillListItem;
