import { useState } from 'react';
import { Text, TextElement, TextKind } from 'design-system/components';
import { func, string } from 'prop-types';
import styles from './review-modal-content.module.scss';

function ReviewModalContent({ title, content, onChangeNote }) {
  const [textValue, setTextValue] = useState();

  const handleChange = (value) => {
    setTextValue(value);
    onChangeNote(value);
  };
  return (
    <div className={styles.root}>
      <Text kind={TextKind.TextMDSemibold} element={TextElement.H1}>
        {title}
      </Text>
      <Text
        kind={TextKind.TextSM}
        element={TextElement.P}
        className={styles.content}
      >
        {content}
      </Text>
      <label htmlFor="retailer-response">
        <Text kind={TextKind.TextSMMedium} element={TextElement.Span}>
          Add Comments
        </Text>
      </label>
      <textarea
        id="retailer-response"
        value={textValue || ''}
        onChange={(e) => handleChange(e.target.value)}
        placeholder="Enter a note..."
      />
      <Text kind={TextKind.TextSM} element={TextElement.P}>
        These comments will be sent directly to the brand
      </Text>
    </div>
  );
}

ReviewModalContent.propTypes = {
  title: string.isRequired,
  content: string.isRequired,
  onChangeNote: func.isRequired,
  noteValue: string,
};

export default ReviewModalContent;
