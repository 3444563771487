import PropTypes from 'prop-types';
import { Pill, PillColor } from 'design-system/components';
import styles from './screening-status-pill.module.scss';

const ScreeningStatus = {
  NeedsInformation: 'needs_info',
  Complete: 'complete',
};

const getColorAndLabel = (status) => {
  switch (status) {
    case ScreeningStatus.NeedsInformation:
      return { color: PillColor.Warning, label: 'Needs attention' };
    case ScreeningStatus.Complete:
      return { color: PillColor.Success, label: 'Complete' };
    default:
      return null;
  }
};

export default function RequirementStatusPill({ status, withDot }) {
  const pillStatus = getColorAndLabel(status);
  if (!pillStatus) {
    return <span />;
  }
  return (
    <Pill color={pillStatus.color}>
      {withDot && <span className={styles.dot} />}
      {pillStatus.label}
    </Pill>
  );
}

RequirementStatusPill.propTypes = {
  status: PropTypes.string.isRequired,
  withDot: PropTypes.bool,
};
