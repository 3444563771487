import { useState, createContext, useContext, useEffect, useMemo } from 'react';
import Dialog from 'components/Dialog';
import {
  Text,
  TextKind,
  TextElement,
  PhosphorIcon,
  Divider,
  Button,
  ButtonKind,
  ToastManager,
  ToastKind,
  Pill,
  PillColor,
} from 'design-system/components';
import styles from './paywall-dialog.module.scss';
import fetcher from 'services/api/fetcher';
import { useApp } from 'context/AppContext';
import { Color } from 'design-system/data';

const PaywallContext = createContext(null);

export const usePaywallContext = () => {
  const ctx = useContext(PaywallContext);
  if (!ctx) {
    throw new Error('Must be used within an PaywallContext');
  }
  return ctx;
};

export const PaywallProvider = ({ children }) => {
  const [selectedTier, setSelectedTier] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [paywallOpen, setPaywallOpen] = useState(false);
  const { selectedRetailerBrandId, useApi, retailerBrand } = useApp();
  const [toastData, setToastData] = useState([]);

  const { requested_tier_id, current_tier_id } = retailerBrand || {};

  const requestSubscription = async () => {
    try {
      await fetcher(
        `/api/v4/retailer_consumer_brands/${selectedRetailerBrandId}/request_subscription_tier`,
        {
          method: 'post',
          body: { tier_id: selectedTier },
        }
      );
      setIsSuccess(true);
      setToastData([
        ...toastData,
        {
          title: 'Success',
          kind: ToastKind.Success,
          message: 'Request submitted.',
        },
      ]);
    } catch (e) {
      setToastData([
        ...toastData,
        {
          title: 'Error',
          kind: ToastKind.Error,
          message: 'Request failed. Please try again or contact support.',
        },
      ]);
    }
  };

  const normalizeSkusCount = (minCount, maxCount) => {
    if (!minCount && !maxCount) {
      return `Unlimited SKUs`;
    }

    if (!minCount) {
      return `Up to ${maxCount} SKUs`;
    }

    if (!maxCount) {
      return `${minCount}+ SKUs`;
    }

    return `${minCount}-${maxCount} SKUs`;
  };

  const { data } = useApi(
    `/api/v4/retailer_consumer_brands/${selectedRetailerBrandId}/subscription_tiers`,
    {
      enabled: Boolean(selectedRetailerBrandId),
    }
  );
  const subscriptionTiers = useMemo(() => {
    if (!data?.subscription_tiers?.length) {
      return [];
    }
    return data?.subscription_tiers.map((subscriptionTier) => {
      return {
        ...subscriptionTier,
        skusCount: normalizeSkusCount(
          subscriptionTier.product_count_min,
          subscriptionTier.product_count_max
        ),
      };
    });
  }, [data?.subscription_tiers]);

  useEffect(() => {
    if (!paywallOpen) {
      setSelectedTier(subscriptionTiers[subscriptionTiers.length - 1]?.id);
      setIsSuccess(false);
    }
  }, [paywallOpen, subscriptionTiers]);

  const context = useMemo(() => {
    return {
      openPaywall: () => setPaywallOpen(true),
    };
  }, [setPaywallOpen]);

  return (
    <PaywallContext.Provider value={context}>
      {children}
      <ToastManager data={toastData} />
      <Dialog open={paywallOpen} onClose={() => setPaywallOpen(false)}>
        {isSuccess ? (
          <div className={styles.root}>
            <div className={styles.wrapper}>
              <Text kind={TextKind.DisplayMDMedium} element={TextElement.H1}>
                Thank you!
              </Text>
              <PhosphorIcon
                className={styles.close}
                onClick={() => setPaywallOpen(false)}
                iconName="X"
                size={24}
              />
            </div>
            <Text
              kind={TextKind.TextMDMedium}
              element={TextElement.P}
              className={styles.subtext}
            >
              We have received your upgrade request. Someone from our team will
              be in touch within two business days.
            </Text>
            <Button
              kind={ButtonKind.Primary}
              className={styles.button}
              onClick={() => setPaywallOpen(false)}
            >
              Close
            </Button>
          </div>
        ) : (
          <>
            <div className={styles.root}>
              <div>
                <div className={styles['close-wrapper']}>
                  <PhosphorIcon
                    className={styles.close}
                    onClick={() => setPaywallOpen(false)}
                    iconName="X"
                    size={24}
                  />
                </div>
                <Text kind={TextKind.DisplayMDMedium} element={TextElement.H1}>
                  Upgrade your account and unlock easy uploads
                </Text>
              </div>
              <Text
                className={styles.subtext}
                kind={TextKind.TextMDMedium}
                element={TextElement.P}
                color={Color.Neutral900}
              >
                All paid plans include bulk uploads and data storage for
                uploaded SKUs.
              </Text>
              {subscriptionTiers.map((subscriptionTier) => {
                const isSelected = subscriptionTier.id === selectedTier;
                const customTier = Boolean(subscriptionTier.description);
                const currentTier =
                  !customTier && subscriptionTier.id === current_tier_id;
                const isRequested =
                  !customTier &&
                  !currentTier &&
                  subscriptionTier.id === requested_tier_id;
                const notSelectable = customTier || currentTier || isRequested;
                return (
                  <div
                    key={subscriptionTier.id}
                    onClick={() =>
                      notSelectable
                        ? null
                        : setSelectedTier(subscriptionTier.id)
                    }
                    className={`${styles.option} ${
                      isSelected ? styles['option-selected'] : ''
                    } ${
                      !notSelectable
                        ? styles['option-selectable']
                        : styles['not-selectable']
                    }`}
                  >
                    <div className={styles['title-wrapper']}>
                      <PhosphorIcon
                        data-active={isSelected}
                        data-current={customTier || currentTier}
                        className={styles.icon}
                        iconName="Check"
                        size={32}
                      />
                      <div>
                        <div className={styles['pill-and-content-wrapper']}>
                          <Text
                            kind={TextKind.DisplaySMBold}
                            element={TextElement.P}
                          >
                            {subscriptionTier.external_name}
                          </Text>
                          {isRequested && (
                            <Pill color={PillColor.Dark}>
                              <Text
                                kind={TextKind.TextSM}
                                element={TextElement.P}
                              >
                                Requested
                              </Text>
                            </Pill>
                          )}
                          {customTier && (
                            <Pill color={PillColor.Dark}>
                              <Text
                                kind={TextKind.TextSM}
                                element={TextElement.P}
                              >
                                Included
                              </Text>
                            </Pill>
                          )}
                          {currentTier && (
                            <Pill color={PillColor.Dark}>
                              <Text
                                kind={TextKind.TextSM}
                                element={TextElement.P}
                              >
                                Current plan
                              </Text>
                            </Pill>
                          )}
                        </div>
                        <Text
                          kind={TextKind.TextMDMedium}
                          element={TextElement.P}
                        >
                          {`${subscriptionTier.skusCount}${
                            customTier ? ' *' : ''
                          }`}
                        </Text>
                      </div>
                    </div>
                    {customTier ? null : (
                      <div className={styles['price-wrapper']}>
                        <Text
                          kind={TextKind.DisplayMDMedium}
                          element={TextElement.P}
                        >
                          ${subscriptionTier.price}
                        </Text>
                        <Text
                          className={styles.subtext}
                          kind={TextKind.TextSMMedium}
                          element={TextElement.Span}
                        >
                          per year
                        </Text>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            {subscriptionTiers.map((subscriptionTier, i) => {
              const description = subscriptionTier.description;
              if (description) {
                return (
                  <Text
                    className={styles['fine-print']}
                    kind={TextKind.TextXS}
                    element={TextElement.P}
                    key={i}
                  >
                    * {subscriptionTier.description}
                  </Text>
                );
              }
              return null;
            })}
            <Divider />
            <Button
              disabled={!selectedTier}
              className={styles.button}
              onClick={requestSubscription}
            >
              Upgrade now
            </Button>
            <Text
              className={styles['fine-print']}
              kind={TextKind.TextXS}
              element={TextElement.P}
            >
              Prices shown subject to state and local taxes where applicable.
            </Text>
          </>
        )}
      </Dialog>
    </PaywallContext.Provider>
  );
};
