import { useState } from 'react';
import {
  Tab,
  TabGroup,
  Text,
  TextElement,
  TextKind,
  Button,
  ButtonKind,
} from 'design-system/components';
import { Color } from 'design-system/data';
import PropTypes from 'prop-types';

import styles from './brand-view-content-section.module.scss';

function BrandViewContentSection({
  header,
  subheader,
  tabData,
  tabContents,
  onTabChange,
  headerCta,
  headerCtaTitle,
  activeTabIndex: tabIndex = 0,
}) {
  const [activeTabIndex, setActiveTabIndex] = useState(tabIndex);

  return (
    <div className={styles.item}>
      {header && (
        <div className={styles.header}>
          <Text
            kind={TextKind.Display2XSBold}
            element={TextElement.H1}
            color={Color.Black}
          >
            {header}
          </Text>
          {headerCta && (
            <Button kind={ButtonKind.Pill} onClick={headerCta}>
              {headerCtaTitle}
            </Button>
          )}
        </div>
      )}
      {subheader && (
        <div className={styles.subheader}>
          <Text
            id="tablist-brands"
            kind={TextKind.TextSM}
            element={TextElement.P}
            color={Color.Black}
          >
            {subheader}
          </Text>
        </div>
      )}

      {!!tabData.length && (
        <TabGroup
          ariaLabelledBy="tablist-brands"
          activeTabIndex={activeTabIndex}
        >
          {tabData.map((data, index) => (
            <Tab
              key={data.value}
              onClick={() => {
                setActiveTabIndex(index);
                onTabChange(data.value);
              }}
              isActive={activeTabIndex === index}
              chipValue={data.quantity || null}
              pillColor={data.pillColor}
            >
              {data.label}
            </Tab>
          ))}
        </TabGroup>
      )}

      {tabContents}
    </div>
  );
}

BrandViewContentSection.propTypes = {
  header: PropTypes.string,
  subheader: PropTypes.string,
  tabData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      quantity: PropTypes.number,
    })
  ).isRequired,
  tabContents: PropTypes.node.isRequired,
  onTabChange: PropTypes.func,
  headerCta: PropTypes.func,
  headerCtaTitle: PropTypes.string,
};

export default BrandViewContentSection;
