import { createRoot } from 'react-dom/client';
import 'utils/datadog';
import 'utils/sentry';
import 'utils/throwme';
import 'design-system/styles/_init.scss';

import App from './components/App';

const container = document.getElementById('root');

const root = createRoot(container!);
root.render(<App />);
