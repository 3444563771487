// * ------------------------------------------------------------------------------------------ * //
// * If any of these change, please update the values in `design-system/styles/_variables.scss` * //
// * ------------------------------------------------------------------------------------------ * //
//

export type BrandColorNames =
  | 'BrandWhite'
  | 'BrandBlack'
  | 'BrandPrimary25'
  | 'BrandPrimary50'
  | 'BrandPrimary100'
  | 'BrandPrimary200'
  | 'BrandPrimary300'
  | 'BrandPrimary400'
  | 'BrandPrimary500'
  | 'BrandPrimary600'
  | 'BrandPrimary700'
  | 'BrandPrimary800'
  | 'BrandPrimary900'
  | 'BrandPrimary950'
  | 'BrandSecondary25'
  | 'BrandSecondary50'
  | 'BrandSecondary100'
  | 'BrandSecondary200'
  | 'BrandSecondary300'
  | 'BrandSecondary400'
  | 'BrandSecondary500'
  | 'BrandSecondary600'
  | 'BrandSecondary700'
  | 'BrandSecondary800'
  | 'BrandSecondary900'
  | 'BrandSecondary950'
  | 'BrandNeon25'
  | 'BrandNeon50'
  | 'BrandNeon100'
  | 'BrandNeon200'
  | 'BrandNeon300'
  | 'BrandNeon400'
  | 'BrandNeon500'
  | 'BrandNeon600'
  | 'BrandNeon700'
  | 'BrandNeon800'
  | 'BrandNeon900'
  | 'BrandNeon950'
  | 'BrandPink25'
  | 'BrandPink50'
  | 'BrandPink100'
  | 'BrandPink200'
  | 'BrandPink300'
  | 'BrandPink400'
  | 'BrandPink500'
  | 'BrandPink600'
  | 'BrandPink700'
  | 'BrandPink800'
  | 'BrandPink900'
  | 'BrandPink950'
  | 'Gray25'
  | 'Gray50'
  | 'Gray100'
  | 'Gray200'
  | 'Gray300'
  | 'Gray400'
  | 'Gray500'
  | 'Gray600'
  | 'Gray700'
  | 'Gray800'
  | 'Gray900'
  | 'Gray950'
  | 'Error25'
  | 'Error50'
  | 'Error100'
  | 'Error200'
  | 'Error300'
  | 'Error400'
  | 'Error500'
  | 'Error600'
  | 'Error700'
  | 'Error800'
  | 'Error900'
  | 'Error950'
  | 'Warning25'
  | 'Warning50'
  | 'Warning100'
  | 'Warning200'
  | 'Warning300'
  | 'Warning400'
  | 'Warning500'
  | 'Warning600'
  | 'Warning700'
  | 'Warning800'
  | 'Warning900'
  | 'Warning950'
  | 'Success25'
  | 'Success50'
  | 'Success100'
  | 'Success200'
  | 'Success300'
  | 'Success400'
  | 'Success500'
  | 'Success600'
  | 'Success700'
  | 'Success800'
  | 'Success900'
  | 'Success950';

export type ColorType = {
  name: string;
  value: string;
};

export type BrandColorsType = Record<BrandColorNames, ColorType>;

export const BrandColors: BrandColorsType = {
  'BrandWhite': { name: 'brand-white', value: '#FFFFFF' },
  'BrandBlack': { name: 'brand-black', value: '#000000' },
  'BrandPrimary25': { name: 'brand-primary-25', value: '#F6F4FC' },
  'BrandPrimary50': { name: 'brand-primary-50', value: '#ECE9FC' },
  'BrandPrimary100': { name: 'brand-primary-100', value: '#D8D3FA' },
  'BrandPrimary200': { name: 'brand-primary-200', value: '#B1A6F4' },
  'BrandPrimary300': { name: 'brand-primary-300', value: '#8B7AEF' },
  'BrandPrimary400': { name: 'brand-primary-400', value: '#644DE9' },
  'BrandPrimary500': { name: 'brand-primary-500', value: '#3D21E5' },
  'BrandPrimary600': { name: 'brand-primary-600', value: '#311AB6' },
  'BrandPrimary700': { name: 'brand-primary-700', value: '#251489' },
  'BrandPrimary800': { name: 'brand-primary-800', value: '#180D5B' },
  'BrandPrimary900': { name: 'brand-primary-900', value: '#0C072E' },
  'BrandPrimary950': { name: 'brand-primary-950', value: '#050314' },
  'BrandSecondary25': { name: 'brand-secondary-25', value: '#F5FCFC' },
  'BrandSecondary50': { name: 'brand-secondary-50', value: '#EDF4F4' },
  'BrandSecondary100': { name: 'brand-secondary-100', value: '#DBE8EA' },
  'BrandSecondary200': { name: 'brand-secondary-200', value: '#B7D1D4' },
  'BrandSecondary300': { name: 'brand-secondary-300', value: '#93BBBF' },
  'BrandSecondary400': { name: 'brand-secondary-400', value: '#6FA4A9' },
  'BrandSecondary500': { name: 'brand-secondary-500', value: '#4B8D94' },
  'BrandSecondary600': { name: 'brand-secondary-600', value: '#3C7176' },
  'BrandSecondary700': { name: 'brand-secondary-700', value: '#2D5559' },
  'BrandSecondary800': { name: 'brand-secondary-800', value: '#1E383B' },
  'BrandSecondary900': { name: 'brand-secondary-900', value: '#0F1C1E' },
  'BrandSecondary950': { name: 'brand-secondary-950', value: '#070E0F' },
  'BrandNeon25': { name: 'brand-neon-25', value: '#FEFFFA' },
  'BrandNeon50': { name: 'brand-neon-50', value: '#FCFFF4' },
  'BrandNeon100': { name: 'brand-neon-100', value: '#F9FFE9' },
  'BrandNeon200': { name: 'brand-neon-200', value: '#F3FFD3' },
  'BrandNeon300': { name: 'brand-neon-300', value: '#ECFFBD' },
  'BrandNeon400': { name: 'brand-neon-400', value: '#E6FFA7' },
  'BrandNeon500': { name: 'brand-neon-500', value: '#E0FF91' },
  'BrandNeon600': { name: 'brand-neon-600', value: '#B3CC74' },
  'BrandNeon700': { name: 'brand-neon-700', value: '#869957' },
  'BrandNeon800': { name: 'brand-neon-800', value: '#5A663A' },
  'BrandNeon900': { name: 'brand-neon-900', value: '#2D331D' },
  'BrandNeon950': { name: 'brand-neon-950', value: '#16190E' },
  'BrandPink25': { name: 'brand-pink-25', value: '#FEF6FA' },
  'BrandPink50': { name: 'brand-pink-50', value: '#FEEFF7' },
  'BrandPink100': { name: 'brand-pink-100', value: '#FDDEEF' },
  'BrandPink200': { name: 'brand-pink-200', value: '#FBBBDF' },
  'BrandPink300': { name: 'brand-pink-300', value: '#F99DCE' },
  'BrandPink400': { name: 'brand-pink-400', value: '#F77CBE' },
  'BrandPink500': { name: 'brand-pink-500', value: '#F55BAE' },
  'BrandPink600': { name: 'brand-pink-600', value: '#C4498B' },
  'BrandPink700': { name: 'brand-pink-700', value: '#933768' },
  'BrandPink800': { name: 'brand-pink-800', value: '#622446' },
  'BrandPink900': { name: 'brand-pink-900', value: '#311223' },
  'BrandPink950': { name: 'brand-pink-950', value: '#180911' },
  'Gray25': { name: 'gray-25', value: '#FCFCFD' },
  'Gray50': { name: 'gray-50', value: '#F9FAFB' },
  'Gray100': { name: 'gray-100', value: '#F2F4F7' },
  'Gray200': { name: 'gray-200', value: '#EAECF0' },
  'Gray300': { name: 'gray-300', value: '#D0D5DD' },
  'Gray400': { name: 'gray-400', value: '#98A2B3' },
  'Gray500': { name: 'gray-500', value: '#667085' },
  'Gray600': { name: 'gray-600', value: '#475467' },
  'Gray700': { name: 'gray-700', value: '#344054' },
  'Gray800': { name: 'gray-800', value: '#182230' },
  'Gray900': { name: 'gray-900', value: '#101828' },
  'Gray950': { name: 'gray-950', value: '#0C111D' },
  'Error25': { name: 'error-25', value: '#FFFBFA' },
  'Error50': { name: 'error-50', value: '#FEF3F2' },
  'Error100': { name: 'error-100', value: '#FEE4E2' },
  'Error200': { name: 'error-200', value: '#FECDCA' },
  'Error300': { name: 'error-300', value: '#FDA29B' },
  'Error400': { name: 'error-400', value: '#F97066' },
  'Error500': { name: 'error-500', value: '#F04438' },
  'Error600': { name: 'error-600', value: '#D92D20' },
  'Error700': { name: 'error-700', value: '#B42318' },
  'Error800': { name: 'error-800', value: '#912018' },
  'Error900': { name: 'error-900', value: '#7A271A' },
  'Error950': { name: 'error-950', value: '#55160C' },
  'Warning25': { name: 'warning-25', value: '#FFFCF5' },
  'Warning50': { name: 'warning-50', value: '#FFFAEB' },
  'Warning100': { name: 'warning-100', value: '#FEF0C7' },
  'Warning200': { name: 'warning-200', value: '#FEDF89' },
  'Warning300': { name: 'warning-300', value: '#FEC84B' },
  'Warning400': { name: 'warning-400', value: '#FDB022' },
  'Warning500': { name: 'warning-500', value: '#F79009' },
  'Warning600': { name: 'warning-600', value: '#DC6803' },
  'Warning700': { name: 'warning-700', value: '#B54708' },
  'Warning800': { name: 'warning-800', value: '#93370D' },
  'Warning900': { name: 'warning-900', value: '#7A2E0E' },
  'Warning950': { name: 'warning-950', value: '#4E1D09' },
  'Success25': { name: 'success-25', value: '#F6FEF9' },
  'Success50': { name: 'success-50', value: '#ECFDF3' },
  'Success100': { name: 'success-100', value: '#DCFAE6' },
  'Success200': { name: 'success-200', value: '#ABEFC6' },
  'Success300': { name: 'success-300', value: '#75E0A7' },
  'Success400': { name: 'success-400', value: '#47CD89' },
  'Success500': { name: 'success-500', value: '#17B26A' },
  'Success600': { name: 'success-600', value: '#079455' },
  'Success700': { name: 'success-700', value: '#067647' },
  'Success800': { name: 'success-800', value: '#085D3A' },
  'Success900': { name: 'success-900', value: '#074D31' },
  'Success950': { name: 'success-950', value: '#053321' },
} as const;
