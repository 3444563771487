import { useCallback, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import useAuth0 from 'hooks/useAuth0';
import RequestRetry from './RequestRetry';
import FetchError from './FetchError';
import { DEFAULT_ERROR_MSG } from '../fetcher';
import { logToSentry } from './utils';

const useErrorHandlers = () => {
  const snackbar = useSnackbar();
  const { auth0 } = useAuth0();

  const displayMessage = useCallback(
    (message, variant = 'error', options = {}) => {
      return () => {
        snackbar.enqueueSnackbar(message, { variant, ...options });
      };
    },
    [snackbar]
  );

  const displayDefaultMessage = useCallback(
    (variant = 'error', options = {}) => {
      return (error) => {
        logToSentry(error);
        if (error instanceof FetchError) {
          displayMessage(error.message, variant, options)();
        } else {
          displayMessage(DEFAULT_ERROR_MSG, variant, options)();
        }
      };
    },
    [displayMessage]
  );

  const defaultErrorHandlers = useMemo(
    () => [
      {
        statusCode: 401,
        handler: refreshToken(auth0, displayDefaultMessage()),
      },
      {
        handler: displayDefaultMessage(),
      },
    ],
    [auth0, displayDefaultMessage]
  );

  return {
    displayMessage,
    displayDefaultMessage,
    retryRequest,
    refreshAuth0Token: refreshToken(auth0),
    defaultErrorHandlers,
  };
};

export const retryRequest = (onFail, times = 1) => {
  return (error) => {
    if (error.retryTimes < times) {
      return new RequestRetry(error.request, error.retryTimes + 1);
    }
    return onFail(error);
  };
};

export const refreshToken = (auth0, onFail) => {
  return async (error) => {
    if (auth0 && !error.retryTimes) {
      await auth0.refreshToken();

      if (auth0.isAuthenticated) {
        return new RequestRetry(error.request);
      }

      auth0.redirectToLogin();
    } else if (onFail) {
      return onFail(error);
    }
  };
};

export const multiHandle = (...handlers) => {
  return (error) => Promise.all(handlers.map((handler) => handler(error)));
};

export default useErrorHandlers;
