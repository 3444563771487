import { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import {
  Text,
  TextElement,
  TextKind,
  Icon,
  Indicator,
  PhosphorIcon,
} from 'design-system/components';
import { Color } from 'design-system/data';
import styles from './menu-item.module.scss';

function MenuItem({ forceNotActive = false, ...props }) {
  const currentPath = window.location.pathname;
  const size = props.iconSize ? props.iconSize : 10;

  const childComponent = useMemo(
    () => (
      <>
        {props.iconName &&
          // TODO ENG-4091: Migrate all usage to phosphor icons
          (props.isPhosphorIcon ? (
            <PhosphorIcon
              iconName={props.iconName}
              size={size}
              style={{ minWidth: `${size}px` }}
              color={Color.Neutral500.value}
            />
          ) : (
            <Icon name={props.iconName} size={size} color={Color.Neutral500} />
          ))}
        <Text kind={TextKind.TextSMMedium} element={TextElement.Span}>
          {props.children}
        </Text>
        {props.showIndicator && <Indicator />}
      </>
    ),
    [
      props.iconName,
      props.showIndicator,
      props.children,
      size,
      props.isPhosphorIcon,
    ]
  );

  if (props.href) {
    return (
      <RouterLink
        to={{ pathname: props.href, search: props.search }}
        data-cy={props['data-cy']}
        className={cn([
          styles[
            (currentPath.includes(props.href) || props.isActive) &&
            !forceNotActive
              ? 'root-active'
              : 'root'
          ],
          props.compact && styles.compact,
        ])}
        onClick={() => props.onClick && props.onClick()}
      >
        {childComponent}
      </RouterLink>
    );
  }

  return (
    <button
      onClick={() => props.onClick && props.onClick()}
      data-cy={props['data-cy']}
      className={styles[!props.isActive ? 'root' : 'root-active']}
    >
      {childComponent}
    </button>
  );
}

MenuItem.propTypes = {
  children: PropTypes.string.isRequired,
  iconName: PropTypes.string,
  iconSize: PropTypes.number,
  href: PropTypes.string,
  search: PropTypes.string,
  showIndicator: PropTypes.bool,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  [`data-cy`]: PropTypes.string,
  isPhosphorIcon: PropTypes.bool,
  compact: PropTypes.bool,
  forceNotActive: PropTypes.bool,
};

export default MenuItem;
