import {
  OpenAxis,
  Text,
  TextKind,
  TextElement,
  Tooltip,
} from 'design-system/components';
import styles from './toggle.module.scss';
import propTypes from 'prop-types';
import { convertToKebabCase } from 'design-system/utils';

const getVariantStyles = (variant) => {
  switch (variant) {
    case 'default':
      return {
        container: styles.container,
        button: styles.button,
        'button-active': styles['button-active'],
      };
    case 'outlined':
      return {
        container: styles['container-outlined'],
        button: styles['button-outlined'],
        'button-active': styles['button-outlined-active'],
      };
    default:
      return {
        container: styles.container,
        button: styles.button,
        'button-active': styles['button-active'],
      };
  }
};

function Toggle(props) {
  const variantStyle = getVariantStyles(props.variant);
  const maximumOptionCharacters = 60;
  const truncatedOption = (option) => {
    return option.length > maximumOptionCharacters
      ? `${option.slice(0, maximumOptionCharacters)}...`
      : option;
  };

  return (
    <div className={variantStyle.container}>
      {props.options.map((option, index) => (
        <Tooltip
          key={`${option}-${index}`}
          openAxis={OpenAxis.Y}
          triggerElement={
            <button
              className={
                variantStyle[
                  props.activeOption === option ? 'button-active' : 'button'
                ]
              }
              key={`${option}-${index}`}
              onClick={() => props.onClick(option)}
              data-cy={
                props[`data-cy`] &&
                `${props[`data-cy`]}-${convertToKebabCase(option)}`
              }
            >
              {truncatedOption(option)}
              {props.secondaryItems && props.secondaryItems[index]}
            </button>
          }
          disabled={option.length <= maximumOptionCharacters}
        >
          <Text kind={TextKind.TextSM} element={TextElement.Span}>
            {option}
          </Text>
        </Tooltip>
      ))}
    </div>
  );
}

Toggle.propTypes = {
  options: propTypes.arrayOf(propTypes.string).isRequired,
  secondaryItems: propTypes.arrayOf(propTypes.element),
  activeOption: propTypes.string.isRequired,
  onClick: propTypes.func.isRequired,
  variant: propTypes.oneOf(['default', 'outlined']),
  [`data-cy`]: propTypes.string,
};

export default Toggle;
