export default function Heart({ size, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      viewBox="0 0 40 40"
    >
      <path
        d="m20 35.333-2.125-1.916q-4.417-3.959-7.25-6.855-2.833-2.895-4.542-5.208-1.708-2.312-2.375-4.187-.666-1.875-.666-3.834 0-3.916 2.625-6.562 2.625-2.646 6.541-2.646 2.292 0 4.292 1.021T20 8.083q1.667-2 3.604-2.979 1.938-.979 4.146-.979 3.917 0 6.562 2.646 2.646 2.646 2.646 6.562 0 1.959-.666 3.834-.667 1.875-2.375 4.166-1.709 2.292-4.563 5.209-2.854 2.916-7.229 6.875Z"
        fill={color.value}
      />
    </svg>
  );
}
