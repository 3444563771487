function Alert(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6809 12.2241C13.8775 9.84047 12.5495 7.18662 10.7602 4.55979C10.1149 3.61118 9.44226 2.71092 8.74808 1.88462V1.88462C8.34991 1.41067 7.63043 1.4103 7.23612 1.88746V1.88746C6.55056 2.71945 5.87938 3.6126 5.23406 4.55979C3.44472 7.18662 2.11672 9.84047 1.31332 12.2241V12.2241C1.13118 12.7618 1.46595 13.3536 2.01823 13.4851C2.13459 13.5128 2.24836 13.5371 2.35624 13.555C6.14864 14.1596 9.93523 14.137 13.7312 13.555C13.8029 13.5434 13.8737 13.5302 13.9438 13.516C14.523 13.3982 14.8703 12.7839 14.6809 12.2241V12.2241Z"
        stroke={props.color.value}
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.9005 4.99504L8.54975 8.50248C8.52151 8.78492 8.28384 9 8 9C7.71616 9 7.47849 8.78492 7.45025 8.50248L7.0995 4.99504C7.04623 4.46228 7.46459 4 8 4C8.53541 4 8.95377 4.46228 8.9005 4.99504ZM8 12C8.55228 12 9 11.5523 9 11C9 10.4477 8.55228 10 8 10C7.44772 10 7 10.4477 7 11C7 11.5523 7.44772 12 8 12Z"
        fill={props.color.value}
      />
    </svg>
  );
}

export default Alert;
