import PropTypes from 'prop-types';

function Formula(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 2.5H3C3.55228 2.5 4 2.94772 4 3.5V11.5C4 12.6046 4.89543 13.5 6 13.5H11C12.1046 13.5 13 12.6046 13 11.5V2.5"
        stroke={props.color.value}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4 10C4.33333 9.66667 4.9 9 6.5 9C8.5 9 10 11 13 11"
        stroke={props.color.value}
        strokeWidth="1.5"
      />
      <path
        d="M13 4H11"
        stroke={props.color.value}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M13 8H11"
        stroke={props.color.value}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <circle cx="7" cy="6" r="1" fill={props.color.value} />
      <circle cx="8.5" cy="3.5" r="0.5" fill={props.color.value} />
    </svg>
  );
}

Formula.propTypes = {
  color: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  size: PropTypes.number,
};

export default Formula;
