import { convertSnakeToCamelCase } from 'design-system/utils';
import useApi from 'services/api/useApi';

const useRequirementGroups = (retailerId) => {
  const { data, loading, error } = useApi(
    `/api/v4/retailers/${retailerId}/policies`,
    {
      enabled: !!retailerId,
    }
  );
  return {
    data: convertSnakeToCamelCase(data),
    error,
    loading,
  };
};

export default useRequirementGroups;
