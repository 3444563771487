import React, { createContext, useContext, useState } from 'react';
import { UrlParam } from 'design-system/data';
import { convertSnakeToCamelCase } from 'design-system/utils';
import { useApp } from 'context/AppContext';
import useQueryParams from 'hooks/useQueryParams';
import { TableDataResponseRaw, TableDataResponse } from '../data/types';

interface SKUEligibilityContextType {
  data: TableDataResponse;
  loading?: boolean;
  error?: any;
  selectedValues: string[];
  setSelectedValues: (values: string[]) => void;
}

const SKUEligibilityContext = createContext<
  SKUEligibilityContextType | undefined
>(undefined);

export const useSKUEligibilityContext = () => {
  const context = useContext(SKUEligibilityContext);
  if (!context) {
    throw new Error(
      'useSKUEligibilityContext must be used within a SKUEligibilityProvider'
    );
  }
  return context;
};

type ProgramStatusFilterPayload = string[];
type PillarStatusFilterPayload = Record<string, string[]>;

const UrlFilterParams = {
  ProgramStatus: 'program_status',
};

type QueryParamsWithValues = {
  [key: string]: { value: string | undefined };
} & {
  _asValues: () => Record<string, string | undefined>; // Explicitly define _asValues method
};

export const SKUEligibilityProvider: React.FC<{
  children: React.ReactNode;
  retailerId: number;
  brandId: number;
  policyId: number;
}> = ({ children, retailerId, brandId, policyId }) => {
  const { useApi } = useApp();
  const params = useQueryParams(Object.values(UrlParam));
  const filterParams = useQueryParams(Object.values(UrlFilterParams));

  // Helper function to safely extract values from filterParams
  const getInitialSelectedValues = (): string[] => {
    const filterValues = filterParams
      ? (filterParams as QueryParamsWithValues)._asValues()
      : {};
    if (!filterValues.program_status) return [];
    // Ensure program_status is an array
    return filterValues.program_status.split(',');
  };

  // Initialize selectedValues with safe default value
  const [selectedValues, setSelectedValues] = useState<string[]>(
    getInitialSelectedValues()
  );

  const programStatusFilterPayload: ProgramStatusFilterPayload =
    selectedValues.filter((value) => !value.includes(':'));

  const pillarStatusFilterPayload: PillarStatusFilterPayload = {};

  // creatge pillar status filter payload as object
  selectedValues
    .filter((value) => value.includes(':'))
    .forEach((value) => {
      const [pillarId, status] = value.split(':');
      if (!pillarStatusFilterPayload[pillarId]) {
        pillarStatusFilterPayload[pillarId] = [];
      }
      pillarStatusFilterPayload[pillarId].push(status);
    });

  const { data, error, loading } = useApi(
    `/api/v4/retailers/${retailerId}/consumer_brands/${brandId}/policies/${policyId}/sku_eligibility_results`,
    {
      enabled: Boolean(retailerId && brandId && policyId),
      param: {
        ...(params ? (params as QueryParamsWithValues)._asValues() : {}),
      },
      fetcherOptions: {
        method: 'POST',
        body: {
          program_status: programStatusFilterPayload,
          pillar_status: pillarStatusFilterPayload,
        },
      },
    }
  );

  const typedData = data as TableDataResponseRaw;
  const convertedData: TableDataResponse = convertSnakeToCamelCase(typedData);

  const value = {
    data: convertedData,
    loading,
    error,
    selectedValues,
    setSelectedValues,
  };

  return (
    <SKUEligibilityContext.Provider value={value}>
      {children}
    </SKUEligibilityContext.Provider>
  );
};
