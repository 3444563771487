import { convertSnakeToCamelCase } from 'design-system/utils';
import { useApp } from 'context/AppContext';

const useBrandSubmissions = () => {
  const { useApi, selectedRetailerId } = useApp();
  const { data, loading, error } = useApi(
    `/api/v4/retailers/${selectedRetailerId}/policy_submissions`,
    {
      enabled: !!selectedRetailerId,
    }
  );
  return {
    data: convertSnakeToCamelCase(data),
    loading,
    error,
  };
};

export default useBrandSubmissions;
