import {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { EditDialog } from './components/EditDialog';
import useFetcher from 'services/api/useFetcher';
import { useHistory, useLocation } from 'react-router-dom';
import { convertCamelToSnakeCase } from 'design-system/utils/case';
import { useFDAProductsContext } from 'views/FDARegistration/Products/Context';
import { handleStateChange } from 'views/FDARegistration/shared/utils';
import { SuccessDialog } from './components/SuccessDialog';
import { ConfirmationDialog } from './components/ConfirmationDialog';
import { useSnackbar } from 'notistack';

const FDASubmissionConfirmationContext = createContext(null);

export const useFDASubmissionConfirmationContext = () => {
  const ctx = useContext(FDASubmissionConfirmationContext);
  if (!ctx) {
    throw new Error('Must be used within an FDASubmissionConfirmationContext');
  }
  return ctx;
};

export const FDASubmissionConfirmationProvider = ({ children }) => {
  const [editOpen, setEditOpen] = useState(false);
  const [formState, setFormState] = useState({
    responsiblePersonPhoneCountryCode: '+1',
    contactPhoneCountryCode: '+1',
    isSmallBusiness: false,
  });
  const [formStateValid] = useState(false);
  const [editPage, setEditPage] = useState(0);
  const fetcher = useFetcher();
  const history = useHistory();
  const { selectedSkuKeys } = useFDAProductsContext();
  const location = useLocation();
  const [
    submissionConfirmationDialogOpen,
    setSubmissionConfirmationDialogOpen,
  ] = useState(false);
  const [submissionConfirmedDialogOpen, setSubmissionConfirmedDialogOpen] =
    useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const directToProducts = useCallback(() => {
    history.push('/fda-registration/product-registration');
    setSubmissionConfirmedDialogOpen(false);
  }, [history]);

  const directToSubmissions = useCallback(
    () => history.push('/fda-registration/submissions'),
    [history]
  );

  useEffect(() => {
    if (
      location.pathname.includes('submission-confirmation') &&
      !formStateValid
    ) {
      setEditOpen(true);
    }
  }, [location.pathname, formStateValid]);

  const handleFormStateChange = useCallback((fieldOrPairs, value) => {
    handleStateChange(setFormState, fieldOrPairs, value);
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      const businessType =
        formState.businessType === 'Distributor (most common)'
          ? 'Distributor'
          : formState.businessType;
      const convertedData = convertCamelToSnakeCase({
        ...formState,
        businessType,
      });
      await fetcher('api/v4/certification_cosmetic_product_listings', {
        method: 'post',
        body: { ...convertedData, cosmetic_product_ids: selectedSkuKeys },
      });
      setSubmissionConfirmationDialogOpen(false);
      setSubmissionConfirmedDialogOpen(true);
      enqueueSnackbar('SKUs submitted', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  }, [directToProducts, fetcher, formState, selectedSkuKeys]);

  const handlePhoneNumberChange = useCallback((value) => {
    const inputPhoneNumber = value.replace(/\D/g, ''); // Remove non-numeric characters
    let formattedPhoneNumber = '';

    if (inputPhoneNumber.length <= 3) {
      formattedPhoneNumber = `(${inputPhoneNumber}`;
      handleFormStateChange('contactPhoneAreaCode', inputPhoneNumber);
    } else if (inputPhoneNumber.length <= 6) {
      formattedPhoneNumber = `(${inputPhoneNumber.slice(
        0,
        3
      )}) ${inputPhoneNumber.slice(3)}`;
      handleFormStateChange('contactPhoneExchange', inputPhoneNumber.slice(3));
    } else {
      formattedPhoneNumber = `(${inputPhoneNumber.slice(
        0,
        3
      )}) ${inputPhoneNumber.slice(3, 6)}-${inputPhoneNumber.slice(6, 10)}`;
      handleFormStateChange(
        'contactPhoneExchange',
        `${inputPhoneNumber.slice(3, 6)}${inputPhoneNumber.slice(6, 10)}`
      );
    }

    handleFormStateChange('phoneNumber', formattedPhoneNumber);
  }, []);

  const handleResponsiblePersonPhoneNumberChange = useCallback((value) => {
    const inputPhoneNumber = value.replace(/\D/g, ''); // Remove non-numeric characters
    let formattedPhoneNumber = '';

    if (inputPhoneNumber.length <= 3) {
      formattedPhoneNumber = `(${inputPhoneNumber}`;
      handleFormStateChange('responsiblePersonPhoneAreaCode', inputPhoneNumber);
    } else if (inputPhoneNumber.length <= 6) {
      formattedPhoneNumber = `(${inputPhoneNumber.slice(
        0,
        3
      )}) ${inputPhoneNumber.slice(3)}`;
      handleFormStateChange(
        'responsiblePersonPhoneExchange',
        inputPhoneNumber.slice(3)
      );
    } else {
      formattedPhoneNumber = `(${inputPhoneNumber.slice(
        0,
        3
      )}) ${inputPhoneNumber.slice(3, 6)}-${inputPhoneNumber.slice(6, 10)}`;
      handleFormStateChange(
        'responsiblePersonPhoneExchange',
        `${inputPhoneNumber.slice(3, 6)}${inputPhoneNumber.slice(6, 10)}`
      );
    }

    handleFormStateChange('responsiblePersonPhoneNumber', formattedPhoneNumber);
  }, []);

  const context = useMemo(() => {
    return {
      editOpen,
      setEditOpen,
      editPage,
      setEditPage,
      handleFormStateChange,
      formState,
      handleSubmit,
      directToProducts,
      setFormState,
      handlePhoneNumberChange,
      handleResponsiblePersonPhoneNumberChange,
      submissionConfirmationDialogOpen,
      setSubmissionConfirmationDialogOpen,
      submissionConfirmedDialogOpen,
      setSubmissionConfirmedDialogOpen,
      directToSubmissions,
    };
  }, [
    editOpen,
    setEditOpen,
    editPage,
    setEditPage,
    formState,
    handleSubmit,
    setFormState,
    directToProducts,
    handlePhoneNumberChange,
    handleResponsiblePersonPhoneNumberChange,
    submissionConfirmationDialogOpen,
    setSubmissionConfirmationDialogOpen,
    submissionConfirmedDialogOpen,
    setSubmissionConfirmedDialogOpen,
    directToSubmissions,
  ]);

  return (
    <FDASubmissionConfirmationContext.Provider value={context}>
      {children}
      <EditDialog />
      <SuccessDialog />
      <ConfirmationDialog />
    </FDASubmissionConfirmationContext.Provider>
  );
};
