import {
  createContext,
  useContext,
  useMemo,
  ReactNode,
  FC,
  useCallback,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { salesChannelOptions } from 'layouts/BrandProductViewSidebar/EditProductModal/constants';
import { useApp } from 'context/AppContext';
import { OptionType, ToastKind } from 'design-system/components';
import { createProduct, publishProduct } from '../useCreateProduct';
import { PATHS } from 'constants/index';
import { createNameToIdMap } from 'utils/nameIdMapper';

export interface CreateProductContextFormDataType {
  salesChannelOptions: OptionType[];
  BPC: {
    intendedUseOptions: OptionType[];
    intendedApplicationOptions: OptionType[];
    formlaFormatOptions: OptionType[];
    productCategoryOptions: OptionType[];
    additionalProductDetailsOptions: OptionType[];
  };
  Food: {
    productCategoryOptions: OptionType[];
    additionalProductDetailsOptions: OptionType[];
  };
  Other: {
    productCategoryOptions: OptionType[];
  };
}
export interface CreateProductContextType {
  formData: CreateProductContextFormDataType;
  handleSubmit: (formData: any) => void;
  handleCancel: () => void;
  loading: boolean;
  toastData: { title: string; kind: string; message: any }[];
}

const CreateProductContext = createContext<CreateProductContextType | null>(
  null
);

export const useCreateProductContext = () => {
  const ctx = useContext(CreateProductContext);

  if (!ctx) {
    throw new Error('Must be used within a CreateProductContext');
  }
  return ctx;
};

interface CreateProductProviderProps {
  children: ReactNode;
}

export const CreateProductProvider: FC<CreateProductProviderProps> = ({
  children,
}) => {
  const { retailerBrand, useApi } = useApp();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [toastData, setToastData] = useState<
    { title: string; kind: string; message: any }[]
  >([]);
  const retailerId = useMemo(
    () => retailerBrand?.retailer?.id,
    [retailerBrand]
  );
  const allProductTypesAttributesData = useApi(
    '/api/v4/product/attributes/all_product_types',
    {
      enabled: true,
      param: {
        retailer_id: retailerId,
      },
    }
  );

  const productTypes = useMemo(() => {
    return allProductTypesAttributesData?.data?.product_types;
  }, [allProductTypesAttributesData]);

  const nameToIdMap = useMemo(() => {
    if (!productTypes) return {};
    return createNameToIdMap(productTypes);
  }, [productTypes]);

  const onSubmit = useCallback(
    async (formData: any) => {
      setLoading(true);
      const productTypeId = productTypes?.find(
        (type: any) =>
          type.name?.toLowerCase() === formData['type']?.toLowerCase()
      )?.id;
      const newProductResponse: { [x: string]: any; error?: any } =
        await createProduct(formData, productTypeId).catch((error) => {
          console.error(error);
          setLoading(false);
          setToastData([
            ...toastData,
            {
              title: 'Uh oh!',
              kind: ToastKind.Error,
              message:
                'Failed to create product, please try again or contact support@noviconnect.com',
            },
          ]);
        });

      if (newProductResponse && !newProductResponse.error) {
        // todo: send body to publishProduct in scalable manner.
        publishProduct(
          retailerId,
          newProductResponse.id,
          formData['sku_id'],
          formData['product_category_id'],
          formData['product_line'],
          formData['retailer_sales_channel']
        )
          .then((res) => {
            if (!res?.error) history.push(PATHS.retailerBrandProducts);
          })
          .catch((error) => {
            console.error(error);
            setLoading(false);
            setToastData([
              ...toastData,
              {
                title: 'Uh oh!',
                kind: ToastKind.Error,
                message:
                  'Product created but failed to publish to retailer, please try again or contact support@noviconnect.com',
              },
            ]);
          });
      }
      setLoading(false);
    },
    [history, productTypes, retailerId, toastData]
  );

  const context = useMemo((): CreateProductContextType => {
    const bpcId = nameToIdMap['BPC'];
    const foodId = nameToIdMap['Food'];
    const otherId = nameToIdMap['Other'];

    const bpcData =
      allProductTypesAttributesData?.data?.attributes_by_product_type[bpcId];
    const foodData =
      allProductTypesAttributesData?.data?.attributes_by_product_type[foodId];
    const otherData =
      allProductTypesAttributesData?.data?.attributes_by_product_type[otherId];

    return {
      loading,
      handleSubmit: onSubmit,
      handleCancel: () => {
        history.goBack();
      },
      formData: {
        salesChannelOptions: salesChannelOptions || [],
        BPC: {
          intendedUseOptions: bpcData?.application_times,
          intendedApplicationOptions: bpcData?.product_uses,
          formlaFormatOptions: bpcData?.product_forms,
          productCategoryOptions: bpcData?.product_categories,
          additionalProductDetailsOptions: bpcData?.product_claims,
        },
        Food: {
          productCategoryOptions: foodData?.product_categories,
          additionalProductDetailsOptions: foodData?.product_claims,
        },
        Other: {
          productCategoryOptions: otherData?.product_categories,
        },
      },
      toastData,
    };
  }, [allProductTypesAttributesData, onSubmit, loading, toastData]);

  return (
    <CreateProductContext.Provider value={context}>
      {children}
    </CreateProductContext.Provider>
  );
};
