import {
  Icon,
  IconName,
  Text,
  TextKind,
  TextElement,
  LoadingSpinner,
} from 'design-system/components';
import styles from './sku-empty-state.module.scss';
import { Color } from 'design-system/data';

import ActionsRow from '../../shared/components/ActionsRow';

export const SkuEmptyState = ({ loading }) => {
  return (
    <div className={styles['empty-section']}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div style={{ width: '114px' }}>
            <Icon name={IconName.NoContent} />
          </div>
          <Text kind={TextKind.Display2XSMedium} element={TextElement.H2}>
            Nothing to see here yet!
          </Text>
          <Text
            kind={TextKind.TextSM}
            element={TextElement.P}
            color={Color.Neutral600}
            className={styles['empty-state-text']}
          >
            Upload or import your SKUs from your retailer portals and formula
            workbench to begin the listings process.
          </Text>
          <ActionsRow variant="initial" />
        </>
      )}
    </div>
  );
};
