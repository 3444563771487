export const DASHBOARD_NEXT_STEPS_DEFAULT_TEXT = {
  signContracts:
    'The contracts are legal documents from your retailer, sent to your email from contracts@noviconnect.com. These contracts must be signed as part of your work in Novi. Once signed, you can review your contracts in Documents.',
  attestations:
    'You must complete product verification questions, or attestations, for each SKU that you would like to make claims for.',
  payment:
    'Please complete payment for your Novi Connect subscription to fully enroll in this program. If you have not already received an invoice or if you have any questions, please reach out to us at support@noviconnect.com.',
  chargebee:
    'You’re on your way to unlocking the remainder of your program requirements. Please complete payment for your Novi subscription to fully enroll in this program, or take a moment to review your SKUs before proceeding.',
};

export const DASHBOARD_NEXT_STEPS_HEADINGS = {
  signContracts: 'Sign the contracts',
  attestations: 'Input your attestations',
  payment: 'Complete payment',
  chargebee: 'Complete payment',
};

export const DASHBOARD_NEXT_STEPS_CTA_TEXT = {
  signContracts: 'Review',
  attestations: 'Attest',
  payment: 'Get in touch',
  chargebee: 'Pay now',
};
