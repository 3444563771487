import PropTypes from 'prop-types';

function Status({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99528 2H8.00471C8.557 2.00043 9.00437 2.44849 9.00394 3.00077C9.00351 3.55306 8.55545 4.00043 8.00317 4H7.99683C7.44454 4.00043 6.99648 3.55306 6.99606 3.00077C6.99563 2.44849 7.443 2.00043 7.99528 2ZM10.8251 3.75514C11.2153 3.36431 11.8485 3.36382 12.2393 3.75404L12.246 3.76068C12.6362 4.15151 12.6357 4.78467 12.2449 5.17489C11.854 5.56511 11.2209 5.56462 10.8306 5.17379L10.8262 5.16935C10.4354 4.77913 10.4349 4.14597 10.8251 3.75514ZM5.17489 3.75514C5.56511 4.14597 5.56462 4.77913 5.17379 5.16935L5.16935 5.17379C4.77913 5.56462 4.14597 5.56511 3.75514 5.17489C3.36431 4.78467 3.36382 4.15151 3.75404 3.76068L3.76068 3.75404C4.15151 3.36382 4.78467 3.36431 5.17489 3.75514ZM3.00077 6.99606C3.55306 6.99648 4.00043 7.44454 4 7.99683V8.00317C4.00043 8.55545 3.55306 9.00351 3.00077 9.00394C2.44849 9.00437 2.00043 8.557 2 8.00471V7.99528C2.00043 7.443 2.44849 6.99563 3.00077 6.99606ZM12.9992 6.99606C13.5515 6.99563 13.9996 7.443 14 7.99528V8.00471C13.9996 8.557 13.5515 9.00437 12.9992 9.00394C12.4469 9.00351 11.9996 8.55545 12 8.00317V7.99683C11.9996 7.44454 12.4469 6.99648 12.9992 6.99606ZM3.75514 10.8251C4.14597 10.4349 4.77913 10.4354 5.16935 10.8262L5.17379 10.8306C5.56462 11.2209 5.56511 11.854 5.17489 12.2449C4.78467 12.6357 4.15151 12.6362 3.76068 12.246L3.75404 12.2393C3.36382 11.8485 3.36431 11.2153 3.75514 10.8251ZM6.99606 12.9992C6.99648 12.4469 7.44454 11.9996 7.99683 12H8.00317C8.55545 11.9996 9.00351 12.4469 9.00394 12.9992C9.00437 13.5515 8.557 13.9996 8.00471 14H7.99528C7.443 13.9996 6.99563 13.5515 6.99606 12.9992Z"
        fill={color.value}
      />
      <path
        d="M12.561 10.4373C13.1457 11.021 13.1464 11.9681 12.5627 12.5528L12.5528 12.5627C11.9681 13.1464 11.021 13.1457 10.4373 12.561C9.85359 11.9764 9.85433 11.0293 10.4389 10.4456L10.4456 10.439C11.0293 9.85433 11.9764 9.85359 12.561 10.4373Z"
        fill={color.value}
      />
    </svg>
  );
}

Status.propTypes = {
  color: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  size: PropTypes.number,
};

export default Status;
