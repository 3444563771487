import { useState, forwardRef } from 'react';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components/macro';
import { color } from 'styled-system';
import { useSnackbar } from 'notistack';
import {
  AppBar,
  Toolbar as MuiToolbar,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  Avatar as MuiAvatar,
} from '@material-ui/core';

import {
  Link,
  LinkKind,
  Icon as DSIcon,
  IconName,
  Text,
  TextElement,
  TextKind,
  Hamburger,
} from 'design-system/components';
import { Color } from 'design-system/data';

import fetcher from 'services/api/fetcher';
import { useAnalyticsEvent } from 'services/analytics/AnalyticsContext';
import useTrackElement from 'hooks/useTrackElement';
import { PATHS, ROLES } from 'constants/index';
import { header } from 'constants/layout';
import { useUserInvitation } from 'context/UserInvitationContext';
import { useNoviEmailOnly } from 'context/NoviEmailOnlyContext';
import { useNavContext } from 'layouts/ConnectedSidenav/context';

import Icon from 'components/Icon';
import Spacer from 'components/Spacer';
import { useApp } from 'context/AppContext';
import Divider from 'components/Divider';
import CircularProgress from 'components/CircularProgress';
import Flex from 'components/Flex';
import UnstyledNoviLogo from 'components/NoviLogo';
import SubNavBanner from 'layouts/NoviNav/SubNavBanner';

const brandPalette = {
  teal: '#74EDEB',
  softPink: '#F8AEAE',
};

export default function SiteHeader() {
  const { organization, user } = useApp();
  const { setHideMobileNav, hideMobileNav, hideNav } = useNavContext();

  const hasSupplierNav = user?.hasRole(ROLES.supplier);

  const brandLink = user?.hasFF('retailer_brand_onboarding')
    ? PATHS.portalSelection
    : PATHS.projectDashboard;
  const logoLink =
    organization?.type === 'ConsumerBrand' ? brandLink : PATHS.base;

  return (
    <>
      <StyledAppBar elevation={0} position="sticky">
        <Toolbar>
          <NoviLogo link={logoLink} />
          <div>
            <Menu />
            {(hasSupplierNav ||
              user?.hasRole(ROLES.retailer) ||
              user?.hasRole(ROLES.brand) ||
              user?.hasRole(ROLES.formulator)) &&
              !hideNav && (
                <Hamburger
                  className="mobile-button"
                  isOpen={!hideMobileNav}
                  onClick={() => setHideMobileNav(!hideMobileNav)}
                />
              )}
          </div>
        </Toolbar>
      </StyledAppBar>
      {/* Enable/Disable when new banner is wanted, per Product team. */}
      {hideMobileNav && <SubNavBanner />}
    </>
  );
}

const StyledAppBar = styled(AppBar)`
  &.MuiAppBar-root {
    max-height: ${header.height};
    background-color: #251489;
    z-index: 1201;
  }
  & .MuiToolbar-root {
    display: flex;
    align-items: center;
  }

  .mobile-button {
    display: none;

    @media (max-width: 744px) {
      display: block;
    }
  }
`;

const linkStyles = {
  textDecoration: 'none',
  color: Color.Neutral700.value,
  fontWeight: 400,
  display: 'flex',
  alignItems: 'center',
};

function Menu() {
  const [isOpen, setOpen] = useState(false);
  const { ref, element } = useTrackElement();
  const [selectedRoleId, setSelectedRoleId] = useState(undefined);
  const { user, auth0 } = useApp();
  const { openModal: openUserInvitationModal } = useUserInvitation();
  const { analyticsWrap } = useAnalyticsEvent();
  const { openModal: openNoviEmailOnlyModal } = useNoviEmailOnly();

  const logout = () => {
    if (auth0) {
      auth0.client.logout({ returnTo: window.location.origin });
    }
  };

  return (
    <Welcome
      ref={ref}
      onClick={() => {
        if (!selectedRoleId) {
          setOpen(!isOpen);
        }
      }}
      data-cy="settings-dropdown"
    >
      Hi, {user?.nickname}
      <Spacer size={8} />
      <DSIcon
        size={12}
        name={IconName.Chevron}
        direction="down"
        color={Color.White}
      />
      <DropdownMenu open={isOpen} anchorEl={element}>
        <MultiOrgItems
          triggerClose={() => setOpen(false)}
          selectedRoleId={selectedRoleId}
          setSelectedRoleId={setSelectedRoleId}
        />
        <Link
          data-cy="settings-page-link"
          to={PATHS.userSettings}
          style={linkStyles}
          kind={LinkKind.Internal}
        >
          <MenuItem icon="Settings" text="User Preferences" />
        </Link>
        <MenuItem
          icon="Exit"
          text="Log Out"
          onClick={logout}
          data-cy="logout-btn"
        />
        <MenuItem
          text="Invite a User"
          onClick={() =>
            analyticsWrap(
              {
                label: 'user_invitation_modal',
                category: 'global_nav',
                action: 'click_user_invitation_cta',
              },
              openUserInvitationModal
            )()
          }
        />
        {user?.is_novi_email && (
          <MenuItem
            onClick={openNoviEmailOnlyModal}
            text="Novi Email Only Dashboard"
          />
        )}
      </DropdownMenu>
    </Welcome>
  );
}

function MultiOrgItems({ triggerClose, selectedRoleId, setSelectedRoleId }) {
  const { organization, user, refetchUser, setSelectedRetailerBrandId } =
    useApp();
  const isMultiOrg = user?.user_roles?.length > 1;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const switchRole = async (role) => {
    // PDCT-785 React batching may disrupt this.
    setSelectedRoleId(role.id);
    try {
      await fetcher('/api/v4/users/switch_active_role', {
        method: 'post',
        body: { 'user_role_id': role.id },
        preventAbort: true,
      });
      await refetchUser();
      setSelectedRetailerBrandId(undefined);
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    } finally {
      enqueueSnackbar('Successfully switched role', { variant: 'success' });
      history.push(PATHS.base);
      setSelectedRoleId(undefined);
      triggerClose();
    }
  };

  if (!isMultiOrg && !selectedRoleId) {
    return null;
  }

  const otherRoles = user?.user_roles?.filter((role) => !role.is_active);

  return (
    <>
      <CurrentUserSummary
        variant="column"
        name={organization.name}
        description={organization?.type}
        bg={
          organization?.type === 'ConsumerBrand'
            ? brandPalette.softPink
            : brandPalette.teal
        }
      />
      <Divider margin="8px" color="rgba(0, 0, 0, .12)" />
      {otherRoles.map((role) => (
        <MenuItem key={role.id}>
          <Link
            kind={LinkKind.Internal}
            style={linkStyles}
            to={PATHS.base}
            onClick={() => {
              switchRole(role);
            }}
            disabled={selectedRoleId}
            key={role?.id}
            data-cy={`switch-role-link-${role?.organization?.type}`}
          >
            <UserRoleSummary
              variant="row"
              name={role?.organization.name}
              description={role?.organization?.type}
              bg={
                role?.organization?.type === 'ConsumerBrand'
                  ? brandPalette.softPink
                  : brandPalette.teal
              }
              loading={selectedRoleId === role?.id}
            />
          </Link>
        </MenuItem>
      ))}
      <Divider margin="8px" color="rgba(0, 0, 0, .12)" />
    </>
  );
}

const Avatar = styled(MuiAvatar)`
  font-size: 16px;
  font-family: Circular;
  color: rgba(26, 43, 96, 0.96);
  ${color}
`;

const RoleSummary = styled((props) => {
  const { variant, name, description, children, bg, color, loading, ...rest } =
    props;
  return (
    <div data-variant={variant} {...rest}>
      {loading ? (
        <Flex justifyContent="center" width="100%">
          <CircularProgress size={40} />
        </Flex>
      ) : (
        <Avatar bg={bg} color={color}>
          {name?.slice(0, 2).toUpperCase()}
        </Avatar>
      )}
      <div className="summary">
        <div>{name}</div>
        <div>{description}</div>
      </div>
      {children}
    </div>
  );
})`
  padding: 20px 20px 18px 20px;
  display: flex;
  flex: 1;
  align-items: center;
  grid-gap: 16px;
  justify-content: center;

  &[data-variant='column'] {
    flex-direction: column;
  }

  .summary {
    display: flex;
    flex-direction: column;
  }
  .summary > div:first-child {
    font-size: 16px;
  }
  .summary > div:nth-child(2) {
    font-size: 12px;
  }
`;

const CurrentUserSummary = styled(RoleSummary)`
  .summary {
    justify-content: center;
    align-items: center;
  }
`;

const UserRoleSummary = styled(RoleSummary)`
  div.summary {
    flex: 2;
    margin-left: 20px;
    align-items: left;
  }
`;

const DropdownMenu = styled(({ children, ...rest }) => (
  <MuiMenu
    getContentAnchorEl={undefined}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    open
    {...rest}
  >
    <div>{children}</div>
  </MuiMenu>
))``;

const MenuItem = styled(
  forwardRef((props, ref) => {
    const { icon, text, children, disabled, ...rest } = props;
    return (
      <MuiMenuItem {...rest} ref={ref} disabled={disabled}>
        {icon && (
          <>
            {disabled ? (
              <CircularProgress size={20} />
            ) : (
              <Icon size={20} fill="#546974" name={icon} />
            )}
            <Spacer size={8} />
          </>
        )}
        <Text kind={TextKind.TextMD} element={TextElement.P}>
          {text}
        </Text>
        {children}
      </MuiMenuItem>
    );
  })
)`
  min-width: 225px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex: 1;
  color: ${Color.Neutral700.value};
`;

const Toolbar = styled(MuiToolbar)`
  display: flex;
  justify-content: space-between;

  & > div {
    display: flex;
    align-items: center;
    gap: 16px;
  }
`;

const NoviLogo = styled((props) => (
  <Link to={props.link} kind={LinkKind.Internal}>
    <UnstyledNoviLogo {...props} />
  </Link>
))`
  height: 25px;
  max-height: 60px;
`;

const Welcome = styled.div`
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
