import YourProductsProvider from '../YourProducts/context';
import { PaywallProvider } from '../YourProducts/context/PaywallContext';
import RetailerBrandDashContainer from './RetailerBrandDashContainer';

const RetailerBrandDashboard = () => {
  return (
    <PaywallProvider>
      <YourProductsProvider>
        <RetailerBrandDashContainer />
      </YourProductsProvider>
    </PaywallProvider>
  );
};

export default RetailerBrandDashboard;
