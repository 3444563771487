import { useEffect } from 'react';
import { useApp } from 'context/AppContext';
import * as Sentry from '@sentry/browser';

export default function SentryConfiguration(props) {
  const { children } = props;
  const { user } = useApp();
  useEffect(() => {
    if (user) {
      Sentry.setUser({ id: user.id, email: user.email });
    }
  }, [user]);

  return children;
}
