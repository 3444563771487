import PropTypes from 'prop-types';
import { Color } from 'design-system/data';

function Subdirectory(props) {
  const color = props.color.value ? props.color.value : Color.Black;
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 0V9H9" stroke={color} strokeLinecap="square" />
    </svg>
  );
}

Subdirectory.propTypes = {
  color: PropTypes.shape({ name: PropTypes.string, value: PropTypes.string }),
  size: PropTypes.number,
};

export default Subdirectory;
