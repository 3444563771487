import { useState } from 'react';
import styled from 'styled-components';
import { Autocomplete, Button, ButtonKind } from 'design-system/components';
import Icon from 'components/Icon';
import { useResolveIngredient } from 'views/ConsumerProducts/hooks/useResolveIngredient';
import { useAnalyticsEvent } from 'services/analytics/AnalyticsContext';

const Row = styled(function AddProductRow(props) {
  const { entityId, ingredientName, ingredientId, className } = props;

  const [resolvedOption, setResolvedOption] = useState();
  const { analyticsEvent } = useAnalyticsEvent();

  const { resolve, options, option, setOption, setTerm } = useResolveIngredient(
    {
      initialTerm: ingredientName.toLowerCase(),
      entity_id: entityId,
      ingredient_id: ingredientId,
      afterSuccess: () => {
        setResolvedOption(option);
      },
    }
  );

  const handleAnalyticsAutocompleteClick = () => {
    analyticsEvent({
      category: 'product_upload_flow',
      action: `click_resolvable_options`,
      label: 'formulations',
      entityId,
      selectedValue: ingredientId,
    });
  };

  const handleAnalyticsResolveClick = () => {
    resolve.call();
    analyticsEvent({
      category: 'product_upload_flow',
      action: `click_resolve`,
      label: 'formulations',
      entityId,
      selectedValue: ingredientId,
    });
  };

  return (
    <div data-active={Boolean(resolvedOption)} className={className}>
      <div className="ing-title">
        {resolvedOption ? resolvedOption.name.toUpperCase() : ingredientName}
      </div>
      <div
        className="action-container"
        data-cy={`autocomplete-${ingredientName}`}
      >
        {!resolvedOption && (
          <div
            className="ga-container"
            onClick={handleAnalyticsAutocompleteClick}
          >
            <Autocomplete
              placeholder="Add Ingredient"
              autocompleteStyle={{ width: '100%', marginRight: '20px' }}
              options={options}
              onChange={setTerm}
              onSelect={setOption}
            />
          </div>
        )}
        <div className="resolve-btn">
          <Button
            disabled={!option || resolvedOption}
            kind={ButtonKind.Pill}
            onClick={handleAnalyticsResolveClick}
            data-cy="resolve-btn"
          >
            {resolvedOption ? (
              <>
                <Icon color="#4AC037" name="CheckMark" size={16} />
                &nbsp;Resolved
              </>
            ) : (
              'Resolve'
            )}
          </Button>
        </div>
      </div>
    </div>
  );
})`
  width: 100%;
  margin: 10px 0;
  min-height: 85px;
  background-color: white;
  padding: 15px;
  border: 1px solid rgb(236, 236, 242);
  border-left: 5px solid #f02121;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgb(0 0 0 / 5%) 0px 3px 6px;
  border-radius: 5px;

  .ga-container {
    margin-right: 20px;
    display: contents;
  }

  .action-container {
    display: flex;
    align-items: center;
    width: 50%;
    justify-content: flex-end;
  }

  &[data-active='true'] {
    border-color: #4ac037;
  }

  .ing-title {
    font-weight: 700;
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    padding-right: 10px;
  }

  .text-field {
    * {
      font-family: var(--font-sans-serif);
      font-weight: normal;
    }
  }
`;

export default Row;
