import { useEffect, useRef } from 'react';
import { hexToCSSFilter } from 'hex-to-css-filter';

export default function Icon(props) {
  const iconRef = useRef(null);

  // Common variable name converter (for backwards compatibility)
  function convertCssVariableToHex(variable) {
    if (variable === `var(--brand-color)` || variable === 'brand-blue') {
      return '#3d21e4';
    }
    if (variable === 'black') {
      return '#000000';
    }
    if (variable === 'fail') {
      return '#f02121';
    }
    if (variable === 'missing') {
      return '#ff9416';
    }
    if (variable === 'pass') {
      return '#4ac037';
    }
    if (variable === 'updating') {
      return '#dcdce8';
    }
    return variable.toString();
  }

  useEffect(() => {
    // Due to these not being inline SVGs, we can only alter the color using the filter CSS attribute
    function setFilterValue(color) {
      const hexRegEx = /^#[0-9a-f]{3,6}$/i;
      color = convertCssVariableToHex(color);
      if (color.match(hexRegEx)) {
        iconRef.current.style.filter =
          hexToCSSFilter(color).filter.split(';')[0];
      }
    }
    if (props.size) {
      const size = props.size ? `${props.size}px` : '100%';
      iconRef.current.style.height = size;
      iconRef.current.style.width = size;
    }
    // Only set filter once for all these prop options (for backwards compatibility)
    if (props.fill) {
      setFilterValue(props.fill);
    } else if (props.stroke) {
      setFilterValue(props.stroke);
    } else if (props.color) {
      setFilterValue(props.color);
    }
  }, [props.size, props.fill, props.stroke, props.color]);

  return props.name ? (
    <img
      ref={iconRef}
      src={require(`../assets/icons/${props.name}.svg`)}
      alt={`${props.name} Icon`}
    />
  ) : null;
}
