export default function withLineBreaks(str) {
  if (!str) return null;
  if (typeof str !== 'string') return str;
  return str.split('\r\n').map((item, key) => {
    if (item === '') {
      return <br key={key} />;
    }
    return <p key={key}>{item}</p>;
  });
}
