export const PRODUCT_CATEGORIES = [
  {
    value: 'skin',
    display: 'Skin',
  },
  {
    value: 'hair',
    display: 'Hair',
  },
  {
    value: 'makeup',
    display: 'Makeup',
  },
  {
    value: 'fragrance',
    display: 'Fragrance',
  },
  {
    value: 'sephora_collection',
    display: 'Sephora Collection',
  },
];

export const COUNTRIES = [
  {
    code: 'US',
    display: 'United States',
    region: 'Americas',
  },
  {
    code: 'CA',
    display: 'Canada',
    region: 'Americas',
  },
  {
    code: 'MX',
    display: 'Mexico',
    region: 'Americas',
  },
  {
    code: 'BR',
    display: 'Brazil',
    region: 'Americas',
  },
  {
    code: 'AU',
    display: 'Australia',
    region: 'Asia Pacific',
  },
  {
    code: 'NZ',
    display: 'New Zealand',
    region: 'Asia Pacific',
  },
  {
    code: 'FR',
    display: 'France',
    region: 'Europe',
  },
  {
    code: 'LU',
    display: 'Luxembourg',
    region: 'Europe',
  },
  {
    code: 'MC',
    display: 'Monaco',
    region: 'Europe',
  },
  {
    code: 'IT',
    display: 'Italy',
    region: 'Europe',
  },
  {
    code: 'ES',
    display: 'Spain',
    region: 'Europe',
  },
  {
    code: 'PT',
    display: 'Portugal',
    region: 'Europe',
  },
  {
    code: 'GB',
    display: 'United Kingdom',
    region: 'Europe',
  },
  {
    code: 'DE',
    display: 'Germany',
    region: 'Europe',
  },
  {
    code: 'CH',
    display: 'Switzerland',
    region: 'Europe',
  },
  {
    code: 'DK',
    display: 'Denmark',
    region: 'Europe',
  },
  {
    code: 'SE',
    display: 'Sweden',
    region: 'Europe',
  },
  {
    code: 'PL',
    display: 'Poland',
    region: 'Europe',
  },
  {
    code: 'CZ',
    display: 'Czech Republic',
    region: 'Europe',
  },
  {
    code: 'RO',
    display: 'Romania',
    region: 'Europe',
  },
  {
    code: 'BG',
    display: 'Bulgaria',
    region: 'Europe',
  },
  {
    code: 'RS',
    display: 'Serbia',
    region: 'Europe',
  },
  {
    code: 'GR',
    display: 'Greece',
    region: 'Europe',
  },
  {
    code: 'TR',
    display: 'Turkey',
    region: 'Middle East',
  },
  {
    code: 'SA',
    display: 'Saudi Arabia',
    region: 'Middle East',
  },
  {
    code: 'QA',
    display: 'Qatar',
    region: 'Middle East',
  },
  {
    code: 'AE',
    display: 'United Arab Emirates',
    region: 'Middle East',
  },
  {
    code: 'BH',
    display: 'Bahrain',
    region: 'Middle East',
  },
  {
    code: 'KW',
    display: 'Kuwait',
    region: 'Middle East',
  },
];

export const COUNTRY_REGION_MAP = COUNTRIES.reduce(
  (acc, c) => ({ ...acc, [c.code]: c.region }),
  {}
);

export const SALES_CHANNELS = [
  {
    id: 'retail',
    name: 'Retail',
  },
  {
    id: 'ecommerce',
    name: 'E-Commerce',
  },
];
