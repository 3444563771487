import { UrlParam } from 'design-system/data';
import { convertSnakeToCamelCase } from 'design-system/utils';
import useQueryParams from 'hooks/useQueryParams';
import { useApp } from 'context/AppContext';

export function usePolicyRequirements(requirementId, screeningStatus) {
  const params = useQueryParams(Object.values(UrlParam));
  const { useApi } = useApp();
  const url = `/api/v4/requirements/${requirementId}/product_screening_results`;
  const { data, loading, error } = useApi(url, {
    enabled: !!requirementId,
    param: {
      ...params._asValues(),
      screening_status: screeningStatus,
    },
  });
  return {
    data: convertSnakeToCamelCase(data),
    loading,
    error,
  };
}
