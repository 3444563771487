import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import {
  LoadingOverlay,
  Link,
  LinkKind,
  LinkVariant,
  Text,
  TextKind,
  TextElement,
  Icon,
  IconName,
  PillarOverviewCard,
  ButtonKind,
  PhosphorIcon,
} from 'design-system/components';
import { Color } from 'design-system/data';

import Disclaimer from '../shared/Disclaimer';

import { useApp } from 'context/AppContext';
import elementsSvg from 'assets/illustrations/elements.svg';
import useRequirementGroups from './useRequirementGroups';
import withLineBreaks from 'utils/withLineBreaks';
import styles from './retailerBrandRequirementGroups.module.scss';
import { PATHS } from 'constants/index';

const hasNonEmptyRequirements = (requirementGroup) =>
  requirementGroup?.totalRequirements !== 0;

function RetailerBrandRequirementGroups() {
  const { retailerBrand, hasRetailerBrandResponseFinished } = useApp();
  const history = useHistory();
  const {
    data: retailerPolicies,
    loading,
    error,
  } = useRequirementGroups(retailerBrand?.retailer?.id);

  const hasPillars = useMemo(() => {
    if (!retailerPolicies) return false;
    return retailerPolicies?.policies?.some(
      (policy) => policy.retailerPolicyRequirementGroups.length
    );
  }, [retailerPolicies]);

  if (error) {
    return (
      <div className={styles['message']}>
        <Icon name={IconName.Alert} size={48} color={Color.Red600} />
        <Text kind={TextKind.TextLGMedium} element={TextElement.H2}>
          Something went wrong!
        </Text>
        <Text kind={TextKind.TextSM} element={TextElement.P}>
          Please check back with us later.
        </Text>
      </div>
    );
  }

  const anyLoading = loading || !hasRetailerBrandResponseFinished;

  const nonEmptyFilteredPolicies = retailerPolicies?.policies.filter((policy) =>
    policy.retailerPolicyRequirementGroups.some(hasNonEmptyRequirements)
  );

  return (
    <main>
      <LoadingOverlay show={anyLoading} />
      {hasPillars &&
        nonEmptyFilteredPolicies?.map((policy) => {
          return (
            <section className={styles['pillars-container']}>
              <Text
                kind={TextKind.DisplaySMMedium}
                element={TextElement.H2}
                color={Color.Black}
              >
                {policy.name} Pillars
              </Text>
              {policy.retailerPolicyRequirementGroups
                .filter(hasNonEmptyRequirements)
                .map((requirementGroup) => {
                  const url = `${history.location.pathname}/${requirementGroup.id}/retailer_requirements`;
                  const handleClick = () => {
                    history.push(url);
                  };
                  const progress = [
                    {
                      label: 'Complete',
                      percentage: requirementGroup.percentComplete,
                    },
                  ];
                  return (
                    <PillarOverviewCard
                      id={requirementGroup.id}
                      title={requirementGroup.name}
                      description={withLineBreaks(requirementGroup.description)}
                      onClick={handleClick}
                      imagePath={requirementGroup.imagePath}
                      subPolicies={requirementGroup.subPolicyCount}
                      screeningStatus={requirementGroup.screeningResult}
                      buttonText="Review"
                      buttonKind={ButtonKind.Primary}
                      progress={progress}
                    />
                  );
                })}
            </section>
          );
        })}
      {((!hasPillars && !anyLoading) || !nonEmptyFilteredPolicies?.length) && (
        <div className={styles['message']}>
          <img src={elementsSvg} alt="There's nothing here" />
          <Text kind={TextKind.TextLGSemibold} element={TextElement.H2}>
            Nothing to see here yet!
          </Text>
          <Text kind={TextKind.TextSM} element={TextElement.P}>
            Upload or import your SKUs to begin the attestation process.
          </Text>
          <Link
            variant={LinkVariant.Alternate}
            kind={LinkKind.Internal}
            to={PATHS.retailerBrandProducts}
          >
            Upload your SKUs <PhosphorIcon iconName="ArrowRight" size={18} />
          </Link>
        </div>
      )}
      {retailerBrand?.retailer?.disclaimer && (
        <Disclaimer text={retailerBrand?.retailer?.disclaimer} />
      )}
    </main>
  );
}

export default RetailerBrandRequirementGroups;
