export const InputKind = {
  Text: 'text',
  Number: 'number',
  Password: 'password',
  Email: 'email',
  Url: 'url',
  Tel: 'tel',
  Search: 'search',
  Date: 'date',
  Time: 'time',
};
