export type PillColorNames =
  | 'Blue'
  | 'BrandNeon'
  | 'BrandPink'
  | 'BrandPrimary'
  | 'BrandSecondary'
  | 'Dark'
  | 'DarkGray'
  | 'Default'
  | 'ErrorColor'
  | 'Gray'
  | 'Green'
  | 'LightGray'
  | 'Orange'
  | 'Purple'
  | 'Red'
  | 'Success'
  | 'Warning';

export type PillColorType = Record<PillColorNames, string>;

export const PillColor: PillColorType = {
  Blue: 'blue',
  BrandNeon: 'brand-neon',
  BrandPink: 'brand-pink',
  BrandPrimary: 'brand-primary',
  BrandSecondary: 'brand-secondary',
  Dark: 'dark',
  DarkGray: 'dark-gray',
  Default: 'default',
  ErrorColor: 'error',
  Gray: 'gray',
  Green: 'green',
  LightGray: 'light-gray',
  Orange: 'orange',
  Purple: 'purple',
  Red: 'red',
  Success: 'success',
  Warning: 'warning',
} as const;
