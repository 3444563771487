import propTypes from 'prop-types';
import {
  Icon,
  IconName,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';
import styles from './material-document.module.scss';
import { Color } from 'design-system/data';
import { applyDateFormat } from 'design-system/utils';

export default function SupplierMaterialDocument({ data }) {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Icon name={IconName.Document} size={16} />
        <Text
          className={styles.title}
          kind={TextKind.TextSMBold}
          element={TextElement.P}
          data-cy="supplier-document"
        >
          {data?.prettyName || data?.name || `untitled document`}
        </Text>
      </div>
      <Text
        className={styles.title}
        kind={TextKind.TextSM}
        element={TextElement.P}
        color={Color.Neutral500}
      >
        {data?.createdAt || data?.lastModifiedDate
          ? applyDateFormat(data.createdAt || data.lastModifiedDate)
          : '-'}
      </Text>
    </div>
  );
}

SupplierMaterialDocument.propTypes = {
  data: propTypes.object,
};
