import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import {
  Text,
  TextElement,
  TextKind,
  Icon,
  IconName,
  Button,
  ButtonKind,
  PhosphorIcon,
} from 'design-system/components';
import { Color } from 'design-system/data';
import styles from './file-upload.module.scss';

function FileUpload(props) {
  const handleFileRemove = (fileName) => {
    const filesArray = props.files.filter((file) => file.name !== fileName);
    props.setFiles(filesArray);
  };

  const onDrop = useCallback(
    (files) => {
      props.onChange(props.multiFileSupport ? files : files[0]);
    },
    [props]
  );
  const { disableFileUpdates } = props;
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const UploadIcon = useMemo(
    () => (
      <PhosphorIcon
        color={Color.Neutral500.value}
        iconName={'CloudArrowUp'}
        size={20}
      />
    ),
    []
  );

  return (
    <div>
      <input disabled={disableFileUpdates} {...getInputProps()} />
      <div
        {...getRootProps()}
        className={`${styles['upload-box']} ${
          isDragActive && styles['drag-active']
        }`}
      >
        <div className={styles['icon-box']}>{UploadIcon}</div>
        <div>
          <Text
            className={styles['center-text']}
            kind={TextKind.TextSM}
            element={TextElement.P}
            color={Color.Neutral600}
          >
            <Text
              kind={TextKind.TextSMSemibold}
              element={TextElement.Span}
              color={Color.Purple700}
            >
              Click to upload
            </Text>{' '}
            or drag and drop{' '}
          </Text>
          <Text
            kind={TextKind.TextXS}
            element={TextElement.P}
            className={styles['center-text']}
            color={Color.Neutral600}
          >
            SVG, PNG, JPG or PDF (max. 100mb)
          </Text>
        </div>
      </div>
      {!!props.files?.length && (
        <ul className={styles['file-list']}>
          {props.files.map((file) => (
            <li
              className={styles['file-container']}
              key={file.name.replace(' ', '_')}
            >
              <button
                onClick={props.onClick ? () => props.onClick(file) : () => {}}
              >
                <span>
                  <Icon name={IconName.Document} />
                  <Text kind={TextKind.TextSMBold} element={TextElement.P}>
                    {file.name}
                  </Text>
                </span>
              </button>
              <Button
                iconName={IconName.Delete}
                onClick={() => handleFileRemove(file.name)}
                onlyIcon
                kind={ButtonKind.Tertiary}
                disabled={disableFileUpdates}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

FileUpload.propTypes = {
  multiFileSupport: PropTypes.bool,
  files: PropTypes.arrayOf(PropTypes.any),
  onChange: PropTypes.func,
  setFiles: PropTypes.func,
  [`data-cy`]: PropTypes.string,
  onClick: PropTypes.func, // handle download from outside the component
  disableFileUpdates: PropTypes.bool,
};

export default FileUpload;
