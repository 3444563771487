import { useState } from 'react';

import {
  ButtonKind,
  Icon,
  IconName,
  Modal,
  ToastKind,
} from 'design-system/components';
import { BrandPolicySubmissionStatus } from 'design-system/data';

import ReviewModalContent from '../ReviewModalContent';
import { reviewBrand } from '../../views/Policies/usePolicies';
import { ReviewTypeInfoType, ReviewType } from '../../types';

interface ReviewModalProps {
  reviewType?: ReviewType;
  submissionId?: number;
  setToastData: (toastData: any) => void;
  openReviewModal: boolean;
  setOpenReviewModal: (openReviewModal: boolean) => void;
}

const ReviewModal = ({
  reviewType = ReviewType.Approve,
  submissionId,
  setToastData,
  openReviewModal,
  setOpenReviewModal,
}: ReviewModalProps) => {
  const [noteValue, setNoteValue] = useState();
  if (!submissionId || !reviewType) return null;

  const reviewTypeInfo: ReviewTypeInfoType = {
    approve: {
      presentLabel: 'Accept',
      pastLabel: 'accepted',
      submissionStatus:
        BrandPolicySubmissionStatus.Approved as keyof typeof BrandPolicySubmissionStatus,
      Icon: <Icon name={IconName.CheckGreenRound} size={48} />,
      buttonKind: ButtonKind.Success as keyof typeof ButtonKind,
    },
    reject: {
      presentLabel: 'Reject',
      pastLabel: 'rejected',
      submissionStatus:
        BrandPolicySubmissionStatus.Rejected as keyof typeof BrandPolicySubmissionStatus,
      Icon: <Icon name={IconName.CancelCircleFilled} size={48} />,
      buttonKind: ButtonKind.Error as keyof typeof ButtonKind,
    },
  };

  return (
    <Modal
      show={openReviewModal}
      title={reviewTypeInfo[reviewType].Icon || ''}
      showActionButtons
      onDone={async () => {
        await reviewBrand(
          submissionId,
          reviewTypeInfo[reviewType].submissionStatus,
          noteValue
        );
        setToastData((prevToastData: any) => [
          ...prevToastData,
          {
            kind: ToastKind.Success,
            title: 'Success',
            message: `You have ${reviewTypeInfo[reviewType].pastLabel} this brand. Refresh your page to see the updated submission status.`,
            autoDismiss: false,
          },
        ]);
        setOpenReviewModal(false);
      }}
      onCancel={() => setOpenReviewModal(false)}
      onClick={() => setOpenReviewModal(false)}
      allowDismiss
      doneCtaLabel={reviewTypeInfo[reviewType].presentLabel}
      doneCtaKind={reviewTypeInfo[reviewType].buttonKind}
    >
      <ReviewModalContent
        onChangeNote={(value) => setNoteValue(value)}
        title={`${reviewTypeInfo[reviewType].presentLabel} this brand?`}
        content={`Once you do this, the brand will be notified that you have ${reviewTypeInfo[reviewType].pastLabel} this submission.`}
      />
    </Modal>
  );
};

export default ReviewModal;
