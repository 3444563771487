export default function Gear({ size, color }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 3H9V5.58569L10.8284 3.75732L12.2426 5.17154L10.4141 7L12.9999 7V9H10.4142L12.2426 10.8284L10.8283 12.2426L9 10.4142V13H7V10.4141L5.17151 12.2426L3.7573 10.8284L5.58569 9H2.99988V7L5.58576 7L3.75727 5.17151L5.17149 3.7573L7 5.58582V3ZM8 9C8.55228 9 9 8.55228 9 8C9 7.44772 8.55228 7 8 7C7.44772 7 7 7.44772 7 8C7 8.55228 7.44772 9 8 9Z"
        fill={color.value}
      />
    </svg>
  );
}
