import styles from '../icon.module.scss';

function Certs(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles[`root-${props.direction}`]}
    >
      <path
        d="M8 3H5C3.89543 3 3 3.89543 3 5V11C3 12.1046 3.89543 13 5 13H11C12.1046 13 13 12.1046 13 11V8"
        stroke={props.color.value}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M6 8.5L7.5 10L12 4"
        stroke={props.color.value}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Certs;
