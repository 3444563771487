import { useCallback } from 'react';
import {
  Button,
  ButtonIconPosition,
  ButtonKind,
  IconDirection,
  IconName,
} from 'design-system/components';
import { SUPPLIER_VETTING_STATUS } from 'constants/index';
import { useModalContext } from 'context/ModalContext';
import { resolveModalData, viewModalData } from './data';
import SupplierModalContents from './modal-contents';

const SupplierPolicyAction = ({ policy, materialID }) => {
  const { launchModal } = useModalContext();
  const isPolicyUnsettled =
    policy?.status === SUPPLIER_VETTING_STATUS.UNSETTLED;

  const clearDocumentData = useCallback(() => null, []);

  return policy?.status !== SUPPLIER_VETTING_STATUS.PASS ? (
    <Button
      kind={ButtonKind.Pill}
      iconName={IconName.Chevron}
      iconDirection={IconDirection.Right}
      iconSize={12}
      iconPosition={ButtonIconPosition.Right}
      onClick={() => {
        launchModal({
          ...(isPolicyUnsettled ? resolveModalData : viewModalData),
          contents: (
            <SupplierModalContents
              data={policy}
              materialID={materialID}
              isPolicyUnsettled={isPolicyUnsettled}
              clearDocumentData={clearDocumentData}
            />
          ),
          onDone: () => clearDocumentData(),
          onCancel: () => clearDocumentData(),
          onClick: () => clearDocumentData(),
        });
      }}
    >
      {isPolicyUnsettled ? 'Resolve' : 'View'}
    </Button>
  ) : null;
};

export default SupplierPolicyAction;
