import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { useApp } from 'context/AppContext';
import { analyticsTrackEvent } from './AnalyticsVendor';
import { customPropertiesMap } from './MixpanelAnalyticsVendor';

export const expectedAnalyticsFields = ['category', 'action', 'label'];

export const optionalAnalyticsFields = ['value', 'page_title', 'url'];

export const validAnalyticsFields = [
  ...optionalAnalyticsFields,
  ...Object.keys(customPropertiesMap),
];

export const AnalyticsContext = createContext();
export const useAnalytics = () => {
  const ctx = useContext(AnalyticsContext);
  if (!ctx) {
    throw new Error('must be used with AnalyticsContextProvider');
  }
  return ctx;
};

export const buildAnalyticsEntityId = (entityArg) => {
  let entityId = [];
  if (!!entityArg && entityArg.constructor === Object) {
    Object.entries(entityArg).forEach(([key, val]) => {
      if (val.value) {
        entityId.push({ type: key, value: val.value });
      }
    });
  }
  if (!!entityArg && entityArg.constructor === Number) {
    entityId.push({ type: null, value: entityArg.toString() });
  }
  return entityId;
};

export const buildAnalyticsResults = (results) => {
  let formattedResults = [];
  if (!!results && results.constructor === Object) {
    Object.entries(results).forEach(([key, val]) => {
      formattedResults.push({ type: key, value: val });
    });
  }
  if (!!results && results.constructor === Array) {
    results.forEach((result) => {
      formattedResults.push({ type: null, value: result });
    });
  }
  return formattedResults;
};

const isNullish = (val) => val === undefined || val === null;

export const formatEventData = (
  props,
  validFields,
  customPropMap,
  title,
  window,
  location,
  insertId
) => {
  for (const field in props) {
    if (!validFields.includes(field)) {
      console.warn({
        message: 'Analytics Warning',
        props,
        warning: `Invalid field "${field}"`,
      });
      delete props[field];
    }
    if (customPropMap && Object.keys(customPropMap).includes(field)) {
      let value;
      switch (true) {
        case field === 'results':
          value = props[field];
          break;
        case field === 'entityId':
          value = buildAnalyticsEntityId(props[field]);
          break;
        case !isNullish(props[field]):
          value = props[field].toString();
          break;
        default:
          value = '(not set)';
      }
      props[customPropMap[field]] = value;
      if (field !== customPropMap[field]) {
        delete props[field];
      }
    }
  }
  const urlSearchParams = new URLSearchParams(window.location.search);
  const url_params = [];
  const utm_campaign = urlSearchParams.get('utm_campaign') || '(not set)';
  const utm_source = urlSearchParams.get('utm_source') || '(not set)';
  const utm_medium = urlSearchParams.get('utm_medium') || '(not set)';
  for (const [name, value] of urlSearchParams) {
    url_params.push({ name: name, value: value });
  }
  const eventState = {
    ...props,
    page_title: title,
    url: location.pathname,
    url_params,
    'UTM Campaign': utm_campaign,
    'UTM Source': utm_source,
    'UTM Medium': utm_medium,
    'Insert ID': insertId,
    data_points: props.data_points || [],
    milliseconds: props.time,
  };
  return eventState;
};

const missingFieldsError = new Error();
missingFieldsError.message = 'Missing expected Analytics fields';
const internalDomains = (
  process.env.REACT_APP_ANALYTICS_INTERNAL_DOMAINS || ''
).split(',');

export const AnalyticsContextProvider = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const pathnameRef = useRef(history.location.pathname);
  const { user } = useApp();
  const [title, setTitle] = useState('');
  const isInternalUser = useMemo(() => {
    if (user) {
      return Boolean(
        internalDomains.find((domain) => user.email.includes(domain))
      );
    }
  }, [user]);

  const eventFn = useCallback(
    (props) => {
      if (isInternalUser) {
        return;
      }
      try {
        if (
          !expectedAnalyticsFields.every((field) =>
            Object.keys(props).includes(field)
          )
        ) {
          throw missingFieldsError;
        }

        const insertId = `${user ? user.id : 'guest'}-${props.action}-${
          props.eventTimestamp
        }`;

        const eventState = formatEventData(
          props,
          validAnalyticsFields,
          customPropertiesMap,
          title,
          window,
          location,
          insertId
        );

        analyticsTrackEvent(eventState);
      } catch (error) {
        let messageObj = { message: 'Analytics Error', props, error };
        Sentry.captureMessage(JSON.stringify(messageObj));
        console.error(messageObj);
      }
    },
    [location, title, user, isInternalUser]
  );

  useEffect(() => {
    // don't fire pageview events if url query or hash changes, only path
    if (pathnameRef.current !== location.pathname) {
      const time = Date.now();
      const pageLoadProps = {
        action: 'page_load',
        label: '(not set)',
        category: '(not set)',
        eventTimestamp: time,
        milliseconds: time,
        page_title: title,
        url: location.pathname,
      };
      eventFn(pageLoadProps);
      pathnameRef.current = location.pathname;
    }
  }, [location, title, eventFn]);

  const context = useMemo(() => {
    return {
      event: eventFn,
      setTitle,
    };
  }, [eventFn]);

  return (
    <AnalyticsContext.Provider value={context}>
      <AnalyticsEventProvider>{children}</AnalyticsEventProvider>
    </AnalyticsContext.Provider>
  );
};

export const AnalyticsEventContext = createContext();
export const useAnalyticsEvent = () => {
  return useContext(AnalyticsEventContext);
};

export function AnalyticsEventProvider(props) {
  const { children, ...param } = props;
  const { event } = useAnalytics();
  const ctx = useAnalyticsEvent();
  const presets = useMemo(() => {
    if (ctx?.presets) {
      return { ...ctx.presets, ...param };
    }
    return param;
  }, [param, ctx]);
  const context = useMemo(() => {
    return {
      presets,
      analyticsEvent: (props) =>
        event({
          ...presets,
          ...props,
          eventTimestamp: props.eventTimestamp || Date.now(),
        }),
      analyticsWrap: (payload, fn) => {
        return (...args) => {
          fn && fn(...args);
          event({
            ...presets,
            ...payload,
            eventTimestamp: payload.eventTimestamp || Date.now(),
          });
        };
      },
      analyticsDelay: (payload, delay = 0) => {
        let tid;
        return {
          fire: () => {
            tid = setTimeout(
              () =>
                event({
                  ...presets,
                  ...payload,
                  eventTimestamp: payload.eventTimestamp || Date.now(),
                }),
              delay
            );
          },
          cancel: () => {
            clearTimeout(tid);
          },
        };
      },
    };
  }, [event, presets]);
  return (
    <AnalyticsEventContext.Provider value={context}>
      {children}
    </AnalyticsEventContext.Provider>
  );
}
