function Kebab(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="4" r="1" fill={props.color.value} />
      <circle cx="8" cy="8" r="1" fill={props.color.value} />
      <circle cx="8" cy="12" r="1" fill={props.color.value} />
    </svg>
  );
}

export default Kebab;
