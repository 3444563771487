import { UrlParam } from 'design-system/data';
import { convertSnakeToCamelCase } from 'design-system/utils';

import { useApp } from 'context/AppContext';
import useQueryParams from 'hooks/useQueryParams';
import {
  SKURequirementsResponseRaw,
  SKURequirementsResponse,
} from '../data/types';

const useSKURequirements = (
  retailerId: number,
  brandId: number,
  policyId: number,
  productId: number,
  screeningStatus: string
): { data: SKURequirementsResponse; loading?: boolean; error?: any } => {
  const { useApi } = useApp();
  const params = useQueryParams(Object.values(UrlParam));
  const endpoint = `/api/v4/retailers/${retailerId}/consumer_brands/${brandId}/policies/${policyId}/sku_eligibility_results/${productId}/requirements`;

  const { data, error, loading } = useApi(endpoint, {
    enabled: Boolean(retailerId && brandId && policyId),
    param: {
      ...(params ? (params as any)._asValues() : {}),
      ...(screeningStatus ? { 'screening_status': screeningStatus } : {}),
    },
  });

  const typedData = data as SKURequirementsResponseRaw;

  return {
    data: convertSnakeToCamelCase(typedData),
    error,
    loading,
  };
};

export default useSKURequirements;
