import {
  Banner,
  BannerKind,
  Link,
  LinkKind,
  Pill,
  PillColor,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';
import { PATHS } from 'constants';
import styles from './unverified-banner.module.scss';

const UnverifiedBanner = () => {
  const ctaUrl = `${PATHS.product_marketplace}?category_key=ingredient`;

  return (
    <Banner kind={BannerKind.Info}>
      <div className={styles.content}>
        <Pill color={PillColor.Orange}>Info</Pill>
        <Text
          className={styles.content}
          kind={TextKind.TextMD}
          element={TextElement.Span}
        >
          Your account is restricted while we get all your approvals sorted.
          Feel free to{' '}
          <Link kind={LinkKind.Internal} to={ctaUrl}>
            check out our suppliers
          </Link>{' '}
          whilst you wait.
        </Text>
      </div>
    </Banner>
  );
};

export default UnverifiedBanner;
