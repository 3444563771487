import {
  Text,
  TextElement,
  TextKind,
  SidebarCta,
  SidebarCtaVariants,
  Button,
  ButtonKind,
} from 'design-system/components';
import { Color } from 'design-system/data';
import { PATHS } from 'constants';
import styles from './brand-certification-sidebar.module.scss';

function CallsToAction() {
  const handleRedirect = (url) => window.open(url, '_blank');
  return (
    <div className={styles.root}>
      <SidebarCta variant={SidebarCtaVariants.Blue}>
        <Text
          kind={TextKind.Display2XSBold}
          element={TextElement.P}
          color={Color.White}
          className={styles['cta-text']}
        >
          Looking for a contract manufacturer? Let Novi help!
        </Text>
        <Button
          kind={ButtonKind.Secondary}
          onClick={() => handleRedirect(PATHS.CM_CTA_URL)}
          color={Color.White}
          className={styles['button-blue']}
        >
          Get in touch now
        </Button>
      </SidebarCta>
      <SidebarCta>
        <Text
          kind={TextKind.TextMDBold}
          element={TextElement.P}
          className={styles['cta-text']}
        >
          Working on a new project?
        </Text>
        <Text
          kind={TextKind.TextMD}
          element={TextElement.P}
          className={styles['cta-text']}
        >
          Refer Novi to your Contract Manufacturer to enhance their research and
          development and sourcing process.
        </Text>
        <Button
          kind={ButtonKind.Secondary}
          onClick={() => handleRedirect(PATHS.NEW_PROJECT_CTA_URL)}
          className={styles['button-white']}
        >
          Get in touch now
        </Button>
      </SidebarCta>
    </div>
  );
}

export default CallsToAction;
