import { useRef, useState } from 'react';
import propTypes from 'prop-types';
import {
  FileUpload,
  LoadingOverlay,
  Text,
  TextElement,
  TextKind,
  Toast,
  ToastKind,
} from 'design-system/components';
import { makeFetcher } from 'services/api/fetcher';
import uploadFile from 'utils/uploadFile';
import styles from './material-file-upload.module.scss';
import { RequestMethod } from 'design-system/data';

export default function SupplierMaterialFileUpload({ materialID, onSuccess }) {
  const fetcher = makeFetcher();
  const toastRef = useRef();
  const successToastData = {
    kind: ToastKind.Success,
    title: 'Success',
    msg: 'Upload successful.',
  };
  const [toastData, setToastData] = useState(successToastData);
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);

  async function uploadDocument(files) {
    if (files) {
      setShowLoadingOverlay(true);
      try {
        const blobIds = [];
        await Promise.all(
          files.map(async (file) => blobIds.push(await uploadFile(file)))
        );
        await fetcher(`/api/v4/suppliers/materials/${materialID}/documents`, {
          method: RequestMethod.POST,
          body: { blob_ids: blobIds },
        });
        setToastData(successToastData);
        onSuccess(files);
      } catch (e) {
        setToastData({
          kind: ToastKind.Error,
          title: 'Error',
          msg: 'Something went wrong.',
        });
      }
      setShowLoadingOverlay(false);
      toastRef.current.launchToast();
    }
  }

  return (
    <>
      <LoadingOverlay show={showLoadingOverlay} />
      <Toast ref={toastRef} kind={toastData.kind} title={toastData.title}>
        {toastData.msg}
      </Toast>
      <div className={styles.container}>
        <Text
          className={styles.title}
          kind={TextKind.Display2XSBold}
          element={TextElement.H1}
        >
          Upload document(s)
        </Text>
        <FileUpload
          multiFileSupport
          onChange={uploadDocument}
          data-cy="supplier-doc-upload"
        />
      </div>
    </>
  );
}

SupplierMaterialFileUpload.propTypes = {
  materialID: propTypes.oneOfType([propTypes.string, propTypes.number]),
  onSuccess: propTypes.func,
};
