// @ts-nocheck
import {
  any,
  arrayOf,
  bool,
  func,
  object,
  oneOf,
  string,
  number,
} from 'prop-types';
import {
  Button,
  ButtonKind,
  Text,
  TextElement,
  TextKind,
  LoadingSpinner,
  IconName,
  Icon,
  Link,
  LinkKind,
  LinkVariant,
} from 'design-system/components';
import { Color, ScreeningStatus } from 'design-system/data';

import withLineBreaks from 'utils/withLineBreaks';
import ResolveName from './components/ModalContent/resolve-name';
import AddPercentage from './components/ModalContent/add-percentage';
import AddFunction from './components/ModalContent/add-function';
import Attestation from './components/ModalContent/attestation';
import styles from './cta-modals.module.scss';
import {
  AttestationActionType,
  RequirementActionContentType,
} from '../../RetailerBrandRequirementSummary/constants';
import DocumentUpload from './components/ModalContent/document-upload';
import Composable from './components/ModalContent/composable';
import { useRequirementActions } from 'views/Brands/shared/RequirementActionModalContents/context';

function RequirementActionContent({
  condition,
  actionType,
  onChange,
  triggerValidation,
  handleConditionValidation,
  isRetailer,
  submitRetailerResponse,
  canAttest,
  setHasChanged,
  productId,
  brandId,
}) {
  switch (actionType) {
    case AttestationActionType.UnresolvedIngredent:
      return (
        <ResolveName
          onChange={onChange}
          condition={condition}
          triggerValidation={triggerValidation}
          onValidate={handleConditionValidation}
          canAttest={canAttest}
          setHasChanged={setHasChanged}
        />
      );
    case AttestationActionType.AlternateIngredient:
      if (!condition?.actionDetail?.alternateMaterials?.length) {
        return null;
      }
      return (
        <div className={styles['alternatives-wrapper']}>
          <Text element={TextElement.P} kind={TextKind.TextMDBold}>
            Alternative Ingredients:
          </Text>
          {condition?.actionDetail?.alternateMaterials?.map((altIng) => {
            return (
              <Link
                kind={LinkKind.Internal}
                to={`/brand/discover/ingredient/${altIng.id}`}
                variant={LinkVariant.NewDefault}
              >
                {altIng.name}
              </Link>
            );
          })}
        </div>
      );
    case AttestationActionType.IngredientPercent:
      return (
        <AddPercentage
          condition={condition}
          onChange={onChange}
          triggerValidation={triggerValidation}
          onValidate={handleConditionValidation}
          canAttest={canAttest}
          setHasChanged={setHasChanged}
        />
      );
    case AttestationActionType.IngredientFunction:
      return (
        <AddFunction
          onChange={onChange}
          condition={condition}
          triggerValidation={triggerValidation}
          onValidate={handleConditionValidation}
          canAttest={canAttest}
          setHasChanged={setHasChanged}
        />
      );
    case AttestationActionType.Attestation:
      return (
        <Attestation
          onChange={onChange}
          condition={condition}
          triggerValidation={triggerValidation}
          onValidate={handleConditionValidation}
          isRetailer={isRetailer}
          submitRetailerResponse={submitRetailerResponse}
          canAttest={canAttest}
          setHasChanged={setHasChanged}
        />
      );
    case AttestationActionType.Documentation:
      return (
        <DocumentUpload
          onChange={onChange}
          condition={condition}
          triggerValidation={triggerValidation}
          onValidate={handleConditionValidation}
          isRetailer={isRetailer}
          submitRetailerResponse={submitRetailerResponse}
          canAttest={canAttest}
          initialFiles={
            condition.attestationProof?.documentName
              ? [
                  {
                    name: condition.attestationProof?.documentName,
                    path: condition.attestationProof?.documentUrl,
                  },
                ]
              : null
          }
          setHasChanged={setHasChanged}
        />
      );
    case AttestationActionType.Composable:
      return (
        <Composable
          onChange={onChange}
          condition={condition}
          triggerValidation={triggerValidation}
          onValidate={handleConditionValidation}
          isReadOnly={!canAttest}
          setHasChanged={setHasChanged}
        />
      );
    case AttestationActionType.EditProduct:
      return (
        <div>
          <Text kind={TextKind.TextLGSemibold} element={TextElement.H3}>
            Edit product details.
          </Text>
          <Link
            kind={LinkKind.Internal}
            to={`/brand/${brandId}/product/${productId}/ingredients`}
            variant={LinkVariant.NewDefault}
          >
            Please add missing product details
          </Link>
        </div>
      );
    default:
      return null;
  }
}

RequirementActionContent.propTypes = {
  conditions: arrayOf(any),
  actionType: oneOf(Object.values(AttestationActionType)),
  loading: bool,
  error: object,
  onChange: func.isRequired,
  triggerValidation: bool,
  handleConditionValidation: func,
  canAttest: bool,
  setHasChanged: func,
  productId: number,
  brandId: number,
};

function RequirementActionModalContentsContainer({
  conditions,
  canAttest = true,
  loading,
  onChange,
  triggerValidation,
  handleConditionValidation,
  submitRetailerResponse,
  isRetailer,
  doneCtaLabel,
  onDone,
  disableDone,
  screeningResult,
  brandId,
  hideDone = false,
  hideDoneNote,
}) {
  const {
    setConfirmClose,
    setInitEdit,
    initEdit,
    setHasChanged,
    hasChanged,
    modalOpen,
    hasErrors,
    currentProductId,
  } = useRequirementActions();

  if (loading) {
    return (
      <div className={styles.center}>
        <LoadingSpinner darkMode size={32} />
      </div>
    );
  }
  if (!conditions?.length) {
    return (
      <div className={styles.center}>
        <div>
          <Icon name={IconName.CheckGreenRound} size={48} />
          <Text kind={TextKind.TextLGSemibold} element={TextElement.H3}>
            No action necessary!
          </Text>
          <Text kind={TextKind.TextMD} element={TextElement.P}>
            This condition does not require an attestation.
          </Text>
        </div>
      </div>
    );
  }

  // clear all internal state when modal is closed
  if (!modalOpen) {
    return null;
  }

  const needsAttestation =
    screeningResult?.screeningStatus === ScreeningStatus.NeedsAttention ||
    screeningResult?.screeningStatus === ScreeningStatus.NeedsInformation;
  const computedCanAttest = canAttest && (needsAttestation || initEdit);
  const hasPreviousResponse = conditions.some(
    (condition) => !!condition.attestationProof
  );

  const handleSetChange = () => {
    setHasChanged(true);
    setConfirmClose(true);
  };

  const computedDisabled =
    disableDone || !computedCanAttest || (!hasPreviousResponse && !hasChanged);
  return (
    <div className={styles['root-container']}>
      <div className={styles['action-content-container']}>
        {!needsAttestation && !initEdit && !isRetailer && canAttest && (
          <div className={styles['edit-container']}>
            <Text
              kind={TextKind.TextSMSemibold}
              element={TextElement.P}
              color={Color.Purple700}
            >
              This requirement has already been attested to. To make changes,
              please unlock.
            </Text>
            <Button
              kind={ButtonKind.Alternate}
              onClick={() => setInitEdit(true)}
              className={styles.edit}
            >
              Edit Attestations
            </Button>
          </div>
        )}
        {conditions?.length &&
          conditions.map((condition, i) => (
            <div className={styles.root} key={i}>
              <Text element={TextElement.P} kind={TextKind.TextMDBold}>
                {condition.name}
              </Text>
              {condition.description && (
                <div className={styles['requirement-container']}>
                  <Text element={TextElement.P} kind={TextKind.TextSM}>
                    {withLineBreaks(condition.description)}
                  </Text>
                </div>
              )}
              <RequirementActionContent
                actionType={condition?.type}
                onChange={onChange}
                condition={condition}
                triggerValidation={triggerValidation}
                handleConditionValidation={handleConditionValidation}
                submitRetailerResponse={submitRetailerResponse}
                isRetailer={isRetailer}
                canAttest={computedCanAttest}
                setHasChanged={handleSetChange}
                productId={currentProductId}
                brandId={brandId}
              />
            </div>
          ))}
        {!isRetailer && !canAttest && !hideDone && (
          <Text
            kind={TextKind.TextSM}
            element={TextElement.P}
            className={styles['dialog-note']}
          >
            <Icon name={IconName.Info} size={24} />
            This requirement is locked after submission. If you need to make
            changes, please contact the project administrator.
          </Text>
        )}
        {hideDone && hideDoneNote && hideDoneNote}
        {!isRetailer && !hideDone && (
          <div className={styles['attest-btn-container']}>
            {hasErrors && (
              <Text
                kind={TextKind.TextSM}
                element={TextElement.P}
                color={Color.Red600}
              >
                Please review fields above.
              </Text>
            )}
            <Button
              className={styles['attest-btn']}
              kind={ButtonKind.Primary}
              onClick={onDone}
              disabled={computedDisabled}
            >
              {doneCtaLabel}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

RequirementActionModalContentsContainer.propTypes = {
  conditions: arrayOf(any),
  modalType: oneOf(Object.values(RequirementActionContentType)),
  loading: bool,
  error: object,
  onChange: func.isRequired,
  triggerValidation: bool,
  handleConditionValidation: func,
  canAttest: bool,
  doneCtaLabel: string,
  onDone: func,
  disableDone: bool,
  brandId: number,
};

export default RequirementActionModalContentsContainer;
