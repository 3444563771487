import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './menu.module.scss';

function Menu({ noBorder, children }) {
  return (
    <ul className={cn([styles.root, noBorder && styles['no-border']])}>
      {children}
    </ul>
  );
}

Menu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  noBorder: PropTypes.bool,
};

export default Menu;
