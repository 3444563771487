import PropTypes from 'prop-types';

function TabPanel({ children, ariaId, ariaLabeledBy }) {
  return (
    <div
      id={ariaId}
      aria-labelledby={ariaLabeledBy}
      tabIndex={0}
      role="tabpanel"
    >
      {children}
    </div>
  );
}

TabPanel.propTypes = {
  ariaLabeledBy: PropTypes.string,
  children: PropTypes.any.isRequired,
  ariaId: PropTypes.string,
};

export default TabPanel;
