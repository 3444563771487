import React, { createContext, useContext, ReactNode } from 'react';
import { useApp } from 'context/AppContext';

interface RetailerBrandPaywallContextType {
  cannotReviewRequirements: boolean;
}

const RetailerBrandPaywallContext = createContext<
  RetailerBrandPaywallContextType | undefined
>(undefined);

export const useRetailerBrandPaywall = (): RetailerBrandPaywallContextType => {
  const context = useContext(RetailerBrandPaywallContext);
  if (!context) {
    throw new Error(
      'useRetailerBrandPaywall must be used within a RetailerBrandPaywallProvider'
    );
  }
  return context;
};

interface RetailerBrandPaywallProviderProps {
  children: ReactNode;
}

export const RetailerBrandPaywallProvider: React.FC<
  RetailerBrandPaywallProviderProps
> = ({ children }) => {
  const { retailerBrand, user } = useApp();

  const cannotReviewRequirements =
    Boolean(retailerBrand?.unlocked) === false &&
    Boolean(user?.hasFF('tmp_chargebee_paywall'));

  const value = {
    cannotReviewRequirements,
  };

  return (
    <RetailerBrandPaywallContext.Provider value={value}>
      {children}
    </RetailerBrandPaywallContext.Provider>
  );
};
