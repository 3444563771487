import propTypes from 'prop-types';
import SupplierMaterialLiteratureItem from './literature-item';
import styles from './material-literature.module.scss';
import SupplierMaterialLiteratureStats from './stats';

export default function SupplierMaterialLiterature({ data }) {
  return (
    <div className={styles.container}>
      <SupplierMaterialLiteratureItem
        title="Marketing data"
        iconName="MegaphoneSimple"
        hasData={!!data?.marketingData}
      >
        <SupplierMaterialLiteratureStats
          alternateLayout
          data={data?.marketingData}
        />
      </SupplierMaterialLiteratureItem>
      <SupplierMaterialLiteratureItem
        title="Technical data"
        iconName="GearSix"
        hasData={!!data?.technicalData}
      >
        <SupplierMaterialLiteratureStats data={data?.technicalData} />
      </SupplierMaterialLiteratureItem>
      <SupplierMaterialLiteratureItem
        title="Manufacturing information"
        iconName="Warehouse"
        hasData={!!data?.manufacturingData}
      >
        <SupplierMaterialLiteratureStats data={data?.manufacturingData} />
      </SupplierMaterialLiteratureItem>
    </div>
  );
}

SupplierMaterialLiterature.propTypes = {
  data: propTypes.object.isRequired,
};
