export interface DataItem {
  id: number;
  name: string;
}

/**
 * Creates a map from name to id.
 *
 * @param {DataItem[]} array - An array of objects containing `id` and `name`.
 * @returns {{ [key: string]: number }} - A map with `name` as the key and `id` as the value.
 *
 * @example
 * const data = [{ id: 1, name: "BPC" }, { id: 2, name: "Food" }];
 * const map = createNameToIdMap(data);
 * // Result: { "BPC": 1, "Food": 2 }
 */
export const createNameToIdMap = (
  array: DataItem[]
): { [key: string]: number } => {
  return array.reduce((map, item) => {
    map[item.name] = item.id;
    return map;
  }, {} as { [key: string]: number });
};

/**
 * Creates a map from id to name.
 *
 * @param {DataItem[]} array - An array of objects containing `id` and `name`.
 * @returns {{ [key: number]: string }} - A map with `id` as the key and `name` as the value.
 *
 * @example
 * const data = [{ id: 1, name: "BPC" }, { id: 2, name: "Food" }];
 * const map = createIdToNameMap(data);
 * // Result: { 1: "BPC", 2: "Food" }
 */
export const createIdToNameMap = (
  array: DataItem[]
): { [key: number]: string } => {
  return array.reduce((map, item) => {
    map[item.id] = item.name;
    return map;
  }, {} as { [key: number]: string });
};
