import PropTypes from 'prop-types';
import styles from './divider.module.scss';
import cn from 'classnames';

function Divider(props) {
  return <div className={cn(styles.root, props.className)} />;
}

Divider.propTypes = {
  className: PropTypes.string,
};

export default Divider;
