/**
 * Formats date string to YYYY-MM-DD
 * @param {string} dateString
 * @returns {string} formatted date string
 */
export const formatDate = (dateString) => {
  if (!dateString) return '';

  const date = new Date(dateString);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

/**
 * Formats date string to MM.DD.YYYY or MM.DD.YYYY, HH:SS
 * @param {string} dateString
 * @param {boolean} includeTime
 * @returns {string} formatted date string
 */
export const formatDateV2 = (dateString, includeTime = false) => {
  if (!dateString) return '';

  const date = new Date(dateString);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hour = String(date.getUTCHours()).padStart(2, '0');
  const minute = String(date.getUTCMinutes()).padStart(2, '0');
  return `${month}.${day}.${year}${includeTime ? `, ${hour}:${minute}` : ''}`;
};

/**
 * Formats date as hour and second time string with client timezone HH:SS (UTC)
 * @param {string} dateString
 * @returns {string} formatted time string with timezone abbreviation
 */
export const formatToShortUTC12hr = (dateString) => {
  if (!dateString) return '';

  const date = new Date(dateString);
  const hour = date.getUTCHours();
  const isMorning = hour < 12;
  const calculateHour = () => {
    // Check if the hour is midnight or noon
    if (hour === 0 || hour === 12) {
      return 12; // Midnight or noon should display as 12
    }
    // Calculate hour for AM/PM display
    return isMorning ? hour : hour - 12;
  };
  const formattedHour = String(calculateHour()).padStart(2, '0');
  const minute = String(date.getUTCMinutes()).padStart(2, '0');
  return `${formattedHour}:${minute} ${isMorning ? 'AM' : 'PM'} UTC`;
};
