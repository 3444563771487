import { useMemo, useRef } from 'react';
import {
  ButtonKind,
  Modal,
  ModalType,
  Text,
  TextKind,
  TextElement,
  PhosphorIcon,
  Divider,
  SelectV2,
  SelectV2Item,
  PhosphorIconWeight,
  Link,
  LinkKind,
  LinkVariant,
} from 'design-system/components';
import { Color } from 'design-system/data';
import { useFDASubmissionConfirmationContext } from '../Context';
import {
  FormInput,
  FormRow,
} from 'views/FDARegistration/shared/components/Form';
import countries from '../../../shared/data/countries.json';
import provinces from '../../../shared/data/provinces.json';

import styles from './edit-dialog.module.scss';

export const EditDialog = () => {
  const {
    editOpen,
    setEditOpen,
    editPage,
    setEditPage,
    formState,
    handleFormStateChange,
  } = useFDASubmissionConfirmationContext();

  const dialogRef = useRef(null);

  const onCancel = () => setEditOpen(false);
  const onComplete = () => {
    if (editPage === 4) {
      setEditOpen(false);
    } else {
      setEditPage(editPage + 1);
    }
  };

  const uniqueCountries = useMemo(() => {
    const unique = [];
    const map = new Map();
    for (const item of countries) {
      if (!map.has(item.dial_code)) {
        map.set(item.dial_code, true); // set any value to Map
        unique.push({
          dial_code: item.dial_code,
          name: item.name,
        });
      } else {
        // update country name to include the other country
        const index = unique.findIndex(
          (country) => country.dial_code === item.dial_code
        );
        unique[index].name = `${unique[index].name}, ${item.name}`;
      }
    }
    return unique;
  }, []);

  const validPageInfo = useMemo(() => {
    if (editPage === 0 && formState.businessType) {
      return true;
    } else if (
      editPage === 1 &&
      formState.responsiblePersonName &&
      formState.responsiblePersonDuns &&
      formState.responsiblePersonPhoneCountryCode &&
      formState.responsiblePersonPhoneAreaCode &&
      formState.responsiblePersonPhoneExchange?.length === 7
    ) {
      return true;
    } else if (
      editPage === 2 &&
      formState.contactFirstName &&
      formState.contactLastName &&
      formState.contactPhoneCountryCode &&
      formState.contactPhoneAreaCode &&
      formState.contactPhoneExchange?.length === 7
    ) {
      return true;
    } else if (
      editPage === 3 &&
      formState.physicalFacilityName &&
      formState.physicalAddressLine1 &&
      formState.physicalCountryCode &&
      formState.physicalPostalCode &&
      formState.physicalCity &&
      ((['US', 'CA'].includes(formState.physicalCountryCode) &&
        formState.physicalRegion) ||
        !['US', 'CA'].includes(formState.physicalCountryCode))
    ) {
      return true;
    } else if (
      editPage === 4 &&
      formState.mailingFacilityName &&
      formState.mailingAddressLine1 &&
      formState.mailingCountryCode &&
      formState.mailingPostalCode &&
      formState.mailingCity &&
      ((['US', 'CA'].includes(formState.mailingCountryCode) &&
        formState.mailingRegion) ||
        !['US', 'CA'].includes(formState.mailingCountryCode))
    ) {
      return true;
    }
    return false;
  }, [formState, editPage]);

  return (
    <Modal
      show={editOpen}
      title="Add your business"
      type={ModalType.Large}
      onClick={onCancel}
      showActionButtons
      dataCyRoot="registration-submission-edit-dialog"
      cancelCtaLabel="Cancel"
      cancelCtaKind={ButtonKind.Tertiary}
      onCancel={onCancel}
      doneCtaLabel="Next"
      doneCtaKind={ButtonKind.Primary}
      onDone={onComplete}
      disableDone={!validPageInfo}
    >
      <div className={styles['progress-container']}>
        {[
          'Business info',
          'Responsible person',
          'Contact info',
          'Business address',
          'Mailing address',
        ].map((title, idx) => {
          return (
            <ProgressItem
              title={title}
              complete={idx < editPage}
              active={idx === editPage}
              onClick={() => setEditPage(idx)}
              key={idx}
            />
          );
        })}
      </div>
      <form
        className={styles['form-content']}
        onSubmit={(event) => event.preventDefault()}
        ref={dialogRef}
      >
        {editPage === 0 && (
          <BusinessInfoForm
            formState={formState}
            handleFormStateChange={handleFormStateChange}
            dialogRef={dialogRef}
          />
        )}
        {editPage === 1 && (
          <ResponsiblePersonForm
            formState={formState}
            handleFormStateChange={handleFormStateChange}
            dialogRef={dialogRef}
            uniqueCountries={uniqueCountries}
          />
        )}
        {editPage === 2 && (
          <ContactForm
            formState={formState}
            handleFormStateChange={handleFormStateChange}
            dialogRef={dialogRef}
            uniqueCountries={uniqueCountries}
          />
        )}
        {editPage === 3 && (
          <BusinessAddressForm
            formState={formState}
            handleFormStateChange={handleFormStateChange}
            dialogRef={dialogRef}
          />
        )}
        {editPage === 4 && (
          <MailingAddressForm
            formState={formState}
            handleFormStateChange={handleFormStateChange}
            dialogRef={dialogRef}
          />
        )}
      </form>
    </Modal>
  );
};

const ProgressItem = ({ title, active, complete, onClick }) => {
  return (
    <div
      onClick={() => (complete ? onClick() : null)}
      className={styles['progress-item']}
      style={active && !complete ? {} : { cursor: 'pointer' }}
    >
      <PhosphorIcon
        iconName={complete ? 'CheckCircle' : 'Circle'}
        color={
          active || complete ? Color.Purple600.value : Color.Neutral300.value
        }
      />
      <Text kind={TextKind.TextSMSemibold} element={TextElement.P}>
        {title}
      </Text>
    </div>
  );
};

const BusinessInfoForm = ({ formState, handleFormStateChange, dialogRef }) => {
  return (
    <>
      <Text
        kind={TextKind.TextSMSemibold}
        element={TextElement.P}
        color={Color.Neutral900}
      >
        Business information
      </Text>
      <Text
        kind={TextKind.TextSM}
        element={TextElement.P}
        color={Color.Neutral600}
      >
        Please provide the following details about your business.
      </Text>
      <Divider />
      <FormRow
        title="Type of business"
        description="Please select the type of business that applies to you"
        field={
          <SelectV2
            placeholder="Select type of business"
            value={formState.businessType}
            onValueChange={(value) =>
              handleFormStateChange('businessType', value)
            }
            container={dialogRef?.current}
            required
          >
            {['Distributor (most common)', 'Packer', 'Manufacturer'].map(
              (businessType) => (
                <SelectV2Item key={businessType} value={businessType}>
                  <Text kind={TextKind.TextMDMedium} element={TextElement.Span}>
                    {businessType}
                  </Text>
                </SelectV2Item>
              )
            )}
          </SelectV2>
        }
      />
      <Divider />
      <FormRow
        title="Parent company"
        description="If your business is owned by a parent company, please list here."
        field={
          <FormInput
            type="text"
            id="business__name"
            placeholder="Company name"
            value={formState.parentCompanyName}
            onChange={(e) =>
              handleFormStateChange('parentCompanyName', e.target.value)
            }
          />
        }
      />
      <Divider />
      <FormRow
        title="Small business exemption"
        description={
          <>
            Certain small businesses are still required to list their products
            with the FDA.{' '}
            <Link
              kind={LinkKind.External}
              variant={LinkVariant.Default}
              href={
                'https://noviconnect.notion.site/Confirm-you-qualify-for-the-Small-Business-Exemption-1b66a73c553246d0a1f1ce860ecf37bf?pvs=4'
              }
            >
              Learn more
            </Link>
          </>
        }
        field={
          <div>
            <div className={styles['checkbox-container']}>
              <input
                type="checkbox"
                name="small_business_exemption"
                value={formState.isSmallBusiness}
                id="subscribe_to_emails"
                onChange={(e) =>
                  handleFormStateChange('isSmallBusiness', e.target.checked)
                }
              />
              <Text
                htmlFor="subscribe_to_emails"
                id="input-label-subscribe_to_emails"
                element={TextElement.Label}
                kind={TextKind.TextSM}
                color={Color.Neutral600}
              >
                This business qualifies as a small business according to the
                guidelines set forth by the FDA.
              </Text>
            </div>

            <Text
              htmlFor="subscribe_to_emails"
              id="input-label-subscribe_to_emails"
              element={TextElement.Label}
              kind={TextKind.TextXS}
              color={Color.Neutral600}
              className={styles.italic}
            >
              The term “small business” are those with an average gross annual
              sales in the United States of cosmetic products for the previous
              3-year period is less than $1,000,000, adjusted for inflation and
              who do not engage in the manufacturing or processing of certain
              cosmetic products described in section 612(b) of the FD&C Act.
            </Text>
          </div>
        }
      />
    </>
  );
};

const ResponsiblePersonForm = ({
  formState,
  handleFormStateChange,
  dialogRef,
  uniqueCountries,
}) => {
  const { handleResponsiblePersonPhoneNumberChange } =
    useFDASubmissionConfirmationContext();

  return (
    <>
      <Text
        kind={TextKind.TextSMSemibold}
        element={TextElement.P}
        color={Color.Neutral900}
      >
        Responsible person
      </Text>
      <Text
        kind={TextKind.TextSM}
        element={TextElement.P}
        color={Color.Neutral600}
      >
        Responsible Person as defined by the FDA is the manufacturer, packer or
        distributor of a cosmetic product whose name appears on the label of
        such cosmetic product.{' '}
        <Link
          kind={LinkKind.External}
          variant={LinkVariant.Default}
          href={'https://www.fda.gov/media/170732/download'}
        >
          Learn more
        </Link>
      </Text>
      <Divider />
      <FormRow
        title="Name"
        field={
          <>
            <FormInput
              type="text"
              id="business__name"
              placeholder="Entity name"
              value={formState.responsiblePersonName}
              onChange={(e) =>
                handleFormStateChange('responsiblePersonName', e.target.value)
              }
            />
          </>
        }
      />
      <Divider />
      <FormRow
        title="DUNS number"
        description="The Data Universal Numbering System (DUNS) number is a unique nine digit identification number provided by Dun & Bradstreet (D&B)."
        field={
          <FormInput
            type="text"
            id="business__name"
            placeholder="DUNS number"
            value={formState.responsiblePersonDuns}
            onChange={(e) =>
              handleFormStateChange('responsiblePersonDuns', e.target.value)
            }
          />
        }
      />
      <Divider />
      <FormRow
        title="Phone number"
        field={
          <>
            <SelectV2
              placeholder="+1"
              value={formState.responsiblePersonPhoneCountryCode}
              onValueChange={(value) =>
                handleFormStateChange(
                  'responsiblePersonPhoneCountryCode',
                  value
                )
              }
              container={dialogRef?.current}
              required
              triggerStyle={{ gap: '10px' }}
            >
              {uniqueCountries.map((country) => (
                <SelectV2Item key={country.name} value={country.dial_code}>
                  <Text kind={TextKind.TextMDMedium} element={TextElement.Span}>
                    {country.name} ({country.dial_code})
                  </Text>
                </SelectV2Item>
              ))}
            </SelectV2>
            <FormInput
              type="text"
              id="business__name"
              placeholder="(123) 555-5555"
              value={formState.responsiblePersonPhoneNumber || ''}
              onChange={(e) =>
                handleResponsiblePersonPhoneNumberChange(e.target.value)
              }
            />
            <FormInput
              type="text"
              id="responsible_person_phone_extension"
              placeholder="EXT-5555"
              value={formState.responsiblePersonPhoneExtension || ''}
              onChange={(e) =>
                handleFormStateChange(
                  'responsiblePersonPhoneExtension',
                  e.target.value
                )
              }
            />
          </>
        }
      />
    </>
  );
};

const ContactForm = ({
  formState,
  handleFormStateChange,
  dialogRef,
  uniqueCountries,
}) => {
  const { handlePhoneNumberChange } = useFDASubmissionConfirmationContext();

  return (
    <>
      <Text
        kind={TextKind.TextSMSemibold}
        element={TextElement.P}
        color={Color.Neutral900}
      >
        Contact info
      </Text>
      <Text
        kind={TextKind.TextSM}
        element={TextElement.P}
        color={Color.Neutral600}
      >
        Please provide the contact information for someone at your organization
        that the FDA can get in contact with questions or followups.
      </Text>
      <Divider />
      <FormRow
        title="Name"
        field={
          <>
            <FormInput
              type="text"
              id="business__name"
              placeholder="First name"
              value={formState.contactFirstName}
              onChange={(e) =>
                handleFormStateChange('contactFirstName', e.target.value)
              }
            />
            <FormInput
              type="text"
              id="business__name"
              placeholder="Last name"
              value={formState.contactLastName}
              onChange={(e) =>
                handleFormStateChange('contactLastName', e.target.value)
              }
            />
          </>
        }
      />
      <Divider />
      <FormRow
        title="Role"
        field={
          <FormInput
            type="text"
            id="business__name"
            placeholder="Job title or position"
            value={formState.contactTitle}
            onChange={(e) =>
              handleFormStateChange('contactTitle', e.target.value)
            }
          />
        }
      />
      <Divider />
      <FormRow
        title="Email address"
        field={
          <FormInput
            type="text"
            id="business__name"
            placeholder="example@example.com"
            value={formState.contactEmail}
            icon={
              <PhosphorIcon
                iconName="EnvelopeSimple"
                size="1.5rem"
                weight={PhosphorIconWeight.Light}
                color={Color.Neutral600.value}
              />
            }
            onChange={(e) =>
              handleFormStateChange('contactEmail', e.target.value)
            }
          />
        }
      />
      <Divider />
      <FormRow
        title="Phone number"
        field={
          <>
            <SelectV2
              placeholder="+1"
              value={formState.contactPhoneCountryCode}
              onValueChange={(value) =>
                handleFormStateChange('contactPhoneCountryCode', value)
              }
              container={dialogRef?.current}
              required
              triggerStyle={{ gap: '10px' }}
            >
              {uniqueCountries.map((country) => (
                <SelectV2Item key={country.name} value={country.dial_code}>
                  <Text kind={TextKind.TextMDMedium} element={TextElement.Span}>
                    {country.name} ({country.dial_code})
                  </Text>
                </SelectV2Item>
              ))}
            </SelectV2>
            <FormInput
              type="text"
              id="business__name"
              placeholder="(123) 555-5555"
              value={formState.phoneNumber || ''}
              onChange={(e) => handlePhoneNumberChange(e.target.value)}
            />
            <FormInput
              type="text"
              id="contact_phone_extension"
              placeholder="EXT-5555"
              value={formState.contactPhoneExtension || ''}
              onChange={(e) =>
                handleFormStateChange('contactPhoneExtension', e.target.value)
              }
            />
          </>
        }
      />
    </>
  );
};

const BusinessAddressForm = ({
  formState,
  handleFormStateChange,
  dialogRef,
}) => {
  const provinceOptions = useMemo(() => {
    if (!formState.physicalCountryCode) return [];
    // TODO: (maintainability) get the state { country_alpha2, name } from the backend
    return provinces.filter(
      (province) => province.country_alpha2 === formState.physicalCountryCode
    );
  }, [formState.physicalCountryCode]);

  return (
    <>
      <Text
        kind={TextKind.TextSMSemibold}
        element={TextElement.P}
        color={Color.Neutral900}
      >
        Business address
      </Text>
      <Text
        kind={TextKind.TextSM}
        element={TextElement.P}
        color={Color.Neutral600}
      >
        Physical address requirements
      </Text>
      <Divider />
      <FormRow
        title="Building name"
        field={
          <FormInput
            type="text"
            id="business__name"
            placeholder="Name"
            value={formState.physicalFacilityName}
            onChange={(e) =>
              handleFormStateChange('physicalFacilityName', e.target.value)
            }
          />
        }
      />
      <Divider />
      <FormRow
        title="Address"
        field={
          <FormInput
            type="text"
            id="business__name"
            placeholder="Street address"
            value={formState.physicalAddressLine1}
            onChange={(e) =>
              handleFormStateChange('physicalAddressLine1', e.target.value)
            }
          />
        }
      />
      <Divider />
      <FormRow
        title="Address line 2"
        field={
          <FormInput
            type="text"
            id="business__name"
            placeholder="Suite, building, floor"
            value={formState.physicalAddressLine2}
            onChange={(e) =>
              handleFormStateChange('physicalAddressLine2', e.target.value)
            }
          />
        }
      />
      <Divider />
      <FormRow
        title="Country and postal code"
        field={
          <>
            <SelectV2
              placeholder="Select country..."
              value={formState.physicalCountryCode}
              onValueChange={(value) =>
                handleFormStateChange([
                  ['physicalCountryCode', value],
                  ['physicalRegion', undefined],
                ])
              }
              container={dialogRef?.current}
              required
            >
              {countries.map((country) => (
                <SelectV2Item
                  key={country.name}
                  value={country.alpha2.toUpperCase()}
                >
                  <Text kind={TextKind.TextMDMedium} element={TextElement.Span}>
                    {country.name}
                  </Text>
                </SelectV2Item>
              ))}
            </SelectV2>
            {/* <FormInput
              type="text"
              id="business__name"
              placeholder="Business name"
              value={formState.physicalCountryCode}
              onChange={(e) =>
                handleFormStateChange('physicalCountryCode', e.target.value)
              }
            /> */}
            <FormInput
              type="text"
              id="business__name"
              placeholder="Postal code"
              value={formState.physicalPostalCode}
              onChange={(e) =>
                handleFormStateChange('physicalPostalCode', e.target.value)
              }
            />
          </>
        }
      />
      <Divider />
      <FormRow
        title="City and state"
        field={
          <>
            <FormInput
              type="text"
              id="business__name"
              placeholder="City"
              value={formState.physicalCity}
              onChange={(e) =>
                handleFormStateChange('physicalCity', e.target.value)
              }
            />
            {provinceOptions.length > 0 ? (
              <div className={styles['relative-parent']}>
                <SelectV2
                  placeholder="Select state..."
                  value={formState.physicalRegion}
                  onValueChange={(value) =>
                    handleFormStateChange('physicalRegion', value)
                  }
                  container={dialogRef?.current}
                  required
                >
                  {provinceOptions.map((province) => (
                    <SelectV2Item key={province.name} value={province.name}>
                      <Text
                        kind={TextKind.TextMDMedium}
                        element={TextElement.Span}
                      >
                        {province.name}
                      </Text>
                    </SelectV2Item>
                  ))}
                </SelectV2>
              </div>
            ) : (
              formState.physicalCountryCode && (
                <FormInput
                  type="text"
                  id="business__name"
                  placeholder="State / Province"
                  value={formState.physicalRegion}
                  onChange={(e) =>
                    handleFormStateChange('physicalRegion', e.target.value)
                  }
                />
              )
            )}
            {/* <FormInput
              type="text"
              id="business__name"
              placeholder="Business name"
              value={formState.physicalRegion}
              onChange={(e) =>
                handleFormStateChange('physicalRegion', e.target.value)
              }
            /> */}
          </>
        }
      />
    </>
  );
};

const MailingAddressForm = ({
  formState,
  handleFormStateChange,
  dialogRef,
}) => {
  const { setFormState } = useFDASubmissionConfirmationContext();

  const provinceOptions = useMemo(() => {
    if (!formState.mailingCountryCode) return [];
    // TODO: (maintainability) get the state { country_alpha2, name } from the backend
    return provinces.filter(
      (province) => province.country_alpha2 === formState.mailingCountryCode
    );
  }, [formState.mailingCountryCode]);

  const handleCheck = (checked) => {
    if (checked) {
      setFormState((prev) => {
        return {
          ...prev,
          mailingFacilityName: formState.physicalFacilityName,
          mailingAddressLine1: formState.physicalAddressLine1,
          mailingAddressLine2: formState.physicalAddressLine2,
          mailingCountryCode: formState.physicalCountryCode,
          mailingPostalCode: formState.physicalPostalCode,
          mailingCity: formState.physicalCity,
          mailingRegion: formState.physicalRegion,
        };
      });
    } else {
      setFormState((prev) => {
        return {
          ...prev,
          mailingFacilityName: '',
          mailingAddressLine1: '',
          mailingAddressLine2: '',
          mailingCountryCode: '',
          mailingPostalCode: '',
          mailingCity: '',
          mailingRegion: '',
        };
      });
    }
  };

  return (
    <>
      <Text
        kind={TextKind.TextSMSemibold}
        element={TextElement.P}
        color={Color.Neutral900}
      >
        Mailing address
      </Text>
      <Text
        kind={TextKind.TextSM}
        element={TextElement.P}
        color={Color.Neutral600}
      >
        Mailing address requirements
      </Text>
      <div className={styles['checkbox-container']}>
        <input
          type="checkbox"
          name="small_business_exemption"
          value="false"
          id="subscribe_to_emails"
          onChange={(e) => handleCheck(e.target.checked)}
        />
        <Text
          htmlFor="subscribe_to_emails"
          id="input-label-subscribe_to_emails"
          element={TextElement.Label}
          kind={TextKind.TextSM}
          color={Color.Neutral600}
        >
          Same as business address
        </Text>
      </div>
      <Divider />
      <FormRow
        title="Building name"
        field={
          <FormInput
            type="text"
            id="business__name"
            placeholder="Name"
            value={formState.mailingFacilityName}
            onChange={(e) =>
              handleFormStateChange('mailingFacilityName', e.target.value)
            }
          />
        }
      />
      <Divider />
      <FormRow
        title="Address"
        field={
          <FormInput
            type="text"
            id="business__name"
            placeholder="Street address"
            value={formState.mailingAddressLine1}
            onChange={(e) =>
              handleFormStateChange('mailingAddressLine1', e.target.value)
            }
          />
        }
      />
      <Divider />
      <FormRow
        title="Address line 2"
        field={
          <FormInput
            type="text"
            id="business__name"
            placeholder="Suite, building, floor"
            value={formState.mailingAddressLine2}
            onChange={(e) =>
              handleFormStateChange('mailingAddressLine2', e.target.value)
            }
          />
        }
      />
      <Divider />
      <FormRow
        title="Country and postal code"
        field={
          <>
            <SelectV2
              placeholder="Select country..."
              value={formState.mailingCountryCode}
              onValueChange={(value) =>
                handleFormStateChange([
                  ['mailingCountryCode', value],
                  ['mailingRegion', undefined],
                ])
              }
              container={dialogRef?.current}
              required
            >
              {countries.map((country) => (
                <SelectV2Item
                  key={country.name}
                  value={country.alpha2.toUpperCase()}
                >
                  <Text kind={TextKind.TextMDMedium} element={TextElement.Span}>
                    {country.name}
                  </Text>
                </SelectV2Item>
              ))}
            </SelectV2>
            {/* <FormInput
              type="text"
              id="business__name"
              placeholder="Business name"
              value={formState.mailingCountryCode}
              onChange={(e) =>
                handleFormStateChange('mailingCountryCode', e.target.value)
              }
            /> */}
            <FormInput
              type="text"
              id="business__name"
              placeholder="Postal code"
              value={formState.mailingPostalCode}
              onChange={(e) =>
                handleFormStateChange('mailingPostalCode', e.target.value)
              }
            />
          </>
        }
      />
      <Divider />
      <FormRow
        title="City and state"
        field={
          <>
            <FormInput
              type="text"
              id="business__name"
              placeholder="City"
              value={formState.mailingCity}
              onChange={(e) =>
                handleFormStateChange('mailingCity', e.target.value)
              }
            />
            {provinceOptions.length > 0 ? (
              <div className={styles['relative-parent']}>
                <SelectV2
                  placeholder="Select state..."
                  value={formState.mailingRegion}
                  onValueChange={(value) =>
                    handleFormStateChange('mailingRegion', value)
                  }
                  container={dialogRef?.current}
                  required
                >
                  {provinceOptions.map((province) => (
                    <SelectV2Item key={province.name} value={province.name}>
                      <Text
                        kind={TextKind.TextMDMedium}
                        element={TextElement.Span}
                      >
                        {province.name}
                      </Text>
                    </SelectV2Item>
                  ))}
                </SelectV2>
              </div>
            ) : (
              formState.mailingCountryCode && (
                <FormInput
                  type="text"
                  id="business__name"
                  placeholder="State / Province"
                  value={formState.mailingRegion}
                  onChange={(e) =>
                    handleFormStateChange('mailingRegion', e.target.value)
                  }
                />
              )
            )}
            {/* <FormInput
              type="text"
              id="business__name"
              placeholder="Business name"
              value={formState.mailingRegion}
              onChange={(e) =>
                handleFormStateChange('mailingRegion', e.target.value)
              }
            /> */}
          </>
        }
      />
    </>
  );
};
