import { ToastV2Provider } from 'design-system/components';

import { RequirementActionsProvider } from 'views/Brands/shared/RequirementActionModalContents/context';
import RequirementSummaryView from './view';

function RetailerBrandRequirementSummary() {
  return (
    <ToastV2Provider>
      <RequirementActionsProvider>
        <RequirementSummaryView />
      </RequirementActionsProvider>
    </ToastV2Provider>
  );
}

export default RetailerBrandRequirementSummary;
