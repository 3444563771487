export default function Bullhorn({ size, color }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 4L3 6.15385V8.84615L13 11V4Z"
        stroke={color.value}
        strokeWidth="1.5"
      />
      <path d="M6 10V12.5" stroke={color.value} strokeWidth="1.5" />
      <path d="M3 4V10.5" stroke={color.value} strokeWidth="1.5" />
    </svg>
  );
}
