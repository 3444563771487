import { PillColor } from 'design-system/components';

export const STATUS_FILTERS = {
  0: 'all',
  1: 'needs_info',
  2: 'complete',
};

export const STATUS_NAMES = {
  0: 'All SKUs',
  1: 'Needs Information',
  2: 'Complete',
};

export const STATUS_MAPPINGS = {
  draft: 'Draft',
  ready_to_submit: 'Ready to submit',
  submitted: 'Submitted',
  processing: 'Processing',
  completed: 'Completed',
  failed: 'Failed',
};

export const STATUS_COLORS = {
  draft: PillColor.Default,
  ready_to_submit: PillColor.Green,
  submitted: PillColor.Blue,
  processing: PillColor.Blue,
  completed: PillColor.Blue,
  failed: PillColor.Blue,
};
