import { BrandViewHeader } from 'design-system/components';

const Header = ({ data, breadcrumbItems }) => (
  <BrandViewHeader
    title={data?.name || ''}
    subTitle={data?.description || 'Policy Requirements Table'}
    breadcrumbsItems={breadcrumbItems}
    showExportButton={false}
    showFilterButton={false}
  />
);

export default Header;
