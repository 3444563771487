export const PolicyType = {
  ConsumerBrandPolicy: 'consumerBrandPolicy',
  RetailerPolicy: 'retailerPolicy',
  ConsumerProductPolicy: 'consumerProductPolicy',
  ConsumerPackagePolicy: 'consumerPackagePolicy',
  FormulationPolicy: 'formulationPolicy',
};

// bit of a refining of the above based on new apis apart of retailer v2
export const PolicyRequirementType = {
  Formulation: 'FORMULATION',
  Product: 'PRODUCT',
  Packaging: 'PACKAGING',
  Brand: 'BRAND',
};
