import { ReactNode } from 'react';
import {
  DropdownMenu,
  DropdownMenuItem,
  RadioGroup,
  Radio,
} from 'design-system/components';
import styles from './dropdown-radio-menu.module.scss';

interface DropdownRadioMenuProps {
  triggerElement: ReactNode;
  activeValue: string;
  setActiveValue: (arg: string) => void;
  fromLeft?: boolean;
  reverse?: boolean;
  options: { [key: string]: string };
}

const DropdownRadioMenu = ({
  triggerElement,
  fromLeft,
  reverse,
  activeValue,
  setActiveValue,
  options,
}: DropdownRadioMenuProps) => {
  return (
    <DropdownMenu
      fromLeft={fromLeft}
      triggerElement={triggerElement || <div />}
    >
      <RadioGroup
        onChange={(val) => setActiveValue(val)}
        name="attestation-response"
        value={activeValue}
      >
        <div className={styles['menu-content']}>
          {Object.keys(options).map((option) => {
            return (
              <DropdownMenuItem
                key={option}
                onClick={() => setActiveValue(option)}
              >
                <div className={styles['menu-item-content']}>
                  <Radio
                    reverse={reverse}
                    value={option}
                    label={options[option]}
                    style={{
                      marginBottom: '0',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  />
                </div>
              </DropdownMenuItem>
            );
          })}
        </div>
      </RadioGroup>
    </DropdownMenu>
  );
};

export default DropdownRadioMenu;
