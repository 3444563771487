import { useEffect, useState } from 'react';
import {
  BrandViewHeader,
  BrandViewContentSection,
  TextElement,
  TextKind,
  Text,
} from 'design-system/components';
import DocumentsTable from './components/documents-table';
import { useDocumentContext } from './context';
import { DocumentStatuses } from './constants';
import styles from './documents.module.scss';
import { Color } from 'design-system/data';
import DocumentsPageEligibility from 'components/EligibilityStatus/DocumentsPageEligibility';
import EligibilityProvider from 'components/EligibilityStatus/EligibilityContext';

function DocumentsContainer() {
  const { documents, loading, refetchDocuments, error } = useDocumentContext();
  const [filteredDocuments, setFilteredDocuments] = useState(documents);

  useEffect(() => {
    setFilteredDocuments(documents);
  }, [documents]);

  const getDocumentQuantityByStatus = (status) => {
    if (status === 'pending')
      return documents.filter((document) => document.status === null).length;
    return documents.filter((document) => document.status === status).length;
  };

  const tabData = [
    {
      label: 'All documents',
      value: '',
      quantity: documents.length,
    },
    {
      label: 'Pending',
      value: 'pending',
      quantity: getDocumentQuantityByStatus('pending'),
    },
    {
      label: 'Needs action',
      value: DocumentStatuses.Rejected,
      quantity: getDocumentQuantityByStatus(DocumentStatuses.Rejected),
    },
    {
      label: 'Approved',
      value: DocumentStatuses.Approved,
      quantity: getDocumentQuantityByStatus(DocumentStatuses.Approved),
    },
  ];

  const handleTabChange = (activeFilter) => getFilteredDocuments(activeFilter);

  const getFilteredDocuments = (status) => {
    if (!status) {
      return setFilteredDocuments(documents);
    }
    if (status === DocumentStatuses.Pending) {
      // Null statuses are treated as pending.
      const filteredDocs = documents.filter(
        (document) => document.status === null
      );
      return setFilteredDocuments(filteredDocs);
    }
    const filteredDocs = documents.filter(
      (document) => document.status === status
    );
    return setFilteredDocuments(filteredDocs);
  };

  return (
    <div className={styles.root}>
      <BrandViewHeader
        title="Your Documents"
        showExportButton={false}
        showFilterButton={false}
      />
      <EligibilityProvider>
        <DocumentsPageEligibility />
      </EligibilityProvider>
      <Text
        color={Color.Neutral900}
        element={TextElement.H2}
        kind={TextKind.Display2XSMedium}
        className={styles['section-heading']}
      >
        Uploaded documents
      </Text>
      <BrandViewContentSection
        tabData={tabData}
        tabContents={
          <DocumentsTable
            documents={filteredDocuments}
            loading={loading}
            refetchDocuments={refetchDocuments}
            error={error}
          />
        }
        onTabChange={handleTabChange}
      />
    </div>
  );
}

export default DocumentsContainer;
