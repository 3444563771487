import { ScreeningStatus, UrlParam } from 'design-system/data';
import { appendParameter, convertSnakeToCamelCase } from 'design-system/utils';
import useQueryParams from 'hooks/useQueryParams';
import { useApp } from 'context/AppContext';
import fetcher from 'services/api/fetcher';

export function setUrlSearchParameters({
  currentPage,
  status,
  history,
  term,
  sort,
}) {
  const params = new URLSearchParams();
  appendParameter(params, UrlParam.Page, currentPage);
  appendParameter(params, UrlParam.Status, status);
  appendParameter(params, UrlParam.Term, term);
  appendParameter(params, UrlParam.Sort, sort);
  history.push({ search: params?.toString() });
}

export function useBrandSubmissions() {
  const { user, useApi } = useApp();
  const { status, term, sort, page } = useQueryParams(Object.values(UrlParam));
  const queryParamsSet = Boolean(
    status?.value !== undefined ||
      term?.value !== undefined ||
      sort?.value !== undefined ||
      page?.value !== undefined
  );

  const retailerId = user?.organization?.in_orgable_id;
  const statusValue =
    status?.value === ScreeningStatus.All ? undefined : status?.value;
  const url = `/api/v4/retailers/${retailerId}/policy_submissions/by_brand`;
  const { data, loading, error } = useApi(url, {
    enabled: !!retailerId && queryParamsSet,
    param: {
      ...(statusValue ? { status: statusValue } : {}),
      ...(term ? { term: term?.value } : {}),
      ...(sort ? { sort: sort?.value } : {}),
      ...(page ? { page: page?.value } : {}),
    },
  });
  return {
    data: convertSnakeToCamelCase(data),
    loading,
    error,
  };
}

export const generateBrandPolicyExport = ({
  onSuccess,
  onFailure,
  retailerId,
  consumerBrandId = null,
  policyId = null,
}) => {
  const apiCall = (url) => {
    return fetcher(url, {
      method: 'get',
      enabled: Boolean(retailerId),
    })
      .then(onSuccess)
      .catch(onFailure);
  };

  if (!consumerBrandId) {
    return apiCall(`/api/v4/retailers/${retailerId}/policies/generate_report`);
  }
  if (!policyId) {
    return apiCall(
      `/api/v4/retailers/${retailerId}/consumer_brands/${consumerBrandId}/policies/generate_report`
    );
  }
  return apiCall(
    `/api/v4/retailers/${retailerId}/consumer_brands/${consumerBrandId}/policies/${policyId}/generate_report`
  );
};
