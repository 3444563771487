import { CircularProgress, Grid } from '@material-ui/core';
import emoji from 'constants/emoji';
import { Text, TextKind, TextElement } from 'design-system/components';

const UploadInProgress = () => {
  return (
    <Grid item lg={7}>
      <Grid container>
        <Grid item lg={2} style={{ textAlign: 'center' }}>
          <CircularProgress color="primary" size={60} />
        </Grid>
        <Grid item lg={10}>
          <Text kind={TextKind.TextLGBold} element={TextElement.P}>
            We are processing your information
          </Text>
          <Text kind={TextKind.TextSM} element={TextElement.P}>
            This may take up to a minute. Thanks for your patience{' '}
            <span role="img" aria-label="thanks-emoji">
              {emoji.folded_hands}
            </span>
          </Text>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UploadInProgress;
