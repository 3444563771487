export const OpenAxis = {
  X: 'x',
  Y: 'y',
};

export const OpenDirection = {
  Up: 'up',
  Right: 'right',
  Down: 'down',
  Left: 'left',
};
