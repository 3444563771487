import styles from './table-body-cell.module.scss';
import propTypes from 'prop-types';

function TableBodyCell(props) {
  return (
    <td
      className={styles.root}
      data-cy={props[`data-cy`]}
      colSpan={props.colSpan}
    >
      {props.children}
    </td>
  );
}

TableBodyCell.propTypes = {
  children: propTypes.any.isRequired,
  [`data-cy`]: propTypes.string,
  colSpan: propTypes.number,
};

export default TableBodyCell;
