const handleErrors = async (errorHandlers, error) => {
  const handlerObj = errorHandlers.find((handlerObj) =>
    isMatch(handlerObj, error)
  );

  if (handlerObj) {
    return (await handlerObj.handler(error)) || true;
  }

  return false;
};

export const isMatch = (handlerObj, error) => {
  if (isDefault(handlerObj)) {
    return true;
  }

  if (
    handlerObj.statusCode &&
    handlerObj.statusCode !== error.response?.status
  ) {
    return false;
  }

  if (handlerObj.code && error.responseData?.error?.code !== handlerObj.code) {
    return false;
  }

  return true;
};

const isDefault = (handlerObj) => !handlerObj.statusCode && !handlerObj.code;

export default handleErrors;
