import { useState, useCallback } from 'react';
import {
  Divider,
  PhosphorIcon,
  PhosphorIconWeight,
  Radio,
  RadioGroup,
  SearchBar,
  Link,
  LinkKind,
  LinkVariant,
  Button,
  ButtonKind,
  Text,
  TextKind,
  TextElement,
  PillTextArea,
} from 'design-system/components';
import { Color } from 'design-system/data';
import styles from './sku-details-form.module.scss';

import { FormInput, FormRow, PillList } from '../../shared/components/Form';
import { useFDAProductContext } from '../Detail/Context';

const SkuDetailsForm = ({ disabled = false }) => {
  const {
    formState,
    handleFormStateChange,
    setAddManufacturerDialogOpen,
    allManufacturers,
    allProductCodes,
    productRef,
  } = useFDAProductContext();

  const [filteredProductCodes, setFilteredProductCodes] =
    useState(allProductCodes);

  // product code option click logic
  const handleOptionClick = (option) => {
    // product codes is [{ id, code, name }, ...]
    let filtersToStage = [...formState.productCodes];
    if (filtersToStage.some((filter) => filter.code === option.code)) {
      filtersToStage = filtersToStage.filter(
        (filter) => filter.code !== option.code
      );
    } else {
      filtersToStage.push(option);
    }
    // filtersToStage.push(option);
    handleFormStateChange('productCodes', filtersToStage);
  };

  const handleOptionRemove = (optionIndex) => {
    const filtersToStage = formState.productCodes.filter(
      (_, index) => index !== optionIndex
    );
    handleFormStateChange('productCodes', filtersToStage);
  };

  // fuzzy search for product codes
  const search = useCallback(
    (value) => {
      const filtered = allProductCodes.filter((code) => {
        return code.name.toLowerCase().includes(value.toLowerCase());
      });
      setFilteredProductCodes(filtered);
    },
    [allProductCodes]
  );

  const [filteredManufacturers, setFilteredManufacturers] =
    useState(allManufacturers);

  // product code option click logic
  const handleManufacturerOptionClick = (option) => {
    // product codes is [{ id, code, name }, ...]
    let filtersToStage = [...formState.manufacturers];
    if (filtersToStage.some((filter) => filter.name === option.name)) {
      filtersToStage = filtersToStage.filter(
        (filter) => filter.name !== option.name
      );
    } else {
      filtersToStage.push(option);
    }
    handleFormStateChange('manufacturers', filtersToStage);
  };

  const handleManufacturerOptionRemove = (optionIndex) => {
    const filtersToStage = formState.manufacturers.filter(
      (_, index) => index !== optionIndex
    );
    handleFormStateChange('manufacturers', filtersToStage);
  };

  // lowercase search for manufacturers
  const searchManufacturer = useCallback(
    (value) => {
      const filtered = allManufacturers.filter((code) => {
        return code.name.toLowerCase().includes(value.toLowerCase());
      });
      setFilteredManufacturers(filtered);
    },
    [allManufacturers]
  );

  return (
    <form
      className={styles.root}
      ref={productRef}
      onSubmit={(event) => event.preventDefault()}
    >
      {/* Row 1 */}
      <FormRow
        title="SKU name"
        fieldId="product__name"
        description="FDA does not accept &, © or ™ in the product name"
        field={
          <FormInput
            type="text"
            id="product__name"
            placeholder="Name"
            value={formState.productName}
            onChange={(e) =>
              handleFormStateChange('productName', e.target.value)
            }
            disabled={disabled}
          />
        }
      />
      <Divider />

      {/* Row 2 */}
      <FormRow
        title="SKU identification"
        fieldId="product__sku-id"
        description="Add any SKU ID number that you use internally at your brand here."
        field={
          <FormInput
            type="text"
            id="product__sku-id"
            placeholder="ID number"
            value={formState.productIdentifier}
            onChange={(e) =>
              handleFormStateChange('productIdentifier', e.target.value)
            }
            disabled={disabled}
          />
        }
      />
      <Divider />

      {/* Row 3 */}
      <FormRow
        title="Product listing number"
        fieldId="product__listing-number"
        description="If this SKU has previously been listed with the FDA and assigned a product listing number, please include it here. If this is your first time registering this SKU, leave this blank and the FDA will assign a number for you."
        field={
          <FormInput
            type="text"
            id="product__listing-number"
            placeholder="Listing number"
            value={formState.productListingNumber}
            onChange={(e) =>
              handleFormStateChange('productListingNumber', e.target.value)
            }
            disabled={disabled}
          />
        }
      />
      <Divider />

      {/* Row 4 */}
      <FormRow
        title="SKU URL"
        fieldId="product__sku-url"
        description="Please include a link to to this SKU’s product description page or another relevant webpage link."
        field={
          <FormInput
            type="text"
            id="product__sku-url"
            placeholder="http://www.example.com"
            value={formState.productWebpageLink}
            onChange={(e) =>
              handleFormStateChange('productWebpageLink', e.target.value)
            }
            disabled={disabled}
            icon={
              <PhosphorIcon
                iconName="Globe"
                size="1.5rem"
                weight={PhosphorIconWeight.Light}
                color={Color.Neutral600.value}
              />
            }
          />
        }
      />
      <Divider />

      {/* Row 5 */}
      <FormRow
        title="Fragrance and/or flavor"
        description="Please select if this SKU has fragrance and/or flavor. If it has neither, please select none."
        field={
          <RadioGroup
            value={formState.fragranceOrFlavor}
            onChange={(value) =>
              handleFormStateChange('fragranceOrFlavor', value)
            }
            required
            name="fragrance-or-flavor"
            disabled={disabled}
          >
            <Radio value="Fragrance" label="Fragrance" />
            <Radio value="Flavor" label="Flavor" />
            <Radio value="Fragrance_and_Flavor" label="Fragrance and flavor" />
            <Radio value="NA" label="None" />
          </RadioGroup>
        }
      />
      <Divider />

      {/* Row 6 */}
      <FormRow
        title="Professional use"
        description={
          <>
            Is this SKU for professional use only? <br />
            Ex: For hair stylists, not customers
          </>
        }
        field={
          <RadioGroup
            value={formState.isProfessionalUseOnly}
            onChange={(value) =>
              handleFormStateChange('isProfessionalUseOnly', value)
            }
            required
            name="is-professional-use-only"
            disabled={disabled}
          >
            <Radio value="YES" label="Yes" />
            <Radio value="NO" label="No" />
          </RadioGroup>
        }
      />
      <Divider />

      {/* Row 7 */}
      <FormRow
        title="Ingredients"
        fieldId="product__ingredients"
        field={
          disabled ? (
            <Text kind={TextKind.TextSM} element={TextElement.P}>
              {formState.ingredients}
            </Text>
          ) : (
            <PillTextArea
              placeholder="Separated by comma"
              id="product__ingredients"
              value={formState.ingredients}
              onChange={(value) => handleFormStateChange('ingredients', value)}
              required
            />
          )
        }
      />
      <Divider />

      {/* Row 3 */}
      <FormRow
        title="Product category code"
        fieldId="product__code"
        description={
          <>
            To find the SKUs product category code please refer to Appendix A
            (page 14) of FDA’s final draft guidance found{' '}
            <Link
              kind={LinkKind.External}
              variant={LinkVariant.Default}
              href={'https://www.fda.gov/media/170732/download'}
            >
              here
            </Link>
            .
          </>
        }
        field={
          disabled ? (
            <Text kind={TextKind.TextSM} element={TextElement.P}>
              {formState.productCodes?.map((code) => code.code).join(', ')}
            </Text>
          ) : (
            <div className={styles['stacked-field']}>
              <SearchBar
                placeholder="Search for product code"
                results={filteredProductCodes}
                onChange={search}
                multiSelect
                onClick={handleOptionClick}
                activeIDs={formState.productCodes}
              />
              <PillList
                values={formState.productCodes?.map((code) => code.name)}
                onRemove={handleOptionRemove}
                required
              />
            </div>
          )
        }
      />
      <Divider />

      {/* Row 8 */}
      <FormRow
        title="Manufacturer"
        description="Please add the facility where this SKU is manufactured or processed"
        field={
          disabled ? (
            <Text kind={TextKind.TextSM} element={TextElement.P}>
              {formState.manufacturers
                ?.map((manufacturer) => manufacturer.name)
                .join(', ')}
            </Text>
          ) : (
            <div className={styles['stacked-field']}>
              <div className={styles.row}>
                <SearchBar
                  placeholder="Search for manufacturer"
                  results={filteredManufacturers}
                  onChange={searchManufacturer}
                  multiSelect
                  onClick={handleManufacturerOptionClick}
                  activeIDs={formState.manufacturers}
                />
                <Button
                  kind={ButtonKind.Tertiary}
                  onClick={(e) => {
                    e.preventDefault();
                    setAddManufacturerDialogOpen(true);
                  }}
                  usePhosphorIcon={true}
                  iconName="Plus"
                >
                  New
                </Button>
              </div>
              <PillList
                values={formState.manufacturers?.map(
                  (manufacturer) => manufacturer.name
                )}
                onRemove={handleManufacturerOptionRemove}
                required
              />
            </div>
          )
        }
      />
      <Divider />
    </form>
  );
};

export default SkuDetailsForm;
