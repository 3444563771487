export const MAX_PRODUCTS = 1000;
export const MAX_UNIQUE_ID_LENGTH = 60;
export const MAX_PRODUCT_NAME_LENGTH = 1000;
export const MAX_UPC_NUMBER_LENGTH = 12;
export const PRODUCT_TYPE_BPC = 'Beauty and Personal Care';
export const fileTypeError =
  'Upload failed. Please try again with a .csv file.';
export const multipleFileError = `Upload failed. Please upload only one .csv file at a time.`;
export const noDataError = 'CSV Format Error: No data found in the file.';
export const maxProductsError = `Please note: Your CSV file contains more than the allowed ${MAX_PRODUCTS} products. Please reduce the amount of products and try again or reach out directly to support to handle additional uploads.`;

export const allowedColumns = [
  'Product Type',
  'Unique ID',
  'UPC',
  'Product Name',
  'Product Line',
  'Product Category',
  'Sales Channels',
  'Intended Use',
  'Intended Application',
  'Product Format',
  'Additional Product Details',
  'Flavor',
  'Fragrance',
  'Ingredients',
];
export const requiredColumns = [
  'Unique ID',
  'Product Name',
  'Additional Product Details',
  'Ingredients',
];
export const validProductTypes = [PRODUCT_TYPE_BPC, 'Food', 'Other'];
// source of truth found here https://novicore.noviconnect.com/admin/legacy/productuses/
export const validIntendedApplications = [
  'Armpit',
  'Bath',
  'Body',
  'Eye',
  'Face',
  'Feet',
  'Food & Beverage',
  'Hair',
  'Hands',
  'Home Care',
  'Lip',
  'Mucosa (Other)',
  'Nails',
  'Nasal',
  'Oral',
  'Scalp',
  'Supplement',
];
// source of truth found here https://novicore.noviconnect.com/admin/legacy/productforms/
export const productFormatOptions = [
  'Aerosol (Pressurized)',
  'Bar/Solid',
  'Cream/Gel/Lotion/Paste',
  'Foam/Mousse',
  'Liquid',
  'Loose Powder',
  'Pressed Powder',
  'Pump spray/mists',
  'Sheet Mask',
  'Wax/Stick',
];
// source of truth found here https://novicore.noviconnect.com/admin/legacy/productclaims/
export const additionalProductDetailsOptions = [
  'Aftershave',
  'Alcohol',
  'Baby Care',
  'CBD/THC',
  'Color Cosmetic',
  'Depilatory',
  'None',
  'Over the Counter (OTC)',
  'Self-Tanning',
  'Sunscreen/SPF Claim',
  'Wound Care',
];
