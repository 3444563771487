import LocalStorage from 'classes/LocalStorage';
import history from 'utils/history';
import { setToken } from 'utils/token';
/** This class exists to wrap data from auth0 with some helper methods to
 * centralize authentication related logic.
 * (eventually let's take all the redirect stuff out of here and put most of it
 * in its own class)
 */
export default class Auth0 {
  // For the most part, this will act as a storage for a few
  // values returned by our authentication process:
  //    this.client - auth0 client library
  //    this.token -  authentication token (JWT)
  //    this.isAuthenticated - flag indicating whether user has a valid JWT
  //    this.user - a collection of values that auth0 stores about the user
  //
  constructor(props = {}) {
    Object.entries(props).forEach(([key, value]) => {
      this[key] = value;
    });
  }

  // let's send users to the login page while preserving their intended destination
  redirectToLogin() {
    this.client.buildAuthorizeUrl().then((authUrl) => {
      const url = new URL(authUrl);
      this.state = url.searchParams.get('state');
      this.setRedirect(window.location.href);
      window.location.href = url.toString();
    });
  }

  // accepts a string url representing the current url a user will
  // be redirected away from to the login page for authentication.
  // this function will determine where to redirect the user after
  // login, and store that information.
  setRedirect(stringUrl) {
    if (!stringUrl) {
      // clear redirect
      return this._redirectStorage.clearCache();
    }
    const url = new URL(stringUrl);
    const param = url.searchParams.get('redirect') || url.searchParams.get('r');
    let redirect;
    if (param) {
      redirect = decodeURIComponent(param);
    } else {
      redirect = url.pathname + url.search + url.hash;
    }
    if (!this.inDenyList(redirect)) {
      this._redirectStorage.value = redirect;
    }
  }

  getRedirect() {
    return this._redirectStorage.value;
  }

  hasRedirect() {
    return this._redirectStorage.isFresh();
  }

  consumeRedirect() {
    const redirect = this.getRedirect();
    this.setRedirect();
    this._redirectStorage.clearNamespace();
    return redirect;
  }

  consumeLoginState() {
    const url = new URL(window.location.href);
    this.state = url.searchParams.get('state');
    url.searchParams.delete('code');
    url.searchParams.delete('state');
    history.replace(url.pathname + url.search + url.hash);
  }

  inDenyList(url) {
    return /(sign-in|sign-out)/i.test(url);
  }

  async refreshToken() {
    // the order of these calls matter. getTokenSilently must be called before
    // the other two.
    this.token = await this.client.getTokenSilently();
    setToken(this.token);
    this.isAuthenticated = await this.client.isAuthenticated();
    this.user = await this.client.getUser();
  }

  // private methods

  get _redirectStorage() {
    return new LocalStorage({
      namespace: 'auth0-login-redirect',
      key: this.state,
      cacheTime: 1000 * 60 * 60 * 12, // 12hr
    });
  }
}
