import {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';
import styled from 'styled-components/macro';
import Dialog from '@material-ui/core/Dialog';

import {
  Button,
  ButtonKind,
  Text as DSText,
  TextKind,
} from 'design-system/components';
import Text from 'components/Text';
import Emoji from 'components/Emoji';
import Spacer from 'components/Spacer';
import Input from 'components/Input';
import Flex from 'components/Flex';
import useFetcher from 'services/api/useFetcher';
import Ellipsis from 'components/Ellipsis';
import { useSnackbar } from 'notistack';
import {
  AnalyticsEventProvider,
  useAnalyticsEvent,
} from 'services/analytics/AnalyticsContext';
import useQueryParams from 'hooks/useQueryParams';
import { useApp } from 'context/AppContext';
import { ROLES } from 'constants/index';
import Divider from 'components/Divider';
import { Link } from 'react-router-dom';
import * as Sentry from '@sentry/react';

export const ReferralContext = createContext();
export const useReferral = () => {
  const ctx = useContext(ReferralContext);
  if (!ctx) throw new Error('must be used withing a ReferralProvider');
  return ctx;
};

export function ReferralProvider(props) {
  const { children } = props;
  const [isOpen, setOpen] = useState(false);
  const { referral_modal } = useQueryParams('referral_modal');

  useEffect(() => {
    if (referral_modal.value === 'true') setOpen(true);
  }, [referral_modal]);

  const context = useMemo(() => {
    return {
      openDialog: () => setOpen(true),
      closeDialog: () => {
        if (referral_modal.value === 'true') {
          referral_modal.replace();
        }
        setOpen(false);
      },
      toggleDialog: () => setOpen((was) => !was),
    };
  }, [referral_modal]);

  return (
    <ReferralContext.Provider value={context}>
      {children}
      <AnalyticsEventProvider label="referral" category="referral_modal">
        <ReferralDialog open={isOpen} onClose={context.closeDialog} />
      </AnalyticsEventProvider>
    </ReferralContext.Provider>
  );
}

const ReferralDialog = styled((props) => {
  const { children, ...rest } = props;

  const [state, setState] = useState({
    pending: false,
    error: false,
    showSuccessDialog: false,
  });
  const formData = useRef({ org_name: '', contact_first_name: '' });

  const { closeDialog } = useReferral();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const fetcher = useFetcher();
  const { analyticsWrap } = useAnalyticsEvent();
  const { user } = useApp();

  const onCancel = () => {
    closeSnackbar();
    closeDialog();
    setState((was) => ({ ...was, showSuccessDialog: false }));
  };

  const onSubmit = async (e) => {
    e?.preventDefault();

    const f = new FormData(e?.target);
    const payload = {
      org_name: f.get('brand-name')?.trim(),
      contact_first_name: f.get('contact_first_name')?.trim(),
      contact_last_name: f.get('contact_last_name')?.trim(),
      contact_email: f.get('contact_email')?.trim(),
      note: f.get('note')?.trim(),
      org_type: 'brand',
    };
    formData.current = payload;

    try {
      // PDCT-785 React batching may disrupt this.
      setState((was) => ({ ...was, error: false, pending: true }));
      await fetcher(`/api/v4/referrals`, {
        method: 'POST',
        body: payload,
      });
      enqueueSnackbar(
        `Success! ${payload.contact_first_name} ${payload.contact_last_name} was referred`,
        {
          variant: 'success',
          preventDuplicate: false,
        }
      );
      setState((was) => ({ ...was, showSuccessDialog: true }));
    } catch (e) {
      setState((was) => ({ ...was, error: e }));
      if (e.message === 'Unexpected server response') {
        Sentry.captureException({ e, msg: 'during referral' });
      }
      enqueueSnackbar(e.message, { variant: 'error', preventDuplicate: false });
    } finally {
      setState((was) => ({ ...was, pending: false }));
    }
  };

  return (
    <Dialog {...rest}>
      {state.showSuccessDialog ? (
        <>
          <Text as="h1" fontSize={18}>
            We've sent a referral to {formData.current.contact_first_name} from{' '}
            {formData.current.org_name}! <Emoji name="thumbs_up" />
          </Text>
          <Divider color="#e8e8e8" margin="8px 0 12px 0" />
          <Text as="h2" fontWeight="500" fontSize={16} mb={12}>
            What happens now?
          </Text>
          {user.hasRole(ROLES.brand) && (
            <Text fontSize="16px">
              We've sent {formData.current.contact_first_name} from{' '}
              {formData.current.org_name} an invitation to join Novi. Once they
              have accepted the invitation, you will both receive a promotional
              code to use on our <Link to="/brand/discover">marketplace</Link>.{' '}
            </Text>
          )}
          {user.hasRole(ROLES.contractManufacturer) && (
            <Text fontSize="16px">
              We've sent {formData.current.contact_first_name} from{' '}
              {formData.current.org_name} an invitation to join Novi. Once they
              and 9 others have accepted their invitations, you will receive a
              $1000 off promotional code.
            </Text>
          )}
          <Spacer size={16} />
          <Button kind={ButtonKind.Primary} onClick={onCancel}>
            <DSText kind={TextKind.TextSMBold}>Close</DSText>
          </Button>
          {children}
        </>
      ) : (
        <form
          onSubmit={() => {
            analyticsWrap(
              {
                action: 'click_send_referral',
              },
              onSubmit
            )();
          }}
        >
          <Text as="h1" fontSize={18} mb={20}>
            Refer Novi to a Brand
          </Text>
          <ReferralCTA />
          <Spacer size={20} />
          <Text as="label" htmlFor="brand-name" mb="4px">
            Company Name *
          </Text>
          <Input required type="text" name="brand-name" />
          <Spacer size={20} />
          <Flex className="contact-names">
            <Flex flexDirection="column" flex="1 0 auto">
              <Text as="label" htmlFor="contact_first_name" mb="4px">
                Contact First Name *
              </Text>
              <Input required type="text" name="contact_first_name" />
            </Flex>
            <Spacer size={20} />
            <Flex flexDirection="column" flex="1 0 auto">
              <Text as="label" htmlFor="contact_last_name" mb="4px">
                Contact Last Name *
              </Text>
              <Input required type="text" name="contact_last_name" />
            </Flex>
          </Flex>
          <Spacer size={20} />
          <Text as="label" htmlFor="contact_email" mb="4px">
            Contact Email *
          </Text>
          <Input required type="email" name="contact_email" />
          <Spacer size={20} />
          <Text as="label" htmlFor="note" mb="4px">
            Note (Optional)
          </Text>
          <Input as="textarea" name="note" />
          <Spacer size={40} />
          <Flex justifyContent="flex-end">
            <Button
              type="button"
              onClick={() => {
                analyticsWrap(
                  {
                    action: 'click_cancel',
                  },
                  onCancel
                )();
              }}
              kind={ButtonKind.Secondary}
            >
              Cancel
            </Button>
            <Spacer size={8} />
            <Button
              disabled={state.pending}
              type="submit"
              kind={ButtonKind.Primary}
              onClick={() => {}}
            >
              {state.pending ? (
                <Ellipsis>Sending</Ellipsis>
              ) : (
                <Text kind={TextKind.TextSMBold}>Send Referral</Text>
              )}
            </Button>
          </Flex>
          {children}
        </form>
      )}
    </Dialog>
  );
})`
  .MuiDialog-paper {
    padding: 20px 25px;
    width: 100%;
    max-width: 630px;

    form {
      display: flex;
      flex-direction: column;
    }

    label {
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
    }
  }

  @media screen and (max-width: 600px) {
    .contact-names {
      flex-direction: column;
    }
  }
`;

export const ReferralCTA = styled((props) => {
  const { children, ...rest } = props;
  const { user } = useApp();

  if (user?.hasRole(ROLES.brand)) {
    return (
      <div {...rest}>
        <FloatingEmojiBox emoji="sparkles">$25</FloatingEmojiBox>
        <Spacer size={15} />
        <div className="text">
          Refer a brand for free access to Novi's marketplace. Get $25 off your
          next order on Novi.
        </div>
        {children}
      </div>
    );
  }

  if (user?.hasRole(ROLES.contractManufacturer)) {
    return (
      <div {...rest}>
        <FloatingEmojiBox emoji="sparkles">$1000</FloatingEmojiBox>
        <Spacer size={15} />
        <div className="text">
          Refer 10 brands and receive $1000 off your next order on Novi.
        </div>
        {children}
      </div>
    );
  }

  return null;
})`
  border-radius: 10px;
  /* TODO can we make this work for both brand colors? */
  background-color: hsla(var(--brand-color-h), 74%, 95%, 1);
  width: 100%;
  padding: 24px 24px 12px 24px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  .text {
    font-size: 16px;
    color: var(--brand-color);
    width: 60%;
    text-align: center;
  }
`;

const FloatingEmojiBox = styled((props) => {
  const { children, emoji, ...rest } = props;
  return (
    <div {...rest}>
      <Emoji name={emoji} />
      {children}
    </div>
  );
})`
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    hsla(var(--brand-color-h), 50%, 96%, 1) 100%
  );
  border: 1px solid #c9bfcd;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.21);
  border-radius: 10px;
  font-size: 32px;
  line-height: 48px;
  font-weight: bold;
  padding: 18px;
  color: var(--brand-color);
  position: relative;

  ${Emoji} {
    font-size: 1.25em;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(35%, -40%);
  }
`;
