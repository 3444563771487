import { UrlParam } from 'design-system/data';
import { convertSnakeToCamelCase } from 'design-system/utils';
import useQueryParams from 'hooks/useQueryParams';
import { useApp } from 'context/AppContext';

export function useRetailerRequirements(
  retailerPolicyRequirementGroupId,
  policyRequirementId,
  consumerBrandId,
  statusFilters
) {
  const params = useQueryParams(Object.values(UrlParam));
  const { useApi } = useApp();
  const url = `/api/v4/retailer_policy_requirement_groups/${retailerPolicyRequirementGroupId}/requirements`;
  const { data, loading, error } = useApi(url, {
    enabled: !!retailerPolicyRequirementGroupId && !!consumerBrandId,
    param: {
      ...params._asValues(),
      policy_requirement_id: policyRequirementId,
      consumer_brand_id: consumerBrandId,
      items: 10,
      status: statusFilters,
    },
  });
  return {
    data: convertSnakeToCamelCase(data),
    loading,
    error,
  };
}
