import { number } from 'prop-types';

function ChatColorful({ size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 13.3332C12 11.8604 13.1939 10.6665 14.6667 10.6665H28C29.4728 10.6665 30.6667 11.8604 30.6667 13.3332V21.3332C30.6667 22.8059 29.4728 23.9998 28 23.9998H14.6667C13.1939 23.9998 12 22.8059 12 21.3332V13.3332ZM22.6667 17.3333C22.6667 18.0697 22.0697 18.6667 21.3333 18.6667C20.597 18.6667 20 18.0697 20 17.3333C20 16.597 20.597 16 21.3333 16C22.0697 16 22.6667 16.597 22.6667 17.3333ZM26.6667 18.6667C27.403 18.6667 28 18.0697 28 17.3333C28 16.597 27.403 16 26.6667 16C25.9303 16 25.3333 16.597 25.3333 17.3333C25.3333 18.0697 25.9303 18.6667 26.6667 18.6667ZM17.3333 12C17.3333 12.7364 16.7364 13.3333 16 13.3333C15.2636 13.3333 14.6667 12.7364 14.6667 12C14.6667 11.2636 15.2636 10.6667 16 10.6667C16.7364 10.6667 17.3333 11.2636 17.3333 12ZM26.6667 24V25.421C26.6667 25.9535 26.0732 26.271 25.6302 25.9757L22.6667 24H26.6667Z"
        fill="url(#paint0_linear_2916_61050)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33398 7.99967C1.33398 6.52691 2.52789 5.33301 4.00065 5.33301H17.334C18.8067 5.33301 20.0007 6.52692 20.0007 7.99967V15.9997C20.0007 17.4724 18.8067 18.6663 17.334 18.6663H4.00065C2.52789 18.6663 1.33398 17.4724 1.33398 15.9997V7.99967ZM6.66732 11.9997C6.66732 12.7361 6.07036 13.333 5.33398 13.333C4.5976 13.333 4.00065 12.7361 4.00065 11.9997C4.00065 11.2633 4.5976 10.6663 5.33398 10.6663C6.07036 10.6663 6.66732 11.2633 6.66732 11.9997ZM10.6673 13.333C11.4037 13.333 12.0007 12.7361 12.0007 11.9997C12.0007 11.2633 11.4037 10.6663 10.6673 10.6663C9.93094 10.6663 9.33398 11.2633 9.33398 11.9997C9.33398 12.7361 9.93094 13.333 10.6673 13.333ZM17.334 11.9997C17.334 12.7361 16.737 13.333 16.0007 13.333C15.2643 13.333 14.6673 12.7361 14.6673 11.9997C14.6673 11.2633 15.2643 10.6663 16.0007 10.6663C16.737 10.6663 17.334 11.2633 17.334 11.9997ZM5.33366 18.6665V20.0875C5.33366 20.62 5.92709 20.9376 6.37013 20.6422L9.33366 18.6665H5.33366Z"
        fill="url(#paint1_linear_2916_61050)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2916_61050"
          x1="12"
          y1="10.6665"
          x2="30.1973"
          y2="24.1516"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB673" />
          <stop offset="1" stopColor="#FF620A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2916_61050"
          x1="20.0007"
          y1="5.33301"
          x2="1.80335"
          y2="18.8181"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A7D3FF" />
          <stop offset="1" stopColor="#1481EE" />
        </linearGradient>
      </defs>
    </svg>
  );
}

ChatColorful.propTypes = {
  size: number,
};

export default ChatColorful;
