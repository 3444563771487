import { datadogRum } from '@datadog/browser-rum';
import isDevelopmentEnv from 'utils/isDevelopmentEnv';

const enableDD = !isDevelopmentEnv();
export class DatadogAnalyticsVendor {
  // Track event with properties, naming event by unique action name
  track = (properties) => {
    if (enableDD) {
      const { event_action: eventName, ...remainingEventProperies } =
        properties;
      datadogRum.addAction(eventName, remainingEventProperies);
    }
  };
}
