import { useCallback } from 'react';
import { useFDAProductContext } from '../Detail/Context';
import {
  Text,
  TextKind,
  TextElement,
  ButtonKind,
  Modal,
  ModalType,
} from 'design-system/components';
import { Color } from 'design-system/data';

import styles from './add-manufacturer-dialog.module.scss';
import ManufacturerForm from './ManufacturerForm';

export const AddManufacturerDialog = () => {
  const {
    addManufacturerDialogOpen,
    setAddManufacturerDialogOpen,
    handleCreateManufacturer,
  } = useFDAProductContext();

  const onCancel = () => setAddManufacturerDialogOpen(false);

  const onComplete = useCallback(async () => {
    const saveResponseSuccessful = await handleCreateManufacturer();
    if (saveResponseSuccessful) {
      setAddManufacturerDialogOpen(false);
    }
  }, [handleCreateManufacturer, setAddManufacturerDialogOpen]);

  return (
    <Modal
      show={addManufacturerDialogOpen}
      title="Add a manufacturer"
      type={ModalType.Large}
      onClick={onCancel}
      showActionButtons
      dataCyRoot="add-manufacturer-dialog-modal"
      cancelCtaLabel="Cancel"
      cancelCtaKind={ButtonKind.Tertiary}
      onCancel={onCancel}
      doneCtaLabel="Save"
      doneCtaKind={ButtonKind.Primary}
      onDone={onComplete}
    >
      <>
        <Text
          color={Color.Neutral600}
          kind={TextKind.TextSM}
          element={TextElement.P}
          className={styles['add-manufacturer-dialog-text']}
        >
          Please add details for the facility where this SKU is manufactured or
          processed
        </Text>
        <ManufacturerForm />
      </>
    </Modal>
  );
};
