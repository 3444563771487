import propTypes from 'prop-types';
import { Pill } from 'design-system/components';
import styles from './order-status-pill.module.scss';
import { StatusLabels } from './constants';

export default function OrderStatusPill({ status }) {
  const className = status.split('_').join('-');

  return (
    <div className={styles[className]}>
      <Pill>{StatusLabels[status]}</Pill>
    </div>
  );
}

OrderStatusPill.propTypes = {
  status: propTypes.string.isRequired,
};
