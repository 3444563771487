import { sentry } from 'utils/sentry';

export const formatResponseForLogging = (error) =>
  `
    Request method: ${error.request?.method}
    Request headers: ${JSON.stringify(error.request?.headers || {})}
    Response status: ${error.response?.status}
    Response body: ${JSON.stringify(error.responseData || {})}
    Response headers: ${error.response?.headers}
  `;

const getUrlPath = (url) => {
  if (!url) {
    return null;
  }

  const urlObj = new URL(url);

  return urlObj.pathname;
};

export const logToSentry = (error) => {
  sentry.withScope((scope) => {
    scope.setFingerprint([
      error.request?.method,
      getUrlPath(error.request?.url),
      String(error.response?.status),
      error.message,
    ]);
    sentry.captureMessage(
      `${error.response?.status} error making request to ${
        error.request?.url
      }: ${formatResponseForLogging(error)}`
    );
  });
};
