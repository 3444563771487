import styled from 'styled-components';
import { Autocomplete } from '@material-ui/lab';
import { Checkbox, TextField } from '@material-ui/core';
import Icon from 'components/Icon';

export default function MultiSelectAutocomplete(props) {
  const { className, options, onChange, dataCy } = props;
  // TODO: AUTOCOMPLETE INPUT (Will just delete this when we update the other autocomplete inputs)
  return (
    <Autocomplete
      multiple
      fullWidth
      disableCloseOnSelect
      className={className}
      data-cy={dataCy}
      size="small"
      options={options || []}
      getOptionLabel={({ name }) => name}
      renderOption={({ id, name }, { selected }) => (
        <Option key={id} data-cy={`multi-select-option-${id}`}>
          <Checkbox
            icon={<Icon name="HCheckboxOff" size={24} />}
            checkedIcon={<Icon name="HCheckboxOn" size={24} />}
            checked={selected}
          />
          {name}
        </Option>
      )}
      renderInput={(params) => <Input {...params} />}
      onChange={onChange}
      getOptionSelected={(opt, val) => opt.id === val.id}
    />
  );
}

const Option = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
  align-items: center;
`;

const Input = styled((props) => {
  return <TextField {...props} variant="outlined" />;
})`
  background-color: white;
`;
