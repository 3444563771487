import { useState, useCallback } from 'react';

/* Returns a ref function that makes any changes to the DOM element a stateful
 * update which provokes a render when used in a component.
 *
 * Usage:
 * function Component() {
 *   const { ref, element } = useTrackElement();
 *   return <div ref={ref}>
 *     Element width: {element.getBoundingClientRect().width}
 *   </div>;
 * }
 */
export default function useTrackElement() {
  const [element, setElement] = useState();
  return {
    ref: useCallback((node) => setElement(node), []),
    element,
  };
}
