import useApi from 'services/api/useApi';

const useRetailerSelection = () => {
  const { data, loading, error } = useApi('/api/v4/retailers');

  return {
    data,
    loading,
    error,
  };
};

export default useRetailerSelection;
