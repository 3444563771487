import PropTypes from 'prop-types';

function Package(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 2L3 5L8 8L13 5L8 2Z"
        stroke={props.color.value}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M3 11V5L8 8V14L3 11Z"
        stroke={props.color.value}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M13 5L8 8V14L13 11V5Z"
        stroke={props.color.value}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
}

Package.propTypes = {
  color: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  size: PropTypes.number,
};

export default Package;
