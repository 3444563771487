import useApi from 'services/api/useApi';

const useRetailerBrand = (id) => {
  const {
    data: lastSelectedRetailerBrand,
    error,
    loading,
  } = useApi(`/api/v4/retailer_consumer_brands/${id}`, {
    enabled: Boolean(id),
  });
  return {
    lastSelectedRetailerBrand,
    error,
    loading,
  };
};

export default useRetailerBrand;
