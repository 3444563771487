import {
  Button,
  ButtonKind,
  Breadcrumbs,
  Text,
  TextKind,
  TextElement,
  PhosphorIcon,
} from 'design-system/components';
import { Color } from 'design-system/data';
import { useFDAProductsContext } from 'views/FDARegistration/Products/Context';
import { useFDASubmissionConfirmationContext } from './Context';
import PageHeader from '../../shared/components/PageHeader';
import styles from './confirmation.module.scss';
import {
  SubmissionInformation,
  SubmissionSkus,
} from '../shared/SubmissionInformation';

const SubmissionConfirmation = () => {
  const { selectedSkus, selectedSkuKeys, setSelectedSkus, newSubscription } =
    useFDAProductsContext();
  const {
    setEditOpen,
    directToProducts,
    setSubmissionConfirmationDialogOpen,
    formState,
  } = useFDASubmissionConfirmationContext();

  const handleRemove = (key) => {
    setSelectedSkus((prev) => {
      const { [key]: removedSku, ...rest } = prev;
      return rest;
    });
  };

  const skuCountDisplay = (subscription) => {
    if (!subscription) return;

    if (subscription.isCustom) {
      return `${subscription.min}+`;
    } else if (subscription.min === subscription.max) {
      return subscription.min;
    }
    return `${subscription.min}-${subscription.max}`;
  };

  const pricingDisplay = (price) => {
    if (!price) return;

    const priceString = price.toString();
    if (priceString.length > 4) {
      const insertIndex = priceString.length - 3;
      return `$${priceString.slice(0, insertIndex)},${priceString.slice(
        insertIndex
      )}`;
    }
    return `$${price}`;
  };

  return (
    <div>
      <PageHeader
        title="Submission Confirmation"
        subtitle="Please check all information is correct before clicking submit. Once your invoice is paid and this information is sent to the FDA, you will not be able to make any changes."
        breadcrumbs={<Breadcrumbs items={breadcrumbsItems} />}
      />
      <div className={styles['content']}>
        <div className={styles['submission-info']}>
          <SubmissionInformation
            submissionData={formState}
            setEditOpen={setEditOpen}
          />
          <SubmissionSkus length={selectedSkuKeys.length}>
            {selectedSkuKeys.map((key, index) => {
              return (
                <div className={styles['sku-row']} key={index}>
                  <Text
                    kind={TextKind.TextSMMedium}
                    element={TextElement.P}
                    color={Color.Neutral900}
                  >
                    {selectedSkus[key]}
                  </Text>
                  <PhosphorIcon
                    className={styles.trash}
                    onClick={() => handleRemove(key)}
                    iconName="Trash"
                  />
                </div>
              );
            })}
          </SubmissionSkus>
        </div>
        <div className={styles['payment-info']}>
          <div className={styles['payment-block']}>
            <Text kind={TextKind.TextLGSemibold} element={TextElement.H5}>
              Purchase Summary
            </Text>
            <Text
              kind={TextKind.TextSM}
              element={TextElement.P}
              color={Color.Neutral600}
            >
              Novi Connect’s MoCRA product listing pricing is calculated by SKU
              count. If you would like to adjust your selections, please return
              to your SKUs page.
            </Text>
          </div>
          <div className={styles['skus-block']}>
            <div className={styles['skus-count']}>
              <Text kind={TextKind.TextMDMedium} element={TextElement.P}>
                {skuCountDisplay(newSubscription)} SKUs
              </Text>
              <Text
                kind={TextKind.TextSM}
                element={TextElement.P}
                color={Color.Neutral600}
              >
                Priced per year
              </Text>
            </div>
            {newSubscription?.isCustom ? (
              <Text kind={TextKind.TextSMMedium} element={TextElement.P}>
                Contact us for pricing
              </Text>
            ) : (
              <Text kind={TextKind.TextSMMedium} element={TextElement.P}>
                {pricingDisplay(newSubscription?.price)}
              </Text>
            )}
          </div>
          <div className={styles['total-block']}>
            <Text kind={TextKind.TextMDMedium} element={TextElement.P}>
              Total due
            </Text>
            {newSubscription?.isCustom ? (
              <Text kind={TextKind.TextMDMedium} element={TextElement.P}>
                Contact us for pricing
              </Text>
            ) : (
              <Text kind={TextKind.TextMDMedium} element={TextElement.P}>
                ${newSubscription?.price}
              </Text>
            )}
          </div>
          <Text
            kind={TextKind.TextXS}
            element={TextElement.P}
            color={Color.Neutral500}
            className={styles.footnote}
          >
            Prices shown subject to state and local taxes where applicable.
          </Text>
          <div className={styles['actions-block']}>
            <Button
              disabled={
                !formState.mailingFacilityName ||
                !formState.mailingAddressLine1 ||
                !formState.mailingCountryCode ||
                !formState.mailingPostalCode ||
                !formState.mailingCity ||
                !formState.mailingRegion ||
                selectedSkuKeys.length < 1
              }
              onClick={() => setSubmissionConfirmationDialogOpen(true)}
            >
              Submit
            </Button>
            <Button kind={ButtonKind.Secondary} onClick={directToProducts}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubmissionConfirmation;

const baseLink = `/fda-registration/product-registration`;
const breadcrumbsItems = [
  {
    text: 'SKUs',
    link: baseLink,
  },
  {
    text: 'Submission Confirmation',
  },
];
