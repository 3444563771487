function ApiError(props) {
  const halfSize = props.size / 2;

  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={halfSize} cy={halfSize} r={halfSize} fill="#FFB9B9" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9999 7C22.3646 7 21.0592 8.36337 21.1302 9.99717L21.913 28.0019C21.9616 29.1192 22.8815 30 23.9999 30C25.1182 30 26.0382 29.1192 26.0868 28.0019L26.8696 9.99717C26.9406 8.36337 25.6352 7 23.9999 7ZM23.9999 39C25.3806 39 26.4999 37.8807 26.4999 36.5C26.4999 35.1193 25.3806 34 23.9999 34C22.6192 34 21.4999 35.1193 21.4999 36.5C21.4999 37.8807 22.6192 39 23.9999 39Z"
        fill="#FF2020"
      />
    </svg>
  );
}

export default ApiError;
