import { useMemo } from 'react';
import styled from 'styled-components/macro';
import { Color } from 'design-system/data';

export default styled(function Divider(props) {
  const {
    size = 1,
    axis = 'horizontal',
    color = Color.Black.value,
    border = 'solid',
    margin = '0',
    style: overrideStyles,
    ...rest
  } = props;

  const inlineStyles = useMemo(() => {
    return {
      '--border-width': `${size}px`,
      '--border-color': color,
      '--border-style': border,
      '--margin': margin,
      ...overrideStyles,
    };
  }, [size, color, border, margin, overrideStyles]);

  return (
    <div role="separator" style={inlineStyles} data-axis={axis} {...rest} />
  );
})`
  margin: var(--margin);
  &[data-axis='horizontal'] {
    border-top-width: var(--border-width);
    border-top-style: var(--border-style);
    border-top-color: var(--border-color);
  }
  &[data-axis='vertical'] {
    border-top-width: var(--border-width);
    border-top-style: var(--border-style);
    border-top-color: var(--border-color);
  }
`;
