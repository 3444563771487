import { createContext, useContext, useMemo, useState } from 'react';
import { useApp } from 'context/AppContext';
import { UrlParam } from 'design-system/data';
import useQueryParams from 'hooks/useQueryParams';
import { convertSnakeToCamelCase } from 'design-system/utils/case';

const FDABusinessContext = createContext(null);

export const useFDABusinessContext = () => {
  const ctx = useContext(FDABusinessContext);
  if (!ctx) {
    throw new Error('Must be used within an FDABusinessContext');
  }
  return ctx;
};

export const FDABusinessProvider = ({ children }) => {
  const { user, useApi } = useApp();
  const params = useQueryParams(Object.values(UrlParam));
  const [importOpen, setImportOpen] = useState();
  const [search, setSearch] = useState();

  const brandID = user.organization.in_orgable_id;
  const products = useApi(`api/v4/brands/${brandID}`, {
    param: { ...params._asValues() },
  });

  const onBusinessCreateSuccess = () => {
    // TODO: handle business success creation flow state
  };

  const context = useMemo(() => {
    return {
      products: convertSnakeToCamelCase(products.data),
      loading: products.loading,
      error: products.error,
      importOpen,
      setImportOpen,
      setSearch,
      search,
      onBusinessCreateSuccess,
    };
  }, [products, importOpen, search]);

  return (
    <FDABusinessContext.Provider value={context}>
      {children}
    </FDABusinessContext.Provider>
  );
};
