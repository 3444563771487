import illustration from 'assets/illustrations/empty-status-image.svg';
import EmptyState from 'components/EmptyState';

const ErrorPage = () => (
  <div>
    <EmptyState
      illustration={illustration}
      title="Something went awry"
      subtitle="Please try again or contact support@noviconnect.com"
    />
  </div>
);

export default ErrorPage;
