import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useApp } from 'context/AppContext';

const TARGET_RETAILER_NAME = 'Target';
const WIDGET_QUERY_PARAM = 'target_widget_init';
const WIDGET_PARAM_VALUE = '1';

type HubSpotConversationsWidget = {
  widget: {
    refresh: () => void;
    remove: () => void;
  };
};

declare global {
  interface Window {
    HubSpotConversations?: HubSpotConversationsWidget;
  }
}

/**
 * TargetHubSpotWidget
 *
 * This component integrates a specific HubSpot chatflow that gets activated
 * only for users in the 'Target' retailer portal. It appends a query string
 * parameter (`target_widget_init=1`) to the URL when the retailer's brand name
 * is detected as 'Target'. This query string triggers the HubSpot chat widget
 * to pull from the Target Zero HubSpot ChatFlow, ensuring users have access to
 * retailer-specific support through the Novi portal.
 *
 * ### Dependency on `useHubSpot`
 * This component relies on the `useHubSpot` hook to load the HubSpot conversations
 * widget. Specifically, the `useHubSpot` hook's `loadVerifiedWidget` function is
 * responsible for calling `window.HubSpotConversations.widget.load()` which is required
 * for the widget to function. Without this call, you may encounter the following error:
 *
 * ```
 * loadImmediately is set to false and widget.load() has not been called on
 * window.HubSpotConversations yet. Please call widget.load() first or set
 * loadImmediately on window.hsConversationsSettings to true.
 * ```
 *
 * This is specific to the Target Zero retailer brand portal and does not reuse
 * the generic HubSpot widget setup in `useHubSpot.js`, which handles general
 * HubSpot integrations across different contexts.
 *
 * The widget will not activate or add the query parameter if the retailer is
 * not 'Target'. The effect is triggered on page load.
 *
 * @returns {null} - This component does not render anything on the page.
 */
export default function TargetHubSpotWidget() {
  const { retailerBrand } = useApp();
  const history = useHistory();
  const location = useLocation();

  const loadTargetRetailerWidget = (
    retailerName: string,
    hubspotConversationsWidget: HubSpotConversationsWidget
  ) => {
    if (retailerName === TARGET_RETAILER_NAME) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set(WIDGET_QUERY_PARAM, WIDGET_PARAM_VALUE);
      history.push({ search: searchParams.toString() });
      hubspotConversationsWidget.widget.refresh();
    } else {
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete(WIDGET_QUERY_PARAM);
      history.push({ search: searchParams.toString() });
      hubspotConversationsWidget.widget.remove();
    }
  };

  useEffect(() => {
    if (!retailerBrand?.retailer?.name) return;
    if (!window.HubSpotConversations) return;
    loadTargetRetailerWidget(
      retailerBrand.retailer.name,
      window.HubSpotConversations
    );
  }, [retailerBrand?.retailer?.name, window.HubSpotConversations]);

  return null;
}
