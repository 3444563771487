import { LoadingOverlay, NoData } from 'design-system/components';
import SupplierMaterialSidePanel from './components/side-panel';
import SupplierMaterialContent from './components/content';
import styles from './supplier-material.module.scss';
import { useSupplierMaterialContext } from './context';

export default function SupplierMaterialView() {
  const { data, loading, error } = useSupplierMaterialContext();

  return loading ? (
    <LoadingOverlay show />
  ) : (
    <div className={`page-layout ${styles.container}`}>
      <SupplierMaterialSidePanel data={data} />
      {!data?.id || error ? (
        <NoData
          hasErrorOccurred={error}
          noContentMessage="No material data is available at this time."
        />
      ) : (
        <SupplierMaterialContent data={data} />
      )}
    </div>
  );
}
