import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0 from 'classes/Auth0';
import * as Sentry from '@sentry/browser';

export async function initializeAuth0() {
  let client;
  const auth0 = new Auth0();

  try {
    client = await createAuth0Client({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      redirect_uri: `${window.location.origin}/`,
      useRefreshTokens: true,
      cacheLocation: 'localstorage',
    });

    auth0.client = client;
    await auth0.refreshToken();
  } catch (e) {
    // this may cause alert noise from expired sessions, need to eventually
    // filter those out.
    if (!/login required/i.test(e.message)) {
      Sentry.captureException(e);
    }
    auth0.isAuthenticated = false;
  }

  auth0.consumeLoginState();

  return auth0;
}
