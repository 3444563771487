import {
  createContext,
  useContext,
  useMemo,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { useApp } from 'context/AppContext';
import { UrlParam } from 'design-system/data';
import useQueryParams from 'hooks/useQueryParams';
import { convertSnakeToCamelCase } from 'design-system/utils/case';
import { ImportDialog } from './components/ImportDialog';
import { useStatefulQueryParam } from 'design-system/utils';
import { STATUS_FILTERS } from './constants';
import { useAddProductDialog } from 'components/ConsumerProductDialogs/AddProductDialog';
import useFetcher from 'services/api/useFetcher';
import { PaywallDialog } from './components/PaywallDialog';
import { DeleteConfirmationDialog } from './components/DeleteConfirmationDialog';
import { useSnackbar } from 'notistack';

const FDAProductsContext = createContext(null);

export const useFDAProductsContext = () => {
  const ctx = useContext(FDAProductsContext);
  if (!ctx) {
    throw new Error('Must be used within an FDAProductsContext');
  }
  return ctx;
};

export const FDAProductsProvider = ({ children }) => {
  const { useApi } = useApp();
  const params = useQueryParams(Object.values(UrlParam));
  const [tableCounts, setTableCounts] = useState({});
  const [importOpen, setImportOpen] = useState();
  const [statusFilter, setStatusFilter] = useState('0');
  const [importSearch, setImportSearch] = useState('');
  const [paywallDialogOpen, setPaywallDialogOpen] = useState(false);
  const [selectedSkus, setSelectedSkus] = useState({});
  const [deleteConfimationOpen, setDeleteConfirmationOpen] = useState(false);
  const fetcher = useFetcher();
  const [, setUrlStatusFilter] = useStatefulQueryParam(
    UrlParam.Status,
    params[UrlParam.ConsumerProductType]
  );
  const {
    Dialog: AddProductDialog,
    openDialog: openAddProductDialog,
    closeDialog,
  } = useAddProductDialog();
  const { enqueueSnackbar } = useSnackbar();

  const products = useApi(`api/v4/certification_cosmetic_products`, {
    param: { ...params._asValues() },
  });

  const subscriptions = useApi(`api/v4/certification_subscriptions`);

  // TODO: use query param
  const productsForImport = useApi(
    `api/v4/certification_cosmetic_products/products_for_import`,
    {
      param: { product_name: importSearch },
    }
  );

  const importProducts = useCallback(
    async (selectedIds) => {
      try {
        await fetcher('api/v4/certification_cosmetic_products/import', {
          method: 'post',
          body: { consumer_product_ids: selectedIds },
        });
        products.refetch();
        enqueueSnackbar('Products imported', { variant: 'success' });
      } catch {
        enqueueSnackbar(`Potential error - Duplicate SKU name forbidden`, {
          variant: 'error',
        });
      }
    },
    [products, fetcher]
  );

  const deleteProduct = useCallback(async () => {
    try {
      await fetcher(
        `api/v4/certification_cosmetic_products/${deleteConfimationOpen}`,
        {
          method: 'delete',
        }
      );
      products.refetch();
      setDeleteConfirmationOpen(false);
      enqueueSnackbar('SKU Deleted', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  }, [deleteConfimationOpen]);

  useEffect(() => {
    setTableCounts({
      all: 1,
      needs_info: 0,
      complete: 1,
    });
  }, []); // Lets only reset these when the counts change, not every change of products (switching tabs)

  const handleStatusChange = useCallback(
    (key) => {
      setStatusFilter(key);
      setUrlStatusFilter(STATUS_FILTERS[key]);
    },
    [setUrlStatusFilter]
  );
  const onProductCreateSuccess = (product) => {
    importProducts([product.id]);
    closeDialog();
  };

  const selectedSkuKeys = useMemo(
    () => Object.keys(selectedSkus),
    [selectedSkus]
  );

  const newSubscription = useMemo(() => {
    const totalSubmittedProducts =
      selectedSkuKeys.length + products.data?.submissions_count;
    const newSub = subscriptions.data?.subscriptions?.find(
      (subscription) =>
        subscription.min <= totalSubmittedProducts &&
        subscription.max >= totalSubmittedProducts
    );
    return newSub;
  }, [selectedSkuKeys, products, subscriptions]);

  const context = useMemo(() => {
    return {
      products: convertSnakeToCamelCase(products.data),
      productsForImport: convertSnakeToCamelCase(productsForImport.data),
      loading: products.loading,
      error: products.error,
      tableCounts,
      importOpen,
      setImportOpen,
      statusFilter,
      handleStatusChange,
      openAddProductDialog,
      importProducts,
      importSearch,
      setImportSearch,
      paywallDialogOpen,
      setPaywallDialogOpen,
      selectedSkus,
      setSelectedSkus,
      subscriptions: convertSnakeToCamelCase(subscriptions.data),
      selectedSkuKeys,
      newSubscription,
      deleteProduct,
      deleteConfimationOpen,
      setDeleteConfirmationOpen,
    };
  }, [
    products,
    tableCounts,
    statusFilter,
    importOpen,
    importProducts,
    importSearch,
    setImportSearch,
    productsForImport,
    paywallDialogOpen,
    setPaywallDialogOpen,
    subscriptions,
    selectedSkus,
    setSelectedSkus,
    selectedSkuKeys,
    handleStatusChange,
    openAddProductDialog,
    newSubscription,
    deleteProduct,
    deleteConfimationOpen,
    setDeleteConfirmationOpen,
  ]);

  return (
    <FDAProductsContext.Provider value={context}>
      {children}
      <ImportDialog />
      <PaywallDialog />
      <AddProductDialog
        isProduct
        onSuccess={(product) => onProductCreateSuccess(product)}
      />
      <DeleteConfirmationDialog />
    </FDAProductsContext.Provider>
  );
};
