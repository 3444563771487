export const TextElement = {
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  P: 'p',
  Span: 'span',
  Label: 'label',
};

export const TextKind = {
  Display2XL: 'display-2xl',
  Display2XLMedium: 'display-2xl-medium',
  Display2XLBold: 'display-2xl-bold',
  DisplayXL: 'display-xl',
  DisplayXLMedium: 'display-xl-medium',
  DisplayXLBold: 'display-xl-bold',
  DisplayLG: 'display-lg',
  DisplayLGMedium: 'display-lg-medium',
  DisplayLGBold: 'display-lg-bold',
  DisplayMD: 'display-md',
  DisplayMDMedium: 'display-md-medium',
  DisplayMDBold: 'display-md-bold',
  DisplaySM: 'display-sm',
  DisplaySMMedium: 'display-sm-medium',
  DisplaySMBold: 'display-sm-bold',
  DisplayXS: 'display-xs',
  DisplayXSMedium: 'display-xs-medium',
  DisplayXSBold: 'display-xs-bold',
  Display2XS: 'display-2xs',
  Display2XSMedium: 'display-2xs-medium',
  Display2XSBold: 'display-2xs-bold',
  TextXL: 'text-xl',
  TextXLMedium: 'text-xl-medium',
  TextXLSemibold: 'text-xl-semibold',
  TextXLBold: 'text-xl-bold',
  TextLG: 'text-lg',
  TextLGMedium: 'text-lg-medium',
  TextLGSemibold: 'text-lg-semibold',
  TextLGBold: 'text-lg-bold',
  TextMD: 'text-md',
  TextMDMedium: 'text-md-medium',
  TextMDSemibold: 'text-md-semibold',
  TextMDBold: 'text-md-bold',
  TextSM: 'text-sm',
  TextSMMedium: 'text-sm-medium',
  TextSMSemibold: 'text-sm-semibold',
  TextSMBold: 'text-sm-bold',
  TextXS: 'text-xs',
  TextXSMedium: 'text-xs-medium',
  TextXSSemibold: 'text-xs-semibold',
  TextXSBold: 'text-xs-bold',
};
