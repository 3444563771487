import { useFDAProductsContext } from '../Context';
import {
  Modal,
  ModalType,
  Text,
  TextKind,
  TextElement,
  Button,
  ButtonKind,
  Pill,
  PillColor,
} from 'design-system/components';
import { Color } from 'design-system/data';
import { useHistory } from 'react-router-dom';
import styles from './paywall-dialog.module.scss';

export const PaywallDialog = () => {
  const {
    paywallDialogOpen,
    setPaywallDialogOpen,
    subscriptions,
    newSubscription,
  } = useFDAProductsContext();
  const history = useHistory();

  const onCancel = () => setPaywallDialogOpen(false);
  const onComplete = () => {
    setPaywallDialogOpen(false);
    history.push('product-registration/submission-confirmation');
  };

  const skuCountDisplay = (subscription) => {
    if (subscription.isCustom) {
      return `${subscription.min}+`;
    } else if (subscription.min === subscription.max) {
      return subscription.min;
    }
    return `${subscription.min}-${subscription.max}`;
  };

  const pricingDisplay = (price) => {
    const priceString = price.toString();
    if (priceString.length > 4) {
      const insertIndex = priceString.length - 3;
      return `$${priceString.slice(0, insertIndex)},${priceString.slice(
        insertIndex
      )}`;
    }
    return `$${price}`;
  };

  return (
    <Modal
      show={paywallDialogOpen}
      title="Confirm your pricing"
      type={ModalType.Large}
      onClick={onCancel}
      titleSize={TextKind.DisplayMDMedium}
      dataCyRoot="registration-paywall-dialog"
      subtitle="Novi Connect’s MoCRA listings pricing is calculated by SKU count. If you would like to adjust your selections, please return to your SKUs page. You will be able to review your information on the next page before submitting to the FDA."
    >
      <div className={styles['paywall-content']}>
        {subscriptions?.subscriptions?.map((subscription, index) => {
          return (
            <div
              data-active={newSubscription?.id === subscription.id}
              className={styles['paywall-row']}
              key={index}
            >
              <div className={styles['pill-container']}>
                <div className={styles['sku-count']}>
                  <Text kind={TextKind.Display2XSBold} element={TextElement.P}>
                    {skuCountDisplay(subscription)}
                  </Text>
                  <Text
                    kind={TextKind.TextSMMedium}
                    element={TextElement.P}
                    color={Color.Neutral600}
                  >
                    SKUs
                  </Text>
                </div>
                {newSubscription?.id === subscription.id && (
                  <Pill color={PillColor.Purple}>Based on your selections</Pill>
                )}
              </div>
              <div className={styles['price-container']}>
                {subscription.isCustom ? (
                  <Text kind={TextKind.TextXSMedium} element={TextElement.P}>
                    Contact us for special pricing
                  </Text>
                ) : (
                  <>
                    <Text
                      kind={TextKind.DisplaySMMedium}
                      element={TextElement.P}
                    >
                      {pricingDisplay(subscription.price)}
                    </Text>
                    <Text
                      kind={TextKind.TextXSMedium}
                      element={TextElement.P}
                      color={Color.Neutral600}
                    >
                      per year
                    </Text>
                  </>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles['action-row']}>
        <Button onClick={onCancel} kind={ButtonKind.Tertiary}>
          Go back
        </Button>
        <Button onClick={onComplete}>Continue</Button>
      </div>
      <Text
        className={styles['fine-print']}
        kind={TextKind.TextXS}
        element={TextElement.P}
        color={Color.Neutral500}
      >
        Prices shown subject to state and local taxes where applicable.
      </Text>
    </Modal>
  );
};
