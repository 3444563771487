export default function Hyphen({ size, color }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 8H12"
        stroke={color.value}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
