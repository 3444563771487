export default function Cluster({ size, color }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="3" fill={color.value} />
      <circle cx="12.5" cy="3.5" r="1.5" fill={color.value} />
      <circle cx="12.5" cy="12.5" r="1.5" fill={color.value} />
      <circle cx="3.5" cy="3.5" r="1.5" fill={color.value} />
      <circle cx="3.5" cy="12.5" r="1.5" fill={color.value} />
    </svg>
  );
}
