import propTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  Button,
  ButtonKind,
  IconDirection,
  IconName,
  ProductStats,
} from 'design-system/components';
import { PATHS } from 'constants/index';
import styles from './supplier-material-side-panel.module.scss';
import { applyLongDateFormat } from 'design-system/utils';

export default function SupplierMaterialSidePanel({
  data,
  fromChildView = false,
}) {
  const history = useHistory();
  const placeholder = '-';
  return (
    <div className={styles.container}>
      <Button
        kind={ButtonKind.Alternate}
        iconName={IconName.Chevron}
        iconDirection={IconDirection.Left}
        iconSize={12}
        onClick={() =>
          fromChildView
            ? history.push(`${PATHS.supplierMaterial}/${data?.id}`)
            : history.push(PATHS.supplierListings)
        }
      >
        Back to {fromChildView ? 'Material Dashboard' : 'Listings Management'}
      </Button>
      <ProductStats
        lastUpdated={
          data?.updatedAt ? applyLongDateFormat(data?.updatedAt) : placeholder
        }
        created={
          data?.createdAt ? applyLongDateFormat(data?.createdAt) : placeholder
        }
        isSupplierView
      />
    </div>
  );
}

SupplierMaterialSidePanel.propTypes = {
  data: propTypes.object,
};
