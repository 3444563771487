import { useEffect, useState } from 'react';
import { groupBy } from 'lodash';

import {
  Checkbox,
  Option,
  PolicyIcon,
  Select,
  SelectKind,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';
import { determineSearchResults, determineSelectedOptions } from './utils';
import SelectedOptions from './SelectedOptions';
import styles from './multi-select.module.scss';

export interface SelectOption {
  id: number | string;
  name: string;
  category?: string;
  imagePath?: string;
}
interface MultiSelectProps {
  options: SelectOption[];
  onSelection: (selectedOptions: SelectOption[]) => void;
  maxOptions?: number;
  hideLabel?: boolean;
  placeholder?: string;
  lockUnavailableOptions?: boolean;
  showFeaturedOptions?: boolean;
  showCounts?: boolean;
}

const MultiSelect = ({
  options,
  onSelection,
  maxOptions = 0,
  hideLabel,
  placeholder,
}: MultiSelectProps) => {
  const [optionsToDisplay, setOptionsToDisplay] = useState(options);

  const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>([]);
  const handleSelection = (options: SelectOption[]) => {
    setSelectedOptions(options);
    onSelection(options);
  };

  useEffect(() => {
    setOptionsToDisplay(options);
  }, [options]);

  const groupedOptions = optionsToDisplay?.length
    ? groupBy(optionsToDisplay, ({ category }) => category)
    : {};
  const groupedOptionsKeyArray = Object.keys(groupedOptions);
  const isSelectedCountBelowLimit = maxOptions
    ? selectedOptions?.length < maxOptions
    : true;

  const handleOptionClick = (option: SelectOption) => {
    handleSelection(determineSelectedOptions(option, selectedOptions));
  };

  return (
    <div className={styles.container}>
      {!hideLabel && (
        <label className={styles.label} id="options-dropdown">
          Select options{maxOptions ? ` (max ${maxOptions})` : ''}:
        </label>
      )}
      <Select
        disableCloseOnClick
        enableSearch
        searchPlaceholder={!selectedOptions?.length ? placeholder : ''}
        onSearchInput={(event) =>
          setOptionsToDisplay(determineSearchResults(event, options))
        }
        kind={SelectKind.Text}
        value=""
        options={options.map((option) => {
          return { value: option.id, display: option.name };
        })}
        placeholder={
          selectedOptions?.length ? (
            <SelectedOptions
              selectedOptions={selectedOptions}
              onSelection={handleSelection}
            />
          ) : null
        }
      >
        {!groupedOptionsKeyArray?.length ? (
          <div className={styles.empty}>
            <Text kind={TextKind.TextLG} element={TextElement.P}>
              No results.
            </Text>
          </div>
        ) : (
          groupedOptionsKeyArray.map((group, i) => {
            return (
              <div key={`${group}-${i}`}>
                {groupedOptions[group].map((option, i) => {
                  const isActive = selectedOptions?.some(
                    (selectedOption) => selectedOption.id === option.id
                  );
                  return (
                    <Option
                      ariaSelected={isActive}
                      key={`${option.id}-${i}`}
                      onClick={(e) => {
                        e?.preventDefault();
                        e?.stopPropagation();
                        handleOptionClick(option);
                      }}
                      tabIndex={-1}
                    >
                      <div className={styles.item}>
                        <Text
                          kind={TextKind.TextSMMedium}
                          element={TextElement.Span}
                          className={styles['option-text']}
                        >
                          {option?.name}
                        </Text>
                        <Checkbox
                          disabled={!isSelectedCountBelowLimit}
                          isActive={isActive}
                          ariaLabelledBy={option?.name}
                          onClick={() => {
                            handleOptionClick(option);
                          }}
                        />
                        {option?.imagePath && (
                          <PolicyIcon
                            height={24}
                            width={24}
                            name={option?.name}
                            src={option.imagePath}
                          />
                        )}
                      </div>
                    </Option>
                  );
                })}
              </div>
            );
          })
        )}
      </Select>
    </div>
  );
};

export default MultiSelect;
