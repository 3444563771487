import propTypes from 'prop-types';
import {
  Checkbox,
  DropdownMenuItem,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';
import styles from './filter-modal-dropdown-item.module.scss';

const FilterModalDropdownItem = ({ value, isActive, onClick, ...props }) => {
  return (
    <DropdownMenuItem onClick={() => onClick(!isActive)} {...props}>
      <div className={styles.container}>
        <Checkbox
          isActive={isActive}
          ariaLabelledBy={value}
          onClick={() => onClick(!isActive)}
        />
        <Text kind={TextKind.TextMD} element={TextElement.Span} id={value}>
          {value}
        </Text>
      </div>
    </DropdownMenuItem>
  );
};

FilterModalDropdownItem.propTypes = {
  value: propTypes.string.isRequired,
  isActive: propTypes.bool,
  onClick: propTypes.func,
  [`data-cy`]: propTypes.string,
};

export default FilterModalDropdownItem;
