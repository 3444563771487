export default function Packaging({ size, color }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.692349 2C0.857682 7.125 1.76635 9.71933 2.19102 10.6667H7.80902C8.23368 9.71933 9.14235 7.125 9.30768 2H0.692349ZM6.33368 6.66667C6.33368 7.035 6.03535 7.33333 5.66702 7.33333H4.33368C3.96535 7.33333 3.66702 7.035 3.66702 6.66667V5.33333C3.66702 4.965 3.96535 4.66667 4.33368 4.66667H5.66702C6.03535 4.66667 6.33368 4.965 6.33368 5.33333V6.66667ZM9.32502 1.33333C9.33002 1.062 9.33368 0.786 9.33468 0.501333C9.33502 0.368667 9.28268 0.241 9.18868 0.147C9.09468 0.053 8.96735 0 8.83468 0H1.16602C1.03335 0 0.906016 0.053 0.812016 0.147C0.718349 0.241 0.666016 0.368667 0.666016 0.501333C0.667016 0.786 0.670682 1.062 0.675682 1.33333H9.32502ZM2.33368 11.3333V12.8333C2.33368 13.4767 2.85702 14 3.50035 14H6.50035C7.14368 14 7.66702 13.4767 7.66702 12.8333V11.3333H2.33368Z"
        fill={color.value}
      />
    </svg>
  );
}
