import mixpanel from 'mixpanel-browser';
import isDevelopmentEnv from 'utils/isDevelopmentEnv';
import { datadogLogs } from '@datadog/browser-logs';

const MIXPANEL_PROJECT_TOKEN = process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN;
const MIXPANEL_PROXY_DOMAIN = process.env.REACT_APP_MIXPANEL_PROXY_DOMAIN;

// custom property mapping for view in mixpanel
export const customPropertiesMap = {
  'distinctID': 'distinct_id',
  'userIdentifier': 'user_identifier',
  'isInternalUser': 'is_internal_user',
  'organizationType': 'organization_type',
  'selectedValue': 'selected_value',
  'page': 'page_flow_number',
  'inputType': 'input_type',
  'featurePathOption': 'feature_path_option',
  'entityId': 'data_points',
  'results': 'results',
  'traceId': 'trace_id',
  'category': 'event_category',
  'label': 'event_label',
  'action': 'event_action',
  'eventTimestamp': 'time',
  'milliseconds': 'milliseconds',
};

const formatProperties = (properties) =>
  Object.entries(properties).reduce((acc, [key, value]) => {
    const propertyKey =
      typeof customPropertiesMap[key] === 'string'
        ? customPropertiesMap[key]
        : key;
    const propertyValue = value ? value.toString() : '';
    acc[propertyKey] = propertyValue;
    return acc;
  }, {});

const enableMixpanel = !isDevelopmentEnv();
export class MixpanelAnalyticsVendor {
  constructor() {
    // During Mixpanel migration, init on TEST and PROD, ignore local dev env.
    // TODO: After migration testing is complete, init on PROD only (update all env conditionals below).
    if (enableMixpanel) {
      mixpanel.init(MIXPANEL_PROJECT_TOKEN, {
        api_host: MIXPANEL_PROXY_DOMAIN,
        ignore_dnt: true,
      });
      datadogLogs.logger.info('Mixpanel Init');
    }
  }

  // Register properties that are sent with every event
  register = (properties) => {
    if (enableMixpanel) {
      const formattedProps = formatProperties(properties);

      mixpanel.register(formattedProps);
      datadogLogs.logger.info('Mixpanel Register User', {
        props: formattedProps,
      });
    }
  };

  // Track event with properties, naming event by unique action name
  track = (properties) => {
    if (enableMixpanel) {
      const { event_action: eventName, ...remainingEventProperies } =
        properties;
      mixpanel.track(eventName, remainingEventProperies);
      datadogLogs.logger.info('Mixpanel Track Event', { props: properties });
    }
  };
}
