import propTypes from 'prop-types';
import {
  Accordion,
  AccordionDetail,
  Icon,
  IconName,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';
import styles from './literature-item.module.scss';
import { convertToKebabCase } from 'design-system/utils';

export default function SupplierMaterialLiteratureItem({
  title,
  iconName,
  hasData,
  children,
}) {
  return (
    <Accordion
      alternateStyle
      title={title}
      iconName={iconName}
      iconSize={20}
      data-cy={`supplier-${convertToKebabCase(title)}-accordion`}
    >
      <AccordionDetail
        data-cy={`supplier-${convertToKebabCase(title)}-accordion-content`}
      >
        {hasData ? (
          children
        ) : (
          <div className={styles.empty}>
            <Icon name={IconName.AlertRound} />
            <Text kind={TextKind.TextMDBold} element={TextElement.P}>
              No data provided.
            </Text>
          </div>
        )}
      </AccordionDetail>
    </Accordion>
  );
}

SupplierMaterialLiteratureItem.propTypes = {
  title: propTypes.string.isRequired,
  iconName: propTypes.string.isRequired,
  hasData: propTypes.bool.isRequired,
  children: propTypes.node.isRequired,
};
