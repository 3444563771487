import { bool, number, oneOf, string } from 'prop-types';
import { ProgressSize, ProgressVariant } from './constants';
import RingProgress from './Ring';
import BarProgress from './Bar';
import { Color } from 'design-system/data';
import { roundPercentageToInteger } from 'design-system/utils/rounding';

const Progress = ({
  percentage,
  variant = ProgressVariant.Ring,
  label,
  size = ProgressSize.Sm,
  color = Color.Purple600.value,
  loading = false,
  roundToNearestInt = true,
}) => {
  const percentageValue = roundToNearestInt
    ? roundPercentageToInteger(percentage)
    : percentage;

  if (variant === ProgressVariant.Bar) {
    return (
      <BarProgress
        percentage={percentageValue}
        label={label}
        color={color}
        loading={loading}
      />
    );
  }
  return (
    <RingProgress
      percentage={percentageValue}
      label={label}
      size={size}
      color={color}
      loading={loading}
    />
  );
};

Progress.propTypes = {
  percentage: number.isRequired,
  variant: oneOf(Object.values(ProgressVariant)),
  label: string,
  size: oneOf(Object.values(ProgressSize)),
  color: oneOf(Object.values(Color).map((color) => color.value)),
  loading: bool,
  roundToNearestInt: bool,
};

export default Progress;
