import { createContext, useContext, useMemo, ReactNode } from 'react';
import { useApp } from 'context/AppContext';
import { convertSnakeToCamelCase } from 'design-system/utils/case';
import { ScreeningStatus } from 'design-system/data';

export type PolicyType = {
  id: number;
  name: string;
  imagePath: string;
  isEnrolled: boolean;
  contractId: number;
  status: (typeof ScreeningStatus)[keyof typeof ScreeningStatus];
};

export interface EligibilityContextType {
  containedPolicies: PolicyType[];
  formulationPolicies: PolicyType[];
  skuResults: { [key: number]: boolean };
}

const EligibilityContext = createContext<EligibilityContextType | null>(null);

export const useEligibilityContext = (): EligibilityContextType => {
  const ctx = useContext(EligibilityContext);
  if (!ctx) {
    throw new Error('Must be used with a EligibilityProvider');
  }
  return ctx;
};

interface EligibilityProviderProps {
  children: ReactNode;
}

function EligibilityProvider({ children }: EligibilityProviderProps) {
  const { useApi, user, selectedRetailerId, hasRetailerBrandResponseFinished } =
    useApp();
  const brandID = user.organization.in_orgable_id;

  // FOR USE IN SIGNED POLICIES TABLE
  const policyEnrollmentStatuses = useApi(
    `/api/v4/consumer_brands/${brandID}/policy_enrollment_statuses`,
    {
      enabled: Boolean(brandID && hasRetailerBrandResponseFinished),
      param: { retailer_id: selectedRetailerId },
    }
  );

  const containedPolicies = useMemo(() => {
    let containedPolicies: PolicyType[] = [];
    if (!policyEnrollmentStatuses?.data) return containedPolicies;

    containedPolicies = policyEnrollmentStatuses.data.contracts;
    return containedPolicies;
  }, [policyEnrollmentStatuses?.data]);

  const context = useMemo<EligibilityContextType>(() => {
    return {
      containedPolicies: convertSnakeToCamelCase(containedPolicies),
      formulationPolicies: convertSnakeToCamelCase(
        policyEnrollmentStatuses?.data?.formulation_policies || []
      ),
      skuResults: policyEnrollmentStatuses?.data?.sku_results,
    };
  }, [policyEnrollmentStatuses]);

  return (
    <EligibilityContext.Provider value={context}>
      {children}
    </EligibilityContext.Provider>
  );
}

export default EligibilityProvider;
