import cn from 'classnames';
import { bool, number, string, func, oneOfType } from 'prop-types';
import {
  Text,
  TextElement,
  TextKind,
  Tooltip,
  OpenAxis,
  Icon,
  IconName,
} from 'design-system/components';
import styles from './composable-conditions-modal.module.scss';

function InputField({
  isReadOnly,
  currentValue,
  value,
  inputName,
  inputDescription,
  inputLabel,
  inputSuffix,
  onChange,
  placeholder,
  step,
  errorMessage,
}) {
  const handleChange = (value) => {
    if (step && value !== undefined) {
      const [, stepDec] = step.toString().split('.');
      return onChange(inputName, value.toFixed(stepDec?.length || 0));
    }
    onChange(inputName, value);
  };

  return (
    <div className={styles['input-label-group']}>
      {!inputDescription ? (
        <Text kind={TextKind.TextSMBold} element={TextElement.P}>
          {inputLabel}
        </Text>
      ) : (
        <div className={styles['input-label-group--with-icon']}>
          <Text kind={TextKind.TextSMBold} element={TextElement.P}>
            {inputLabel}
          </Text>
          <Tooltip
            openAxis={OpenAxis.Y}
            triggerElement={<Icon name={IconName.Info} size={16} />}
            width={300}
          >
            <Text kind={TextKind.TextSMBold} element={TextElement.P}>
              {inputLabel}
            </Text>
            <Text kind={TextKind.TextMD} element={TextElement.P}>
              {inputDescription}
            </Text>
          </Tooltip>
        </div>
      )}

      <div className={styles['input-container']}>
        {isReadOnly ? (
          <Text
            kind={TextKind.TextMD}
            element={TextElement.P}
            className={styles['input']}
          >
            {currentValue}
          </Text>
        ) : (
          <input
            type="number"
            step={step}
            value={Number(value) ? Number(value) : null}
            placeholder={placeholder}
            className={cn(
              styles['input'],
              errorMessage && styles['input-error']
            )}
            onChange={(e) =>
              handleChange(e.target.value ? Number(e.target.value) : undefined)
            }
          />
        )}
        {inputSuffix && (
          <span className={styles['right-section']}>{inputSuffix}</span>
        )}
      </div>
      {errorMessage && (
        <Text
          kind={TextKind.TextMD}
          element={TextElement.P}
          className={styles['error']}
        >
          {errorMessage}
        </Text>
      )}
    </div>
  );
}

InputField.propTypes = {
  isReadOnly: bool,
  currentValue: oneOfType([string, number]),
  inputName: string,
  inputLabel: string,
  inputDescription: string,
  inputSuffix: string,
  onChange: func,
  placeholder: string,
  step: number,
  errorMessage: string,
};

export default InputField;
