import { IconVersion } from '../constants';

function Document(props) {
  return props.version === IconVersion.Small ? (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 3C3 2.44772 3.44772 2 4 2H10.0977C10.3558 2 10.604 2.09983 10.7902 2.27861L11.75 3.2L12.6925 4.10483C12.889 4.2934 13 4.55392 13 4.82622V13C13 13.5523 12.5523 14 12 14H4C3.44772 14 3 13.5523 3 13V3Z"
        fill="none"
        stroke={props.color.value}
        strokeWidth="1.5"
      />
      <path
        d="M8 5V7M8 7L6 9M8 7L10 8"
        stroke={props.color.value}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  ) : (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 5C5 3.89543 5.89543 3 7 3H15.4783C15.9749 3 16.4538 3.18476 16.8217 3.51833L18.125 4.7L19.3434 5.80466C19.7615 6.18377 20 6.72191 20 7.28633V18C20 19.1046 19.1046 20 18 20H7C5.89543 20 5 19.1046 5 18V5Z"
        fill="none"
        stroke={props.color.value}
        strokeWidth="2"
      />
      <path
        d="M12 7V10M12 10L9 13M12 10L15 11.5"
        stroke={props.color.value}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default Document;
