import PropTypes from 'prop-types';
import cn from 'classnames';

import { ToastV2 } from 'design-system/components';

import styles from './index.module.scss';

export default function ContextMenu({
  open,
  children,
  className = '',
  viewportClassName = '',
}) {
  return (
    <>
      <ToastV2
        rootProps={{ open, className: cn(styles['toast-root'], className) }}
        viewportProps={{
          className: cn(styles['toast-viewport'], viewportClassName),
        }}
      >
        {children}
      </ToastV2>
    </>
  );
}

ContextMenu.ContextMenu = {
  open: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  viewportClassName: PropTypes.string,
};
