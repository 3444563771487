import PropTypes from 'prop-types';
import styles from './switch.module.scss';

function Switch(props) {
  function getClassNames() {
    let classNames = props.isActive
      ? `${styles['root-active']}`
      : `${styles.root}`;
    if (props.small) {
      classNames += ` ${styles.small}`;
    }
    return classNames;
  }

  return (
    <button
      role="switch"
      className={getClassNames()}
      aria-checked={props.isActive}
      onClick={props.onClick}
      aria-labelledby={props.ariaLabelledBy}
      disabled={props.disabled}
      aria-disabled={props.disabled}
      data-cy={props[`data-cy`]}
    >
      <span className={styles.circle} />
    </button>
  );
}

Switch.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  ariaLabelledBy: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
};

export default Switch;
