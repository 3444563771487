import { useState } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as Accordion from '@radix-ui/react-accordion';
import cn from 'classnames';
import {
  Checkbox,
  PhosphorIcon,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';
import { Color } from 'design-system/data';
import styles from './filter-dropdown.module.scss';

export interface Option {
  value: string;
  label: string;
}

export interface AccordionOptionGroup {
  title: string;
  options: Option[];
}

export interface FilterDropdownProps {
  options?: Option[];
  accordionOptions?: AccordionOptionGroup[];
  selectedValues?: string[];
  setSelectedValues?: (values: string[]) => void;
}

const FilterDropdown = ({
  options = [],
  accordionOptions = [],
  selectedValues: propSelectedValues,
  setSelectedValues: propSetSelectedValues,
}: FilterDropdownProps) => {
  const [internalSelectedValues, setInternalSelectedValues] = useState<
    string[]
  >(propSelectedValues || []);
  const selectedValues = propSelectedValues || internalSelectedValues;
  const setSelectedValues = propSetSelectedValues || setInternalSelectedValues;
  const [expandedAccordion, setExpandedAccordion] = useState<string | null>(
    'accordion-item-0'
  );

  const handleCheckboxChange = (value: string) => {
    const newSelectedValues = selectedValues.includes(value)
      ? selectedValues.filter((v) => v !== value)
      : [...selectedValues, value];
    setSelectedValues(newSelectedValues);
  };

  const renderCheckboxItem = (option: Option) => (
    <DropdownMenu.CheckboxItem
      key={option.value}
      onClick={() => handleCheckboxChange(option.value)}
      onSelect={(event) => event.preventDefault()} // Prevent menu close
      asChild
    >
      <div
        className={cn(styles['checkbox-item'], {
          [styles['selected']]: selectedValues.includes(option.value),
        })}
      >
        <label htmlFor={option.value}>{option.label}</label>
        <Checkbox
          isActive={selectedValues.includes(option.value)}
          onClick={() => handleCheckboxChange(option.value)}
          ariaLabelledBy={option.label}
          size="small"
        />
      </div>
    </DropdownMenu.CheckboxItem>
  );

  const renderAccordionGroup = (group: AccordionOptionGroup, index: number) => (
    <Accordion.Item
      className={styles['accordion-item']}
      key={`accordion-item-${index}`}
      value={`accordion-item-${index}`}
    >
      <Accordion.Header asChild>
        <Accordion.Trigger className={styles['checkbox-item']} asChild>
          <DropdownMenu.Item
            onSelect={(event) => event.preventDefault()}
            asChild
          >
            <div className={styles['checkbox-item']}>
              <label htmlFor={group.title}>{group.title}</label>
              <PhosphorIcon
                iconName={
                  expandedAccordion === `accordion-item-${index}`
                    ? 'Minus'
                    : 'Plus'
                }
                size={16}
              />
            </div>
          </DropdownMenu.Item>
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content className={styles['accordion-content']}>
        {group.options.map(renderCheckboxItem)}
      </Accordion.Content>
    </Accordion.Item>
  );

  return (
    <div className={styles['filter-dropdown-container']}>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <button className={styles['trigger-button']}>
            <PhosphorIcon iconName="FunnelSimple" title="filter" size={20} />
            <Text
              kind={TextKind.TextSMSemibold}
              element={TextElement.Span}
              color={Color.Neutral600}
            >
              Filter
            </Text>
          </button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content
          align="end"
          className={styles['dropdown-content']}
        >
          {/* Regular options */}
          {options.map(renderCheckboxItem)}

          {/* Accordion Section */}
          <Accordion.Root
            type="single"
            collapsible
            defaultValue="accordion-item-0"
            onValueChange={setExpandedAccordion}
          >
            {accordionOptions.map(renderAccordionGroup)}
          </Accordion.Root>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </div>
  );
};

export default FilterDropdown;
