import YourProductsContainer from './YourProductsContainer';
import YourProductsProvider from './context';
import { UploadSkuProvider } from './context/UploadSkuContext';
import { PaywallProvider } from './context/PaywallContext';

function YourProducts() {
  return (
    <PaywallProvider>
      <YourProductsProvider>
        <UploadSkuProvider>
          <YourProductsContainer />
        </UploadSkuProvider>
      </YourProductsProvider>
    </PaywallProvider>
  );
}

export default YourProducts;
