import { number, shape, string, func } from 'prop-types';
import {
  TableRow,
  TableBodyCell,
  Text,
  TextKind,
  TextElement,
  CloudinaryImg,
} from 'design-system/components';
import styles from './documents-table.module.scss';
import DocumentsTableRowDropDown from './documents-table-row-dropdown';
import RequirementStatusPill from '../requirement-status-pill';

function DocumentsTableRow({
  document,
  setShowDocumentModal,
  setShowRetailerNotesModal,
}) {
  const { documentName, retailer, productName, policy, status, id } = document;

  return (
    <TableRow key={id}>
      <TableBodyCell>
        <Text kind={TextKind.TextMDBold} element={TextElement.Span}>
          {documentName}
        </Text>
      </TableBodyCell>
      <TableBodyCell>
        <div className={styles['table-row-retailer']}>
          <CloudinaryImg src={document?.retailer?.image} alt={retailer?.name} />
          <Text kind={TextKind.TextSM} element={TextElement.P}>
            {retailer?.name}
          </Text>
        </div>
      </TableBodyCell>
      <TableBodyCell>
        <Text kind={TextKind.TextSM} element={TextElement.P}>
          {productName || '-'}
        </Text>
      </TableBodyCell>
      <TableBodyCell>
        <Text kind={TextKind.TextSM} element={TextElement.P}>
          {policy.name || '-'}
        </Text>
      </TableBodyCell>
      <TableBodyCell>
        <RequirementStatusPill status={status} />
      </TableBodyCell>
      <TableBodyCell>
        <DocumentsTableRowDropDown
          document={document}
          setShowDocumentModal={setShowDocumentModal}
          setShowRetailerNotesModal={setShowRetailerNotesModal}
        />
      </TableBodyCell>
    </TableRow>
  );
}

DocumentsTableRow.propTypes = {
  setShowDocumentModal: func,
  document: shape({
    name: string,
    createdAt: string,
    documentName: string,
    id: number,
    policy: shape({
      name: string,
      id: number,
    }),
    retailer: shape({
      id: number,
      name: string,
      image: string,
    }),
    status: string,
    updatedAt: string,
  }).isRequired,
};

export default DocumentsTableRow;
