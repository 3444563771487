export default function Building({ size, color }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3.75"
        y="3.75"
        width="8.5"
        height="8.5"
        stroke={color.value}
        strokeWidth="1.5"
      />
      <path d="M8 3.5V7" stroke={color.value} strokeWidth="1.5" />
      <path d="M8 9V12.5" stroke={color.value} strokeWidth="1.5" />
    </svg>
  );
}
