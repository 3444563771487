import { Switch, Route } from 'react-router-dom';
import { PATHS } from 'constants/index';
import PolicySelectionPage from './PolicySelectionPage';
import SKURequirementsPage from './SKURequirementsPage';
import SKULevelStatusPage from './SKULevelStatusPage';
import { useApp } from 'context/AppContext';

const RetailerBrandEligibility = () => {
  const { retailerBrand } = useApp();
  const link = (policyId: number) =>
    PATHS.retailerBrandSKULevelStatus.replace(
      ':policy_id',
      policyId.toString()
    );
  return (
    <Switch>
      <Route
        path={PATHS.retailerBrandEligibility}
        component={() => (
          <PolicySelectionPage
            breadCrumbText="Eligibility"
            title="Your eligibility"
            subTitle={`View your eligibility status for ${
              (retailerBrand?.retailer as any)
                ? retailerBrand.retailer.name
                : 'retailer'
            } programs here.`}
            retailerBrand={retailerBrand}
            link={link}
          />
        )}
        secure
        exact
      />
      <Route
        path={PATHS.retailerBrandSKULevelStatus}
        component={SKULevelStatusPage}
        secure
        exact
      />
      <Route
        path={PATHS.retailerBrandSKURequirements}
        component={SKURequirementsPage}
        secure
        exact
      />
    </Switch>
  );
};

export default RetailerBrandEligibility;
