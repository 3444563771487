import styles from './submission-card.module.scss';
import {
  Button,
  ButtonKind,
  Pill,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';
import { Color } from 'design-system/data';

export default function SubmissionCard({ title, badge, buttonLink, stats }) {
  return (
    <div className={styles.root}>
      <div className={styles['content-row']}>
        <div className={styles['text-and-badge']}>
          <Text kind={TextKind.Display2XSMedium} element={TextElement.H3}>
            {title}
          </Text>
          <Pill>{badge}</Pill>
        </div>
        <Button
          kind={ButtonKind.Primary}
          onClick={buttonLink}
          iconPosition="right"
          iconName="ArrowRight"
          usePhosphorIcon
        >
          View details
        </Button>
      </div>
      <div className={styles['stats-row']}>
        {stats.map((stat, index) => (
          <div key={index} className={styles.stat}>
            <Text
              kind={TextKind.TextSMSemibold}
              element={TextElement.Span}
              color={Color.Neutral500}
            >
              {stat.label}
            </Text>
            {stat.value}
          </div>
        ))}
      </div>
    </div>
  );
}
