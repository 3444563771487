import cn from 'classnames';
import {
  Divider,
  PhosphorIcon,
  PhosphorIconWeight,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';
import { Color } from 'design-system/data';
import styles from './form.module.scss';

export const FormInput = ({
  className = '',
  type,
  placeholder,
  id = undefined,
  name = undefined,
  icon = undefined,
  value = undefined,
  onChange = undefined,
  required = true,
  disabled = false,
}) => {
  return (
    <div className={cn(styles['input-wrapper'], className)}>
      {icon}
      <input
        type={type}
        id={id}
        className={styles.input}
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={onChange}
        required={required}
        disabled={disabled}
      />
    </div>
  );
};

export const PillList = ({ values = [], onRemove, required = false }) => {
  const removePill = (pillIndex) => {
    onRemove(pillIndex);
  };

  return (
    <div className={styles['pill-text-area']}>
      <div className={styles['pills-container']}>
        {values.map((pill, index) => (
          <span key={index} className={styles.pill}>
            <Text
              kind={TextKind.TextSMMedium}
              element={TextElement.Span}
              color={Color.Neutral700}
            >
              {pill}
            </Text>
            <span onClick={() => removePill(index)}>
              <PhosphorIcon
                iconName="X"
                size="0.75rem"
                weight={PhosphorIconWeight.Bold}
                color={Color.Neutral400.value}
              />
            </span>
          </span>
        ))}
      </div>
      {required && (
        <input
          className={styles['pills-input']}
          required={required}
          value={values.length ? 'filled' : undefined}
        />
      )}
    </div>
  );
};

export const FormRow = ({
  title,
  description = undefined,
  field,
  fieldId = undefined,
}) => {
  return (
    <div className={cn(styles['form-row'])}>
      <div className={styles['label-column']}>
        <Text
          kind={TextKind.TextSMSemibold}
          element={TextElement.Label}
          color={Color.Neutral700}
          for={fieldId}
        >
          {title}
        </Text>
        {description && (
          <Text
            kind={TextKind.TextSM}
            element={TextElement.P}
            color={Color.Neutral600}
          >
            {description}
          </Text>
        )}
      </div>
      <div className={styles['input-column']}>{field}</div>
    </div>
  );
};

export const FormColumn = ({
  title,
  description = undefined,
  field,
  fieldId = undefined,
}) => {
  return (
    <div className={cn(styles['form-col'])}>
      <Text
        kind={TextKind.TextSMSemibold}
        element={TextElement.Label}
        color={Color.Neutral700}
        for={fieldId}
      >
        {title}
      </Text>
      <div className={styles['input-column']}>{field}</div>
      {description && (
        <Text
          kind={TextKind.TextSM}
          element={TextElement.P}
          color={Color.Neutral600}
        >
          {description}
        </Text>
      )}
    </div>
  );
};

export const SectionHeader = ({
  title,
  subtitle = undefined,
  suffix = undefined,
  noMargin = false,
  noDivider = false,
}) => {
  return (
    <div
      className={cn(
        styles['section-header'],
        noMargin ? styles['no-margin'] : ''
      )}
    >
      <Text kind={TextKind.TextMDSemibold} element={TextElement.H2}>
        {title}
      </Text>
      {subtitle && (
        <Text
          kind={TextKind.TextSM}
          element={TextElement.P}
          color={Color.Neutral600}
        >
          {subtitle}
        </Text>
      )}
      {!noDivider && <Divider />}
      {suffix}
    </div>
  );
};
