export default function filterKeyVal(obj, filterMatching, replacer) {
  if (typeof obj !== 'object') return obj;
  const compare = Array.isArray(filterMatching)
    ? (key) => !filterMatching.includes(key)
    : typeof filterMatching === 'object'
    ? (key) => obj[key] !== filterMatching[key]
    : (key) => filterMatching !== key;
  return Object.entries(obj).reduce((o, [key, val]) => {
    if (compare(key)) {
      o[key] = obj[key];
    } else if (replacer) {
      o[key] = replacer(obj[key]);
    }
    return o;
  }, {});
}
