import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './loading-spinner.module.scss';

function LoadingSpinner({ darkMode, size }) {
  const className = cn(styles.spinner, darkMode && styles['spinner-dark']);
  return (
    <div
      className={className}
      style={size ? { height: `${size}px`, width: `${size}px` } : {}}
    />
  );
}

LoadingSpinner.propTypes = {
  darkMode: PropTypes.bool,
  size: PropTypes.number,
};

export default LoadingSpinner;
