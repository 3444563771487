import styled from 'styled-components';
import { layout, flexbox, border, compose, typography } from 'styled-system';

const BaseInput = styled('input')`
  border: 1px solid #ececf2;
  background: white;
  font-family: var(--font-sans-serif);
  padding: 10px 16px;
  outline: none;
  border-radius: 4px;
  &:focus {
    outline: none;
    box-shadow: 0 0 1px 1px var(--brand-color);
    background-color: hsla(
      var(--brand-color-h),
      var(--brand-color-s),
      var(--brand-color-l),
      0.05
    );
    border-color: transparent;
  }
  &:hover {
    border-color: #444444;
  }
  &:hover:focus {
    border-color: transparent;
  }
  ${compose(layout, flexbox, border, typography)}
`;

export default BaseInput;
