import {
  Grid,
  GridItem,
  GridBreakpoints,
  ResponsiveSidenav,
} from 'design-system/components';

import SiteHeader from 'components/SiteHeader';
import styles from './connectedSidenav.module.scss';
import { useNavContext } from './context';
import useNavData from './useNavData';

const ConnectedSidenav = ({ children }) => {
  const { hideNav, hideMobileNav, setHideMobileNav } = useNavContext();

  const navData = useNavData({
    setHideMobileNav,
  });

  const contentSpans = {
    Mobile: 4,
    Tablet: hideNav ? 8 : 7,
    Desktop: hideNav ? 12 : 11,
  };

  return (
    <>
      <SiteHeader />
      <Grid className={!hideMobileNav ? styles.fixed : null}>
        {(!hideNav || !hideMobileNav) && (
          <GridItem
            span={4}
            responsiveSpan={{
              [GridBreakpoints.Tablet]: 1,
              [GridBreakpoints.Desktop]: 1,
            }}
            className={hideMobileNav ? styles['hidden-mobile'] : null}
          >
            <ResponsiveSidenav
              navData={navData}
              setHideMobileNav={setHideMobileNav}
            />
          </GridItem>
        )}
        <GridItem
          span={contentSpans.Mobile}
          responsiveSpan={{
            [GridBreakpoints.Tablet]: contentSpans.Tablet,
            [GridBreakpoints.Desktop]: contentSpans.Desktop,
          }}
        >
          <div className={styles['content-container']}>{children}</div>
        </GridItem>
      </Grid>
    </>
  );
};

export default ConnectedSidenav;
