import { BrandViewHeader, NoContent } from 'design-system/components';
import { convertSnakeToCamelCase } from 'design-system/utils';
import { useApp } from 'context/AppContext';
import PolicyCard from './PolicyCard/PolicyCard';
import { PolicyProps } from './PolicyCard/types';
import Disclaimer from 'views/Brands/shared/Disclaimer';

interface PolicySelectionPageProps {
  breadCrumbText: string;
  title: string;
  subTitle: string;
  retailerBrand: any;
  link: (policyId: number) => string;
}

const PolicySelectionPage = ({
  breadCrumbText,
  title,
  subTitle,
  retailerBrand,
  link,
}: PolicySelectionPageProps) => {
  const breadcrumbItems = [
    {
      text: breadCrumbText,
      link: '',
    },
  ];
  const { useApi, user } = useApp();
  const retailer = retailerBrand?.retailer;
  const brandId = user?.organization?.in_orgable_id;

  const url = `/api/v4/retailers/${retailer?.id}/consumer_brands/${brandId}/policies`;

  const { data, loading, error } = useApi(url, {
    enabled: !!retailer?.id && !!brandId,
  });

  const policyData = convertSnakeToCamelCase(data);
  const hasContent = policyData?.policies?.length > 0;

  return (
    <main>
      <BrandViewHeader
        title={title}
        subTitle={subTitle}
        showExportButton={false}
        showFilterButton={false}
        breadcrumbsItems={breadcrumbItems}
      />
      {loading && <NoContent loading />}
      {!loading && (error || !hasContent) && (
        <NoContent>
          {error &&
            `An error occurred while fetching ${breadCrumbText} data. Please try again later or reach out to support.`}
          {!hasContent &&
            !error &&
            `No ${breadCrumbText} data found at this time. Please try again later or reach out to support.`}
        </NoContent>
      )}
      {hasContent &&
        policyData.policies.map((policy: PolicyProps) => (
          <PolicyCard key={policy.id} policy={policy} link={link} />
        ))}
      {retailerBrand?.retailer?.disclaimer && (
        <Disclaimer text={retailer?.disclaimer || ''} />
      )}
    </main>
  );
};

export default PolicySelectionPage;
