import PropTypes from 'prop-types';
import styles from './sidebar-layout.module.scss';
import SiteHeader from 'components/SiteHeader';

function SidebarLayout(props) {
  const { menu: Menu } = props;
  return (
    <>
      <SiteHeader />
      <div className={styles.root}>
        <Menu />
        {props.children}
      </div>
    </>
  );
}

SidebarLayout.propTypes = {
  menu: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default SidebarLayout;
