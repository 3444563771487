import { useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { Button, ButtonKind } from 'design-system/components';
import useDialog from 'hooks/useDialog';
import Input from 'components/Input';
import SiteHeader from 'components/SiteHeader';
import { useApp } from 'context/AppContext';
import MultiSelectAutocomplete from 'views/ConsumerProducts/components/MultiSelectAutocomplete';
import useUserFlow from 'hooks/useUserFlow';
import noop from 'utils/noop';
import useMergeStateProperties from 'hooks/useMergeStateProperties';
import useAsyncCall from 'services/api/useAsyncCall';
import fetcher from 'services/api/fetcher';
import CloseDialogButton from './CloseDialogButton';
import DialogStyles from './DialogStyles';
import IngredientListForm from './IngredientListForm';
import IngredientResolver from './IngredientResolver';
import SephoraProductFields from './SephoraProductFields';
import UploadInProgress from './UploadInProgress';
import useSubmitNewTextProduct from './useSubmitNewTextProduct';
import { FlavorOptions, FragranceOptions } from './utils';

export function useAddProductDialog() {
  return useDialog(AddProductDialog);
}

const AddProductDialog = styled(function AddProductDialog(props) {
  const {
    Dialog,
    open,
    setOpen,
    className,
    onSuccess = noop,
    children,
    projectId,
    isProduct,
    allowSkuInput,
  } = props;
  const { useApi, retailerBrand } = useApp();
  const attributesData = useApi('/api/v4/product/attributes', {
    enabled: open,
  });
  const flow = useUserFlow();
  const entityLabel = isProduct ? 'Product' : 'Formula';
  const isSephoraRetailerBrand = retailerBrand?.sephora_retailer_brand;

  const initializeState = (state = {}) => {
    state.productParam = {};
    if (flow.legacy_sephora_flow || isSephoraRetailerBrand) {
      state.productParam.launchDates = {};
      state.productParam.shareWithSephora = true;
    }
    state.unresolvedUpload = undefined;
    return state;
  };

  const { application_times, product_forms, product_uses, product_claims } =
    attributesData?.data || {};

  const [{ productParam, unresolvedUpload }, mergeState, setState] =
    useMergeStateProperties({}, initializeState);

  const [skuId, setSkuId] = useState('');

  async function handleCleanup(response) {
    await onSuccess(response, ...(allowSkuInput ? [skuId] : []));
    setState(initializeState());
    setOpen(false);
  }

  const canSelectUploadType = Boolean(
    productParam.consumer_product_name &&
      productParam.application_times &&
      productParam.product_use_ids?.length > 0 &&
      productParam.product_form_id &&
      productParam.flavor &&
      productParam.fragrance
  );

  const canUpload = canSelectUploadType;

  const afterCreateWithIL = async (response) => {
    if (!response) return;

    const { total_unresolved_ingredient_count } = response;

    if (total_unresolved_ingredient_count > 0) {
      mergeState({ unresolvedUpload: response });
    } else {
      handleCleanup(response);
    }
  };

  const createProductWithIL = useSubmitNewTextProduct({
    afterDone: afterCreateWithIL,
    isLegacySephora: flow.legacy_sephora_flow,
    isSephoraRetailerBrand: isSephoraRetailerBrand,
  });

  // TODO: figure out what TODO below means
  // TODO: Update existing hook to handle this case
  const createFormulationWithIL = useAsyncCall(
    async (params) => {
      const {
        consumer_product_name,
        product_application_id,
        ingredient_names,
        product_use_ids,
        product_form_id,
        product_claim_ids,
        application_times,
        flavor = null,
        fragrance = null,
      } = params;
      let body = {
        name: consumer_product_name,
        product_application_id,
        ingredients_list: ingredient_names,
        product_use_ids,
        product_form_id,
        product_claim_ids,
        application_time: application_times,
      };

      if (flavor !== null) {
        body.flavor = FlavorOptions[flavor];
      }
      if (fragrance !== null) {
        body.fragrance = FragranceOptions[fragrance];
      }

      if (flow.legacy_sephora_flow) {
        body = {
          ...body,
          brand_line: params.brandLine,
          product_category: params.productCategory,
          countries: params.countries,
          launch_dates: params.launchDates,
          parent_company: params.parentCompany,
          sales_channel: params.salesChannel,
          share_with_sephora: params.shareWithSephora,
        };
      }

      if (!flow.legacy_sephora_flow && isSephoraRetailerBrand) {
        body = {
          ...body,
          countries: params.countries,
          launch_dates: params.launchDates,
        };
      }
      const response = await fetcher(
        `/api/product_design/v1/projects/${projectId}/formulations`,
        {
          method: 'post',
          body,
        }
      );

      return response;
    },
    {
      afterDone: (response) => {
        const total_unresolved_ingredient_count =
          response.chemical_ingredients.reduce(
            (count, ing) => (!ing.chemical_compound_id ? count + 1 : count),
            0
          );
        if (total_unresolved_ingredient_count > 0) {
          mergeState({ unresolvedUpload: response });
        } else {
          handleCleanup(response);
        }
      },
    }
  );

  const handleSubmit = async () => {
    if (isProduct) {
      await createProductWithIL.call(productParam);
    } else {
      await createFormulationWithIL.call(productParam);
    }
  };

  const showUploadInProgress =
    createProductWithIL.loading || createFormulationWithIL.loading;
  const showIngredientResolver = Boolean(unresolvedUpload);
  const showProductUploadForm =
    !showUploadInProgress && !showIngredientResolver;

  return (
    <Dialog fullScreen className={className}>
      <DialogStyles>
        <SiteHeader />
        <CloseDialogButton onClick={() => setOpen(false)} />

        <div className="content">
          {showUploadInProgress && <UploadInProgress />}

          {showProductUploadForm && (
            <>
              <div className="subtitle">
                1. Add Details <p>* indicates a required field</p>
              </div>

              <label data-cy="entity-name">{entityLabel} Name *</label>

              <Input
                className="input"
                placeholder={`Enter ${entityLabel.toLowerCase()} name`}
                value={productParam.consumer_product_name || ''}
                data-cy="entity-name-input"
                onChange={(e) =>
                  mergeState({
                    productParam: { consumer_product_name: e.target.value },
                  })
                }
              />

              {allowSkuInput && (
                <>
                  <label data-cy="entity-sku-id">SKU number</label>

                  <Input
                    className="input"
                    placeholder="Enter SKU number"
                    value={skuId}
                    data-cy="entity-sku-id-input"
                    onChange={(e) => setSkuId(e.target.value)}
                  />
                </>
              )}

              <label>Intended Use *</label>

              <Select
                className="styled-select input"
                required
                data-active={!productParam.application_times}
                value={productParam.application_times || ''}
                style={{ backgroundColor: 'white' }}
                variant="outlined"
                data-cy="intended-use-select"
                onChange={(e) =>
                  mergeState({
                    productParam: { application_times: e.target.value },
                  })
                }
              >
                <MenuItem disabled value="">
                  Select intended use
                </MenuItem>
                {application_times?.map((app, i) => (
                  <MenuItem
                    data-cy={`intended-use-item-${i}`}
                    key={app.name}
                    value={app.name}
                  >
                    {app.name}
                  </MenuItem>
                ))}
              </Select>

              <label>Intended Application *</label>

              <MultiSelectAutocomplete
                className="styled-select input"
                options={product_uses}
                dataCy="intended-application-multiselect"
                onChange={(_, value) =>
                  mergeState({
                    productParam: { product_use_ids: value.map((v) => v.id) },
                  })
                }
              />

              <label>{entityLabel} Format *</label>

              <Select
                className="styled-select input"
                required
                data-active={!productParam.product_form_id}
                value={productParam.product_form_id || ''}
                style={{ backgroundColor: 'white' }}
                variant="outlined"
                data-cy="entity-format-select"
                onChange={(e) =>
                  mergeState({
                    productParam: { product_form_id: +e.target.value },
                  })
                }
              >
                <MenuItem disabled value="">
                  Select {entityLabel.toLowerCase()} format
                </MenuItem>
                {product_forms?.map((product, i) => (
                  <MenuItem
                    key={product.id}
                    value={product.id}
                    data-cy={`intended-application-item-${i}`}
                  >
                    {product.name}
                  </MenuItem>
                ))}
              </Select>

              <label>Additional {entityLabel} Details</label>

              <MultiSelectAutocomplete
                className="styled-select input"
                options={product_claims}
                dataCy="additional-details-multiselect"
                onChange={(_, value) =>
                  mergeState({
                    productParam: { product_claim_ids: value.map((v) => v.id) },
                  })
                }
              />
              <label>Flavor *</label>

              <Select
                className="styled-select input"
                required
                data-active={!productParam.flavor}
                value={productParam.flavor || ''}
                style={{ backgroundColor: 'white' }}
                variant="outlined"
                data-cy="flavor-select"
                onChange={(e) =>
                  mergeState({
                    productParam: {
                      flavor: e.target.value,
                    },
                  })
                }
              >
                {Object.keys(FlavorOptions).map((flavor, i) => (
                  <MenuItem
                    data-cy={`flavor-item-${i}`}
                    key={flavor}
                    value={flavor}
                  >
                    {flavor}
                  </MenuItem>
                ))}
              </Select>
              <label>Fragrance *</label>

              <Select
                className="styled-select input"
                required
                data-active={!productParam.fragrance}
                value={productParam.fragrance || ''}
                style={{ backgroundColor: 'white' }}
                variant="outlined"
                data-cy="fragrance-select"
                onChange={(e) =>
                  mergeState({
                    productParam: {
                      fragrance: e.target.value,
                    },
                  })
                }
              >
                {Object.keys(FragranceOptions).map((fragrance, i) => (
                  <MenuItem
                    data-cy={`fragrance-item-${i}`}
                    key={fragrance}
                    value={fragrance}
                  >
                    {fragrance}
                  </MenuItem>
                ))}
              </Select>

              {(flow.legacy_sephora_flow || isSephoraRetailerBrand) && (
                <SephoraProductFields
                  onChange={(val) =>
                    mergeState({
                      productParam: val,
                    })
                  }
                  values={_.pick(productParam, [
                    'brandLine',
                    'productCategory',
                    'countries',
                    'launchDates',
                    'parentCompany',
                    'salesChannel',
                    'shareWithSephora',
                  ])}
                  isLegacySephoraFlow={flow.legacy_sephora_flow}
                />
              )}

              <>
                <div className="subtitle" style={{ marginTop: '30px' }}>
                  2. Paste Ingredients
                </div>
                <IngredientListForm
                  dataCy="ingredient-list-form"
                  onChange={(value) =>
                    mergeState({
                      productParam: { ingredient_names: value },
                    })
                  }
                />
              </>

              <div className="button-box">
                <Button
                  kind={ButtonKind.Primary}
                  disabled={!canUpload}
                  data-cy="submit-entity-button"
                  onClick={handleSubmit}
                >
                  Submit {entityLabel}
                </Button>
              </div>
            </>
          )}

          {showIngredientResolver && (
            <IngredientResolver
              stepNumber="4"
              entityId={
                isProduct
                  ? unresolvedUpload.formulation.id
                  : unresolvedUpload.id
              }
              ingredient_names={productParam?.ingredient_names}
              onSubmit={() => handleCleanup(unresolvedUpload)}
            />
          )}
        </div>

        {children}
      </DialogStyles>
    </Dialog>
  );
})`
  .subtitle {
    p {
      font-size: 12px;
      color: darkgray;
    }
  }
`;
