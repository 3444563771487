import { useState } from 'react';
import {
  Button,
  ButtonKind,
  Text,
  TextKind,
  TextElement,
  Tab,
  TabGroup,
  Pill,
  PillColor,
} from 'design-system/components';
import { Color } from 'design-system/data';
import styles from './submission.module.scss';

const tabData = [
  'Business info',
  'Responsible person',
  'Contact info',
  'Business address',
  'Mailing address',
];

export const SubmissionInformation = ({
  submissionData,
  setEditOpen = null,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <div className={styles['info-container']}>
      <div className={styles['info-header']}>
        <Text kind={TextKind.TextLGSemibold} element={TextElement.H2}>
          Business Information
        </Text>
        {Boolean(setEditOpen) && (
          <Button kind={ButtonKind.Pill} onClick={() => setEditOpen(true)}>
            Edit
          </Button>
        )}
      </div>
      <TabGroup activeTabIndex={activeTabIndex}>
        {tabData.map((tab, index) => (
          <Tab
            key={index}
            isActive={activeTabIndex === index}
            onClick={() => {
              setActiveTabIndex(index);
            }}
          >
            {tab}
          </Tab>
        ))}
      </TabGroup>
      <div className={styles['info-content']}>
        {activeTabIndex === 0 && (
          <BusinessInfoContent submissionData={submissionData} />
        )}
        {activeTabIndex === 1 && (
          <ResponsiblePersonContent submissionData={submissionData} />
        )}
        {activeTabIndex === 2 && (
          <BusinessContactContent submissionData={submissionData} />
        )}
        {activeTabIndex === 3 && (
          <BusinessAddressContent submissionData={submissionData} />
        )}
        {activeTabIndex === 4 && (
          <MailingAddressContent submissionData={submissionData} />
        )}
      </div>
    </div>
  );
};

export const SubmissionSkus = ({ children, length }) => {
  return (
    <div className={styles['info-container']}>
      <div className={styles['info-header']}>
        <div className={styles['title-pill-wrapper']}>
          <Text kind={TextKind.TextLGSemibold} element={TextElement.H2}>
            Your SKUs
          </Text>
          <Pill color={PillColor.Purple}>{length} SKUs</Pill>
        </div>
      </div>
      <div className={styles['head-row']}>
        <Text kind={TextKind.TextXSMedium} element={TextElement.P}>
          SKU
        </Text>
      </div>
      {children}
    </div>
  );
};

const BusinessInfoContent = ({ submissionData }) => {
  return (
    <>
      <ContentRow label="Business type" value={submissionData.businessType} />
      <ContentRow
        label="Parent company"
        value={submissionData.parentCompanyName}
      />
      <ContentRow
        label="Small business"
        value={submissionData.isSmallBusiness ? 'Yes' : 'No'}
      />
    </>
  );
};

const ResponsiblePersonContent = ({ submissionData }) => {
  const phoneNumber =
    submissionData.responsiblePersonPhoneCountryCode &&
    submissionData.responsiblePersonPhoneNumber
      ? `${submissionData.responsiblePersonPhoneCountryCode} ${
          submissionData.responsiblePersonPhoneNumber
        } ${
          submissionData.responsiblePersonPhoneExtension
            ? `EXT-${submissionData.responsiblePersonPhoneExtension}`
            : ''
        }`
      : '-';

  return (
    <>
      <ContentRow label="Name" value={submissionData.responsiblePersonName} />
      <ContentRow label="Phone number" value={phoneNumber} />
      <ContentRow
        label="DUNS number"
        value={submissionData.responsiblePersonDuns}
      />
    </>
  );
};

const BusinessContactContent = ({ submissionData }) => {
  const name =
    submissionData.contactFirstName && submissionData.contactLastName
      ? [submissionData.contactFirstName, submissionData.contactLastName].join(
          ' '
        )
      : '-';
  const phoneNumber =
    submissionData.contactPhoneCountryCode && submissionData.phoneNumber
      ? `${submissionData.contactPhoneCountryCode} ${
          submissionData.phoneNumber
        } ${
          submissionData.contactPhoneExtension
            ? `EXT-${submissionData.contactPhoneExtension}`
            : ''
        }`
      : '-';

  return (
    <>
      <ContentRow label="Name" value={name} />
      <ContentRow label="Role" value={submissionData.contactTitle} />
      <ContentRow label="Email address" value={submissionData.contactEmail} />
      <ContentRow label="Phone number" value={phoneNumber} />
    </>
  );
};

const BusinessAddressContent = ({ submissionData }) => {
  return (
    <>
      <ContentRow
        label="Building name"
        value={submissionData.physicalFacilityName}
      />
      <ContentRow label="Address" value={submissionData.physicalAddressLine1} />
      <ContentRow
        label="Address line 2"
        value={submissionData.physicalAddressLine2}
      />
      <ContentRow label="Country" value={submissionData.physicalCountryCode} />
      <ContentRow
        label="Postal code"
        value={submissionData.physicalPostalCode}
      />
      <ContentRow label="City" value={submissionData.physicalCity} />
      <ContentRow label="State" value={submissionData.physicalRegion} />
    </>
  );
};

const MailingAddressContent = ({ submissionData }) => {
  return (
    <>
      <ContentRow
        label="Building name"
        value={submissionData.mailingFacilityName}
      />
      <ContentRow label="Address" value={submissionData.mailingAddressLine1} />
      <ContentRow
        label="Address line 2"
        value={submissionData.mailingAddressLine2}
      />
      <ContentRow label="Country" value={submissionData.mailingCountryCode} />
      <ContentRow
        label="Postal code"
        value={submissionData.mailingPostalCode}
      />
      <ContentRow label="City" value={submissionData.mailingCity} />
      <ContentRow label="State" value={submissionData.mailingRegion} />
    </>
  );
};

const ContentRow = ({ label, value }) => {
  return (
    <div className={styles['content-row']}>
      <Text kind={TextKind.TextSMMedium} element={TextElement.H5}>
        {label}
      </Text>
      <Text
        kind={TextKind.TextSM}
        element={TextElement.P}
        color={Color.Neutral600}
      >
        {value || '-'}
      </Text>
    </div>
  );
};
