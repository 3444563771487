import { Text, TextElement, TextKind } from 'design-system/components';
import { useApp } from 'context/AppContext';
import { isRetailerWithQuicksight } from 'utils/retailer';
import QuickSightDashboard from 'components/QuicksightDashboard';
import MetabaseDashboard from 'components/MetabaseDashboard';
import styles from './metrics-dashboard.module.scss';

function RetailerBrandMetricsDashboard() {
  const { user } = useApp();
  const retailerID = user.organization.in_orgable_id;
  const hasAccess = user.hasFF('metabase_dashboard_view');
  const dashboardType = 'brand-metrics';

  // once a user gets the FF enabled, they should no longer see quicksight even without a change to the code (remove id from list of retailers with quicksight access)
  // chore: once a user does have FF enabled, eventually their id should be removed from the quicksight list
  const showQuicksightDashboard =
    retailerID && isRetailerWithQuicksight(retailerID) && !hasAccess;

  return (
    <>
      <Text
        element={TextElement.H1}
        kind={TextKind.TextMDBold}
        className={styles['text']}
      >
        Brand Metrics
      </Text>
      {showQuicksightDashboard ? (
        <QuickSightDashboard
          dashboardType={dashboardType}
          retailerID={retailerID}
        />
      ) : (
        <MetabaseDashboard
          dashboardType={dashboardType}
          retailerID={retailerID}
        />
      )}
    </>
  );
}

export default RetailerBrandMetricsDashboard;
