import { createContext, useContext, useMemo, useState, useEffect } from 'react';
import { convertSnakeToCamelCase } from 'design-system/utils/case';

import useApi from 'services/api/useApi';

const FDASubmissionContext = createContext(null);

export const useFDASubmissionContext = () => {
  const ctx = useContext(FDASubmissionContext);
  if (!ctx) {
    throw new Error('Must be used within an FDASubmissionContext');
  }
  return ctx;
};

export default function FDASubmissionProvider({ children }) {
  const [allSubmissions, setAllSubmissions] = useState([]);

  // GET MANUFACTURER DATA
  const submissions = useApi(`api/v4/certification_cosmetic_product_listings`);

  // HYDRATE AND SET SUBMISSIONS STATE
  useEffect(() => {
    if (!submissions.data) return;

    setAllSubmissions(convertSnakeToCamelCase(submissions.data.submissions));
  }, [submissions.data]);

  const context = useMemo(() => {
    return {
      allSubmissions,
    };
  }, [allSubmissions]);

  return (
    <FDASubmissionContext.Provider value={context}>
      {children}
    </FDASubmissionContext.Provider>
  );
}
