import _ from 'lodash';
import { createContext, useRef, useState } from 'react';

import BackDropLoading from '../components/shared/BackDropLoader';
export const GlobalContext = createContext();

export const GlobalContextProvider = ({ children }) => {
  let loaders = [];
  const prevLoad = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const _setIsLoading = (value, loadingId = '') => {
    if (!value) {
      _.remove(loaders, (n) => n === loadingId);
    } else {
      loaders = _.concat(loaders, loadingId);
    }
    loaders = _.uniq(loaders);

    const currentIsLoading = loaders.length > 0;
    if (prevLoad.current !== currentIsLoading) {
      prevLoad.current = currentIsLoading;
      setIsLoading(prevLoad.current);
    }
  };

  const values = {
    isDrawerOpen,
    setIsLoading: _setIsLoading,
    setIsDrawerOpen,
  };

  return (
    <GlobalContext.Provider value={values}>
      <BackDropLoading open={isLoading} />
      {children}
    </GlobalContext.Provider>
  );
};
