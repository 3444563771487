import { createContext, useState, useContext } from 'react';

export const PreloadedOrderContext = createContext({
  order: {},
  setOrder: (newOrder) => {},
});

export const PreloadedOrderContextProvider = ({ children }) => {
  const [order, setOrder] = useState({});
  return (
    <PreloadedOrderContext.Provider value={{ order, setOrder }}>
      {children}
    </PreloadedOrderContext.Provider>
  );
};

export const usePreloadedOrderContext = () => {
  const ctx = useContext(PreloadedOrderContext);
  if (!ctx)
    throw new Error('must be used within PreloadedOrderContextProvider');
  return ctx;
};
