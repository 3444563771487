export default class FetchError extends Error {
  constructor(
    message,
    request,
    response,
    { responseData, retryTimes, ...options } = {}
  ) {
    super(message, options);
    this.request = request;
    this.response = response;
    this.responseData = responseData;
    this.retryTimes = retryTimes || 0;
  }

  errorCode() {
    return this.responseData?.error?.code;
  }
}
