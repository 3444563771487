import React from 'react';
import {
  Button,
  ButtonKind,
  Text,
  TextKind,
  TextElement,
} from 'design-system/components';

import { useRequirementActions } from './context';
import styles from './cta-modals.module.scss';

interface SlideoutFooterProps {
  onNext?: () => void;
  onPrev?: () => void;
  nextActive?: boolean;
  prevActive?: boolean;
  totalItemCount?: number;
  currentRequirementNum?: number;
  hideNav?: boolean;
  disabled?: boolean;
}

interface RequirementActionsContextType {
  setAttestationActions: React.Dispatch<React.SetStateAction<{}>>;
}

const SlideoutFooter = ({
  onNext,
  onPrev,
  nextActive,
  prevActive,
  totalItemCount,
  currentRequirementNum,
  hideNav = false,
  disabled,
}: SlideoutFooterProps) => {
  const { setAttestationActions } =
    useRequirementActions() as RequirementActionsContextType;
  return (
    <div className={styles.footer}>
      <div>
        <Text kind={TextKind.TextSM} element={TextElement.P}>
          {totalItemCount
            ? `SKU ${currentRequirementNum || '-'} of ${totalItemCount}`
            : 'All SKUs'}
        </Text>
      </div>
      {!hideNav && (
        <div className={styles['modal-nav']}>
          <Button
            onClick={() => {
              setAttestationActions({});
              onPrev && onPrev();
            }}
            kind={ButtonKind.Secondary}
            disabled={!prevActive || disabled}
          >
            {!prevActive ? 'End of page' : 'Back'}
          </Button>
          <Button
            onClick={() => {
              setAttestationActions({});
              onNext && onNext();
            }}
            kind={ButtonKind.Secondary}
            disabled={!nextActive || disabled}
          >
            {!nextActive ? 'End of page' : 'Next'}
          </Button>
        </div>
      )}
    </div>
  );
};

export default SlideoutFooter;
