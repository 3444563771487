import PropTypes from 'prop-types';

export const RootProps = {
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  children: PropTypes.node, // SelectV2Item | SelectV2Group | SelectV2Separator
  asChild: PropTypes.bool,
  onValueChange: PropTypes.func,
  defaultOpen: PropTypes.bool,
  open: PropTypes.bool,
  onOpenChange: PropTypes.func,
  dir: PropTypes.oneOf(['ltr', 'rtl']),
  name: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  triggerStyle: PropTypes.object,
};

export const ValueProps = {
  placeholder: PropTypes.string,
};

export const PortalProps = {
  container: PropTypes.element,
};

export const ContentProps = {
  onCloseAutoFocus: PropTypes.func,
  onEscapeKeyDown: PropTypes.func,
  position: PropTypes.oneOf(['item-aligned', 'popper']),
  side: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  sideOffset: PropTypes.number,
  align: PropTypes.oneOf(['start', 'center', 'end']),
  alignOffset: PropTypes.number,
  avoidCollisions: PropTypes.bool,
  collisionBoundary: PropTypes.any, // Element | null | Array<Element | null>
  collisionPadding: PropTypes.oneOfType([PropTypes.number, PropTypes.any]), // number | Partial<Record<Side, number>>
  sticky: PropTypes.oneOf(['partial', 'always']),
};

export const SelectV2ItemProps = {
  value: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  asChild: PropTypes.bool,
  disabled: PropTypes.bool,
  textValue: PropTypes.string,
};

export const SelectV2GroupProps = {
  asChild: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
  ]),
  children: PropTypes.node.isRequired, // SelectV2Item | SelectV2Separator
};

const SelectV2Props = {
  ...RootProps,
  ...ValueProps,
  ...PortalProps,
  ...ContentProps,
};

export default SelectV2Props;
