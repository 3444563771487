export default function SpecialOffers({ size, color }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9_424)">
        <path
          d="M10.0293 1.9065C11.1281 0.843503 12.8719 0.843504 13.9707 1.9065C14.5991 2.51437 15.4728 2.79825 16.3384 2.67581C17.8522 2.4617 19.263 3.48671 19.5272 4.99256C19.6782 5.85367 20.2182 6.59689 20.9905 7.00665C22.341 7.7232 22.8799 9.38171 22.2085 10.7552C21.8245 11.5407 21.8245 12.4593 22.2085 13.2448C22.8799 14.6183 22.341 16.2768 20.9905 16.9934C20.2182 17.4031 19.6782 18.1463 19.5272 19.0074C19.263 20.5133 17.8522 21.5383 16.3384 21.3242C15.4728 21.2017 14.5991 21.4856 13.9707 22.0935C12.8719 23.1565 11.1281 23.1565 10.0293 22.0935C9.4009 21.4856 8.5272 21.2017 7.66155 21.3242C6.14778 21.5383 4.73697 20.5133 4.47282 19.0074C4.32177 18.1463 3.78179 17.4031 3.0095 16.9934C1.65898 16.2768 1.1201 14.6183 1.79152 13.2448C2.17546 12.4593 2.17546 11.5407 1.79152 10.7552C1.1201 9.38171 1.65898 7.7232 3.0095 7.00665C3.78179 6.59689 4.32177 5.85367 4.47282 4.99256C4.73697 3.48671 6.14778 2.4617 7.66155 2.67581C8.5272 2.79825 9.4009 2.51437 10.0293 1.9065Z"
          fill={color.value}
        />
        <path
          d="M10.4142 10.4142C9.63317 11.1953 8.36683 11.1953 7.58579 10.4142C6.80474 9.63317 6.80474 8.36683 7.58579 7.58579C8.36683 6.80474 9.63317 6.80474 10.4142 7.58579C11.1953 8.36683 11.1953 9.63317 10.4142 10.4142Z"
          fill="white"
        />
        <path
          d="M16.4142 16.4142C15.6332 17.1953 14.3668 17.1953 13.5858 16.4142C12.8047 15.6332 12.8047 14.3668 13.5858 13.5858C14.3668 12.8047 15.6332 12.8047 16.4142 13.5858C17.1953 14.3668 17.1953 15.6332 16.4142 16.4142Z"
          fill="white"
        />
        <path d="M7 17L17 7" stroke="white" strokeWidth="1.5" />
      </g>
      <defs>
        <clipPath id="clip0_9_424">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
