import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { PATHS } from 'constants/index';
import { useApp } from 'context/AppContext';
import PolicySelectionPage from 'views/Brands/RetailerBrandEligibility/PolicySelectionPage';

const RetailerBrandSubmissionsV2: FC = () => {
  const { retailerBrand } = useApp();
  const link = (policyId: number) =>
    PATHS.retailerBrandSubmissionsWithId.replace(
      ':policy_id',
      policyId.toString()
    );
  return (
    <Switch>
      <Route
        path={PATHS.retailerBrandSubmissions}
        component={() => (
          <PolicySelectionPage
            breadCrumbText="Submissions"
            title="Your submissions"
            subTitle={`View that status of your submissions to the ${
              (retailerBrand?.retailer as any)
                ? retailerBrand.retailer.name
                : 'retailer'
            } programs here.`}
            retailerBrand={retailerBrand}
            link={link}
          />
        )}
        secure
        exact
      />
      {/* Page 2 route goes here */}
    </Switch>
  );
};

export default RetailerBrandSubmissionsV2;
