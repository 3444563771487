import PropTypes from 'prop-types';
import styles from './accordion.module.scss';

function AccordionDetail(props) {
  return (
    <div className={styles.detail} data-cy={props[`data-cy`]}>
      {props.children}
    </div>
  );
}

AccordionDetail.propTypes = {
  [`data-cy`]: PropTypes.string,
};

export default AccordionDetail;
