import styles from '../icon.module.scss';

function Chevron(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles[`root-${props.direction}`]}
    >
      <path d="M1 6L5 2L9 6" stroke={props.color.value} strokeWidth="1.5" />
    </svg>
  );
}

export default Chevron;
