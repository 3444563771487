import { useState, useCallback, useMemo } from 'react';

// merges state property values rather than replacing them
function useMergeStateProperties(initialState, init = (x) => x) {
  const [state, setState] = useState(init(initialState));

  const mergeState = useCallback((newState) => {
    setState((oldState) => {
      return Object.keys(newState).reduce(
        (acc, prop) => {
          if (typeof newState[prop] !== 'object') {
            acc[prop] = newState[prop];
          } else {
            acc[prop] = { ...acc[prop], ...newState[prop] };
          }
          return acc;
        },
        { ...oldState }
      );
    });
  }, []);

  return useMemo(() => [state, mergeState, setState], [state, mergeState]);
}

export default useMergeStateProperties;
