import { useState, useMemo } from 'react';
import useDelayedChange from 'hooks/useDelayedChange';
import { useApp } from 'context/AppContext';
import useAsyncCall from 'services/api/useAsyncCall';
import fetcher from 'services/api/fetcher';
import { matchSorter } from 'match-sorter';
import noop from 'utils/noop';

export function useResolveIngredient({
  initialTerm = '',
  entity_id = '',
  ingredient_id = '',
  afterSuccess = noop,
  isMaterial,
}) {
  const [{ term, option }, setState] = useState({
    term: initialTerm,
    option: undefined,
  });
  const { useApi } = useApp();

  const chemicalCompounds = useApi(`/api/v4/autocomplete/chemical_compounds`, {
    param: { term: useDelayedChange(term, { delay: 500 }) },
    enabled: term?.length > 1 && !isMaterial,
  });

  const materials = useApi(`/api/v4/autocomplete/materials`, {
    param: { term: useDelayedChange(term, { delay: 500 }) },
    enabled: term?.length > 1 && isMaterial,
  });

  const resolve = useAsyncCall(async (e) => {
    e?.preventDefault?.();
    await fetcher(
      `/api/product_design/v1/formulations/${entity_id}/chemical_ingredients/${ingredient_id}`,
      {
        method: 'put',
        body: isMaterial
          ? { supplier_material_id: option.id }
          : { chemical_compound_id: option.id },
      }
    );
    await afterSuccess(option.id);
  });

  const memoized = useMemo(() => {
    return {
      resolve,
      options: matchSorter(
        chemicalCompounds?.data?.results || materials?.data?.results || [],
        term,
        { keys: ['name'] }
      ),
      option,
      setOption(option) {
        setState((was) => ({ ...was, option }));
      },
      setTerm(term = '') {
        setState((was) => ({ ...was, term }));
      },
    };
  }, [chemicalCompounds, materials, resolve, option, term]);

  return memoized;
}
