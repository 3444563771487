// @ts-nocheck
import { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { PillColor } from 'design-system/components';
import { useFilters, useStatefulQueryParam } from 'design-system/utils';
import { UrlParam, PolicyRequirementType } from 'design-system/data';

import { useApp } from 'context/AppContext';
import { PATHS } from 'constants/index';
import Disclaimer from '../shared/Disclaimer';
import { useRequirementActions } from '../shared/RequirementActionModalContents/context';
import { usePillarRequirements } from './hooks/useData';
import useFilterOptions from './hooks/useFilterOptions';
import { tableHeaderData, tabStatuses, VIEW_ALL_NAME } from './data/constants';
import Header from './components/Header';
import PolicyRequirementsTabsAndTable from './components/TabsAndTable';
import SlideoutModal from './components/SlideoutModal';

const PolicyRequirementsView = () => {
  const baseUrl = PATHS.retailerBrandRequirementGroups;
  const { retailerBrand } = useApp();
  const searchParams = new URLSearchParams();
  const params = useParams();
  const { requirement_group_id, requirement_id } = params;

  const [activeSort, setActiveSort] = useStatefulQueryParam(
    UrlParam.Direction,
    searchParams.get(UrlParam.Direction) || ''
  );
  const [page, setPage] = useStatefulQueryParam(UrlParam.Page, 1);

  const { setModalOpen, modalOpen, setSelectedRequirement } =
    useRequirementActions();
  const [filterState, filterStateDispatch] = useFilters();

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [currentRow, setCurrentRow] = useState(null);
  const [previousActive, setPreviousActive] = useState(false);
  const [nextActive, setNextActive] = useState(false);

  const {
    loading,
    error,
    data,
    currentTabData,
    hasLoadedSuccessfully,
    statusQuantities,
  } = usePillarRequirements(requirement_id, tabStatuses[activeTab], '');
  const subcategoryFilterOptions = useFilterOptions(currentTabData);

  // TODO: update to use more descriptive live data when ready to be hydrated by BE data
  const breadcrumbItems = [
    {
      text: 'Pillars',
      link: baseUrl,
    },
    {
      text: data?.policyRequirementGroupName || 'Retailer Requirements',
      link: `${baseUrl}/${requirement_group_id}/retailer_requirements`,
    },
    {
      text: data?.name || 'Policy Requirements',
      link: `${baseUrl}/${requirement_group_id}/retailer_requirements/${data?.id}`,
    },
  ];

  const tabData = useMemo(
    () => [
      {
        label: 'All Requirements',
        value: 0,
        quantity: statusQuantities.total || 0,
        pillColor: PillColor.Purple,
      },
      {
        label: 'Needs attention',
        value: 1,
        quantity:
          (statusQuantities.needsInformation || 0) +
          (statusQuantities.updating || 0),
        pillColor: PillColor.Warning,
      },
      {
        label: 'Meets',
        value: 2,
        quantity: statusQuantities.pass || 0,
        pillColor: PillColor.Success,
      },
      {
        label: 'Does not meet',
        value: 3,
        quantity:
          (statusQuantities.fail || 0) + (statusQuantities.rejected || 0), // combine fail and rejected
        pillColor: PillColor.ErrorColor,
      },
      {
        label: 'Pending',
        value: 4,
        quantity: statusQuantities.pending || 0,
        pillColor: PillColor.Blue,
      },
      {
        label: 'Not applicable',
        value: 5,
        quantity: statusQuantities.notApplicable || 0,
        pillColor: PillColor.Default,
      },
    ],
    [statusQuantities]
  );

  const subcategoryFilterOptionsWithAll = useMemo(
    () => [
      {
        name: VIEW_ALL_NAME,
        count: currentTabData?.length,
        id: VIEW_ALL_NAME.toLowerCase(),
      },
      ...subcategoryFilterOptions,
    ],
    [subcategoryFilterOptions, currentTabData]
  );

  const showSubcategoryToggleRow = subcategoryFilterOptions.length > 1;

  /** Filter Modal Logic:
   * if the toggle row is hidden, and there are non-subcategory filters, show the filter button-modal
   */
  const SUBCATEGORIES_FILTER_NAME = 'Subcategories';
  const filters = [
    {
      name: SUBCATEGORIES_FILTER_NAME,
      value: SUBCATEGORIES_FILTER_NAME.toLowerCase(),
      options: subcategoryFilterOptions,
    },
  ];
  const filtersWithoutSubcategories = filters.filter(
    (filter) => filter.name !== SUBCATEGORIES_FILTER_NAME
  );

  const showFilterModalButton =
    !showSubcategoryToggleRow && filtersWithoutSubcategories.length > 0;

  // Filtered Table Data
  const currentFilteredData = useMemo(
    () =>
      currentTabData?.filter(
        (req) =>
          !filterState.subcategories ||
          !filterState.subcategories?.length ||
          filterState.subcategories.some(
            (subcat) => subcat.name === req?.category
          )
      ),
    [currentTabData, filterState.subcategories]
  );

  const hasData = currentFilteredData?.length > 0;

  const modalNavNextLogic = (i) => {
    setNextActive(i < currentFilteredData?.length - 1);
  };

  const modalNavPrevLogic = (i) => {
    setPreviousActive(i > 0);
  };

  return (
    <main>
      <SlideoutModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        currentRow={currentRow}
        setCurrentRow={setCurrentRow}
        currentFilteredData={currentFilteredData}
        modalNavPrevLogic={modalNavPrevLogic}
        modalNavNextLogic={modalNavNextLogic}
        previousActive={previousActive}
        nextActive={nextActive}
        expectedRequirementType={PolicyRequirementType.Brand}
      />
      <Header data={data} breadcrumbItems={breadcrumbItems} />
      <PolicyRequirementsTabsAndTable
        tabData={tabData}
        filterState={filterState}
        filterStateDispatch={filterStateDispatch}
        currentFilteredData={currentFilteredData}
        tableHeaderData={tableHeaderData}
        activeSort={activeSort}
        setActiveSort={setActiveSort}
        setSelectedRequirement={setSelectedRequirement}
        setModalOpen={setModalOpen}
        setCurrentRow={setCurrentRow}
        modalNavPrevLogic={modalNavPrevLogic}
        modalNavNextLogic={modalNavNextLogic}
        baseUrl={baseUrl}
        requirementGroupId={requirement_group_id}
        requirementId={requirement_id}
        loading={loading}
        error={error}
        hasLoadedSuccessfully={hasLoadedSuccessfully}
        hasData={hasData}
        showSubcategoryToggleRow={showSubcategoryToggleRow}
        subcategoryFilterOptionsWithAll={subcategoryFilterOptionsWithAll}
        showFilterModalButton={showFilterModalButton}
        filters={filters}
        page={page}
        setPage={setPage}
        data={data}
        setActiveTabIndex={setActiveTabIndex}
        setActiveTab={setActiveTab}
        currentRow={currentRow}
        modalOpen={modalOpen}
        activeTabIndex={activeTabIndex}
      />
      {retailerBrand?.retailer?.disclaimer && (
        <Disclaimer text={retailerBrand.retailer.disclaimer || ''} />
      )}
    </main>
  );
};

export default PolicyRequirementsView;
