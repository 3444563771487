export const ButtonKind = {
  Primary: 'primary',
  Secondary: 'secondary',
  Tertiary: 'tertiary',
  Alternate: 'alternate',
  Pill: 'pill',
  Success: 'success',
  Error: 'error',
  Link: 'link',
  Close: 'close',
};

export const ButtonIconPosition = {
  Left: 'left',
  Right: 'right',
};

export const ButtonSize = {
  Small: 'size-sm',
  Medium: 'size-md',
  Large: 'size-lg',
  XL: 'size-xl',
  XXL: 'size-2xl',
};
