import useApi from 'services/api/useApi';

export type SubscriptionTier = {
  id: number;
  bulk_upload_included: boolean;
  description: string;
  external_name: string;
  name: string;
  price: number;
  product_count_max: number | null;
  product_count_min: number | null;
  subscription_duration_days: number;
  created_at: string;
  updated_at: string;
};

const useSubscriptionTiers = (
  id: string | number
): {
  subscriptionTiers: SubscriptionTier[] | null;
  error: any;
  loading: boolean;
} => {
  const { data, error, loading } = useApi(
    `/api/v4/retailer_consumer_brands/${id}/subscription_tiers`,
    {
      enabled: Boolean(id),
    }
  );
  const typedData = data as { [key: string]: any };
  return {
    subscriptionTiers: data?.hasOwnProperty('subscription_tiers')
      ? (typedData.subscription_tiers as SubscriptionTier[])
      : null,
    error,
    loading,
  };
};

export default useSubscriptionTiers;
