export const FilterOptions = {
  Brands: 'brands',
  Products: 'products',
};

export const FilterModalTypes = {
  Search: 'search',
  Dropdown: 'dropdown',
};

export const buildFilterModalState = (filters) => {
  const filterState = {};
  filters.forEach((filter) => {
    if (typeof filter === 'string') {
      filterState[filter] = {
        isLoading: false,
        results: [],
      };
    } else {
      filterState[filter.value] = {
        isLoading: false,
        results: [],
        isDynamic: filter.isDynamic,
        toggleOptions: filter.toggleOptions,
      };
    }
  });
  return filterState;
};

export const encodeIDs = (records) => {
  if (!records) return [];
  const ids = records?.map((record) => {
    if (typeof record === 'object') {
      return record.id;
    }
    return record;
  });
  return ids.join(',');
};
