import { useHistory } from 'react-router';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Button, ButtonKind } from 'design-system/components';
import { Color } from 'design-system/data';

const NotFound = styled(function NotFound(props) {
  const { children, className } = props;
  const history = useHistory();
  return (
    <section className={className}>
      <Helmet>
        <title>Page not found</title>
      </Helmet>
      <div className="error">
        <h1>Page Not Found</h1>
        <p>
          The resource you requested is not available. Please return to the home
          screen, or to the previous page.
        </p>
        <div className="button-box">
          <Button kind={ButtonKind.Secondary} onClick={() => history.goBack()}>
            Go Back
          </Button>
          <Button kind={ButtonKind.Primary} onClick={() => history.push('/')}>
            Return to Home
          </Button>
        </div>
      </div>
      {children}
    </section>
  );
})`
  background-color: var(--brand-color);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-sans-serif);
  color: ${Color.Black.value};

  h1 {
    font-size: 32px;
  }

  p {
    font-size: 16px;
    text-align: center;
  }

  .error {
    padding: 3em;
    border-radius: 4px;
    background-color: white;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 16px;
    max-width: 600px;
  }

  .button-box {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
`;

export default NotFound;
