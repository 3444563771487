import React from 'react';
import {
  Link,
  LinkKind,
  LinkVariant,
  PhosphorIcon,
  PhosphorIconWeight,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';

import { NextStepsV2Props } from './types';
import styles from './next-steps-cta.module.scss';
import { BrandColors } from 'design-system/data';

export const NextStepsCTA: React.FC<NextStepsV2Props> = ({
  cardHeading,
  phosphorIconName,
  descriptiveText,
  ctaButtonText,
  ctaOnClick,
  redirectPath,
  linkKind,
  buttonAction,
  secondaryAction,
  footerElement,
}) => {
  const renderCTA = () => {
    if (buttonAction) {
      return buttonAction;
    }

    if (!linkKind || !redirectPath) {
      return null;
    }

    return (
      <Link
        kind={linkKind}
        variant={LinkVariant.Primary}
        {...(linkKind === LinkKind.Internal ? { to: redirectPath } : {})}
        {...(linkKind === LinkKind.External ? { href: redirectPath } : {})}
        onClick={ctaOnClick}
      >
        <span className={styles.cta}>
          {ctaButtonText}
          <span className={styles['icon-container']}>
            <PhosphorIcon iconName="ArrowRight" size={20} />
          </span>
        </span>
      </Link>
    );
  };

  return (
    <div className={styles['pillar-dashboard-card']}>
      <div className={styles['pillar-dashboard-content']}>
        <div>
          <div className={styles['pillar-dashboard-header']}>
            <div className={styles['policy-contract-icon']}>
              {' '}
              <PhosphorIcon
                iconName={phosphorIconName}
                weight={
                  PhosphorIconWeight.Regular as keyof typeof PhosphorIconWeight
                }
                size={32}
                color={BrandColors.Gray600.value}
              />
            </div>
            <Text
              kind={TextKind.Display2XSMedium}
              element={TextElement.H3}
              color={BrandColors.Gray900}
            >
              {cardHeading}
            </Text>
          </div>
          <div>
            {' '}
            <Text
              kind={TextKind.TextMD}
              element={TextElement.P}
              color={BrandColors.Gray600}
            >
              {descriptiveText}
            </Text>
          </div>
        </div>
        <div className={styles['pillar-dashboard-cta']}>
          {secondaryAction}
          {renderCTA()}
        </div>
      </div>
      {footerElement && <div className={styles['footer']}>{footerElement}</div>}
    </div>
  );
};

export default NextStepsCTA;
