import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
  IconName,
  Text,
  TextKind,
  TextElement,
} from 'design-system/components';
import { Color } from 'design-system/data';
import styles from './editable-title.module.scss';

const EditableTitle = ({
  title,
  onTitleChange,
  placeholder = 'Enter title',
}) => {
  const [editedTitle, setEditedTitle] = useState(title);

  // open if edited is empty string
  const [toggleInput, setToggleInput] = useState(editedTitle === '');

  // allow click outside element to close input
  useEffect(() => {
    const handleClickOutside = () => {
      if (editedTitle.length > 0) {
        setToggleInput(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [setToggleInput, editedTitle]);

  // open and close input
  const handleClick = () => {
    setToggleInput(!toggleInput);
  };

  // update title and fire callback
  const handleInputChange = (e) => {
    const val = e.target.value;
    setEditedTitle(val);
    onTitleChange(val);
  };

  // toggle input on enter
  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (editedTitle.length > 0) {
        setToggleInput(!toggleInput);
      }
    }
  };

  return (
    <span
      tabIndex={0}
      onKeyDown={onKeyDown}
      className={styles['editable-title']}
    >
      {!toggleInput && (
        <div className={styles.title} onClick={handleClick}>
          <Text
            kind={TextKind.DisplaySMBold}
            element={TextElement.H1}
            color={Color.Black}
            data-cy="editable-title-header"
          >
            {editedTitle}
          </Text>
          <Icon size={18} name={IconName.Edit} color={Color.Neutral500} />
        </div>
      )}
      {toggleInput && (
        <input
          className={styles.input}
          value={editedTitle}
          onChange={handleInputChange}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          size={editedTitle.length && editedTitle.length + 4}
          autoFocus
          data-cy="editable-title-input"
        />
      )}
    </span>
  );
};

EditableTitle.propTypes = {
  title: PropTypes.string,
  onTitleChange: PropTypes.func,
  placeholder: PropTypes.string,
};

export default EditableTitle;
