import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';

import styles from './quicksight-dashboard.module.scss';
import { useApp } from 'context/AppContext';

function QuickSightDashboard({ dashboardType, retailerID }) {
  const [embeddingContext, setEmbeddingContext] = useState(null);
  const { useApi } = useApp();

  const endpoint = `api/v4/retailers/${retailerID}/quicksight_embed_url`;
  const response = useApi(endpoint, {
    enabled: Boolean(retailerID && dashboardType),
    param: {
      dashboard_type: dashboardType,
    },
  });
  const embedUrl = response?.data?.embed_url || null;

  // Creating the context
  useEffect(() => {
    const createContext = async () => {
      const context = await createEmbeddingContext();

      setEmbeddingContext(context);
    };

    if (embedUrl) {
      createContext();
    }
  }, [embedUrl]);

  // Creating the dashboard experience
  useEffect(() => {
    const createDashboardExperience = async () => {
      const frameOptions = {
        url: embedUrl,
        container: '#experience-container',
      };
      const contentOptions = {};

      await embeddingContext.embedDashboard(frameOptions, contentOptions);
    };

    if (embeddingContext) {
      createDashboardExperience();
    }
  }, [embeddingContext, embedUrl]);

  return <div id="experience-container" className={styles.container}></div>;
}

QuickSightDashboard.propTypes = {
  dashboardType: PropTypes.string.isRequired,
  retailerID: PropTypes.number.isRequired,
};

export default QuickSightDashboard;
