import { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { PLACEHOLDER } from 'design-system/data';
import {
  Modal,
  ModalType,
  Text,
  TextKind,
  TextElement,
  PolicyIcon,
  Icon,
  IconName,
  Link,
  LinkKind,
  ButtonKind,
  LoadingOverlay,
} from 'design-system/components';
import { RequirementReviewModalKind } from './constants';
import styles from './requirement-review-modal.module.scss';
import withLineBreaks from 'utils/withLineBreaks';

const RequirementReviewModal = ({
  kind = RequirementReviewModalKind.View,
  requirementName,
  policy,
  attestationDescription,
  document,
  attestationProof,
  show,
  onClick,
  onCancel,
  onReject,
  onDone,
  loading,
}) => {
  const [notes, setNotes] = useState('');

  useEffect(() => {
    if (document?.retailerNotes) {
      setNotes(document.retailerNotes);
    }
  }, [document?.retailerNotes]);

  const isViewKind = kind === RequirementReviewModalKind.View;

  const additionalModalProps = isViewKind
    ? {}
    : {
        cancelCtaLabel: 'Reject Document',
        cancelCtaKind: ButtonKind.Error,
        doneCtaLabel: 'Approve Document',
        doneCtaKind: ButtonKind.Success,
      };

  const handleCancel = () => {
    if (isViewKind) {
      onCancel();
    } else {
      onReject(notes);
    }
  };

  const showAttestationView =
    policy?.attestationRequired || attestationProof?.responseValue;

  return (
    <>
      <LoadingOverlay show={loading} />
      <Modal
        showActionButtons
        title={
          isViewKind
            ? showAttestationView
              ? 'View attestation'
              : 'Review document'
            : 'Document approval'
        }
        type={ModalType.Large}
        show={show}
        onClick={onClick}
        onCancel={handleCancel}
        onDone={isViewKind ? onClick : () => onDone(notes)}
        {...additionalModalProps}
      >
        <div className={styles.container}>
          <div className={styles.section}>
            <Text kind={TextKind.TextSMBold} element={TextElement.P}>
              Requirement
            </Text>
            <div className={styles.row}>
              <PolicyIcon src={policy?.image} name={policy?.name} />
              <Text kind={TextKind.TextMD} element={TextElement.P}>
                {requirementName || PLACEHOLDER}
              </Text>
            </div>
          </div>
          <div className={styles.alert}>
            <Text kind={TextKind.TextSMBold} element={TextElement.P}>
              Requirement
            </Text>
            <Text kind={TextKind.TextMD} element={TextElement.P}>
              {withLineBreaks(attestationDescription) || PLACEHOLDER}
            </Text>
          </div>
          {showAttestationView ? (
            <div className={styles.section}>
              <Text kind={TextKind.TextSMBold} element={TextElement.P}>
                Attestation
              </Text>
              <div className={styles.attestation}>
                <Text kind={TextKind.TextMD} element={TextElement.P}>
                  {attestationProof?.responseValue || PLACEHOLDER}
                </Text>
              </div>
            </div>
          ) : (
            <div className={styles.section}>
              <Text kind={TextKind.TextSMBold} element={TextElement.P}>
                Document
              </Text>
              <div className={styles.row}>
                <Icon name={IconName.Document} size={16} />
                {document?.documentUrl ? (
                  <Link kind={LinkKind.External} href={document?.documentUrl}>
                    {document?.documentName || PLACEHOLDER}
                  </Link>
                ) : (
                  <Text kind={TextKind.TextMDBold} element={TextElement.P}>
                    {document?.documentName || PLACEHOLDER}
                  </Text>
                )}
              </div>
            </div>
          )}
          {!isViewKind && (
            <div className={styles.section}>
              <Text kind={TextKind.TextSMBold} element={TextElement.P}>
                Add notes
              </Text>
              <textarea
                className="textarea"
                placeholder="Adding notes optional..."
                value={notes}
                onChange={(event) => setNotes(event.target.value)}
              />
            </div>
          )}
          {attestationProof?.note && attestationProof?.note !== 'null' && (
            <div className={styles.section}>
              <Text kind={TextKind.TextSMBold} element={TextElement.P}>
                Note
              </Text>
              <div className={styles.attestation}>
                <Text kind={TextKind.TextMD} element={TextElement.P}>
                  {attestationProof?.note}
                </Text>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

RequirementReviewModal.propTypes = {
  kind: propTypes.oneOf(Object.values(RequirementReviewModalKind)),
  requirementName: propTypes.string,
  policy: propTypes.object,
  attestationDescription: propTypes.string,
  attestationProof: propTypes.object,
  document: propTypes.object,
  show: propTypes.bool,
  loading: propTypes.bool,
  onClick: propTypes.func,
  onCancel: propTypes.func,
  onDone: propTypes.func,
  onReject: propTypes.func,
};

export default RequirementReviewModal;
