import { LoadingOverlay, NoData } from 'design-system/components';

import { useSupplierMaterialContext } from '../context';
import SupplierMaterialSidePanel from '../components/side-panel';
import SupplierMaterialPoliciesContent from '../components/policies-content';
import styles from '../supplier-material.module.scss';

export default function SupplierMaterialPolicies() {
  const { data, loading, error, policies, toastData } =
    useSupplierMaterialContext();

  return loading ? (
    <LoadingOverlay show />
  ) : (
    <div className={`page-layout ${styles.container}`}>
      <SupplierMaterialSidePanel data={data} fromChildView />
      {!data?.id || error ? (
        <NoData
          hasErrorOccurred={error}
          noContentMessage="No material data is available at this time."
        />
      ) : (
        <SupplierMaterialPoliciesContent
          data={data}
          policies={policies}
          toastData={toastData}
        />
      )}
    </div>
  );
}
