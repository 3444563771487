import { createContext, useContext, useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';

export const StripeContext = createContext();
export const StripeConsumer = StripeContext.Consumer;
export function useStripe() {
  const ctx = useContext(StripeContext);
  if (!ctx) {
    throw new Error('useStripe must be used withing a StripeContext');
  }
  return ctx;
}

export function StripeProvider(props) {
  const { children } = props;

  const [context, setContext] = useState({ loading: true });

  useEffect(() => {
    async function load() {
      const stripe = await loadStripe(
        process.env.REACT_APP_STRIPE_PUBLISHABLE_TOKEN
      );
      setContext((ctx) => ({ ...ctx, loading: false, stripe }));
    }
    load();
  }, []);

  return (
    <StripeContext.Provider value={context}>{children}</StripeContext.Provider>
  );
}
