import PropTypes from 'prop-types';
import {
  Divider,
  Icon,
  IconName,
  Text,
  TextElement,
  TextKind,
  Button,
  ButtonKind,
  ButtonIconPosition,
  OpenAxis,
  Tooltip,
} from 'design-system/components';
import { Color } from 'design-system/data';
import styles from './product-stats.module.scss';
import LoadingSkeletons from './loading-skeletons';

function ProductStats({
  lastUpdated,
  created,
  stats,
  statsArrayLength = 6,
  isEditable,
  onEdit,
  isLoading,
  isSupplierView = false,
}) {
  return isLoading ? (
    <LoadingSkeletons statsArrayLength={statsArrayLength} />
  ) : (
    <div className={styles.root}>
      <Text
        kind={TextKind.TextSMBold}
        element={TextElement.P}
        color={Color.Neutral500}
        className={styles.label}
      >
        Last updated
      </Text>
      <Text
        kind={TextKind.TextMDBold}
        element={TextElement.P}
        className={styles.value}
      >
        {lastUpdated}
      </Text>
      <Text
        kind={TextKind.TextSMBold}
        element={TextElement.P}
        color={Color.Neutral500}
        className={styles.label}
      >
        Created
      </Text>
      <Text
        kind={TextKind.TextMDBold}
        element={TextElement.P}
        className={styles.value}
      >
        {created}
      </Text>
      {!isSupplierView && (
        <>
          <Divider />
          {stats.map((stat, index) => (
            <div key={`${stat.label}-${index}`} className={styles.stats}>
              <div className={styles.label}>
                <Text
                  kind={TextKind.TextSMBold}
                  element={TextElement.P}
                  color={Color.Neutral500}
                >
                  {stat.label}
                </Text>
                {stat.tooltipDescription && (
                  <Tooltip
                    openAxis={OpenAxis.X}
                    triggerElement={
                      <Icon
                        name={IconName.Info}
                        size={16}
                        color={Color.Blue600}
                      />
                    }
                  >
                    <Text kind={TextKind.TextSMBold} element={TextElement.Span}>
                      {stat.tooltipDescription}
                    </Text>
                  </Tooltip>
                )}
              </div>
              {stat.value instanceof Array ? (
                <ul>
                  {stat.value.map((value, i) => (
                    <li key={i}>
                      <Text kind={TextKind.TextMDBold} element={TextElement.P}>
                        {value}
                      </Text>
                    </li>
                  ))}
                </ul>
              ) : (
                <Text kind={TextKind.TextMDBold} element={TextElement.P}>
                  {stat.value}
                </Text>
              )}
            </div>
          ))}
        </>
      )}
      {isEditable && (
        <>
          <Divider />
          <Button
            kind={ButtonKind.Pill}
            onClick={onEdit}
            iconName={IconName.Edit}
            iconPosition={ButtonIconPosition.Right}
          >
            Edit
          </Button>
        </>
      )}
    </div>
  );
}

ProductStats.propTypes = {
  lastUpdated: PropTypes.string,
  created: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      tooltipDescription: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }).isRequired
  ),
  statsArrayLength: PropTypes.number,
};

export default ProductStats;
