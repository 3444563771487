import { useState, useMemo } from 'react';
import propTypes from 'prop-types';
import {
  Button,
  ButtonIconPosition,
  ButtonKind,
  IconName,
  Modal,
  ModalType,
} from 'design-system/components';
import { FilterReducerAction } from 'design-system/utils';
import styles from './filter-modal.module.scss';
import { FilterModalTypes } from './utils';
import FilterModalSearch from './filter-modal-search';
import FilterModalDropdown from './filter-modal-dropdown';

const FilterModal = ({
  filters,
  state,
  onModalClose,
  dynamicFetch = false,
  dataCyRoot,
  type,
  onFetch,
  searchResults,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(state);
  const areFiltersActive = state
    ? Object.values(state).find((item) => item.length > 0)
    : false;

  const renderFilterContent = () => {
    if (type === FilterModalTypes.Search) {
      return filters?.map((filter, index) => {
        const filterIsDynamic = dynamicFetch || filter.isDynamic;
        if (filterIsDynamic) {
          return (
            <FilterModalSearch
              key={`${filter.value}-${index}`}
              title={filter.name}
              options={
                filterIsDynamic
                  ? searchResults[filter.value].results
                  : filter.options
              }
              activeIDs={selectedFilters[filter.value] || []}
              onChange={(selectedIDs) => {
                setSelectedFilters({
                  ...selectedFilters,
                  [filter.value]: [...selectedIDs],
                });
              }}
              dataCyRoot={dataCyRoot}
              dynamicFetch={filterIsDynamic}
              onSearchChange={(value, toggleValue) =>
                onFetch(value, filter.value, toggleValue)
              }
              isLoading={filter.isLoading}
              toggleOptions={filter.toggleOptions}
            />
          );
        }
        return (
          <FilterModalDropdown
            key={`${filter.value}-${index}`}
            title={filter.name}
            options={filter.options}
            activeIDs={selectedFilters[filter.value] || []}
            onChange={(selectedIDs) => {
              setSelectedFilters({
                ...selectedFilters,
                [filter.value]: selectedIDs,
              });
            }}
            dataCyRoot={dataCyRoot}
          />
        );
      });
    }
    return filters?.map((filter, index) =>
      filter.isSearchable ? (
        <FilterModalSearch
          key={`${filter.value}-${index}`}
          title={filter.name}
          options={filter.options}
          activeIDs={selectedFilters[filter.value] || []}
          onChange={(selectedIDs) => {
            setSelectedFilters({
              ...selectedFilters,
              [filter.value]: [...selectedIDs],
            });
          }}
          dataCyRoot={dataCyRoot}
          isLoading={filter.isLoading}
        />
      ) : (
        <FilterModalDropdown
          key={`${filter.value}-${index}`}
          title={filter.name}
          options={filter.options}
          activeIDs={selectedFilters[filter.value] || []}
          onChange={(selectedIDs) =>
            setSelectedFilters({
              ...selectedFilters,
              [filter.value]: selectedIDs,
            })
          }
          isSingleSelect={filter.isSingleSelect}
          dataCyRoot={dataCyRoot}
          singularTitle={filter.singularTitle}
        />
      )
    );
  };

  const filterChips = useMemo(() => {
    let filterChips = [];
    Object.entries(selectedFilters).forEach((entry) => {
      entry[1].forEach((item) => {
        filterChips.push({
          type: entry[0],
          id: item.id,
          name: item.name,
        });
      });
    });

    return filterChips;
  }, [selectedFilters]);

  const handleRemoveFilter = (filterType, filterId) => {
    const activeFilters = selectedFilters[filterType].filter(
      (item) => item.id !== filterId
    );
    setSelectedFilters({ ...selectedFilters, [filterType]: activeFilters });
  };

  return (
    <>
      <div className={areFiltersActive ? styles.active : undefined}>
        <Button
          kind={ButtonKind.Tertiary}
          iconName={IconName.Filter}
          onClick={() => {
            setSelectedFilters(state);
            setShowModal(true);
          }}
          data-cy={`${dataCyRoot}-button`}
          className={styles['filter-button']}
        >
          <span className={styles.button}>
            Filters
            <span className={styles.icon} />
          </span>
        </Button>
      </div>
      <Modal
        show={showModal}
        title="Filters"
        type={ModalType.Medium}
        onClick={() => {
          setShowModal(false);
          onModalClose();
        }}
        showActionButtons
        cancelCtaLabel="Clear all"
        onCancel={() => {
          setShowModal(false);
          onModalClose({ type: FilterReducerAction.ClearFilters });
        }}
        doneCtaLabel="Apply filters"
        onDone={() => {
          setShowModal(false);
          onModalClose({
            type: FilterReducerAction.ApplyFilters,
            filters: selectedFilters,
          });
        }}
      >
        <div>
          {!!filterChips.length && (
            <div className={styles['filter-row']}>
              {filterChips?.map((filter, i) => {
                return (
                  <Button
                    key={i}
                    kind={ButtonKind.Pill}
                    iconName={IconName.Close}
                    iconPosition={ButtonIconPosition.Right}
                    className={styles['button-reverse']}
                    onClick={() => handleRemoveFilter(filter.type, filter.id)}
                  >
                    {filter.name}
                  </Button>
                );
              })}
            </div>
          )}
          <div className={styles.container}>{renderFilterContent()}</div>
        </div>
      </Modal>
    </>
  );
};

FilterModal.propTypes = {
  type: propTypes.oneOf(Object.values(FilterModalTypes)).isRequired,
  filters: propTypes.arrayOf(
    propTypes.shape({
      name: propTypes.string,
      value: propTypes.string,
      options: propTypes.arrayOf(
        propTypes.shape({ id: propTypes.string, name: propTypes.string })
      ),
    })
  ).isRequired,
  state: propTypes.object.isRequired,
  onModalClose: propTypes.func,
  dynamicFetch: propTypes.bool,
  dataCyRoot: propTypes.string.isRequired,
};

export default FilterModal;
