import { IdentifierKeys, SortByOptions } from './types';

// Map to specify identifier columns per retailer
export const identifierColumnsToDisplay: {
  [key: string]: { label: string; value: IdentifierKeys }[];
} = {
  Target: [{ label: 'UPC Number', value: 'upcNumber' }],
  // Add more retailers as needed
};

export const defaultIdentifierColumns: {
  label: string;
  value: IdentifierKeys;
}[] = [{ label: 'SKU ID', value: 'skuId' }];

export const getIdentifierColumns = (
  retailerName: string
): { label: string; value: IdentifierKeys }[] => {
  return identifierColumnsToDisplay[retailerName] || defaultIdentifierColumns;
};

export const sortByOptionsToDisplay: {
  [key: string]: { label: string; value: SortByOptions }[];
} = {
  Target: [
    { label: 'Product Name (A-Z)', value: 'product_name__asc' },
    { label: 'Product Name (Z-A)', value: 'product_name__desc' },
    { label: 'UPC Number (Ascending)', value: 'upc_number__asc' },
    { label: 'UPC Number (Descending)', value: 'upc_number__desc' },
  ],
  // Add more retailers as needed
};

export const defaultSortByOptions: { label: string; value: SortByOptions }[] = [
  { label: 'Product Name (A-Z)', value: 'product_name__asc' },
  { label: 'Product Name (Z-A)', value: 'product_name__desc' },
  { label: 'SKU ID (Ascending)', value: 'sku_id__asc' },
  { label: 'SKU ID (Descending)', value: 'sku_id__desc' },
];

export const getSortByOptions = (
  retailerName: string
): { label: string; value: SortByOptions }[] => {
  return sortByOptionsToDisplay[retailerName] || defaultSortByOptions;
};
