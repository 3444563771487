import {
  Breadcrumbs,
  Text,
  TextElement,
  TextKind,
  ToastManager,
} from 'design-system/components';
import { PLACEHOLDER } from 'design-system/data';

import { PATHS } from 'constants/index';
import PoliciesList from './PoliciesList';
import ComingSoonBlurb from './ComingSoonBlurb';
import styles from './policies-content.module.scss';

export default function SupplierMaterialPoliciesContent({
  data,
  policies,
  toastData,
}) {
  return (
    <div className={styles.container}>
      <Breadcrumbs
        items={[
          { text: 'Listings Management', link: PATHS.supplierListings },
          {
            text: data?.name || PLACEHOLDER,
            link: `${PATHS.supplierMaterial}/${data?.id}`,
          },
          { text: 'Policies', link: '' },
        ]}
      />
      <div>
        <Text
          className={styles.name}
          kind={TextKind.DisplaySMBold}
          element={TextElement.H1}
        >
          {data?.name || PLACEHOLDER}
        </Text>
      </div>
      <div>
        <Text kind={TextKind.Display2XSBold} element={TextElement.H2}>
          Retailer Related Policies
        </Text>
      </div>
      <PoliciesList policies={policies} materialID={data?.id} />
      <ComingSoonBlurb />
      <ToastManager data={toastData} />
    </div>
  );
}
