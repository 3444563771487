import { ScreeningStatus } from 'design-system/data';

export const tableHeaderData = [
  {
    label: 'Requirement',
    value: 'name',
  },
  {
    label: 'Subcategory',
    value: 'category',
  },
  {
    label: 'Status',
    value: 'screeningStatus',
  },
];

export const tabStatuses = [
  '', // All
  `${ScreeningStatus.NeedsInformation},${ScreeningStatus.Updating}`,
  ScreeningStatus.Pass,
  `${ScreeningStatus.Fail},${ScreeningStatus.Rejected}`,
  ScreeningStatus.Pending,
  ScreeningStatus.NotApplicable,
];

export const SORT_OPTIONS = {
  'requirement_name_asc': 'Requirement (A to Z)',
  'requirement_name_desc': 'Requirement (Z to A)',
  'subcategory_asc': 'Subcategory (A to Z)',
  'subcategory_desc': 'Subcategory (Z to A)',
};

export const VIEW_ALL_NAME = 'View all';
