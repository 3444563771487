import { useMemo } from 'react';
import useStableObject from 'hooks/useStableObject';
import { makeFetcher } from 'services/api/fetcher';

/* A hook to return a fetch wrapper with up-to-date token
 *
 * Usage:
 * ```
 * import useFetcher from 'hooks/useFetcher';
 * function Component() {
 *   const fetcher = useFetcher();
 *   useEffect(() => {
 *     setData(fetcher(endpoint, options));
 *   }, [fetcher]);
 * }
 * function App() {
 *   return <Auth0><Component /></Auth0>;
 * }
 * ```
 */

export default function useFetcher(fetcherOptions) {
  const options = useStableObject(fetcherOptions);
  return useMemo(() => makeFetcher(options), [options]);
}
