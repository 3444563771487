import { useEffect, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import NotFoundView from 'views/NotFound';
import { useApp } from 'context/AppContext';
import { PATHS } from 'constants/index';
import FallBackComponent from 'components/shared/FallBackComponent';
import { useAnalytics } from 'services/analytics/AnalyticsContext';
import ErrorPage from './shared/ErrorPage';
import getQueryParam from 'utils/getQueryParam';

export default function View({
  path,
  exact = false,
  strict = false,
  sensitive = false,
  title = '',
  secure = true,
  requiresTos = true,
  roles = [],
  featureFlags = [],
  requiredPermissions = [],
  onlyNoviEmail = false,
  deprecationFlag = undefined,
  Layout = ({ children }) => children,
  Component = ({ children }) => children,
  Fallback = FallBackComponent,
  NotFound = NotFoundView,
}) {
  const { setTitle } = useAnalytics();
  useEffect(() => {
    setTitle(title);
  }, [title, setTitle]);

  return (
    <Route
      path={path}
      exact={exact}
      strict={strict}
      sensitive={sensitive}
      render={(routeProps) => {
        return (
          <Suspense fallback={<Fallback />}>
            <AuthorizationCheck
              secure={secure}
              // public pages do not require you to sign the Terms of Service
              requiresTos={secure && requiresTos}
              roles={roles}
              featureFlags={featureFlags}
              requiredPermissions={requiredPermissions}
              deprecationFlag={deprecationFlag}
              onlyNoviEmail={onlyNoviEmail}
              NotFound={NotFound}
            >
              <Layout>
                <Helmet>
                  <title>{title}</title>
                </Helmet>
                <Component {...routeProps} />
              </Layout>
            </AuthorizationCheck>
          </Suspense>
        );
      }}
    />
  );
}

View.propTypes = {
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  exact: PropTypes.bool,
  strict: PropTypes.bool,
  sensitive: PropTypes.bool,
  title: PropTypes.string,
  secure: PropTypes.bool,
  requiresTos: PropTypes.bool,
  roles: PropTypes.array,
  featureFlags: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      flags: PropTypes.array,
      options: PropTypes.shape({
        every: PropTypes.bool,
      }),
    }),
  ]),
  requiredPermissions: PropTypes.array,
  onlyNoviEmail: PropTypes.bool,
  deprecationFlag: PropTypes.string,
  Layout: PropTypes.elementType,
  Component: PropTypes.elementType,
  Fallback: PropTypes.elementType,
  NotFound: PropTypes.elementType,
};

function AuthorizationCheck({
  secure = true,
  requiresTos = true,
  roles = [],
  featureFlags = [],
  requiredPermissions,
  onlyNoviEmail,
  deprecationFlag,
  NotFound = () => null,
  children,
}) {
  const { user, loading, auth0, errorLoadingUser } = useApp();

  // Check if user has the required permissions
  const userHasRequiredPermissions =
    requiredPermissions.length === 0
      ? true
      : requiredPermissions.every(
          (permissionKey) => user?.permissions?.[permissionKey]
        );

  // wait until the user is authenticated and their data has been fetched
  // before making decisions about routing
  if (loading) {
    return null;
  }

  if (auth0.isAuthenticated) {
    if (errorLoadingUser) {
      return <ErrorPage />;
    }

    // authenticated users must accept the Terms of Service before they can
    // access the application
    if (requiresTos && !user.is_tos_current) {
      return <Redirect to={PATHS.tos} />;
    }

    // certain routes will be viewable only by users with a noviconnect.com
    // email domain.
    if (onlyNoviEmail && !user.is_novi_email) {
      return <NotFound />;
    }

    // Views can optionally limit access to certain roles, certain permissions, or place themselves
    // behind feature flags. Authenticated users must then have the role (if any) AND
    // feature flag (if any) that provides permission to access the view.
    const userHasRole = roles.length === 0 ? true : user.hasRole(roles);
    const userHasFeature = Array.isArray(featureFlags)
      ? featureFlags.length === 0
        ? true
        : user.hasFF(featureFlags)
      : user.hasFF(featureFlags.flags, featureFlags.options);

    // In the case of a deprecation flag, having the flag leads to different behavior from other feature flags (inverted): handling is different
    const featureDeprecatedForUser = deprecationFlag
      ? user.hasFF(deprecationFlag)
      : false;

    if (
      !userHasRole ||
      !userHasFeature ||
      !userHasRequiredPermissions ||
      featureDeprecatedForUser
    ) {
      return <NotFound />;
    }
  }
  // NOT authenticated
  else {
    // secure views require authentication
    if (secure) {
      const shouldRouteToLogin = getQueryParam('force_unauthenticated_login');
      if (shouldRouteToLogin) {
        auth0.redirectToLogin();
      } else {
        const redirect = encodeURIComponent(
          window.location.pathname + window.location.search
        );
        return <Redirect to={`${PATHS.signUp}?redirect=${redirect}`} />;
      }
    }
  }

  // authenticated users that pass role + feature flag checks go through, and
  // non-secure + non-authenticated users go through
  return children;
}

AuthorizationCheck.propTypes = {
  secure: PropTypes.bool,
  requiresTos: PropTypes.bool,
  roles: PropTypes.array,
  featureFlags: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      flags: PropTypes.array,
      options: PropTypes.shape({
        every: PropTypes.bool,
      }),
    }),
  ]),
  children: PropTypes.node,
  NotFound: PropTypes.elementType,
};
