import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CloudinaryImage } from '@cloudinary/url-gen';
import {
  AdvancedImage,
  lazyload,
  responsive,
  // accessibility,
  placeholder,
} from '@cloudinary/react';
import styles from './cloudinary-img.module.scss';

const CloudinaryImg = ({ src, fallbackElement, ...props }) => {
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [hasErrorOccurred, setHasErrorOccurred] = useState(false);

  function fetchCloudinaryImage(imagePath) {
    return new CloudinaryImage(imagePath, {
      cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
    });
  }

  const [cloudinaryImage, setCloudinaryImage] = useState(
    fetchCloudinaryImage(src)
  );

  // Re-fetch Cloudinary image if src value changes
  useEffect(() => {
    setHasErrorOccurred(false);
    setCloudinaryImage(fetchCloudinaryImage(src));
  }, [src]);

  useEffect(() => {
    if (cloudinaryImage) {
      setTimeout(() => setShowSkeleton(false), 100);
    }
  }, [cloudinaryImage]);

  return hasErrorOccurred ? (
    <>{fallbackElement}</>
  ) : (
    <>
      {showSkeleton && (
        <div
          className={styles.skeleton}
          style={{
            height: props.height,
            width: props.width,
          }}
        />
      )}
      <AdvancedImage
        {...props}
        style={{ visibility: showSkeleton ? 'hidden' : 'initial' }}
        cldImg={cloudinaryImage}
        plugins={[
          lazyload(),
          responsive(),
          // Transforms images to black and white for accessibility
          // DES-1: ensure prod images are svg or png for this to work properly.
          // accessibility(),
          placeholder(),
        ]}
        onError={() => !!fallbackElement && setHasErrorOccurred(true)}
      />
    </>
  );
};

CloudinaryImg.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  fallbackElement: PropTypes.node,
};

export default CloudinaryImg;
