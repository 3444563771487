export const SortDirection = {
  Ascending: 'asc',
  Descending: 'desc',
};

export const ClassName = {
  Sortable: 'sortable',
  SortableActive: 'sortable-active',
  IconDesc: 'icon-desc',
};
