import propTypes from 'prop-types';
import {
  Text,
  TextKind,
  TextElement,
  OpenAxis,
  PolicyIcon,
  Tooltip,
  Icon,
  IconName,
} from 'design-system/components';
import styles from './policy-status-pill.module.scss';
import { Color, ScreeningStatus } from 'design-system/data';
import { ScreeningStatusLabel } from '../../../data/screening-status';

const statusMapping = {
  [ScreeningStatus.Pass]: {
    icon: IconName.Check,
    color: Color.Green700,
  },
  [ScreeningStatus.Fail]: {
    icon: IconName.Close,
    color: Color.Red700,
  },
  [ScreeningStatus.NeedsInformation]: {
    icon: IconName.ExclamationMark,
    color: Color.Orange600,
  },
  [ScreeningStatus.Unresolved]: {
    icon: IconName.Alert,
    color: Color.Orange500,
  },
  default: {
    icon: IconName.Hyphen,
    color: Color.Black,
  },
};

const PolicyStatusPill = ({
  src,
  name,
  status,
  isEnrolled,
  restriction = '',
}) => {
  const { icon, color } = statusMapping[status] || statusMapping.default;

  return (
    <Tooltip
      openAxis={OpenAxis.Y}
      triggerElement={
        <div className={styles[isEnrolled ? 'container' : 'not-applicable']}>
          <PolicyIcon src={src} name={name} />
          <span className={styles.divider} />
          <span
            className={styles.icon}
            style={
              status === ScreeningStatus.Pass ? { marginRight: '4px' } : {}
            }
          >
            <Icon
              name={icon}
              color={isEnrolled && color}
              size={status === ScreeningStatus.Pass ? 16 : 20}
            />
          </span>
        </div>
      }
    >
      <div className={styles.tooltip}>
        <Text kind={TextKind.TextSM} element={TextElement.Span}>
          {status === ScreeningStatus.Fail ? restriction : name}
        </Text>
        <Text kind={TextKind.TextSMBold} element={TextElement.Span}>
          {ScreeningStatusLabel[status] || '-'}
        </Text>
      </div>
    </Tooltip>
  );
};

PolicyStatusPill.propTypes = {
  src: propTypes.string,
  name: propTypes.string.isRequired,
  status: propTypes.oneOf([...Object.values(ScreeningStatus), null]),
  isEnrolled: propTypes.bool,
  restriction: propTypes.string,
};

export default PolicyStatusPill;
