import {
  Button,
  ButtonKind,
  Icon,
  IconName,
  Text,
  TextKind,
  TextElement,
  PhosphorIcon,
  Tooltip,
  OpenAxis,
} from 'design-system/components';
import styles from './sku-empty-state.module.scss';
import { Color } from 'design-system/data';
import { useYourProductContext } from '../context';
import Ellipsis from 'components/Ellipsis';
import { useUploadSkuContext } from '../context/UploadSkuContext';
import { SkuUploadSteps } from './SkuUploadSteps';
import { useApp } from 'context/AppContext';
import useRetailer from 'layouts/ConnectedSidenav/useRetailer';

export const SkuEmptyState = () => {
  const { openAddProductDialog } = useYourProductContext();
  const { currentProcessingFiles } = useUploadSkuContext();
  const { selectedRetailerId } = useApp();
  const { retailer } = useRetailer(selectedRetailerId);
  const allowUpload = retailer && retailer.allows_publishing_brand_products;

  return (
    <div>
      <div className={styles['empty-section']}>
        <div style={{ width: '114px' }}>
          <Icon name={IconName.NoContent} />
        </div>
        <Text kind={TextKind.Display2XSMedium} element={TextElement.H2}>
          Nothing to see here yet!
        </Text>
        {allowUpload && (
          <Text
            kind={TextKind.TextSM}
            element={TextElement.P}
            color={Color.Neutral600}
          >
            Upload or import your SKUs to begin the attestation process.
          </Text>
        )}
      </div>
      {allowUpload && (
        <div>
          <Text kind={TextKind.Display2XSMedium} element={TextElement.H2}>
            Upload SKUs
          </Text>
          <div className={styles['upload-container']}>
            {currentProcessingFiles.length > 0 && (
              <Text
                kind={TextKind.TextSMMedium}
                element={TextElement.Span}
                color={Color.Purple700}
              >
                You will receive an email when processing is finished.
              </Text>
            )}
            {currentProcessingFiles.length > 0 &&
              currentProcessingFiles.map((fileName, idx) => {
                return (
                  <Ellipsis key={idx}>
                    <Text
                      kind={TextKind.TextSMSemibold}
                      element={TextElement.Span}
                      color={Color.Purple700}
                    >
                      Processing:{' '}
                    </Text>
                    <Text
                      kind={TextKind.TextSM}
                      element={TextElement.Span}
                      color={Color.Neutral600}
                    >
                      {fileName}
                    </Text>
                  </Ellipsis>
                );
              })}
            <SkuUploadSteps />
            <Text
              kind={TextKind.TextSM}
              element={TextElement.P}
              color={Color.Neutral600}
            >
              or
            </Text>
            <Button kind={ButtonKind.Alternate} onClick={openAddProductDialog}>
              <Text
                className={styles['button-text']}
                kind={TextKind.TextSMSemibold}
                element={TextElement.Span}
              >
                <PhosphorIcon iconName="Plus" />
                Manually upload single SKUs
              </Text>
            </Button>
          </div>
          <Text kind={TextKind.Display2XSMedium} element={TextElement.H2}>
            Import from other retailers
          </Text>
          <div className={styles['retail-upload-wrapper']}>
            <Tooltip
              openAxis={OpenAxis.Y}
              triggerElement={
                <div className={styles['retail-upload-box']}>
                  <div className={styles['icon-box']}>
                    <PhosphorIcon
                      color={Color.Neutral500.value}
                      iconName="DownloadSimple"
                      size={20}
                    />
                  </div>
                  <Text
                    className={styles['center-text']}
                    kind={TextKind.TextSM}
                    element={TextElement.P}
                    color={Color.Neutral600}
                  >
                    <Text
                      kind={TextKind.TextSMSemibold}
                      element={TextElement.Span}
                      color={Color.Purple700}
                    >
                      Click to import
                    </Text>{' '}
                    SKUs from another retailer{' '}
                    <Text kind={TextKind.TextXS} element={TextElement.Span}>
                      (max 1000 rows)
                    </Text>
                  </Text>
                </div>
              }
            >
              <Text kind={TextKind.Display2XSMedium} element={TextElement.Span}>
                Coming Soon!
              </Text>
            </Tooltip>
          </div>
        </div>
      )}
    </div>
  );
};
