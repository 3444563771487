import PropTypes from 'prop-types';
import cn from 'classnames';

import { PhosphorIcon, PhosphorIconWeight } from 'design-system/components';
import { SelectSize } from './constants';
import styles from './select-native.module.scss';

const SelectNative = ({
  options,
  id,
  name,
  defaultValue,
  onChange,
  label,
  placeholder,
  emptyPlaceholder,
  required,
  size = SelectSize.Medium,
}) => {
  return (
    <label className={cn([styles.label, styles[size]])}>
      <div>
        {label}
        {required && <span className={styles.required}> *</span>}
      </div>
      <select
        className={styles.select}
        id={id}
        name={name}
        onChange={onChange}
        defaultValue={defaultValue}
      >
        {(placeholder || emptyPlaceholder) && (
          <option className={styles.disabled} disabled value="" selected>
            {placeholder}
          </option>
        )}
        {options?.map(({ text, value, disabled }, index) => {
          return (
            <option key={index} value={value} disabled={disabled}>
              {text}
            </option>
          );
        })}
      </select>
      <div className={styles.icon}>
        <PhosphorIcon
          iconName="CaretDown"
          weight={PhosphorIconWeight.Bold}
          size={16}
        />
      </div>
    </label>
  );
};

SelectNative.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      disabled: PropTypes.bool,
    })
  ),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  required: PropTypes.bool,
  emptyPlaceholder: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(SelectSize)),
};

export default SelectNative;
