import propTypes from 'prop-types';
import styles from './loading-overlay.module.scss';
import {
  Text,
  TextElement,
  TextKind,
  LoadingSpinner,
} from 'design-system/components';
import { Color } from 'design-system/data';

function LoadingOverlay(props) {
  return (
    <div className={styles[props.show ? 'show' : 'hide']}>
      <div className={styles.container} aria-live="polite" aria-busy="true">
        <LoadingSpinner />
        <Text
          kind={TextKind.Display2XSBold}
          element={TextElement.H2}
          color={Color.White}
        >
          Loading
        </Text>
      </div>
    </div>
  );
}

LoadingOverlay.propTypes = {
  show: propTypes.bool.isRequired,
};

export default LoadingOverlay;
