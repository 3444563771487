import propTypes from 'prop-types';
import { Icon, IconName } from 'design-system/components';
import styles from './api-error.module.scss';

function ApiError(props) {
  return (
    <div className={styles.container}>
      <div className={styles['container-inner']}>
        <Icon name={IconName.ApiError} size={48} />
        {props.children}
      </div>
    </div>
  );
}

ApiError.propTypes = {
  children: propTypes.node,
};

export default ApiError;
