import { useState, useEffect } from 'react';

export default function useDelayedChange(value, options = {}) {
  const { delay = 0, initialValue = value } = options;
  const [state, setState] = useState(initialValue);
  useEffect(() => {
    const tid = setTimeout(() => {
      setState(value);
    }, delay);
    return () => {
      clearTimeout(tid);
    };
  }, [value, delay]);
  return state;
}
