import styles from '../icon.module.scss';

function Arrow(props) {
  return (
    <svg
      width={props.size}
      height={props.size * 2}
      viewBox="0 0 8 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles[`root-${props.direction}`]}
    >
      <path
        d="M1 7.5L4 4.5L7 7.5"
        stroke={props.color.value}
        strokeWidth="1.5"
      />
      <path d="M4 4.5V12.5" stroke={props.color.value} strokeWidth="1.5" />
    </svg>
  );
}

export default Arrow;
