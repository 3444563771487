import { useState, ChangeEvent, useImperativeHandle, forwardRef } from 'react';
import {
  InputV2,
  InputKind,
  PillTextArea,
  Radio,
  RadioGroup,
  SelectV2,
  Text,
  TextKind,
  TextElement,
  SelectV2Item,
  Button,
  ButtonKind,
  MultiSelect,
} from 'design-system/components';
import { Color } from 'design-system/data';
import { FormConfig, FormData, ErrorData, InputField } from './types';
import styles from './product-form.module.scss';
import { SelectOption } from 'design-system/components/molecules/multi-select';

interface ProductFormProps {
  formConfig: FormConfig;
  formData?: FormData;
  onSubmit: (formState: FormData) => void;
  onCancel?: () => void;
  loading?: boolean;
}

const ProductForm = forwardRef(
  (
    {
      formConfig,
      formData,
      onSubmit,
      onCancel,
      loading = false,
    }: ProductFormProps,
    ref
  ) => {
    const [formState, setFormState] = useState<FormData>(formData || {});
    const [errorState, setErrorState] = useState<ErrorData>({});

    const staticFieldsErrors = (): ErrorData => {
      return formConfig.staticFields.reduce(
        (errors: ErrorData, field: InputField) => {
          const onValidate =
            typeof field.onValidate === 'function'
              ? field.onValidate(formState[field.name])
              : null;
          if (typeof onValidate === 'string') errors[field.name] = onValidate;
          return errors;
        },
        {}
      );
    };

    // right now tightly coupled to 'type' field
    // conditional should be able to change
    const conditionalFieldsErrors = (): ErrorData => {
      if (formState.type === undefined) return {};
      return formConfig.conditionalFields[formState.type].reduce(
        (errors: ErrorData, field: InputField) => {
          const onValidate =
            typeof field.onValidate === 'function'
              ? field.onValidate(formState[field.name])
              : null;
          if (typeof onValidate === 'string') errors[field.name] = onValidate;
          return errors;
        },
        {}
      );
    };

    const handleChange = (event: {
      target: { name: string; value: string };
    }) => {
      const { name, value } = event.target;
      setFormState({
        ...formState,
        [name]: value,
      });
    };

    const handleRadioChange = (name: string, value: string) => {
      setFormState({
        ...formState,
        [name]: value,
      });
    };

    const isValid = (): boolean => {
      const errors = { ...staticFieldsErrors(), ...conditionalFieldsErrors() };
      setErrorState(errors);
      return Object.keys(errors).length === 0;
    };

    const handleDropdownChange = (event: {
      target: { name: any; value: any };
    }) => {
      const { name, value } = event.target;
      setFormState({
        ...formState,
        [name]: value,
      });
    };

    const handleSubmit = (event: { preventDefault: () => void }) => {
      event.preventDefault();
      if (isValid()) onSubmit(formState);
    };

    useImperativeHandle(ref, () => ({
      triggerSubmit() {
        handleSubmit({ preventDefault: () => {} });
      },
      triggerCancel() {
        handleCancel({ preventDefault: () => {} });
      },
    }));

    const handleCancel = (e: { preventDefault: () => void }) => {
      e?.preventDefault();
      setFormState(formData || {});
      setErrorState({});
      if (typeof onCancel === 'function') onCancel();
    };

    const renderInputField = (field: InputField) => {
      switch (field.type) {
        case 'text':
          return (
            <div className={styles['field']} key={field.name}>
              <InputV2
                label={field.label || ''}
                name={field.name}
                value={formState[field?.name] || ''}
                required={field.required}
                handleInputChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleChange(event);
                  if (field.onChange) field.onChange(event);
                }}
                hasError={!!errorState[field.name]}
                errorMessage={errorState[field.name]}
              />
            </div>
          );
        case 'number':
          return (
            <div className={styles['field']} key={field.name}>
              <InputV2
                label={field.label}
                name={field.name}
                type={InputKind.Number}
                required={field.required}
                value={formState[field?.name] || ''}
                handleInputChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleChange(event);
                  if (field.onChange) field.onChange(event);
                }}
                hasError={!!errorState[field.name]}
                errorMessage={errorState[field.name]}
              />
            </div>
          );
        case 'select':
          return (
            <div className={styles['field']} key={field.name}>
              <label>
                <Text
                  kind={TextKind.TextSMMedium}
                  element={TextElement.P}
                  color={Color.Neutral700}
                >
                  {field.label}
                  {field.required && (
                    <Text
                      kind={TextKind.TextSMMedium}
                      element={TextElement.Span}
                    >
                      *
                    </Text>
                  )}
                </Text>
              </label>

              <div className={styles['select']}>
                <SelectV2
                  placeholder={field.placeholder}
                  name={field.name}
                  value={formState[field?.name] || ''}
                  onValueChange={(value: any) => {
                    const event = {
                      target: { name: field.name, value: value },
                    };
                    handleDropdownChange(event);
                    if (field.onChange) field.onChange(event);
                  }}
                >
                  {field.options?.map((option) => (
                    <SelectV2Item
                      key={option?.value?.toString() || option?.id}
                      value={
                        option?.value?.toString() ||
                        option?.id?.toString() ||
                        ''
                      }
                    >
                      <Text kind={TextKind.TextMD} element={TextElement.Span}>
                        {option.label || option.name}
                      </Text>
                    </SelectV2Item>
                  ))}
                </SelectV2>
                {errorState[field.name] && (
                  <Text
                    color={Color.Red600}
                    element={TextElement.Label}
                    kind={TextKind.TextSM}
                  >
                    {errorState[field.name]}
                  </Text>
                )}
              </div>
            </div>
          );
        case 'text-area':
          return (
            <div className={styles['field']} key={field.name}>
              <label>
                <Text
                  kind={TextKind.TextSMMedium}
                  element={TextElement.P}
                  color={Color.Neutral700}
                >
                  {field.label}
                  {field.required && (
                    <Text
                      kind={TextKind.TextSMMedium}
                      element={TextElement.Span}
                    >
                      *
                    </Text>
                  )}
                </Text>
              </label>
              <PillTextArea
                onChange={(newValue: string) =>
                  handleChange({
                    target: { name: field.name, value: newValue },
                  })
                }
                required={field.required}
                value={formState[field.name]}
                label={field.label || ''}
                id={field.name}
              />

              {errorState[field.name] && (
                <Text
                  color={Color.Red600}
                  element={TextElement.Label}
                  kind={TextKind.TextSM}
                >
                  {errorState[field.name]}
                </Text>
              )}
            </div>
          );
        case 'multi-select':
          return (
            <div className={styles['field']} key={field.name}>
              <label>
                <Text
                  kind={TextKind.TextSMMedium}
                  element={TextElement.P}
                  color={Color.Neutral700}
                >
                  {field.label}
                  {field.required && (
                    <Text
                      kind={TextKind.TextSMMedium}
                      element={TextElement.Span}
                    >
                      *
                    </Text>
                  )}
                </Text>
              </label>

              <MultiSelect
                onSelection={(selected) => {
                  handleChange({
                    target: {
                      name: field.name,
                      value: (selected as any).map(
                        (option: SelectOption) => option.id as number
                      ), // dirty cast.
                    },
                  });
                }}
                options={field.options as SelectOption[]}
                placeholder={field.placeholder}
                hideLabel
              />
              {errorState[field.name] && (
                <Text
                  color={Color.Red600}
                  element={TextElement.Label}
                  kind={TextKind.TextSM}
                >
                  {errorState[field.name]}
                </Text>
              )}
            </div>
          );
        case 'radio':
          return (
            <div className={styles['field']} key={field.name}>
              <label>
                <Text
                  kind={TextKind.TextSMMedium}
                  element={TextElement.P}
                  color={Color.Neutral700}
                >
                  {field.label}
                  {field.required && (
                    <Text
                      kind={TextKind.TextSMMedium}
                      element={TextElement.Span}
                    >
                      *
                    </Text>
                  )}
                </Text>
              </label>
              <RadioGroup
                name={field.name}
                value={formState[field?.name] || ''}
                onChange={(value) => handleRadioChange(field.name, value)}
                hasError={!!errorState[field.name]}
                errorMessage={errorState[field.name]}
                required={field.required}
              >
                {(field.options || [])?.map((option) => (
                  <Radio
                    key={option?.value?.toString()}
                    value={option?.value}
                    label={option.label}
                    disabled={option.disabled}
                  />
                ))}
              </RadioGroup>
              {errorState[field.name] && (
                <Text
                  color={Color.Red600}
                  element={TextElement.Label}
                  kind={TextKind.TextSM}
                >
                  {errorState[field.name]}
                </Text>
              )}
            </div>
          );
        default:
          return null;
      }
    };

    const renderConditionalFields = () => {
      const selectedValue = formState.type;

      const conditionalFields =
        formConfig.conditionalFields[selectedValue] || [];

      return conditionalFields.map((field: InputField) =>
        renderInputField(field)
      );
    };

    return (
      <form onSubmit={handleSubmit} className={styles['product-form']}>
        {formConfig.staticFields.map((field: InputField) =>
          renderInputField(field)
        )}
        {renderConditionalFields()}
        <div className={styles['buttons']}>
          {onCancel && (
            <Button
              kind={ButtonKind.Tertiary}
              className={styles['cancel-button']}
              onClick={handleCancel}
              disabled={loading}
            >
              Cancel
            </Button>
          )}
          <Button kind={ButtonKind.Primary} disabled={loading} type="submit">
            {loading ? 'Submitting...' : 'Save'}
          </Button>
        </div>
      </form>
    );
  }
);

export default ProductForm;
