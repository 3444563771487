import cn from 'classnames';
import styles from './table-foot.module.scss';
import PropTypes from 'prop-types';

function TableFoot({ children, className = undefined, bgWhite = false }) {
  return (
    <tfoot
      className={cn([
        styles.root,
        className,
        bgWhite ? styles['bg-white'] : '',
      ])}
    >
      {children}
    </tfoot>
  );
}

TableFoot.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  bgWhite: PropTypes.bool,
};

export default TableFoot;
