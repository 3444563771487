export const paginationOptions = [
  {
    value: 5,
    display: '5 / page',
  },
  {
    value: 10,
    display: '10 / page',
  },
  {
    value: 25,
    display: '25 / page',
  },
  {
    value: 50,
    display: '50 / page',
  },
];

export const PLACEHOLDER = '...';
