import {
  createContext,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react';
import styled from 'styled-components/macro';
import {
  Button,
  ButtonKind,
  Select,
  SelectKind,
} from 'design-system/components';
import Dialog from 'components/Dialog';
import Text from 'components/Text';
import Input from 'components/Input';
import Ellipsis from 'components/Ellipsis';
import Spacer from 'components/Spacer';
import useFetcher from 'services/api/useFetcher';
import { useSnackbar } from 'notistack';
import { useAnalyticsEvent } from '../services/analytics/AnalyticsContext';

export const UserInvitationContext = createContext();
export const UserInvitationConsumer = UserInvitationContext.Consumer;
export const useUserInvitation = () => useContext(UserInvitationContext);

const DEPARTMENT_OPTONS = [
  { display: 'Regulatory & Compliance', value: 'regulatory' },
  { display: 'Sales', value: 'sales' },
  { display: 'Product Development', value: 'product_development' },
  { display: 'C-suite', value: 'c_suite' },
  { display: 'Operations', value: 'operations' },
  { display: 'Packaging', value: 'packaging' },
  { display: 'Marketing', value: 'marketing' },
  { display: 'R&D', value: 'r_and_d' },
  { display: 'Other', value: 'other' },
];
const JOB_FUNCTION_OPTIONS = [
  { display: 'CM-R&D', value: 'cm_rd' },
  { display: 'CM-Purchasing', value: 'cm_purchasing' },
  { display: 'Formulator', value: 'formulator' },
];

export function UserInvitationProvider(props) {
  const { children } = props;

  const [open, setOpen] = useState(false);

  const context = useMemo(() => {
    return {
      openModal: () => setOpen(true),
      closeModal: () => setOpen(false),
    };
  }, []);

  return (
    <UserInvitationContext.Provider value={context}>
      {children}
      <UserInvitationModal open={open} onClose={context.closeModal} />
    </UserInvitationContext.Provider>
  );
}

const UserInvitationModal = styled(function UserInvitationModal(props) {
  const { children, ...rest } = props;
  const [loading, setLoading] = useState(false);
  const fetcher = useFetcher();
  const { enqueueSnackbar } = useSnackbar();
  const [department, setDepartment] = useState('');
  const [jobFunction, setJobFunction] = useState('');
  const { closeModal } = useUserInvitation();
  const { analyticsEvent } = useAnalyticsEvent();

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const f = new FormData(e.target);
      try {
        setLoading(true);
        const { id: userInvitationId } = await fetcher(
          `/api/v4/user_invitations`,
          {
            method: 'post',
            body: {
              email: f.get('email'),
              first_name: f.get('first_name'),
              last_name: f.get('last_name'),
              department: f.get('department'),
              job_function: f.get('job_function'),
            },
          }
        );
        analyticsEvent({
          label: 'user_invitation_modal',
          category: 'submit_invite',
          action: 'submit_user_invitation',
          entityId: userInvitationId,
        });
        enqueueSnackbar('Success!', { variant: 'success' });
        closeModal();
      } catch (e) {
        enqueueSnackbar(e.message, { variant: 'error' });
      } finally {
        setLoading(false);
      }
    },
    [fetcher, enqueueSnackbar, closeModal, analyticsEvent]
  );

  return (
    <Dialog {...rest}>
      <Dialog.Content as="form" onSubmit={handleSubmit}>
        <h1>Invite Your Team to Novi</h1>
        <Spacer size={10} />
        <p>
          Add team members from your organization for easy collaboration on
          product development
        </p>
        <Spacer size={12} />
        {/* email, first name, last name, department, job_function */}
        <Text as="label" htmlFor="email">
          Email
        </Text>
        <Input type="email" name="email" />
        <Text as="label" htmlFor="first_name">
          First Name
        </Text>
        <Input type="text" name="first_name" />
        <Text as="label" htmlFor="last_name">
          Last Name
        </Text>
        <Input type="text" name="last_name" />
        <Text as="label" htmlFor="department">
          Department
        </Text>
        <Select
          compactVersion
          kind={SelectKind.Text}
          onChange={setDepartment}
          value={department}
          options={DEPARTMENT_OPTONS}
        />
        <Text as="label" htmlFor="department">
          Job Function
        </Text>
        <Select
          compactVersion
          kind={SelectKind.Text}
          onChange={setJobFunction}
          value={jobFunction}
          options={JOB_FUNCTION_OPTIONS}
        />
        <Button
          className="submit"
          kind={ButtonKind.Primary}
          type="submit"
          onClick={() => {}}
        >
          {loading ? <Ellipsis>Inviting User</Ellipsis> : 'Invite User'}
        </Button>
      </Dialog.Content>
      {children}
    </Dialog>
  );
})`
  font-family: var(--font-sans-serif);

  .MuiPaper-root {
    min-width: 300px;
  }

  h1 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
  }

  label {
    margin-top: 4px;
  }

  .submit {
    display: block;
    margin-top: 24px;
    margin-left: auto;
  }

  ${Dialog.Content} {
    padding: 16px 24px;
    display: flex;
    flex-flow: column nowrap;
  }
`;
