import _ from 'lodash';

export const convertToCamelCase = (string) => {
  return _.camelCase(string);
};

export const convertToSnakeCase = (string) => {
  return _.snakeCase(string);
};

export const convertToTitleCase = (string) => {
  return _.startCase(string);
};

export const convertToPascalCase = (string) => {
  return _.startCase(string).replace(/\s/g, '');
};

export const convertToKebabCase = (string) => {
  return _.kebabCase(string);
};

const convertCase = (transformFn) => (data) => {
  if (_.isArray(data)) {
    return data.map((item) => convertCase(transformFn)(item));
  }

  if (_.isObject(data)) {
    return _.mapKeys(
      _.mapValues(data, (value, key) => {
        return convertCase(transformFn)(value);
      }),
      (value, key) => transformFn(key)
    );
  }

  return data;
};

export const convertCamelToSnakeCase = convertCase(convertToSnakeCase);
export const convertSnakeToCamelCase = convertCase(convertToCamelCase);
