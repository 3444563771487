import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  CloudinaryImg,
  Text,
  TextKind,
  TextElement,
  PhosphorIcon,
  ToastManager,
} from 'design-system/components';
import { convertSnakeToCamelCase } from 'design-system/utils';

import Disclaimer from 'views/Brands/shared/Disclaimer';
import { useApp } from 'context/AppContext';
import { PATHS } from 'constants/index';
import Header from './components/Header';
import Table from './components/Table';
import styles from './sku-level-status.module.scss';
import { useSKUEligibilityContext } from './context/SKUEligibilityContext';
import ReviewModal from 'views/Retailer/Submissions/components/ReviewModal';
import { ReviewType } from 'views/Retailer/Submissions/types';

interface SKULevelStatusPageProps {
  retailerBrand?: any;
  isRetailer?: boolean;
}

const SKULevelStatusPageView = ({
  retailerBrand,
  isRetailer = false,
}: SKULevelStatusPageProps) => {
  const { brand_id, policy_id } = useParams();
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [reviewType, setReviewType] = useState<undefined | ReviewType>();
  const [toastData, setToastData] = useState([]);

  const { useApi, user } = useApp();
  const response = useSKUEligibilityContext();
  const retailerId = isRetailer ? user?.organization?.in_orgable_id : null;
  const policyRes = useApi(
    `/api/v4/retailers/${retailerId}/consumer_brands/${brand_id}/policies`,
    {
      enabled: Boolean(isRetailer && retailerId && brand_id),
    }
  );

  const currentPolicy = useMemo(() => {
    const policies = convertSnakeToCamelCase(policyRes?.data?.policies);
    return policies?.find(
      (policy: any) => parseInt(policy.id) === parseInt(policy_id)
    );
  }, [policyRes?.data?.policies, policy_id]);

  const caption = () => {
    return isRetailer ? (
      <div className={styles.caption}>
        {currentPolicy?.imagePath ? (
          <>
            <CloudinaryImg
              fallbackElement={
                <PhosphorIcon size={40} iconName="ShieldCheck" />
              }
              src={currentPolicy.imagePath}
              alt="Policy Image"
            />{' '}
          </>
        ) : null}
        <Text kind={TextKind.DisplayXSMedium} element={TextElement.Span}>
          {response?.data?.retailerPolicyName
            ? response?.data?.retailerPolicyName
            : 'Policy'}{' '}
          Status
        </Text>
      </div>
    ) : null;
  };

  const breadcrumbItems = isRetailer
    ? [
        {
          text: 'Submissions',
          link: PATHS.retailerBrandsSubmissions,
        },
        {
          text: response?.data?.consumerBrandName,
          link: '',
        },
      ]
    : [
        {
          text: 'Eligibility',
          link: PATHS.retailerBrandEligibility,
        },
        {
          text: response?.data?.retailerPolicyName,
          link: '',
        },
      ];

  return (
    <main>
      <ReviewModal
        reviewType={reviewType}
        setToastData={setToastData}
        submissionId={currentPolicy?.policySubmission?.id}
        openReviewModal={openReviewModal}
        setOpenReviewModal={setOpenReviewModal}
      />
      <ToastManager data={toastData} />
      <Header
        policyName={response?.data?.retailerPolicyName}
        breadcrumbItems={breadcrumbItems}
        brandName={response?.data?.consumerBrandName}
        isRetailer={isRetailer}
        caption={caption}
        policy={currentPolicy}
        setReviewType={setReviewType}
        handleReviewAction={() => {
          setOpenReviewModal(true);
        }}
      />
      <Table
        data={response}
        uniquePillarResultNames={
          isRetailer === false
            ? response?.data?.pillars?.map((pillar) => pillar.name)
            : undefined
        }
        isRetailer={isRetailer}
      />
      {retailerBrand?.retailer?.disclaimer && (
        <Disclaimer text={retailerBrand?.retailer?.disclaimer || ''} />
      )}
    </main>
  );
};

export default SKULevelStatusPageView;
