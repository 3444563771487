export default function List(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 4H11"
        stroke={props.color.value}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M3 8H13"
        stroke={props.color.value}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M3 12H11"
        stroke={props.color.value}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
