import cn from 'classnames';
import { oneOf } from 'prop-types';

import largeNoviIcon from 'assets/icons/textMark.svg';

import NoviLogoText from './NoviLogoText';
import { LogoSize } from './constants';
import styles from './logo.module.scss';

function NoviLogo({ variant = 'default', size = LogoSize.Large }) {
  if (variant === 'text') {
    return <NoviLogoText size={size} />;
  }

  return (
    <img
      src={largeNoviIcon}
      alt="novi connect"
      className={cn(styles.root, styles[size])}
    />
  );
}

NoviLogo.propTypes = {
  size: oneOf(Object.values(LogoSize)),
  variant: oneOf(['default', 'text']),
};

export default NoviLogo;
