import { useRef } from 'react';
import { determineSelectedOptions } from './utils';
import InputPillListItem from './InputPillListItem';
import styles from './multi-select.module.scss';
import { SelectOption } from './index';

interface SelectedOptionsProps {
  selectedOptions: SelectOption[];
  onSelection: (selectedOptions: SelectOption[]) => void;
}

const SelectedOptions = ({
  selectedOptions,
  onSelection,
}: SelectedOptionsProps) => {
  const pillRefs = useRef<(HTMLLIElement | null)[]>([]);
  return (
    <div className={styles.selected}>
      {selectedOptions?.map((option, i) => (
        <InputPillListItem
          key={i}
          pill={option?.name}
          index={i}
          onRemove={() => {
            onSelection(determineSelectedOptions(option, selectedOptions));
          }}
          handlePillKeyDown={(e) => {
            if (e.key === 'Backspace') {
              onSelection(determineSelectedOptions(option, selectedOptions));
            }
          }}
          pillRefs={pillRefs}
        />
      ))}
    </div>
  );
};

export default SelectedOptions;
