import {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { useApp } from 'context/AppContext';
import {
  convertSnakeToCamelCase,
  convertCamelToSnakeCase,
} from 'design-system/utils/case';
import { useHistory, useParams } from 'react-router-dom';
import { AddManufacturerDialog } from '../components/AddManufacturerDialog';
import fetcher from 'services/api/fetcher';
import { handleStateChange } from 'views/FDARegistration/shared/utils';
import { useSnackbar } from 'notistack';

const FDAProductContext = createContext(null);
const manufacturerFormDefaultState = {
  country: 'US',
};

export const preprocessIngredients = (ingredients) => {
  return ingredients && ingredients[ingredients.length - 1] !== ','
    ? `${ingredients},`
    : ingredients;
};

export const useFDAProductContext = () => {
  const ctx = useContext(FDAProductContext);
  if (!ctx) {
    throw new Error('Must be used within an FDAProductContext');
  }
  return ctx;
};

/**
 * Checks the form on which a React useRef is attached.
 *
 * @param {React.RefObject} ref - The React useRef object attached to the form.
 * @returns {boolean} Returns true if the form is valid, otherwise false.
 */
function verifyFormByRef(ref) {
  // form check
  if (!ref.current) {
    console.warn('Make sure the manufacturerRef is set on the form');
    return false;
  }
  // validation
  ref.current?.reportValidity();
  if (!ref.current?.checkValidity()) return false;
  return true;
}

/**
 * React context provider for FDA product and manufacturer information.
 *
 * This provider component is responsible for managing the state and actions related to FDA product and manufacturer information.
 * It provides the necessary context values and functions to its child components.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components to be wrapped by the provider.
 * @returns {JSX.Element} The FDAProductProvider component.
 */
export const FDAProductProvider = ({ children }) => {
  const { useApi } = useApp();
  const { product_id } = useParams();

  // dialogs
  const [addManufacturerDialogOpen, setAddManufacturerDialogOpen] =
    useState(false);
  // form state
  const [formState, setFormState] = useState({});
  const [productCodes, setProductCodes] = useState([]);
  const [allManufacturers, setAllManufacturers] = useState([]);
  const [manufacturerFormState, setManufacturerFormState] = useState({});
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  // refs
  const productRef = useRef();
  const manufacturerRef = useRef();

  const directToProducts = useCallback(
    () => history.push('/fda-registration/product-registration'),
    [history]
  );

  // GET PRODUCT DATA
  const product = useApi(
    `api/v4/certification_cosmetic_products/${product_id}`,
    { enabled: Boolean(product_id) }
  );
  // HYDRATE AND SET FORM STATE
  useEffect(() => {
    if (!product.data) return;

    const convertedData = convertSnakeToCamelCase(product.data);
    const convertedProductCodes = Object.entries(convertedData.codes).map(
      ([key, value]) => ({
        code: key,
        name: value,
      })
    );
    const productCodesWithNames = convertedData.productCodes?.map((code) =>
      convertedProductCodes.find(
        (productCode) => productCode.code === code.code
      )
    );
    const ingredients = preprocessIngredients(convertedData.ingredients);

    const hydratedData = {
      ...convertedData,
      ingredients,
      productCodes: productCodesWithNames,
    };

    setProductCodes(convertedProductCodes);
    setFormState(hydratedData);
  }, [product.data]);

  // GET MANUFACTURER DATA
  const manufacturers = useApi(`api/v4/certification_cosmetic_manufacturers`);
  // HYDRATE AND SET MANUFACTURER STATE
  useEffect(() => {
    if (!manufacturers.data) return;

    setAllManufacturers(
      convertSnakeToCamelCase(manufacturers.data.cosmetic_manufacturers).map(
        (manufacturer) => ({
          ...manufacturer,
          name: manufacturer.feiNumber
            ? `${manufacturer.name} (FEI ${manufacturer.feiNumber})`
            : manufacturer.name,
        })
      )
    );
    setManufacturerFormState(manufacturerFormDefaultState);
  }, [manufacturers.data]);

  // PRODUCT HANDLERS
  const handleFormStateChange = useCallback((fieldOrPairs, value) => {
    handleStateChange(setFormState, fieldOrPairs, value);
  }, []);

  const handleSave = useCallback(async () => {
    if (
      formState.productName?.includes('&') ||
      formState.productName?.includes('©') ||
      formState.productName?.includes('™')
    ) {
      enqueueSnackbar('FDA does not accept &, © or ™ in the product name', {
        variant: 'error',
      });
      return false;
    }
    // -- preformatting + defaults

    const updateFormState = {
      ...formState,
      productCodes: formState.productCodes.map((code) => code.code),
      is_small_business: formState.isSmallBusiness || false,
      cosmeticManufacturerIds: formState.manufacturers.map(
        (manufacturer) => manufacturer.id
      ),
      ingredients: preprocessIngredients(formState.ingredients),
    };

    try {
      await fetcher(`api/v4/certification_cosmetic_products/${product_id}`, {
        method: 'put',
        body: convertCamelToSnakeCase(updateFormState),
      });
      enqueueSnackbar('SKU updated', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(
        `Potential error - Duplicate SKU names or identifiers forbidden`,
        { variant: 'error' }
      );
      return false;
    }
    return true;
  }, [formState, product_id]);

  // MANUFACTURER HANDLERS
  const handleManufacturerStateChange = useCallback((fieldOrPairs, value) => {
    handleStateChange(setManufacturerFormState, fieldOrPairs, value);
  }, []);

  const handleCreateManufacturer = useCallback(async () => {
    const verified = verifyFormByRef(manufacturerRef);
    if (!verified) return false;

    // -- preformatting + defaults
    const updateFormState = {
      isSmallBusiness: false,
      region: 'N/A',
      ...manufacturerFormState,
    };

    let fetcherResponse;
    try {
      fetcherResponse = await fetcher(
        `api/v4/certification_cosmetic_manufacturers`,
        {
          method: 'post',
          body: convertCamelToSnakeCase(updateFormState),
        }
      );
      enqueueSnackbar('Manufacturer created', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }

    // -- after effects
    setManufacturerFormState(manufacturerFormDefaultState);
    // append the new manufacturer to the form state
    const newManufacturer = fetcherResponse.data;
    handleFormStateChange('manufacturers', [
      ...formState.manufacturers,
      newManufacturer,
    ]);
    return true;
  }, [
    formState.manufacturers,
    handleFormStateChange,
    manufacturers,
    manufacturerFormState,
  ]);

  const context = useMemo(() => {
    return {
      formState: formState || {},
      allManufacturers,
      handleFormStateChange,
      // manufacturer-related
      addManufacturerDialogOpen,
      setAddManufacturerDialogOpen,
      manufacturerFormState,
      handleManufacturerStateChange,
      handleSave,
      handleCreateManufacturer,
      allProductCodes: productCodes,
      productRef,
      manufacturerRef,
      directToProducts,
    };
  }, [
    formState,
    allManufacturers,
    handleFormStateChange,
    addManufacturerDialogOpen,
    setAddManufacturerDialogOpen,
    manufacturerFormState,
    handleManufacturerStateChange,
    handleSave,
    handleCreateManufacturer,
    productCodes,
    directToProducts,
  ]);

  return (
    <FDAProductContext.Provider value={context}>
      <AddManufacturerDialog />
      {children}
    </FDAProductContext.Provider>
  );
};
