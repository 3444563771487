import useQueryParams from 'hooks/useQueryParams';

/*
  parameterKey: string
    - the name of the parameter
  fallbackValue: string | number
    - if there is no initial value for the parameter, the fallback value will be returned.
*/

function useInitialQueryParams(parameterKey, fallbackValue) {
  const initialValue = useQueryParams(parameterKey)[parameterKey].value;
  return initialValue || fallbackValue;
}

export default useInitialQueryParams;
