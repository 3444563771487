import {
  Table,
  TableHead,
  TableRow,
  TableHeadCell,
  TableBody,
  TableBodyCell,
  Button,
  ButtonKind,
  TableFoot,
  PaginationV2,
  NoData,
} from 'design-system/components';
import styles from './reports-table.module.scss';
import { QueryParam } from '../index';

interface Brand {
  name: string;
  id: number;
}

interface PaginationData {
  perPage: number;
  totalEntries: number;
  currentPage: number;
  brands: Brand[];
}

interface ReportsTableProps {
  loading: boolean;
  data: PaginationData;
  pageParam: QueryParam;
  error: string;
  generateReport: (brandId: number) => void;
}

export const ReportsTable = ({
  data,
  loading,
  error,
  pageParam,
  generateReport,
}: ReportsTableProps) => {
  if (!data || loading || error)
    return (
      <NoData
        hasErrorOccurred={!!error}
        errorMessage={error}
        noContentMessage="No brands to display"
        isLoading={loading}
      />
    );

  const brands = data.brands;

  return (
    <div className={styles.table}>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>Brand name</TableHeadCell>
            <TableHeadCell>
              <p className="sr-only">Additional options</p>
            </TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {brands.map((brand, i) => {
            return (
              <TableRow key={i}>
                <TableBodyCell>{brand.name}</TableBodyCell>
                <TableBodyCell>
                  <Button
                    onClick={() => generateReport(brand.id)}
                    kind={ButtonKind.Tertiary}
                  >
                    Export report
                  </Button>
                </TableBodyCell>
              </TableRow>
            );
          })}
        </TableBody>
        <TableFoot bgWhite>
          <TableRow>
            <TableBodyCell colSpan={4}>
              <PaginationV2
                onNextPage={() =>
                  pageParam.push(parseInt(pageParam.value || 1) + 1)
                }
                onPreviousPage={() =>
                  pageParam.push(parseInt(pageParam.value) - 1)
                }
                onPageSelect={pageParam.push}
                currentPage={data.currentPage}
                totalItemCount={data.totalEntries}
                itemsPerPage={data.perPage}
              />
            </TableBodyCell>
          </TableRow>
        </TableFoot>
      </Table>
    </div>
  );
};
