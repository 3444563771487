import { ToastV2Provider } from 'design-system/components';
import { RequirementActionsProvider } from 'views/Brands/shared/RequirementActionModalContents/context';
import PolicyRequirementsView from './view';

const PolicyRequirements = () => {
  return (
    <ToastV2Provider>
      <RequirementActionsProvider>
        <PolicyRequirementsView />
      </RequirementActionsProvider>
    </ToastV2Provider>
  );
};

export default PolicyRequirements;
