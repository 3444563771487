import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Carousel, Modal, ModalType } from 'design-system/components';

const CarouselModal = ({ images, cols, modalImageHeight = '400px' }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const focusedRef = useRef();

  const handleImageClick = (index) => {
    setSelectedIndex(index);
    setModalOpen(true);
  };

  useEffect(() => {
    if (modalOpen) {
      focusedRef.current?.focus();
    }
  }, [modalOpen, focusedRef]);

  return (
    <>
      <Carousel
        images={images}
        cols={cols}
        onImageClick={(idx) => handleImageClick(idx)}
      />
      <Modal
        type={ModalType.Large}
        show={modalOpen}
        onClick={() => setModalOpen(false)}
      >
        <div style={{ height: modalImageHeight }}>
          <Carousel
            ref={focusedRef}
            images={images}
            cols={1}
            selectedIndex={selectedIndex}
          />
        </div>
      </Modal>
    </>
  );
};

CarouselModal.propTypes = {
  images: PropTypes.array,
  cols: PropTypes.number,
  modalImageHeight: PropTypes.string,
};

export default CarouselModal;
