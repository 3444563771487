import cn from 'classnames';

import { oneOf } from 'prop-types';

import styles from './text.module.scss';
import { LogoSize } from '../constants';

function NoviLogoText({ size }) {
  return <span className={cn(styles.root, styles[size])}>novi</span>;
}
NoviLogoText.propTypes = {
  size: oneOf(Object.values(LogoSize)),
};

export default NoviLogoText;
