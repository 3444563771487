import fetcher from 'services/api/fetcher';

export const createProduct = async (
  body: { [key: string]: string },
  productTypeId: string
) => {
  const {
    flavor,
    fragrance,
    type,
    product_category_id,
    retailer_sales_channel,
    ...rest
  } = body;
  const newBody = {
    ...rest,
    product_type_id: productTypeId,
    ...(Boolean(product_category_id) && {
      product_category_id: parseInt(product_category_id),
    }),
    ...(Boolean(flavor) && { flavor: flavor === 'yes' }),
    ...(Boolean(fragrance) && { fragrance: fragrance === 'yes' }),
  };
  const response = await fetcher('/api/product_design/v1/consumer_products', {
    method: 'post',
    body: newBody,
  });

  return response;
};

export const publishProduct = async (
  retailerId: number,
  productId: number,
  skuId?: string,
  productCategoryId?: string,
  productLine?: string,
  retailerSalesChannel?: string[]
) => {
  const body = {
    retailer_id: retailerId,
    ...(Boolean(skuId) && { sku_id: skuId }),
    ...(Boolean(productCategoryId) && {
      product_category_id: parseInt(productCategoryId || ''),
    }),
    ...(Boolean(productLine) && { product_line: productLine }),
    ...(Boolean(retailerSalesChannel?.length) && {
      retailer_sales_channel: retailerSalesChannel?.join(','),
    }),
  };
  const response = await fetcher(
    `/api/product_design/v1/consumer_products/${productId}/publish_to_retailer`,
    {
      body,
      method: 'post',
      enabled: !!(retailerId && productId),
    }
  );

  return response;
};
