import {
  Pill,
  PillColor,
  Text,
  TextKind,
  TextElement,
} from 'design-system/components';
import styles from './policies-content.module.scss';

const BlurRow = () => {
  return (
    <div className={styles['blur-wrapper']}>
      <div className={styles.blur}>
        <Text
          className={styles.title}
          kind={TextKind.Display2XSBold}
          element={TextElement.H2}
        >
          Fake policy name
        </Text>
        <Text kind={TextKind.TextMD} element={TextElement.P}>
          Fake policy info
        </Text>
      </div>
      <div className={styles['overlay-pill']}>
        <Pill color={PillColor.Green}>Coming soon!</Pill>
      </div>
    </div>
  );
};

const ComingSoonBlurb = () => {
  return (
    <div className={styles['coming-soon-wrapper']}>
      <Text
        className={styles.title}
        kind={TextKind.Display2XSBold}
        element={TextElement.H2}
      >
        Non-Retailer Policies
      </Text>
      {[...Array(3)].map((_, i) => (
        <BlurRow key={i} />
      ))}
    </div>
  );
};

export default ComingSoonBlurb;
