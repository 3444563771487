import { AnalyticsBrowser } from '@segment/analytics-next';
import isDevelopmentEnv from 'utils/isDevelopmentEnv';

const enableSegment = !isDevelopmentEnv();
export class SegmentAnalyticsVendor {
  constructor() {
    if (enableSegment) {
      this.analyticsIdentifier = AnalyticsBrowser.load({
        writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY,
      });
    }
  }

  //this method takes a user input to retrieve the userId and userEmail, called on login
  identifyByUser(user) {
    if (enableSegment) {
      this.analyticsIdentifier.identify({
        user_id: user.id,
        traits: {
          name: user.email,
        },
      });
    }
  }
  //this method only requires the email as input (called on sign-up, before the user has an id)
  identifyByEmail(email) {
    if (enableSegment) {
      this.analyticsIdentifier.identify({
        email: email,
      });
    }
  }
}
