import { func, number, shape, string } from 'prop-types';
import {
  Link,
  LinkKind,
  LinkVariant,
  TextElement,
  TextKind,
  Text,
  Pill,
  PillColor,
  PhosphorIcon,
} from 'design-system/components';
import withLineBreaks from 'utils/withLineBreaks';
import styles from '../../retailerBrandRequirementGroupsDetail.module.scss';
import ScreeningStatusItem from '../ScreeningStatusItem';

function RequirementCard({
  title,
  description,
  linkUrl,
  screeningStatusCounts,
}) {
  const { total, incomplete, pass, fail, pending } =
    screeningStatusCounts || {};
  return (
    <div className={styles.card}>
      <div className={styles.content}>
        <div>
          <div className={styles.title}>
            <Text kind={TextKind.Display2XSMedium} element={TextElement.H2}>
              {title}
            </Text>
            {screeningStatusCounts.total && (
              <Pill isRound={false}>
                {/* complete itself not available, but can be computed from total - incomplete */}
                {total - incomplete || 0}/{total} complete
              </Pill>
            )}
          </div>
          <Text kind={TextKind.TextSM} element={TextElement.P}>
            {withLineBreaks(description)}
          </Text>
        </div>
        <Link
          kind={LinkKind.Internal}
          variant={LinkVariant.Primary}
          to={linkUrl}
        >
          Review <PhosphorIcon iconName="ArrowRight" size={20} />
        </Link>
      </div>
      <ul className={styles.statuses}>
        <ScreeningStatusItem
          name="Needs attention"
          count={incomplete}
          color={PillColor.Orange}
        />
        <ScreeningStatusItem
          name="Meets"
          count={pass}
          color={PillColor.Green}
        />
        <ScreeningStatusItem
          name="Does not meet"
          count={fail}
          color={PillColor.Red}
        />
        <ScreeningStatusItem
          name="Pending"
          count={pending}
          color={PillColor.Blue}
        />
        <ScreeningStatusItem
          name="All requirements"
          count={total}
          color={PillColor.Purple}
        />
      </ul>
    </div>
  );
}

RequirementCard.propTypes = {
  title: string.isRequired,
  description: string,
  onClick: func.isRequired,
  screeningStatusCounts: shape({
    complete: number,
    total: number,
    pass: number,
    fail: number,
    needsAttention: number,
    pending: number,
    allRequirements: number,
  }).isRequired,
};

export default RequirementCard;
