/* eslint-disable no-unused-vars */
import React from 'react';
import { ButtonKind } from 'design-system/components';
import { BrandPolicySubmissionStatus } from 'design-system/data';

export enum ReviewType {
  Approve = 'approve',
  Reject = 'reject',
}

export interface TypeInfoType {
  presentLabel: string;
  pastLabel: string;
  submissionStatus: keyof typeof BrandPolicySubmissionStatus;
  Icon: React.ReactNode;
  buttonKind: keyof typeof ButtonKind;
}

export interface ReviewTypeInfoType {
  approve: TypeInfoType;
  reject: TypeInfoType;
}
