import {
  Breadcrumbs,
  Link,
  LinkKind,
  LinkVariant,
  Button,
  ButtonKind,
} from 'design-system/components';
import PageHeader from '../shared/components/PageHeader';
import styles from './view.module.scss';
import BusinessDetailsForm from './components/BusinessDetailsForm';

const ActionsRow = () => {
  return (
    <div className={styles.actions}>
      <Button onClick={() => alert()} kind={ButtonKind.Tertiary}>
        Cancel
      </Button>
      <Button onClick={() => alert(true)} kind={ButtonKind.Primary}>
        Save
      </Button>
    </div>
  );
};

const BusinessView = () => {
  const baseLink = `/fda-registration/business-registration`;
  const breadcrumbsItems = [
    {
      text: 'Brand',
      link: baseLink,
    },
    {
      text: 'Edit business info',
      link: `${baseLink}/edit`,
    },
  ];

  return (
    <main>
      <PageHeader
        title="Edit business info"
        subtitle={
          <>
            Update the information about your business that is required for an
            FDA Cosmetic Listings Registration here.{' '}
            <Link
              kind={LinkKind.External}
              variant={LinkVariant.Default}
              href={'#'}
            >
              Learn more
            </Link>
          </>
        }
        breadcrumbs={<Breadcrumbs items={breadcrumbsItems} />}
        actions={<ActionsRow />}
      />
      <BusinessDetailsForm />
      <div className={styles['actions-bottom-row']}>
        <ActionsRow />
      </div>
    </main>
  );
};

export default BusinessView;
