export default function DollarSign(props) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4243 21.5V19.1761C16.0538 18.7673 17.5 16.9383 17.5 14.9587C17.5 13.0221 16.251 11.4083 13.49 10.8058L11.496 10.3539C10.4661 10.1387 9.98406 9.57927 9.98406 8.91223C9.98406 8.03001 10.751 7.23386 12.1534 7.23386C13.9502 7.23386 14.5199 8.48188 14.6514 9.23499L17.3466 8.48188C17.0398 7.0402 15.9442 5.3188 13.4243 4.86693V2.5H11.1892V4.80238C8.84462 5.16818 7.04781 6.84655 7.04781 9.12741C7.04781 11.1931 8.51594 12.6563 10.8825 13.1727L12.8984 13.6031C13.994 13.8398 14.5418 14.3992 14.5418 15.1308C14.5418 16.0776 13.7092 16.7446 12.2849 16.7446C10.2689 16.7446 9.34861 15.4966 9.21713 14.141L6.5 14.7865C6.69721 16.6155 8.14343 18.7888 11.1892 19.1976V21.5H13.4243Z"
        fill={props.color.value}
      />
    </svg>
  );
}
