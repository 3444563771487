export default function sortBy(...options) {
  const makeSorter =
    ({ order = 'asc', prop }, options) =>
    (a, b) => {
      // decide what values to sort based on
      const [before, after] =
        prop === undefined || prop === null ? [a, b] : [a[prop], b[prop]];
      // decide how to perform the comparison
      let compare = () => 0;
      if (typeof before === 'string') {
        compare = (a, b) => a.localeCompare(b);
      }
      if (typeof before === 'number') {
        compare = (a, b) => a - b;
      }
      // decide what order to place the items in
      let sort = 0; // default to keeping the sort the same
      if (order === 'asc') {
        sort = compare(before, after);
      }
      if (order === 'desc') {
        sort = compare(after, before);
      }
      if (sort === 0 && options.length) {
        const [next, ...remaining] = options;
        return makeSorter(next, remaining)(a, b);
      }
      return sort;
    };

  // optionally sort by multiple properties
  const [first, ...remaining] = options;
  // enable a recursive loop, while allowing default values for a simple sort
  return makeSorter(first || {}, remaining);
}
