import { convertSnakeToCamelCase } from 'design-system/utils';
import useApi from 'services/api/useApi';

const useRequirementGroupDetail = (retailerPolicyRequirementGroupId) => {
  const { data, error, loading } = useApi(
    `/api/v4/retailer_policy_requirement_groups/${retailerPolicyRequirementGroupId}/policy_requirements`
  );
  return {
    data: convertSnakeToCamelCase(data) || [],
    error,
    loading,
  };
};

export default useRequirementGroupDetail;
