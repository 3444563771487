export default function Star({ size, color }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.51868 1.15274L8.30864 4.79157L12.3099 5.37523C12.3607 5.38278 12.4084 5.40436 12.4476 5.43753C12.4868 5.47071 12.5159 5.51417 12.5317 5.56301C12.5475 5.61185 12.5494 5.66414 12.5371 5.71399C12.5248 5.76384 12.4988 5.80926 12.4621 5.84515L9.56731 8.67696L10.2508 12.6764C10.2596 12.7269 10.2541 12.7789 10.2348 12.8265C10.2156 12.8741 10.1834 12.9153 10.1419 12.9455C10.1004 12.9757 10.0513 12.9937 10.0001 12.9974C9.94894 13.0011 9.89773 12.9904 9.85232 12.9665L6.27321 11.0787L2.69411 12.9671C2.64873 12.9911 2.59753 13.0018 2.54633 12.9982C2.49513 12.9945 2.44598 12.9766 2.40448 12.9464C2.36297 12.9162 2.33077 12.8749 2.31153 12.8274C2.29229 12.7798 2.28679 12.7277 2.29564 12.6772L2.97912 8.67696L0.083486 5.84515C0.0467708 5.80926 0.0208041 5.76384 0.00851019 5.71399C-0.00378369 5.66414 -0.00191644 5.61185 0.0139017 5.56301C0.0297198 5.51417 0.0588605 5.47071 0.098042 5.43753C0.137224 5.40436 0.18489 5.38278 0.235672 5.37523L4.23697 4.79157L6.02775 1.15274C6.05009 1.10663 6.08496 1.06774 6.12837 1.04053C6.17178 1.01332 6.22198 0.998886 6.27321 0.998886C6.32445 0.998886 6.37465 1.01332 6.41806 1.04053C6.46147 1.06774 6.49634 1.10663 6.51868 1.15274V1.15274Z"
        fill={color.value}
      />
    </svg>
  );
}
