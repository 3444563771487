import { Icon, IconName } from 'design-system/components';
import { oneOf, node, oneOfType, arrayOf } from 'prop-types';
import { SidebarCtaVariants } from './constants';
import styles from './sidebar-cta.module.scss';

function SidebarCtas({ variant, children, iconName = IconName.ChatColorful }) {
  const className = variant ? `root-${variant}` : 'root';
  return (
    <div className={styles[className]}>
      <Icon name={iconName} size={32} />
      {children}
    </div>
  );
}

SidebarCtas.propTypes = {
  variant: oneOf(Object.values(SidebarCtaVariants)),
  children: oneOfType([node, arrayOf(node)]).isRequired,
  iconName: oneOf(Object.values(IconName)),
};

export default SidebarCtas;
